import React, { Children, FunctionComponent, ReactNode } from 'react'
import cs from 'clsx'

interface HorizontalListProps extends React.HTMLProps<HTMLDivElement> {
  children: ReactNode
  className?: string
  divider?: ReactNode
  margin?: number
  noWrap?: boolean
  inlineFlex?: boolean
}

export const HorizontalList: FunctionComponent<HorizontalListProps> = ({
  children,
  className,
  divider,
  margin = 16,
  noWrap = false,
  inlineFlex = true,
  ...rest
}) => {
  /**
   * use children.toArray to skip null/undefined values and to avoid adding
   * an unnecessary divider after the second to last element when the last child is null/undefined
   */
  const childrenArr = Children.toArray(children).filter((value) => value !== '')
  const count = childrenArr.length

  return (
    <div {...rest} className={cs('horizontal-list', className)}>
      {childrenArr.map((child, idx) => {
        return (
          <React.Fragment key={idx}>
            <span
              style={{
                whiteSpace: noWrap ? 'nowrap' : 'normal',
                display: inlineFlex ? 'inline-flex' : undefined,
                alignItems: inlineFlex ? 'center' : undefined,
              }}
            >
              {child}
            </span>
            {idx !== count - 1 && (
              <span
                className="horizontal-list__item-divider"
                style={{
                  marginLeft: margin,
                  marginRight: margin,
                  whiteSpace: noWrap ? 'nowrap' : 'normal',
                }}
              >
                {divider || '•'}
              </span>
            )}
          </React.Fragment>
        )
      })}
    </div>
  )
}

import { sortBy } from 'lodash'

export const median = (arr: number[]) => {
  if (!arr.length) {
    return 0
  }

  arr = sortBy(arr)

  if (arr.length % 2 === 0) {
    return Number(
      ((arr[arr.length / 2] + arr[arr.length / 2 - 1]) / 2).toFixed(0)
    )
  }

  return Number(arr[(arr.length - 1) / 2].toFixed(0))
}

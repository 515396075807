import { setLocale } from 'yup'

setLocale({
  mixed: {
    required: 'Field is required',
  },
  string: {
    email: 'Please enter a valid email',
  },
  number: {
    max: 'Value must be less than or equal to ${max}',
  },
})

import { Icon, palette } from '@frontend/design-system'
import cs from 'clsx'
import { debounce } from 'lodash'
import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react'
import Select, { components } from 'react-select'
import { ActionMeta, ValueType } from 'react-select/src/types.js'
import { MultiSelectOptionType } from './types'
import CreatableSelect from 'react-select/creatable'

interface MultiSelectDropdownProps {
  customOption?: any
  dropdownClassName?: string
  footer?: ReactNode
  getOptionLabel?(option: MultiSelectOptionType): string
  isLoading?: boolean
  isSearchable?: boolean
  noOptionsMessage?: any
  onChange(
    value: ValueType<MultiSelectOptionType, any>,
    actionMeta?: ActionMeta<MultiSelectOptionType>
  ): void
  onSearch?(input: string, actionMeta?: ActionMeta<MultiSelectOptionType>): void
  onInputChange?(
    input: string,
    actionMeta?: ActionMeta<MultiSelectOptionType>
  ): void
  options: MultiSelectOptionType[]
  placeholder?: string
  selected: MultiSelectOptionType[]
  isOpen: boolean
  isMulti?: boolean
  menuIsOpen?: boolean
  asFilter?: boolean
  isCreatable?: boolean
}

function filterOption(
  option: { data: MultiSelectOptionType },
  searchText: string
) {
  // Coerce into strings so that we don't
  // break if the URL contains NULL values:
  const strLabel = option.data.label ?? ''
  const strValue = option.data.value ?? ''
  return (
    strLabel.toLowerCase().includes(searchText.toLowerCase()) ||
    strValue.toLowerCase().includes(searchText.toLowerCase())
  )
}

const customStyles = {
  menu: (provided) => ({
    ...provided,
    boxShadow:
      '0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1)!important',
    borderTop: 'none',
    borderBottom: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? palette.indigo50
      : state.isFocused
      ? palette.indigo50
      : 'transparent',
    color: state.isSelected ? palette.indigo600 : palette.grayA2,
    // Only include the style if needed.
    // "Not available" for NULL filter is italicized.
    ...(state.value === null && { fontStyle: 'italic' }),
  }),
}

const customTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: palette.indigo400,
    primary75: palette.indigo200,
    primary50: palette.indigo100,
    primary25: palette.indigo50,
    neutral0: palette.grayA1,
    neutral10: palette.gray100,
    neutral20: palette.gray200,
    neutral30: palette.gray300,
    neutral40: palette.gray400,
    neutral50: palette.gray500,
    neutral60: palette.gray600,
    neutral70: palette.gray700,
    neutral80: palette.gray800,
    neutral90: palette.gray900,
    danger: palette.red500,
    dangerLight: palette.red200,
  },
})

const CustomOption = (props) => (
  <components.Option {...props}>
    {props.isSelected ? (
      <Icon name="check-square" className="filter__checkbox fa-lg primary" />
    ) : (
      <Icon name="square-o" className="filter__checkbox fa-lg" />
    )}{' '}
    {props.label}
  </components.Option>
)

export const MultiSelectDropdown: FunctionComponent<
  MultiSelectDropdownProps
> = ({
  customOption,
  dropdownClassName,
  footer = null,
  getOptionLabel,
  isLoading = false,
  isSearchable = true,
  noOptionsMessage,
  onChange,
  onInputChange,
  onSearch,
  options,
  placeholder = 'Search...',
  selected,
  isOpen,
  isMulti,
  menuIsOpen,
  asFilter = true,
  isCreatable = false,
}) => {
  const [inputValue, updateInputValue] = useState('')
  const onSearchRef = useRef<
    ((input: string, actionMeta?: any) => void) | null
  >(null)
  const searchInputRef = useRef<Select<MultiSelectOptionType> | null>()
  useEffect(() => {
    /* istanbul ignore next */
    if (!onSearch) {
      return
    }

    onSearchRef.current = debounce(onSearch, 500)
  }, [onSearch])

  useEffect(() => {
    if (isOpen && isSearchable && !isCreatable) {
      searchInputRef.current?.select?.focus()
    }
  }, [isCreatable, isOpen, isSearchable])

  const CustomMenu = useCallback(
    (props) => (
      <>
        <components.Menu {...props}>{props.children}</components.Menu>
        {Boolean(footer) && <div>{footer}</div>}
      </>
    ),
    [footer]
  )

  const sharedProps = {
    className: cs(dropdownClassName, {
      'multi-select-filter--select': asFilter,
      'multi-select-filter--select--disabled': !isSearchable,
      'multi-select-filter--custom-footer': Boolean(footer),
    }),
    classNamePrefix: 'select',
    components: { Menu: CustomMenu, Option: customOption || CustomOption },
    filterOption,
    getOptionLabel,
    hideSelectedOptions: false,
    isLoading,
    isSearchable,
    menuIsOpen,
    noOptionsMessage,
    options: options || [],
    placeholder,
    selected,
  }

  return isCreatable ? (
    <CreatableSelect<MultiSelectOptionType, true>
      isMulti
      onChange={onChange}
      onInputChange={(input, metaData) => {
        if (metaData.action !== 'input-change') {
          return
        }

        if (onInputChange) {
          onInputChange(input)
        }

        if (onSearchRef.current) {
          onSearchRef.current(input)
        }
      }}
      styles={customStyles}
      theme={customTheme}
      {...sharedProps}
    />
  ) : (
    <Select<MultiSelectOptionType, any>
      controlShouldRenderValue={false}
      inputValue={inputValue}
      isMulti={isMulti}
      onChange={(value, metaData) => {
        // sometimes we need to click without fully disabling (see ProjectTagsFilter)
        // @ts-ignore
        if (metaData.option && metaData.option.disabled) {
          return
        }
        /* istanbul ignore next */
        if (metaData.action === 'pop-value') {
          return
        }

        onChange(value, metaData)
      }}
      onInputChange={(input, metaData) => {
        if (metaData.action !== 'input-change') {
          return
        }

        updateInputValue(input)

        if (onInputChange) {
          onInputChange(input)
        }

        if (onSearchRef.current) {
          onSearchRef.current(input)
        }
      }}
      value={selected}
      ref={(i) => {
        searchInputRef.current = i
      }}
      styles={customStyles}
      theme={customTheme}
      {...sharedProps}
    />
  )
}

import React, { useLayoutEffect } from 'react'
import type { Function1 } from 'lodash'
import { Tab, TabProps } from './Tab'
import useResizeObserver from '@react-hook/resize-observer'
import { Divider } from '../Divider'
import { SingleSelect } from '../Select/Select'
import { useDebouncedCallback } from 'use-debounce'

interface TabBarTabState extends Pick<TabProps, 'icon' | 'title' | 'value'> {
  visible: boolean
}

export type TabBarProps = {
  tabs: Pick<TabProps, 'icon' | 'title' | 'value'>[]
  trailingTabs?: Pick<TabProps, 'icon' | 'title' | 'value'>[]
  onChangeTab?: Function1<TabProps['value'] | null, void>
  renderContent?: (activeTabValue: TabProps['value']) => React.ReactNode
  ['data-pendo']?: string
  ['data-cy']?: string
  minVisibleTabs?: number // when using minVisibleTabs, ensure parent component CSS handles responsive UI
}

function useNodeWidth(nodeRef: React.MutableRefObject<HTMLDivElement | null>) {
  const [nodeWidth, setNodeWidth] = React.useState(-1)
  useResizeObserver(
    nodeRef,
    useDebouncedCallback((entry) => setNodeWidth(entry.contentRect.width), 100)
  )
  return nodeWidth
}

export const TabBar: React.FC<TabBarProps> = (props) => {
  // Active tab management
  const { onChangeTab, renderContent, tabs, minVisibleTabs = 0 } = props
  const dataCy = props['data-cy'] || 'tabbar'
  const [tabsInBar, setTabConfig] = React.useState<{
    tabs: Array<TabBarTabState>
    layoutChecked: boolean
  }>({
    tabs: tabs.map((t) => ({ ...t, visible: false })),
    layoutChecked: false,
  })
  const [activeTab, setActiveTab] = React.useState<TabProps['value'] | null>(
    tabsInBar.tabs[0]?.value || null
  )
  const handleSelectedFromDropdown = (value: TabProps['value']) => {
    const selectedTab = tabsInBar.tabs.find((t) => t.value === value)
    setActiveTab(value)
    setTabConfig({
      tabs: [
        ...(selectedTab ? [{ ...selectedTab, visible: true }] : []),
        ...tabs
          .map((t) => ({ ...t, visible: false }))
          .filter((t) => t.value !== value),
      ],
      layoutChecked: true,
    })
  }
  React.useEffect(() => {
    onChangeTab ? onChangeTab(activeTab) : undefined
  }, [activeTab, onChangeTab])
  const renderedTabContent = React.useMemo(() => {
    return activeTab && renderContent ? renderContent(activeTab) : null
  }, [renderContent, activeTab])

  // Re-size management
  const parentRef = React.useRef<HTMLDivElement | null>(null)
  const tabRefs = React.useRef<{
    [key: string]: HTMLDivElement | null
  }>({})
  const parentWidth = useNodeWidth(parentRef)
  useLayoutEffect(() => {
    if (
      parentRef.current !== null &&
      parentWidth >= 0 &&
      tabsInBar.tabs.every((t) => tabRefs.current[t.value] !== null)
    ) {
      setTabConfig((currentlytabsInBar) => {
        let totalSpace = parentWidth
        let futureVisibleTabBars = currentlytabsInBar.tabs.map((tab) => {
          const tabRef = tabRefs.current[tab.value]
          if (tabRef !== null) {
            totalSpace -= tabRef.getBoundingClientRect().width
            tab.visible = totalSpace > 0
          }
          return tab
        })

        // Re-shuffle visible tabs to prioritize the tab order we started with
        const activeTabInitialIndex = props.tabs.findIndex(
          (t) => t.value === activeTab
        )
        const activeTabVisibleIndex = futureVisibleTabBars.findIndex(
          (t) => t.value === activeTab
        )
        const isInDesignatedSpot =
          activeTabInitialIndex === activeTabVisibleIndex
        const lastVisibleTab =
          futureVisibleTabBars.findIndex((t) => !t.visible) - 1
        if (
          activeTabInitialIndex <= lastVisibleTab &&
          [activeTabInitialIndex, lastVisibleTab].every(
            (tabIndex) => tabIndex >= 0
          ) &&
          !isInDesignatedSpot
        ) {
          const [activeTab, ...restOfTabs] = futureVisibleTabBars
          const beforeActive = restOfTabs.slice(0, lastVisibleTab)
          const afterActive = restOfTabs.slice(
            lastVisibleTab,
            restOfTabs.length
          )
          return {
            tabs: [...beforeActive, activeTab, ...afterActive],
            layoutChecked: true,
          }
        }

        // if minVisibleTabs passed in, update logic accordingly
        const visibleTabs = futureVisibleTabBars.filter(
          ({ visible }) => visible
        )
        const numTabsToMakeVisible = minVisibleTabs - visibleTabs.length

        if (numTabsToMakeVisible > 0) {
          let tabsMadeVisible = 0
          futureVisibleTabBars = futureVisibleTabBars.map((tab) => {
            if (numTabsToMakeVisible > tabsMadeVisible && !tab.visible) {
              tabsMadeVisible += 1
              return { ...tab, visible: true }
            }
            return tab
          })
        }

        return { tabs: futureVisibleTabBars, layoutChecked: true }
      })
    }
  }, [parentWidth, tabRefs.current, tabsInBar.tabs[0].value, minVisibleTabs])
  const tabsHiddenInDropdown = React.useMemo(() => {
    return tabsInBar.tabs.filter((t) => !t.visible)
  }, [tabsInBar])
  return (
    <div>
      <div className="tabbar-component relative flex flex-nowrap bg-white border-solid border-0 border-b border-b-gray-100">
        <div
          ref={parentRef}
          className="grow flex flex-nowrap gap-2 overflow-x-hidden overflow-y-visible"
        >
          {tabsInBar.tabs.map((tab) => (
            <div
              key={`${tab.title}-${tab.value}`}
              ref={(r) => {
                tabRefs.current[tab.value] = r
              }}
              style={{
                visibility: tab.visible ? 'visible' : 'hidden',
                position: 'relative',
              }}
              data-cy={`${dataCy}-${tab.visible ? 'visible' : 'hidden'}`}
            >
              <Tab
                data-pendo={`${props['data-pendo']}-tab-${tab.value}`}
                data-cy={`${dataCy}-${tab.visible ? 'visible' : 'hidden'}-tab-${
                  tab.value
                }`}
                icon={tab.icon}
                title={tab.title}
                value={tab.value}
                selected={activeTab === tab.value}
                onSelect={() => {
                  setActiveTab(tab.value)
                }}
              />
            </div>
          ))}
        </div>
        <div className="grow" />
        <div
          className="w-80 min-w-80 px-4 shrink-0 flex items-center"
          style={{
            visibility:
              tabsInBar.layoutChecked && tabsHiddenInDropdown.length > 0
                ? 'visible'
                : 'hidden',
          }}
          data-pendo={`${props['data-pendo']}-select`}
          data-cy={`${dataCy}-select`}
        >
          <SingleSelect
            isMulti={false}
            className="grow tabbar-dropdown"
            title="More providers"
            placeholder="More providers"
            onChange={(option) => {
              handleSelectedFromDropdown((option as any).value)
            }}
            options={tabsHiddenInDropdown.map((ht) => ({
              icon: ht.icon,
              label: ht.title,
              value: ht.value,
            }))}
          ></SingleSelect>
        </div>
        {(props.trailingTabs || []).length > 0 && (
          <div
            className="flex justify-items-center content-center mx-2"
            style={{
              visibility:
                tabsInBar.layoutChecked && tabsHiddenInDropdown.length > 0
                  ? 'visible'
                  : 'hidden',
            }}
          >
            <Divider />
          </div>
        )}
        {(props.trailingTabs || []).map((tab) => (
          <Tab
            {...tab}
            data-pendo={`${props['data-pendo']}-tab-${tab.value}`}
            data-cy={`${dataCy}-trailing-tab-${tab.value}`}
            key={`${tab.title}-${tab.value}`}
            selected={activeTab === tab.value}
            onSelect={() => {
              setActiveTab(tab.value)
            }}
          />
        ))}
      </div>
      {renderedTabContent}
    </div>
  )
}

import _ from 'lodash'

export const browserNameFormatted = (browserName?: string | null) => {
  if (!browserName || typeof browserName !== 'string') return ''

  // depending on where this is called, the browser name is in various shapes...
  // this is an effort to keep the capitalization of "WebKit" correct
  if (['web kit', 'webkit'].includes(browserName.toLowerCase())) return 'WebKit'

  return browserName.split(' ').map(_.capitalize).join(' ')
}

export const browserVersionFormatted = (browserVersion?: string | null) => {
  if (!browserVersion) return ''

  // looks like: '53.0.2785.143'
  return browserVersion.split('.')[0]
}

import React, { FunctionComponent, ReactNode } from 'react'

import { ScrollableMenuContainer } from '~/common/scrollable-menu-container/ScrollableMenuContainer'

type FiltersContainerProps = {
  children: ReactNode
  extra?: ReactNode
  viewBy?: ReactNode
  viewByHeader?: string
  childSelector?: string
  childExpandedClassName?: string
}

export const FiltersContainer: FunctionComponent<FiltersContainerProps> = ({
  children,
  extra,
  viewBy,
  viewByHeader,
  childSelector,
  childExpandedClassName,
}) => {
  const gridTemplateAreas = viewBy
    ? `'viewByHeader filtersHeader extra' 'viewBy filters filters'`
    : `'filtersHeader extra' 'filters filters'`
  const gridTemplateColumns = viewBy ? 'auto 1fr auto' : '1fr auto'

  return (
    <div
      className="filters-container"
      style={{
        gridTemplateAreas,
        gridTemplateColumns,
      }}
    >
      {viewBy && (
        <div className="filters-container__view-by-header">
          <span>{viewByHeader || 'View by'}</span>
        </div>
      )}
      <div className="filters-container__filters-header">Filter by</div>
      <div className="filters-container__extra">{extra}</div>
      {viewBy && <div className="filters-container__view-by">{viewBy}</div>}
      <ScrollableMenuContainer
        className="filters-container__filters"
        childExpandedClassName={childExpandedClassName}
        childSelector={childSelector}
      >
        {children}
      </ScrollableMenuContainer>
    </div>
  )
}

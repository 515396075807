import { RunStats, StatusIcon, Tooltip } from '@frontend/design-system'
import { isNil, lowerCase } from 'lodash'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { CopyFilePath } from '~/common/copy-file-path/CopyFilePath'
import SpecName from '~/specs/SpecName'
import { SpecBrowser } from '~/specs/stats/SpecBrowser'
import { SpecDuration } from '~/specs/stats/SpecDuration'
import { SpecOs } from '~/specs/stats/SpecOs'

@observer
export default class InstanceTitle extends Component {
  state = {
    shareLinkText: this.props.shareLinkText,
  }

  onShareCopy = () => {
    this.setState({
      shareLinkText: 'Copied!',
    })
  }

  resetShareText = () => {
    setTimeout(() => {
      this.setState({
        shareLinkText: this.props.shareLinkText,
      })
    }, 250)
  }

  render() {
    const {
      modal,
      status,
      id,
      spec,
      platform,
      browserName,
      browserVersion,
      completedAt,
      duration,
      wallClockDuration,
      claimedAt,
      osName: osNameProp,
      totalPassed,
      totalFailed,
      osVersion: osVersionProp,
      totalPending,
      totalSkipped,
      shareLinkUrl,
    } = this.props

    const formattedStatus = lowerCase(status)

    const osName = platform ? platform.osName : osNameProp
    const osVersion = platform
      ? platform.osVersionFormatted || platform.osFormatted
      : osVersionProp

    const shortPath = spec?.shortPath

    return (
      <div key={id} className="instance-title">
        <div className="instance-left-title">
          <div className="the-title">
            {modal ? (
              <span className={`status ${formattedStatus}`}>
                <span className="status-text">
                  <StatusIcon status={formattedStatus} />{' '}
                </span>
                <CopyFilePath filePath={shortPath} />
                <SpecName spec={shortPath} />
                {this._groupName()}
              </span>
            ) : (
              <>
                <a
                  href="#"
                  onClick={this.props.handleToggle}
                  className="spec-expand"
                >
                  <i
                    className={`fa fa-caret-${
                      this.props.specOpen ? 'down' : 'right'
                    } fa-fw`}
                  />
                </a>
                <CopyFilePath filePath={shortPath} />
                <SpecName spec={shortPath} />
                {this._groupName()}
              </>
            )}
            {shareLinkUrl && (
              <Tooltip
                placement="top"
                overlay={<span>{this.state.shareLinkText}</span>}
              >
                <CopyToClipboard onCopy={this.onShareCopy} text={shareLinkUrl}>
                  <span
                    onMouseOut={this.resetShareText}
                    className="copy-share-link"
                  >
                    <i className="fa fa-share"></i>
                  </span>
                </CopyToClipboard>
              </Tooltip>
            )}
          </div>
        </div>
        <div className="env-data">
          <div className="env-browser">
            <SpecBrowser
              browserName={browserName}
              browserVersion={browserVersion}
            />
            <SpecOs osName={osName} osVersion={osVersion} />
          </div>
          <div className="env-duration">
            <SpecDuration
              isComplete={!isNil(completedAt)}
              duration={wallClockDuration || duration}
              claimedAt={claimedAt}
            />
          </div>
          {modal && (
            <div>
              <RunStats
                className={formattedStatus}
                passed={totalPassed}
                failed={totalFailed}
                pending={totalPending}
                skipped={totalSkipped}
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  _groupName = () =>
    this.props.groupName ? (
      <span className="label label-default">
        <i className="fa fa-cube" />
        {this.props.groupName}
      </span>
    ) : null
}

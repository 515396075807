import { IconButton, Modal } from '@frontend/design-system'
import React, { FunctionComponent, useEffect } from 'react'
import { useVideoLightboxLazyQuery } from '~/graphql-codegen-operations.gen'

import InstanceTitle from '~/run/InstanceTitle'
import Video from './video'
import { getShareUrl } from '../output/util'
import { IconActionDeleteXlarge } from '@cypress-design/react-icon'

type VideoType = {
  id?: string
  url?: string | null
  videoUrl?: string | null
}

type VideoLightboxProps = {
  instanceId: string
  isOpen?: boolean
  onClose?: () => void
  onEntered?: () => void
  videoTimestamp?: number | null
  videos: VideoType[]
}

export const VideoLightbox: FunctionComponent<VideoLightboxProps> = ({
  instanceId,
  isOpen = false,
  onClose = () => {},
  onEntered,
  videoTimestamp,
  videos,
}) => {
  const [fetch, { data, loading }] = useVideoLightboxLazyQuery({
    variables: {
      instanceId,
    },
  })

  useEffect(() => {
    if (isOpen) {
      fetch()
    }
  }, [isOpen, fetch])

  const instance = data?.instance

  return (
    <Modal
      key={403}
      className="modal-fullscreen"
      show={isOpen}
      onHide={onClose}
      onEntered={onEntered}
    >
      <Modal.Body>
        <IconButton
          IconComponent={IconActionDeleteXlarge}
          aria-label="Close"
          className="close"
          onClick={onClose}
        />

        {isOpen &&
          videos.map((video, i) => {
            return (
              <div key={(video.id || '0') + i}>
                {loading || !instance ? (
                  <div />
                ) : (
                  <InstanceTitle
                    {...instance}
                    totalPassed={instance.totalPassed}
                    totalFailed={instance.totalFailed}
                    totalPending={instance.totalPending}
                    totalSkipped={instance.totalSkipped}
                    spec={instance.spec}
                    browserName={instance.browser.name}
                    browserVersion={instance.browser.version}
                    osName={instance.os.name}
                    osVersion={instance.os.version}
                    shareLinkText={'Copy video URL'}
                    shareLinkUrl={getShareUrl('Video')}
                    video={video}
                    modal
                  />
                )}
                <Video {...video} startTime={videoTimestamp} />
              </div>
            )
          })}
      </Modal.Body>
    </Modal>
  )
}

import React from 'react'
import Button from '@cypress-design/react-button'
import styles from './module.GroupList.scss'

import { Alert } from './Alert'
import { Link } from '@reach/router'

type ViewLatestRunsProps = {
  isUsingCI: boolean
  hasRecordedRun: boolean
  recordedRunsInCiCount: number
  projectId: string
}

export const ViewLatestRuns: React.FunctionComponent<ViewLatestRunsProps> = ({
  isUsingCI,
  hasRecordedRun,
  recordedRunsInCiCount,
  projectId,
}) => {
  if (!isUsingCI && hasRecordedRun) {
    return <Alert isCiAlert />
  }

  return (
    <div>
      <div className={styles.latestRuns}>
        <span>{recordedRunsInCiCount} out of 10 runs recorded</span>
      </div>
      <div className={styles.actionButtons}>
        <Link to={`/projects/${projectId}/runs`}>
          <Button>View latest run</Button>
        </Link>
      </div>
    </div>
  )
}

import React from 'react'
import Button from '@cypress-design/react-button'
import styles from './module.GroupList.scss'
import { SkipButton } from './SkipButton'

type ViewParallelizationProps = {
  orgId: string
  field: string
  isStepChecked: boolean
}

export const ViewParallelization: React.FunctionComponent<
  ViewParallelizationProps
> = ({ orgId, field, isStepChecked }) => {
  return (
    <div className={styles.actionButtons}>
      <a href="https://on.cypress.io/parallelization" target="_blank">
        <Button>Parallelization</Button>
      </a>
      <SkipButton orgId={orgId} field={field} isStepChecked={isStepChecked} />
    </div>
  )
}

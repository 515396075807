import { HorizontalList } from '@frontend/design-system'
import React, { FunctionComponent } from 'react'
import { Link } from '@reach/router'

import moment from 'moment'

import { CommitHorizontalListFragment } from '~/graphql-codegen-operations.gen'
import { CommitAuthor } from '~/run/commit/CommitAuthor'
import { CommitSha } from '~/run/commit/CommitSha'
import { stringify } from 'querystring'

type CommitHorizontalListProps = {
  projectId: string
  run: CommitHorizontalListFragment
  testResultId?: string
  linkTo?: 'flakiness' | 'failure'
}

export const CommitHorizontalList: FunctionComponent<
  CommitHorizontalListProps
> = ({ projectId, run, testResultId, linkTo }) => {
  let link = `/projects/${projectId}/runs/${run.buildNumber}`

  if (testResultId && linkTo === 'flakiness') {
    const flakyQueryString = stringify({
      isFlaky: JSON.stringify([{ value: true, label: 'Flaky' }]),
    })

    link = `${link}/test-results/${testResultId}?${flakyQueryString}`
  } else if (testResultId && linkTo === 'failure') {
    const failedStatusQueryString = stringify({
      statuses: JSON.stringify([{ value: 'FAILED', label: 'FAILED' }]),
    })

    link = `${link}/test-results/${testResultId}?${failedStatusQueryString}`
  }

  return (
    <HorizontalList margin={8} noWrap>
      <CommitAuthor
        authorName={run.commit.authorName}
        authorAvatar={run.commit.authorAvatar}
      />
      <CommitSha sha={run.commit.sha} url={run.commit.url} />
      <span>
        Run <Link to={link}>#{run.buildNumber}</Link>
      </span>
      {moment(run.createdAt).fromNow()}
    </HorizontalList>
  )
}

import {
  Button,
  CustomIcon,
  EmptyState,
  Panel,
  Icon,
  OrgLogo,
  Tag,
  Tooltip,
  IconButton,
} from '@frontend/design-system'
import cs from 'clsx'
import commaNumber from 'comma-number'
import { take } from 'lodash'
import React, { FunctionComponent, useState } from 'react'
import { BrowserIcon } from '~/common/icon'
import { osIcon } from '~/lib/utils'
import { readableViewByEnum } from '~/lib/utils-ts'
import { LoadingContainer } from '~/project-analytics/LoadingContainer'

import styles from '../module.AnalyticsTable.scss'

import {
  ProjectTopFailuresQuery,
  ViewByEnum,
} from '~/graphql-codegen-operations.gen'
import { AnalyticsProgressBar } from '~/project-analytics/AnalyticsProgressBar'
import { SpecFile } from '~/specs/stats/SpecFile'
import { TestResultTitleProvider } from '~/test-results/hooks/useTitleTruncation'
import { TestTitle } from '~/test-results/TestTitle'
import { AnalyticRowLink } from '../AnalyticRowLink/AnalyticRowLink'

import { LastRun } from '../LastRun/LastRun'
import { IconChevronRightLarge } from '@cypress-design/react-icon'

type TopFailuresTableProps = {
  data?: ProjectTopFailuresQuery
  activeDrawerId?: string
  isLoading: boolean
  viewBy: ViewByEnum
}

interface ViewByRowProps {
  row: ProjectTopFailuresQuery['metrics']['projectTopFailingTestsOverTime']['nodes'][0]
  viewBy: ViewByEnum
}

const ViewByRow: FunctionComponent<ViewByRowProps> = ({ row, viewBy }) => {
  if (viewBy === 'BROWSER') {
    return (
      <div className={styles.viewByRow}>
        <BrowserIcon
          alt=""
          className="fa-fw has-label"
          name={row.browser.unformattedName}
        />
        {row.browser.formattedNameWithVersion}
      </div>
    )
  }

  if (viewBy === 'BRANCH') {
    return <span>{row.branch}</span>
  }

  if (viewBy === 'CYPRESS_VERSION') {
    return (
      <div className={styles.viewByRow}>
        <div className={styles.viewByRowLogo}>
          <OrgLogo
            logo="/img/cypress-logo-round.svg"
            org={{ name: 'Cypress' }}
          />
        </div>
        {`v${row.cypressVersion}`}
      </div>
    )
  }

  if (viewBy === 'OS') {
    return (
      <div className={styles.viewByRow}>
        <Icon className="fa-fw" name={osIcon(row.os.unformattedName)} />
        {row.os.nameWithVersion}
      </div>
    )
  }

  if (viewBy === 'SPEC') {
    return (
      <div className={styles.viewByRow}>
        <SpecFile specPath={row.specPath} isFlaky={row.isFlaky} />
      </div>
    )
  }

  if (viewBy === 'TAG' && row.tag) {
    return (
      <div className={styles.viewByRow}>
        <Tag backgroundColor={row.tag.color}>{row.tag.name}</Tag>
      </div>
    )
  }

  return (
    <>
      <TestTitle
        id={row.id}
        titleParts={row.titleParts || []}
        isFlaky={row.isFlaky}
      />
      <SpecFile specPath={row.specPath} />
    </>
  )
}

export const TopFailuresTable: FunctionComponent<TopFailuresTableProps> = ({
  data,
  activeDrawerId,
  viewBy,
  isLoading,
}) => {
  const PER_PAGE = 30
  const [numberOfTestsShown, setNumberOfTestsShown] = useState(PER_PAGE)

  if (!data || !data.metrics) {
    return (
      <Panel>
        <EmptyState>
          <span>Loading...</span>
        </EmptyState>
      </Panel>
    )
  }

  const loadMoreTests = () => {
    setNumberOfTestsShown(numberOfTestsShown + PER_PAGE)
  }

  const metrics = data.metrics.projectTopFailingTestsOverTime
  const rows = take(metrics.nodes, numberOfTestsShown) || []
  const rowHasDrillIn = viewBy === 'TEST_CASE'
  const gridTemplateColumns = rowHasDrillIn
    ? 'auto 1fr auto auto auto auto auto'
    : 'auto 1fr auto auto auto auto'

  return (
    <LoadingContainer active={isLoading}>
      <Panel data-cy="top-failures-table-container">
        {metrics.nodes.length < 1 ? (
          <EmptyState>
            <span>No data for this time range.</span>
          </EmptyState>
        ) : (
          <div
            className={cs(styles.analyticsTable, styles.topFailuresTable)}
            data-cy="top-failures-table"
            style={{ gridTemplateColumns }}
          >
            <AnalyticRowLink dataCy="top-failures-table__header">
              <h6 />
              <h6>{readableViewByEnum(viewBy)}</h6>
              <h6 />
              <h6>Last failure</h6>
              <h6>
                <span className={styles.textRight}>Failed runs</span>
              </h6>
              <h6>
                <span className={styles.textRight}>Failure rate</span>
              </h6>
              {rowHasDrillIn && <h6 />}
            </AnalyticRowLink>

            <TestResultTitleProvider>
              {rows.map((row, i) => {
                return (
                  <AnalyticRowLink
                    isEnabled={rowHasDrillIn}
                    to={`/projects/${data.project.id}/analytics/top-failures/${row.id}`}
                    isActive={activeDrawerId === row.id}
                    dataCy="top-failures-table__row"
                    key={row.id}
                  >
                    <div>
                      <span className={styles.textRight}>{i + 1}</span>
                    </div>
                    <div>
                      <div className={styles.viewByRowContainer}>
                        <ViewByRow row={row} viewBy={viewBy} />
                      </div>
                    </div>
                    <div className={styles.mutedTestContainer}>
                      {Boolean(row.activeMuteOrchestration) && (
                        <Tooltip
                          placement="top"
                          overlay="Cypress will ignore the status of this test because it is muted."
                        >
                          <CustomIcon name="mute-test" />
                        </Tooltip>
                      )}
                    </div>
                    <div>
                      <LastRun lastRun={row.lastFailureRun} />
                    </div>
                    <div>
                      <span className={styles.textRight}>
                        <span className={styles.title}>
                          {commaNumber(row.totalFailedResults)}/
                          {commaNumber(row.totalResults)}
                        </span>
                      </span>
                    </div>
                    <div>
                      <AnalyticsProgressBar rate={row.failureRate} />
                    </div>
                    {rowHasDrillIn && (
                      <div className={styles.chevron} data-cy="icon-chevron">
                        <IconButton
                          IconComponent={IconChevronRightLarge}
                          aria-label="Open test results"
                        />
                      </div>
                    )}
                  </AnalyticRowLink>
                )
              })}
              {metrics.nodes.length > numberOfTestsShown && (
                <div
                  className={styles.footer}
                  style={{ gridColumn: rowHasDrillIn ? '1 / 7' : '1 / 6' }}
                >
                  <Button bsStyle="link" onClick={loadMoreTests}>
                    Load more
                  </Button>
                </div>
              )}
            </TestResultTitleProvider>
          </div>
        )}
      </Panel>
    </LoadingContainer>
  )
}

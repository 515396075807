import { Checkbox, CustomIcon, FilterEmptyState } from '@frontend/design-system'
import React, { FunctionComponent, useState } from 'react'

import { useProjectBranchesQuery } from '~/graphql-codegen-operations.gen'
import { MultiSelectOptionType } from '~/common/filters'
import { FormSelect } from './FormSelect/FormSelect'

import styles from './module.ProjectBranchSelect.scss'

type ProjectBranchSelectProps = {
  projectId: string
  selected?: string | null
  onChange: (value: string) => void
  defaultAnalytics: boolean
  onToggleDefaultAnalytics: (value: boolean) => void
}

export const ProjectBranchSelect: FunctionComponent<ProjectBranchSelectProps> =
  React.memo(
    ({
      onChange,
      onToggleDefaultAnalytics,
      projectId,
      selected,
      defaultAnalytics,
    }) => {
      const [branchesSearch, setBranchesSearch] = useState('')
      const [isLoading, updateIsLoading] = useState(false)

      const { data } = useProjectBranchesQuery({
        variables: {
          id: projectId,
          branchesSearch,
        },
        onCompleted() {
          updateIsLoading(false)
        },
      })

      const branchOptions: MultiSelectOptionType[] = data
        ? data.project.branches.nodes.map((branch) => {
            return {
              label: branch.name,
              suggested: false,
              value: branch.name,
            }
          })
        : []

      const NoOptionsMessage: FunctionComponent = () => (
        <FilterEmptyState
          filter="branch"
          icon={<CustomIcon name="branch" />}
          info="Provide git information to Cypress to select a branch"
          searchQuery={branchesSearch}
          url="http://on.cypress.io/git-info"
        />
      )

      return (
        <div data-cy="project-branch-select">
          <div className={styles.input}>
            <FormSelect
              title={<CustomIcon name="branch" alt="Branch" />}
              placeholder="Search for a branch..."
              isLoading={isLoading}
              formatCreateLabel={(branch) => (
                <span>
                  Use <b>"{branch}"</b> even though I haven't recorded any runs
                  to it
                </span>
              )}
              Icon={() => <CustomIcon name="branch" />}
              noOptionsMessage={NoOptionsMessage}
              onChange={onChange}
              onInputChange={(value) => {
                if (!value && branchOptions.length) {
                  return
                }

                updateIsLoading(true)
              }}
              onSearch={setBranchesSearch}
              options={branchOptions}
              selected={{ label: selected || '', value: selected }}
            />
          </div>
          <Checkbox
            isDisabled={!Boolean(selected)}
            isChecked={Boolean(selected) && defaultAnalytics}
            onChange={() => {
              onToggleDefaultAnalytics(!defaultAnalytics)
            }}
            label="Set this branch as default Analytics filter too"
          />
        </div>
      )
    }
  )

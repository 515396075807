import { debounce, isArray, first, isString } from 'lodash'
import React, {
  FunctionComponent,
  ReactNode,
  ReactElement,
  useEffect,
  useCallback,
  useRef,
  useState,
} from 'react'
import { components } from 'react-select'
import Creatable from 'react-select/creatable'
import { MultiSelectOptionType } from '~/common/filters'

import styles from './module.FormSelect.scss'

interface FormSelectProps {
  isLoading?: boolean
  noOptionsMessage?: any
  defaultSearchValue?: string
  onChange(value: string): void
  onSearch?(input: string): void
  onInputChange?(input: string): void
  options: MultiSelectOptionType[]
  placeholder?: string
  selected: MultiSelectOptionType
  title: ReactNode
  formatCreateLabel?: (value: string) => ReactNode
  Icon?: () => ReactElement
}

function filterOption(
  option: { data: MultiSelectOptionType },
  searchText?: string
) {
  if (!isString(option.data.label)) {
    return true
  }

  return option.data.label
    .toLowerCase()
    .includes((searchText || '').toLowerCase())
}

const CustomOption = (props) => {
  return (
    <div data-cy="form-select-option">
      <components.Option {...props}>{props.label}</components.Option>
    </div>
  )
}

export const FormSelect: FunctionComponent<FormSelectProps> = ({
  isLoading = false,
  noOptionsMessage,
  onChange,
  onInputChange,
  onSearch,
  formatCreateLabel,
  options,
  defaultSearchValue = '',
  placeholder = 'Search...',
  selected,
  Icon = () => null,
}) => {
  const [inputValue, updateInputValue] = useState(selected.label ?? undefined) // pass `undefined` when `null`
  const onSearchRef = useRef<
    ((input: string, actionMeta?: any) => void) | null
  >(null)

  useEffect(() => {
    /* istanbul ignore next */
    if (!onSearch) {
      return
    }

    onSearchRef.current = debounce(onSearch, 500)
  }, [onSearch])

  const CustomValueContainer = useCallback(
    (props) => (
      <div className={styles.valueContainer}>
        <Icon />
        <components.ValueContainer {...props}>
          {props.children}
        </components.ValueContainer>
      </div>
    ),
    []
  )

  return (
    <Creatable<MultiSelectOptionType>
      components={{
        ValueContainer: CustomValueContainer,
        Option: CustomOption,
        IndicatorSeparator: null,
      }}
      controlShouldRenderValue={false}
      filterOption={inputValue === selected.label ? null : filterOption}
      hideSelectedOptions={false}
      inputValue={inputValue}
      isLoading={isLoading}
      isSearchable
      formatCreateLabel={formatCreateLabel}
      noOptionsMessage={noOptionsMessage}
      onCreateOption={(value) => {
        onChange(value)
        updateInputValue(value)

        if (onSearchRef.current) {
          onSearchRef.current(defaultSearchValue)
        }
      }}
      onChange={(value) => {
        const selectedValue = isArray(value) ? first(value) : value

        onChange(selectedValue.value)
        updateInputValue(selectedValue.label)

        if (onSearchRef.current) {
          onSearchRef.current(defaultSearchValue)
        }
      }}
      onInputChange={(input, metaData) => {
        if (metaData.action === 'input-blur' && inputValue !== selected.value) {
          updateInputValue(selected.value)

          if (onSearchRef.current) {
            onSearchRef.current(defaultSearchValue)
          }

          return
        }

        if (metaData.action !== 'input-change') {
          return
        }

        updateInputValue(input)

        if (onInputChange) {
          onInputChange(input)
        }

        if (onSearchRef.current) {
          onSearchRef.current(input)
        }
      }}
      options={options || []}
      placeholder={placeholder}
      selected={selected}
      value={selected}
    />
  )
}

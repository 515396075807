import cn from 'clsx'
import { FieldProps } from 'formik'
import * as React from 'react'

export interface InputFieldProps
  extends FieldProps,
    Omit<React.HTMLProps<HTMLInputElement>, 'form'> {
  id?: string
  label?: string
  optional?: boolean
  suppressError?: boolean
}

export const InputField: React.FC<InputFieldProps> = ({
  id,
  form,
  label,
  children,
  className,
  field,
  optional,
  suppressError,
  ...props
}) => {
  const error = form.errors[field.name]
  const toShowError =
    !suppressError && Boolean(form.touched[field.name] && error)

  return (
    <div className={cn('form-group', className, { 'has-error': toShowError })}>
      <input
        id={id}
        type="text"
        className="form-control"
        {...field}
        value={field.value || ''}
        {...props}
      />

      <span className={cn('help-block', 'bg-danger', { hide: !toShowError })}>
        {error}
      </span>
    </div>
  )
}

import React, { FunctionComponent } from 'react'
import { DrawerJiraIssue } from '~/drawer-jira-issue/DrawerJiraIssue'
import { useDrawerTestResultAnalyticJiraIssueQuery } from '~/graphql-codegen-operations.gen'

interface DrillInJiraIssueProps {
  titleHash: string | null
  specHash: string | null
  projectId: string
}

export const DrillInJiraIssue: FunctionComponent<DrillInJiraIssueProps> = ({
  specHash,
  titleHash,
  projectId,
}) => {
  const { data } = useDrawerTestResultAnalyticJiraIssueQuery({
    skip: !specHash || !titleHash,
    variables: {
      projectId,
      specHash: specHash!,
      titleHash: titleHash!,
    },
  })

  const jiraIssues = data?.testResultAnalytic?.jiraIssues || []

  return <DrawerJiraIssue jiraIssues={jiraIssues} />
}

import { IconOsWindows } from '@cypress-design/react-icon'
import { Icon, Tooltip } from '@frontend/design-system'
import { osNameFormatted } from '@packages/common'
import { lowerCase } from 'lodash'
import React, { FunctionComponent } from 'react'

import { osIcon } from '~/lib/utils'

type SpecOsProps = {
  osName?: string | null
  osVersion?: string | null
  isTooltip?: boolean
}

export const SpecOs: FunctionComponent<SpecOsProps> = ({
  osName,
  osVersion,
  isTooltip = true,
}) => {
  /* istanbul ignore next */
  if (!osName) {
    return null
  }

  const formattedOSName = osNameFormatted(osName)
  const formattedOsVersion = osVersion || ''
  const iconName = osIcon(lowerCase(osName))

  let icon = <Icon name={iconName} />
  switch (osName) {
    case 'windows':
      icon = <IconOsWindows />
      break
    // TODO: for some mysterious reason the IconOsApple, IconOsLinux, IconOsGeneric design system icons create an overflow scroll on the page
    // To reproduce, use any of these icons and go to the Properties tab. The page scrolls
    // case 'mac':
    // case 'apple':
    //   icon = <IconOsApple />
    //   break
    // case 'linux':
    //   icon = <IconOsLinux />
    //   break
    default:
      break
  }

  const style = {
    display: 'inline-flex',
    alignItems: 'center',
    gap: '4px',
  }

  if (!isTooltip) {
    return (
      <span style={style} className="spec-os">
        {icon}
        {`${formattedOSName} ${formattedOsVersion}`}
      </span>
    )
  }

  return (
    <span style={style} className="spec-os">
      <Tooltip placement="top" overlay={<span>{formattedOSName}</span>}>
        {icon}
      </Tooltip>
      {formattedOsVersion}
    </span>
  )
}

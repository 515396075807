import { LocationProvider, Redirect, Router } from '@reach/router'
import React from 'react'
import queryString from 'query-string'

import { App } from '~/app'
import auth from '~/auth/auth-service'
import currentUser from '~/auth/current-user-model'
import { chunks } from '~/chunks'
import OrganizationContainer from '~/containers/OrganizationContainer'
import ProjectContainer from '~/containers/ProjectContainer'
import { history } from '~/lib/history'
import { saveCurrentRoute } from '~/lib/navigate'
import { Pendo } from '~/lib/pendo'
import {
  LAST_ORG_ID_KEY,
  LAST_ORG_ID_VISITOR,
  SHOW_ONBOARDING_CHECKLIST_NEXT,
} from '~/navs/constants'
import { FullStory } from '~/lib/fullstory'
import { PendoProvider } from '~/pendo/PendoProvider'
import { storeUtmParamsOnCookie } from './lib/utils'
import { maybeNotifyTestRunnerAuth } from './common/notify-test-runner/notifyTestRunner'

declare global {
  interface Window {
    cypressRouter: any
  }
}

window.cypressRouter = history

const needsHashRedirect = Boolean(
  // "//", weird, due to https://github.com/cypress-io/cypress-services/issues/480
  (location.pathname === '/' || location.pathname === '//') && location.hash
)

if (needsHashRedirect) {
  history.navigate(location.hash.replace(/^\/?#/, ''), { replace: true })
}

const publicRoutes = [
  /login/,
  /signup/,
  /reset-password/,
  /terms-of-use/,
  /access_token=/,
  /^\/projects\/[^/]+/,
]

const privateRoutes = [/^\/projects\/[^/]+\/settings/]

function requireAuth(location: Location) {
  if (
    !auth.loggedIn() &&
    (privateRoutes.some((route) => route.test(location.pathname)) ||
      !publicRoutes.some((route) => route.test(location.pathname)))
  ) {
    const { setupBilling, newUser } = queryString.parse(location.search)

    saveCurrentRoute(location)

    const routeToVisit =
      setupBilling || newUser === 'true' ? '/signup' : '/login'
    history.navigate(routeToVisit, { replace: true })
  }
}

function initializeTracking() {
  // When the user is not logged in, Pendo should not have a visitor ID for the current/anonymous user. Ideally, we wouldn't even load pendo.
  if (auth.loggedIn()) {
    Pendo.init()
    FullStory.init()
  }
}

storeUtmParamsOnCookie(queryString.parse(location.search))

// initial mount
requireAuth(history.location)
currentUser.logPageView(location)
initializeTracking()

history.listen(({ location }) => requireAuth(location))
history.listen(({ location }) => currentUser.logPageView(location))

export const Routes = () => {
  maybeNotifyTestRunnerAuth()

  const currentOrganizationId = window.localStorage.getItem(LAST_ORG_ID_KEY)
  const lastOrganizationIdVisitor =
    window.localStorage.getItem(LAST_ORG_ID_VISITOR)

  let indexPath = '/organizations'
  if (currentOrganizationId && lastOrganizationIdVisitor === currentUser.id) {
    indexPath = `/organizations/${currentOrganizationId}/projects`

    // Show the Onboarding Checklist Next
    localStorage.setItem(SHOW_ONBOARDING_CHECKLIST_NEXT, String(true))
  }

  return (
    <LocationProvider>
      <PendoProvider>
        <Router primary={false}>
          <chunks.Login path="/login/:provider" />
          <chunks.Login path="/login" />
          <chunks.Signup path="/signup/:provider" />
          <chunks.Signup path="/signup" />
          <chunks.Welcome path="/welcome" />
          <chunks.Welcome path="/profile/refresh" />
          <chunks.TermsOfUse path="/terms-of-use" />
          <chunks.Pricing path="/organizations/:orgId/pricing" />
          <App path="/">
            <chunks.OrgsList path="/organizations" />
            <chunks.CreateOrg path="/organizations/new" />
            <chunks.SlackIntegrationOrganizationSelection path="/integrations/slack/organization-selection" />
            <chunks.JiraIntegrationOrganizationSelection path="/integrations/jira/organization-selection" />
            <Redirect from="/" to={indexPath} noThrow />
            <Redirect
              from="/projects/:projectId"
              to="/projects/:projectId/runs"
              noThrow
            />
            <Redirect
              from="/projects/:projectId/analytics"
              to="/projects/:projectId/analytics/runs-over-time"
              noThrow
            />
            <Redirect
              from="/projects/:projectId/runs/:runId"
              to={`/projects/:projectId/runs/:runId/overview`}
              noThrow
            />
            <Redirect
              from="/projects/:projectId/runs/:runId/failures"
              to="/projects/:projectId/runs/:runId/test-results"
              noThrow
            />
            <Redirect
              from="/projects/:projectId/runs/:runId/failures/:testId"
              to="/projects/:projectId/runs/:runId/test-results/:testId"
              noThrow
            />
            <Redirect
              from="/projects/:projectId/runs/:runId/failures/:testId/:media"
              to="/projects/:projectId/runs/:runId/test-results/:testId/:media"
              noThrow
            />
            <Redirect
              from="/organizations/:id"
              to="/organizations/:id/projects"
              noThrow
            />
            <Redirect from="/access_token=:token" to="/login" noThrow />
            {/* TODO remove in CYCLOUD-1183 temporary rerouting from ms-teams to teams*/}
            <Redirect
              from="/organizations/:id/integrations/ms-teams"
              to="/organizations/:id/integrations/teams"
              noThrow
            />
            <OrganizationContainer path="/organizations/:id/*" />

            <ProjectContainer path="/projects/:projectId/*" />

            <chunks.Profile path="/profile" />
            <chunks.MembershipsInvite path="/memberships/:invitationId/accept" />
            <chunks.ResetPassword path="/reset-password" />
            <chunks.ResetPassword path="/reset-password/:token" />
            <chunks.Verify path="/verify" />
            <chunks.NotFound default />
          </App>
        </Router>
      </PendoProvider>
    </LocationProvider>
  )
}

export default Routes

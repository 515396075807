import { reduce } from 'lodash'
import React, {
  createContext,
  Dispatch,
  MutableRefObject,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react'
import { useDebounce } from 'use-debounce'
import useResizeObserver from 'use-resize-observer'

interface TitleRow {
  widths: {
    sum: number
    partWidths: number[]
  }
  formattedParts: string[]
}

interface UpdateAction {
  type: 'UPDATE_TITLE_ROW'
  payload: { id: string; titleRow: TitleRow }
}

interface TitlePartsLookup {
  [id: string]: TitleRow
}

interface TitlePartsStore {
  state: TitlePartsLookup
  dispatch: Dispatch<UpdateAction>
}

export function createTestResultTitleContext(): [
  React.FunctionComponent,
  () => TitlePartsStore
] {
  const Context = createContext<TitlePartsStore>({
    state: {},
    /* istanbul ignore next */
    dispatch: () => {},
  })

  const TestResultTitleProvider: React.FunctionComponent = (props) => {
    const reducer = (state: TitlePartsLookup, action: UpdateAction) => {
      switch (action.type) {
        case 'UPDATE_TITLE_ROW':
          return {
            ...state,
            [action.payload.id]: action.payload.titleRow,
          }
        /* istanbul ignore next */
        default:
          return {}
      }
    }

    const [state, dispatch] = useReducer(reducer, {})
    const value = { state, dispatch }
    return <Context.Provider value={value} {...props} />
  }

  const useTestResultTitle = () => useContext(Context)

  return [TestResultTitleProvider, useTestResultTitle]
}

export const [TestResultTitleProvider, useTestResultTitle] =
  createTestResultTitleContext()

export const useTitleTruncation = (
  id: string,
  titleParts: string[] | null,
  forwardedRef: MutableRefObject<HTMLDivElement | null>,
  querySelector: string
) => {
  const formatterRef = useRef<() => void>(() => {})
  const { state, dispatch } = useTestResultTitle()

  const { width: forwardedRefWidth } = useResizeObserver({
    ref: forwardedRef,
  })
  const [debouncedForwardedRefWidth] = useDebounce(forwardedRefWidth, 250)

  useEffect(() => {
    formatterRef.current = () => {
      /* istanbul ignore next */
      if (!titleParts || !forwardedRef.current) {
        return
      }

      const isCached = state[id] !== undefined

      let updatedTitleRow: TitleRow = {
        widths: { sum: 0, partWidths: [] },
        formattedParts: [],
      }

      const maxWidth = forwardedRef.current.offsetWidth

      if (!isCached) {
        const titlePartEls: NodeListOf<HTMLElement> =
          forwardedRef.current.querySelectorAll(querySelector)

        const widths = reduce(
          titlePartEls,
          (lookup, part, idx, array) => {
            const partWidth = part.offsetWidth
            const isLast = idx === array.length - 1
            const dividerWidth = isLast ? 0 : 23

            lookup.sum += partWidth + dividerWidth
            lookup.partWidths.push(partWidth)

            return lookup
          },
          updatedTitleRow.widths
        )

        updatedTitleRow = {
          formattedParts: [],
          widths,
        }
      }

      const truncatedTitleParts: string[] = []
      const titleRow = isCached ? state[id] : updatedTitleRow
      const lookup = titleRow.widths

      let sum = lookup.sum

      // display the whole first title part
      truncatedTitleParts.push(titleParts[0])

      // decide whether to display the others or truncate them
      let i = 1
      while (sum > maxWidth) {
        if (i === titleParts.length - 1) {
          const lastTitlePartSections = titleParts[i].split(' ')
          const middleIdx = Math.floor((lastTitlePartSections.length - 1) / 2)
          const truncatedLastTitlePart = lastTitlePartSections
            .slice(middleIdx)
            .join(' ')

          truncatedTitleParts.push(`...${truncatedLastTitlePart}`)
          ++i

          break
        }

        truncatedTitleParts.push('...')

        const ellipsisWidth = 12
        const subtractedWidth = lookup.partWidths[i] - ellipsisWidth
        sum -= subtractedWidth
        ++i
      }

      const updatedTitleParts = truncatedTitleParts.length
        ? truncatedTitleParts.concat(titleParts.slice(i))
        : titleParts

      // Avoid updating the state when the sum is the same as the previous state.
      // This will ensure, that the hook will not try to process again if not needed
      if (state[id]?.widths.sum !== sum) {
        dispatch({
          type: 'UPDATE_TITLE_ROW',
          payload: {
            id,
            titleRow: {
              ...titleRow,
              formattedParts: updatedTitleParts,
            },
          },
        })
      }
    }
  }, [dispatch, forwardedRef, id, querySelector, state, titleParts])

  useEffect(() => {
    formatterRef.current()
  }, [debouncedForwardedRefWidth, forwardedRef, querySelector, titleParts])

  return state
}

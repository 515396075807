import { useEffect, useRef, useState, useMemo, MutableRefObject } from 'react'
import { useDrillInDrawerWidth } from '~/data/common/hooks'

const getWindowWidth = (htmlRef: MutableRefObject<HTMLElement | null>) => {
  return htmlRef.current?.clientWidth || window.innerWidth - 12
}

const SIDE_NAV_WIDTH = 320
const MIN_DRAWER_WIDTH = 250
const getMaxWidth = (htmlRef: MutableRefObject<HTMLElement | null>) => {
  return (getWindowWidth(htmlRef) - SIDE_NAV_WIDTH) * 0.8
}

export const useDrawerResizeable = (
  initialWidth: number,
  localStorageKey: string
) => {
  const { drillInDrawerWidth, setDrillInDrawerWidth } = useDrillInDrawerWidth()
  const handleDoubleClick = (initialWidth: number, localStorageKey: string) => {
    localStorage.removeItem(localStorageKey)
    setDrawerWidth(initialWidth)
  }

  const getHtmlElRef = (): HTMLElement | null => document.querySelector('html')
  const [dragging, setDragging] = useState(false)
  const htmlRef = useRef<HTMLElement | null>(useMemo(() => getHtmlElRef(), []))
  const [maxWidth, setMaxWidth] = useState(getMaxWidth(htmlRef))
  const [drawerWidth, setDrawerWidth] = useState(
    parseInt(localStorage.getItem(localStorageKey) as string) || initialWidth
  )
  const resizeableRef = useRef<HTMLDivElement | null>(null)
  const onDoubleClick = useRef((e: MouseEvent) => {})
  const onMouseMove = useRef((e: MouseEvent) => {})
  const onMouseDown = useRef((e: MouseEvent) => {
    e.preventDefault()
    setDragging(true)
  })
  const onMouseUp = useRef((e: MouseEvent) => {})
  const onWindowResize = useRef((e: MouseEvent) => {})

  useEffect(() => {
    onDoubleClick.current = (e) => {
      handleDoubleClick(initialWidth, localStorageKey)
    }
  }, [initialWidth, localStorageKey])

  useEffect(() => {
    onMouseMove.current = (e) => {
      if (!dragging) {
        return
      }

      e.preventDefault()

      const width = getWindowWidth(htmlRef) - e.clientX

      if (width > MIN_DRAWER_WIDTH && width < maxWidth) {
        setDrawerWidth(width)
      } else if (width > maxWidth + 10 || width < MIN_DRAWER_WIDTH - 10) {
        setDragging(false)
      }
    }
  }, [dragging, initialWidth, maxWidth, localStorageKey])

  useEffect(() => {
    onMouseUp.current = (e) => {
      localStorage.setItem(localStorageKey, drawerWidth.toString())
      setDragging(false)
    }
  }, [drawerWidth, localStorageKey])

  useEffect(() => {
    if (drawerWidth > maxWidth + 10) {
      setDrawerWidth(maxWidth)
    }
  }, [])

  useEffect(() => {
    function onWindowMouseMove(e) {
      onMouseMove.current(e)
    }

    function onWindowResizeEvent(e) {
      onWindowResize.current(e)
    }

    window.addEventListener('mousemove', onWindowMouseMove)
    window.addEventListener('resize', onWindowResizeEvent)

    return () => {
      window.removeEventListener('mousemove', onWindowMouseMove)
      window.removeEventListener('resize', onWindowResizeEvent)
    }
  }, [])

  useEffect(() => {
    onWindowResize.current = () => {
      setMaxWidth(getMaxWidth(htmlRef))
      if (drawerWidth > maxWidth && drawerWidth > 250) {
        setDrawerWidth(maxWidth)
        return
      }

      setDrawerWidth(drawerWidth)
    }
  }, [drawerWidth, maxWidth])

  useEffect(() => {
    const resizeableEl = resizeableRef.current
    const elemToAddListeners = resizeableEl

    function onDoubleClickEvent(e) {
      onDoubleClick.current(e)
    }

    function onMouseDownEvent(e) {
      onMouseDown.current(e)
    }

    function onMouseUpEvent(e) {
      onMouseUp.current(e)
    }

    if (!resizeableEl || !elemToAddListeners) {
      return
    }

    elemToAddListeners.addEventListener('mousedown', onMouseDownEvent)
    elemToAddListeners.addEventListener('mouseup', onMouseUpEvent)
    elemToAddListeners.addEventListener('dblclick', onDoubleClickEvent)

    return () => {
      elemToAddListeners.removeEventListener('mousedown', onMouseDownEvent)
      elemToAddListeners.removeEventListener('mouseup', onMouseUpEvent)
      elemToAddListeners.removeEventListener('dblclick', onDoubleClickEvent)
    }
  }, [])

  if (localStorageKey === 'drillInDrawerWidth') {
    if (drillInDrawerWidth !== drawerWidth) {
      // set in recoil
      setDrillInDrawerWidth(drawerWidth)
    }
  }

  return { resizeableRef, drawerWidth }
}

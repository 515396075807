import React, { FunctionComponent, useRef } from 'react'
import cs from 'clsx'
import { useTitleTruncation } from '~/test-results/hooks/useTitleTruncation'
import { HorizontalList, Icon, FlakyBadge } from '@frontend/design-system'
import styles from './module.TestTitle.scss'

type TestTitleProps = {
  id: string
  titleParts: string[]
  isFlaky?: boolean
  noWrap?: boolean
  style?: string
}

export const TestTitle: FunctionComponent<TestTitleProps> = ({
  id,
  titleParts,
  noWrap = false,
  isFlaky,
  style,
}) => {
  const resultTitleRef = useRef<HTMLDivElement | null>(null)

  const testResultTitle = useTitleTruncation(
    id,
    titleParts,
    resultTitleRef,
    '.test-results__title-part'
  )

  const formattedTitlePartLookup = testResultTitle[id]

  const hasFormattedTitleParts = formattedTitlePartLookup !== undefined
  const renderedTitleParts = hasFormattedTitleParts
    ? formattedTitlePartLookup.formattedParts
    : titleParts

  return (
    <div
      ref={resultTitleRef}
      className={cs('test-results__test-result-title', style)}
      data-cy="test-title-parts"
    >
      <HorizontalList
        margin={8}
        noWrap={noWrap}
        inlineFlex={false}
        divider={
          <Icon
            name="chevron-right"
            className="test-results__test-result-title-divider"
          />
        }
      >
        {(renderedTitleParts || []).map((titlePart: string, idx: number) => (
          <span
            key={`${titlePart}-${idx}`}
            style={{
              visibility: hasFormattedTitleParts ? 'visible' : 'hidden',
            }}
            className="test-results__title-part"
            data-cy="test-title-fragment"
          >
            {titlePart}
          </span>
        ))}
      </HorizontalList>
      {isFlaky && (
        <span className={cs('test-results__title-part', styles.flakyBadge)}>
          <FlakyBadge />
        </span>
      )}
    </div>
  )
}

import React from 'react'
import { CustomIcon } from '..'
import styles from './module.CopyText.scss'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Button } from 'react-bootstrap'
import { Tooltip } from '@frontend/design-system'

interface CopyTextProps {
  label: string | React.ReactNode
  copyText: string
  mask?: boolean
  dataPendo?: string
  overlayDefaultText?: string
  copyBtnDataCy?: string
  variant?: `default` | `bordered`
}

export const CopyText: React.FunctionComponent<CopyTextProps> = ({
  label,
  copyText,
  overlayDefaultText = 'Copy',
  mask = false,
  dataPendo,
  copyBtnDataCy = '',
  variant = 'default',
}) => {
  const [copyState, setCopyState] = React.useState({
    copied: false,
    copyText: overlayDefaultText,
  })
  const onCopy = () => {
    setCopyState({
      copied: true,
      copyText: 'Copied!',
    })
  }
  const resetText = () =>
    setTimeout(() => {
      setCopyState({
        ...copyState,
        copyText: overlayDefaultText,
      })
    }, 250)

  const isDefaultStyle = variant === 'default'

  return (
    <div
      className={isDefaultStyle ? styles.container : styles.borderedContainer}
      data-cy="copy-text-component"
    >
      <div className={isDefaultStyle ? styles.label : styles.labelVariant}>
        <span>{label}</span>
      </div>
      <div
        className={
          isDefaultStyle ? styles.copyTextHolder : styles.copyTextHolderVariant
        }
      >
        <div
          className={isDefaultStyle ? styles.copyText : styles.copyTextVariant}
        >
          <CustomIcon
            className={`${styles.icon} code-icon`}
            name="technology-terminal"
            size="x16"
          />
          {mask ? `(...)` : copyText}
        </div>
        <Tooltip placement="bottom" overlay={copyState.copyText}>
          <CopyToClipboard onCopy={onCopy} text={copyText}>
            <div className={styles.copyButtonHolder} onMouseOut={resetText}>
              <Button
                className={
                  isDefaultStyle ? styles.copyButton : styles.copyButtonVariant
                }
                data-pendo={dataPendo}
                data-cy={copyBtnDataCy}
              >
                <CustomIcon
                  className="copy-icon"
                  name="general-clipboard"
                  size="x16"
                />{' '}
                <div>Copy</div>
              </Button>
            </div>
          </CopyToClipboard>
        </Tooltip>
      </div>
    </div>
  )
}

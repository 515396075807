import React, { FunctionComponent } from 'react'
import styles from './module.LayeredLogoIcons.scss'
import { CustomIcon } from '@frontend/design-system/src'

interface LayeredLogoIconsProps {
  browsers: string[]
}

export const getBrowserIcon = (browser: string) => {
  if (browser.toLowerCase().includes('chrome')) {
    return (
      <CustomIcon
        data-cy="browser-logo-chrome"
        name="logo-browser-google-chrome"
        size="x16"
      />
    )
  }
  if (browser.toLowerCase().includes('safari')) {
    return (
      <CustomIcon
        data-cy="browser-logo-safari"
        name="logo-browser-apple-safari"
        size="x16"
      />
    )
  }
  if (browser.toLowerCase().includes('firefox')) {
    return (
      <CustomIcon
        data-cy="browser-logo-firefox"
        name="logo-browser-mozilla-firefox"
        size="x16"
      />
    )
  }
  if (browser.toLowerCase().includes('edge')) {
    return (
      <CustomIcon
        data-cy="browser-logo-edge"
        name="logo-browser-microsoft-edge"
        size="x16"
      />
    )
  }
  if (browser.toLowerCase().includes('electron')) {
    return (
      <CustomIcon
        data-cy="browser-logo-electron"
        name="general-electron"
        size="x16"
        className={styles.genericIconStyle}
      />
    )
  }
  return (
    <CustomIcon
      className={styles.genericIconStyle}
      data-cy="browser-logo-generic"
      name="logo-browser-generic"
      size="x16"
    />
  )
}

export const LayeredLogoIcons: FunctionComponent<LayeredLogoIconsProps> = ({
  browsers,
}) => (
  <ul className={styles.layeredLogoWrapper}>
    {browsers.map((browser) => (
      <li
        key={`browser-${browser.toLowerCase()}`}
        className={styles.browserLogo}
      >
        {getBrowserIcon(browser)}
      </li>
    ))}
  </ul>
)

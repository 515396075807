import React, { FunctionComponent } from 'react'

import TimerDisplay from '~/duration-timer/TimerDisplay'
import { DurationDisplay } from '~/duration-timer/DurationDisplay'

type SpecDurationProps = {
  isComplete: boolean
  claimedAt?: Date
  duration: number | null
}

export const SpecDuration: FunctionComponent<SpecDurationProps> = ({
  isComplete,
  claimedAt,
  duration,
}) => {
  if (isComplete && duration) {
    return <DurationDisplay duration={duration} data-cy="spec-duration" />
  }

  if (claimedAt) {
    return <TimerDisplay startTime={claimedAt} data-cy="spec-duration" />
  }

  return null
}

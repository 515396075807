import { CustomIcon } from '@frontend/design-system'
import React, { FunctionComponent } from 'react'

import { MultiSelectFilter } from '../MultiSelectFilter'
import { MultiSelectOptionType, onMultiSelectFilterChange } from '../types'

type TestResultActionsFilterProps = {
  id: string
  onChange: onMultiSelectFilterChange
  selected: MultiSelectOptionType[]
}

export const TestResultActionsFilter: FunctionComponent<TestResultActionsFilterProps> =
  React.memo(({ id, onChange, selected }) => {
    const options = [
      {
        label: 'Muted',
        value: 'MUTE',
      },
    ]

    return (
      <MultiSelectFilter
        id={id}
        title={<CustomIcon name="mute-test" alt="Actions" />}
        emptyLabel="Action Taken"
        isSearchable={false}
        selected={selected}
        onChange={onChange}
        options={options}
      />
    )
  })

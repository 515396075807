import React, { FunctionComponent, ReactNode } from 'react'
import { history } from '~/lib/history'
import cs from 'clsx'
import { PageHeader } from '@frontend/dashboard/src/navs/page-header'
import { LoginHeader, LoginHeaderProps } from '@frontend/design-system'

interface StackedLayoutProps {
  children: ReactNode
  className?: string
  contentClassName?: string
  title: ReactNode
  headerActions?: ReactNode
  isBeta?: boolean
  scrollTop?: boolean
  hideNav?: boolean
  loginHeaderProps?: LoginHeaderProps
  withBackgroundColorShim?: boolean
}

const StackedLayout: FunctionComponent<StackedLayoutProps> = ({
  children,
  className,
  contentClassName,
  title,
  headerActions,
  isBeta,
  scrollTop,
  hideNav,
  loginHeaderProps,
  withBackgroundColorShim,
}) => {
  const scrollableRef = React.useRef<HTMLDivElement | null>(null)

  React.useEffect(() => {
    if (scrollTop) {
      return history.listen(() => {
        scrollableRef.current = document.querySelector(
          '.stacked-layout--content'
        )
        if (scrollableRef.current) {
          scrollableRef.current.scrollTop = 0
        }
      })
    }
    return
  }, [scrollTop])

  return (
    <div
      className={cs(
        'stacked-layout',
        className,
        withBackgroundColorShim ? 'shim-background-color' : '',
        loginHeaderProps ? 'shim-background-color-login' : ''
      )}
    >
      {loginHeaderProps ? (
        <LoginHeader {...loginHeaderProps} />
      ) : (
        <PageHeader
          title={title}
          className="stacked-layout--header"
          actions={headerActions}
          isBeta={isBeta}
          hideNav={hideNav}
        />
      )}
      <div className={cs(`stacked-layout--content`, contentClassName)}>
        {children}
      </div>
    </div>
  )
}

export { StackedLayout }

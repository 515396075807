import React, { FunctionComponent } from 'react'
import { isNil } from 'lodash'
import { TestResultHistoricalFlakinessBranchFragment } from '~/graphql-codegen-operations.gen'
import { Percentage } from '~/common/percentage/Percentage'
import { FlakyLabel } from '~/common/flaky-label'
import styles from './module.TestTrendFlakinessLabel.scss'

interface TestTrendFlakinessLabelProps {
  percentage: number | '-' | null
  defaultData?: TestResultHistoricalFlakinessBranchFragment | null
}

export const TestTrendFlakinessLabel: FunctionComponent<
  TestTrendFlakinessLabelProps
> = ({ percentage, defaultData }) => {
  return (
    <>
      <div className={styles.percentage}>
        <Percentage
          percentage={
            isNil(percentage) ? defaultData?.averageFlakiness : percentage
          }
        />
      </div>
      <div className={styles.flakyLabel}>
        <FlakyLabel
          severity={isNil(percentage) ? defaultData?.averageSeverity : null}
          percentage={
            isNil(percentage) || percentage === '-' ? null : percentage / 100
          }
        />
      </div>
    </>
  )
}

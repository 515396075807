import {
  Button,
  CustomIcon,
  EmptyState,
  Panel,
  Tag,
  Tooltip,
} from '@frontend/design-system'
import cs from 'clsx'
import commaNumber from 'comma-number'
import { take } from 'lodash'
import React, { FunctionComponent, useState } from 'react'
import { readableViewByEnum } from '~/lib/utils-ts'
import { durationFormatted } from '@packages/common'
import { LoadingContainer } from '~/project-analytics/LoadingContainer'
import {
  ProjectSlowestTestsQuery,
  ViewByEnum,
} from '~/graphql-codegen-operations.gen'
import { AnalyticRowLink } from '../AnalyticRowLink/AnalyticRowLink'
import { SpecFile } from '~/specs/stats/SpecFile'
import { TestResultTitleProvider } from '~/test-results/hooks/useTitleTruncation'
import { TestTitle } from '~/test-results/TestTitle'

import styles from '../module.AnalyticsTable.scss'

type SlowestTestsTableProps = {
  data?: ProjectSlowestTestsQuery
  isLoading: boolean
  viewBy: ViewByEnum
}

interface ViewByRowProps {
  row: ProjectSlowestTestsQuery['metrics']['projectSlowestTests']['nodes'][0]
  viewBy: ViewByEnum
}

const ViewByRow: FunctionComponent<ViewByRowProps> = ({ row, viewBy }) => {
  if (viewBy === 'BRANCH') {
    return <span>{row.branch}</span>
  }

  if (viewBy === 'SPEC') {
    return (
      <div className={styles.viewByRow}>
        <SpecFile specPath={row.specPath} isFlaky={row.isFlaky} />
      </div>
    )
  }

  if (viewBy === 'TAG' && row.tag) {
    return (
      <div className={styles.viewByRow}>
        <Tag backgroundColor={row.tag.color}>{row.tag.name}</Tag>
      </div>
    )
  }

  return (
    <>
      <TestTitle
        id={row.id}
        titleParts={row.titleParts || []}
        isFlaky={row.isFlaky}
      />
      <SpecFile specPath={row.specPath} />
    </>
  )
}

export const SlowestTestsTable: FunctionComponent<SlowestTestsTableProps> = ({
  data,
  viewBy,
  isLoading,
}) => {
  const PER_PAGE = 30
  const [numberOfTestsShown, setNumberOfTestsShown] = useState(PER_PAGE)

  if (!data || !data.metrics) {
    return (
      <Panel>
        <EmptyState>
          <span>Loading...</span>
        </EmptyState>
      </Panel>
    )
  }

  const loadMoreTests = () => {
    setNumberOfTestsShown(numberOfTestsShown + PER_PAGE)
  }

  const metrics = data.metrics.projectSlowestTests

  const rows = take(metrics.nodes, numberOfTestsShown) || []

  return (
    <LoadingContainer active={isLoading}>
      <Panel>
        {metrics.nodes.length < 1 ? (
          <EmptyState>
            <span>No data for this time range.</span>
          </EmptyState>
        ) : (
          <div
            className={cs(styles.analyticsTable, styles.slowestTestsTable)}
            data-cy="slowest-tests-table"
          >
            <AnalyticRowLink dataCy="slowest-tests-table__header">
              <h6 />
              <h6>{readableViewByEnum(viewBy)}</h6>
              <h6 />
              <h6>
                <span className={styles.textRight}>Total runs</span>
              </h6>
              <h6>
                <span className={styles.textRight}>Median duration</span>
              </h6>
            </AnalyticRowLink>
            <TestResultTitleProvider>
              {rows.map((row, i) => {
                return (
                  <AnalyticRowLink
                    dataCy="slowest-tests-table__row"
                    key={row.id}
                  >
                    <div>
                      <span className={styles.textRight}>{i + 1}</span>
                    </div>
                    <div>
                      <div className={styles.viewByRowContainer}>
                        <ViewByRow row={row} viewBy={viewBy} />
                      </div>
                    </div>
                    <div className={styles.mutedTestContainer}>
                      {Boolean(row.activeMuteOrchestration) && (
                        <Tooltip
                          placement="top"
                          overlay="Cypress will ignore the status of this test because it is muted."
                        >
                          <CustomIcon name="mute-test" />
                        </Tooltip>
                      )}
                    </div>
                    <div>
                      <span className={styles.textRight}>
                        {commaNumber(row.totalResults)}
                      </span>
                    </div>
                    <div>
                      <span className={styles.textRight}>
                        {durationFormatted(row.medianDuration)}
                      </span>
                    </div>
                  </AnalyticRowLink>
                )
              })}
              {metrics.nodes.length > numberOfTestsShown && (
                <div className={styles.footer} key={metrics.nodes.length}>
                  <Button bsStyle="link" onClick={loadMoreTests}>
                    Load more
                  </Button>
                </div>
              )}
            </TestResultTitleProvider>
          </div>
        )}
      </Panel>
    </LoadingContainer>
  )
}

import * as React from 'react'

export type Action<TType, TAdditional = {}> = { type: TType } & TAdditional

export function createReducerService<TState, TAction>(
  reducer: (state: TState, action: TAction) => TState,
  initialState: TState,
  reducerName = 'NotSpecified'
): [React.FC, () => [TState, React.Dispatch<TAction>]] {
  const SidebarContext = React.createContext<
    [TState, React.Dispatch<TAction>] | null
  >(null)

  const Provider: React.FC = (props) => {
    const value = React.useReducer(reducer, initialState)
    return <SidebarContext.Provider value={value} {...props} />
  }

  Provider.displayName = `ReducerServiceProvider(${reducerName})`

  const useService = () => {
    const ctx = React.useContext(SidebarContext)
    if (ctx === null) {
      throw new Error('useService must be nested inside a ServiceProvider')
    }
    return ctx
  }

  return [Provider, useService]
}

import { palette } from '@frontend/design-system'
import React, { FunctionComponent } from 'react'

export const NotificationIndicator: FunctionComponent<{
  count: number
  size?: number
}> = ({ count, size = 24 }) => {
  return (
    <div
      className="organization-switcher--menu-icon"
      data-cy="pending-notifications-indicator"
      data-pendo="pending-notifications-indicator"
    >
      <div
        className="ripple"
        data-cy="pending-notifications-indicator"
        data-pendo="pending-notifications-indicator"
      >
        <svg height={size} width={size}>
          <title>Notification Indicator</title>
          <circle
            cx={size / 2}
            cy={size / 2}
            r={size / 2}
            fill={palette.orange400}
          />
          <text
            x={size / 2}
            y={size / 2}
            fontSize={size / 2.25}
            fill="#fff"
            fontFamily={palette.fontSans}
            textAnchor="middle"
            dominantBaseline="central"
          >
            {count}
          </text>
        </svg>
      </div>
    </div>
  )
}

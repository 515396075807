/* global pendo */
import currentUser, { CurrentUser } from '../auth/current-user-model'
import {
  CypressLabToggleDocument,
  SidebarOrganizationDocument,
} from '~/graphql-codegen-operations.gen'
import { Feature } from '@packages/feature-flags'
import { ApolloClient, NormalizedCacheObject } from 'apollo-client'
import { dashboardApolloClient } from '~/gql/dashboardApolloClient'
import { history } from '~/lib/history'
import { abTest } from './ab-tests'

interface Org {
  id: string
  name?: string
}

type PendoHelperType = {
  apolloClient: ApolloClient<NormalizedCacheObject>
  org: Org | null
  identify: (org: Org) => void
  getUser: () => CurrentUser
  enableBeta: (feature: Feature) => Promise<boolean>
  getLatestProject: () => Promise<any>
  goToLatestProject: (page: string) => Promise<void>
  goToLatestRun: (tab: string) => Promise<void>
}

declare global {
  interface Window {
    PendoHelper: PendoHelperType
  }
}

export const PendoHelper: PendoHelperType = {
  apolloClient: dashboardApolloClient,
  org: null,
  identify(org) {
    this.org = org
  },
  getUser() {
    return currentUser
  },
  enableBeta(feature) {
    if (this.org === null) {
      return Promise.reject()
    }

    return dashboardApolloClient
      .mutate({
        variables: { labName: feature, orgId: this.org.id, isEnabled: true },
        mutation: CypressLabToggleDocument,
      })
      .then((res) => res.data?.organizationLabToggle.isEnabled)
  },
  getLatestProject() {
    if (this.org === null) {
      return Promise.reject()
    }

    return dashboardApolloClient
      .query({
        variables: {
          organizationId: this.org.id,
          orderProjectsByDirection: 'DESC',
          orderProjectsByField: 'ACTIVITY',
        },
        query: SidebarOrganizationDocument,
      })
      .then((res) => res.data.organizationOrDefault)
      .then((org) => {
        if (!org) {
          return Promise.reject()
        }

        return org.projects.nodes[0]
      })
  },
  goToLatestProject(page = 'runs') {
    return this.getLatestProject().then((project) => {
      return project
        ? history.navigate(`/projects/${project.id}/${page}`)
        : Promise.reject()
    })
  },
  goToLatestRun(tab = 'specs') {
    return this.getLatestProject().then((project) => {
      return project?.latestRun
        ? history.navigate(
            `/projects/${project.id}/runs/${project.latestRun.id}/${tab}`
          )
        : Promise.reject()
    })
  },
}

export const Pendo = {
  init() {
    window.PendoHelper = PendoHelper

    const orgId = window.location.pathname.startsWith('/organizations/')
      ? window.location.pathname.split('/')[2]
      : ''
    /* istanbul ignore if */
    if (typeof pendo !== 'undefined') {
      try {
        pendo.initialize(Pendo.payload({ id: orgId }))
      } catch {}
    }
  },

  identify(org: Org) {
    PendoHelper.identify(org)

    /* istanbul ignore if */
    if (typeof pendo !== 'undefined') {
      try {
        pendo.identify(Pendo.payload(org))
      } catch {}
    }
  },

  payload(org: Org) {
    return {
      visitor: {
        id:
          window.env === 'production'
            ? currentUser.id
            : `${window.env}_${currentUser.id}`,
      },
      account: {
        id: window.env === 'production' ? org.id : `${window.env}_${org.id}`,
        Name: org.name || '',
        // Feature flag: record-first-run-ab
        abOrgCohort: abTest(org.id, 2),
      },
    }
  },
}

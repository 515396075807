import { Link, LinkProps } from '@reach/router'
import React, { FunctionComponent, ReactNode } from 'react'

interface NavLinkProps extends LinkProps<any> {
  to: string
  children: ReactNode
}

export const NavLink: FunctionComponent<NavLinkProps> = ({
  to,
  children,
  ...rest
}) => (
  <Link className="nav-link" to={to} {...(rest as any)}>
    {children}
  </Link>
)

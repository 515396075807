import React, { FunctionComponent, useState } from 'react'
import { isNil } from 'lodash'
import { CollapsingSection, palette } from '@frontend/design-system'
import {
  TestHistoryItemFragment,
  useDrawerTestResultAnalyticHistoricalFailureRateQuery,
} from '~/graphql-codegen-operations.gen'
import { TestTrend } from '~/test-trend/TestTrend'
import * as analytics from '~/data/projects/analytics/hooks'
import { Percentage } from '~/common/percentage/Percentage'
import { getValuesFromMultiSelectOptions } from '~/common/filters'
import { NeedSingleBranchEmptyState } from '../NeedSingleBranchEmptyState'

type DrillInFailureRateProps = {
  familyId: string
  projectId: string
  specHash: string | null
  titleHash: string | null
  isHistoryEnabled: boolean
  onModificationClick(testResultId: string): void
  history: TestHistoryItemFragment[]
  isSingleBranchSelected: boolean
}

export const DrillInFailureRate: FunctionComponent<DrillInFailureRateProps> = ({
  familyId,
  onModificationClick,
  projectId,
  isHistoryEnabled,
  specHash,
  titleHash,
  history,
  isSingleBranchSelected,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true)

  const { selected: branches } = analytics.useBranchesFilter(familyId)
  const { timeRange } = analytics.useTimeRangeFilter(familyId)
  const { selected: tags } = analytics.useTagsFilter(familyId)
  const { selected: runGroups } = analytics.useRunGroupsFilter(familyId)
  const { selected: browsers } = analytics.useBrowsersFilter(familyId)
  const { selected: committers } = analytics.useCommittersFilter(familyId)
  const { selected: cypressVersions } =
    analytics.useCypressVersionsFilter(familyId)
  const { selected: operatingSystems } =
    analytics.useOperatingSystemsFilter(familyId)

  const selectedBranch = branches?.[0]?.value

  const { data, loading } =
    useDrawerTestResultAnalyticHistoricalFailureRateQuery({
      skip: !specHash || !titleHash || !selectedBranch || isCollapsed,
      variables: {
        projectId,
        specHash: specHash!,
        titleHash: titleHash!,
        input: {
          timeRange: timeRange!,
          commitBranch: selectedBranch,
          tags: getValuesFromMultiSelectOptions(tags || []),
          runGroups: getValuesFromMultiSelectOptions(runGroups || []),
          committers: getValuesFromMultiSelectOptions(committers || []),
          cypressVersions: getValuesFromMultiSelectOptions(
            cypressVersions || []
          ),
          browsers: getValuesFromMultiSelectOptions(browsers || []),
          oses: getValuesFromMultiSelectOptions(operatingSystems || []),
        },
      },
    })

  const failureColor = palette.red600
  const integrationBranchData =
    data?.testResultAnalytic?.historicalFailureRate?.integrationBranch
  const commitBranchData =
    data?.testResultAnalytic?.historicalFailureRate?.commitBranch

  return (
    <CollapsingSection
      title="Failure rate"
      data-cy="drill-in-failure-rate"
      isCollapsedSection={isCollapsed}
      setIsCollapsedSection={setIsCollapsed}
      data-pendo="test-result-drawer--failure-rate"
    >
      {isSingleBranchSelected ? (
        <TestTrend
          id="historical-failure-rate"
          projectId={projectId}
          commitBranchColor={failureColor}
          isLoading={loading}
          isEnabled={isHistoryEnabled}
          history={history}
          formatYAxis={() => ({
            domain: [0, 100],
            tickValues: [0, 50, 100],
            tickFormat: (value: number) => `${value}%`,
          })}
          onModificationClick={onModificationClick}
          commitBranchPoints={commitBranchData?.points || []}
          integrationBranchPoints={integrationBranchData?.points || []}
          commitBranchName={commitBranchData?.branch}
          integrationBranchName={integrationBranchData?.branch}
          IntegrationBranchStat={({ percentage }) => (
            <Percentage
              color={failureColor}
              percentage={
                isNil(percentage)
                  ? integrationBranchData?.averageFailureRate
                  : percentage
              }
            />
          )}
          CommitBranchStat={({ percentage }) => (
            <Percentage
              color={failureColor}
              percentage={
                isNil(percentage)
                  ? commitBranchData?.averageFailureRate
                  : percentage
              }
            />
          )}
        />
      ) : (
        <NeedSingleBranchEmptyState />
      )}
    </CollapsingSection>
  )
}

// Replace react-bootstrap's tooltip with rc-tooltip
import BaseTooltip, { RCTooltip } from 'rc-tooltip'
import React, { FC } from 'react'

// default tooltip placement is right unless given a prop
export const Tooltip: FC<RCTooltip.Props & { hideTooltip?: boolean }> = (
  props
) => {
  if (props.hideTooltip) {
    return <>{props.children}</>
  }

  return (
    <BaseTooltip
      {...props}
      overlayClassName={`${props.visible ? '' : 'hide-in-percy'} ${
        props.overlayClassName || ''
      }`}
    >
      {props.children}
    </BaseTooltip>
  )
}

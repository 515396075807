import _ from 'lodash'
import pluralize from 'pluralize'
import {
  ProjectTopFailuresQuery,
  ViewByEnum,
} from '~/graphql-codegen-operations.gen'
import { median } from '@packages/common'
import { readableViewByEnum } from '~/lib/utils-ts'

export const getKPIs = (
  ProjectTopFailures: ProjectTopFailuresQuery | undefined,
  loading: boolean,
  viewBy: ViewByEnum,
  chartRange?: number[]
) => {
  let kpis
  let failureRateName = 'Median failure rate'

  if (ProjectTopFailures) {
    const metrics = ProjectTopFailures.metrics.projectTopFailingTestsOverTime
    let medianFailureRateValue: string | null = null

    if (chartRange?.length) {
      const [chartRangeMin, chartRangeMax] = chartRange
      medianFailureRateValue = `${chartRangeMin}% - ${chartRangeMax}%`
      failureRateName = 'Failure rate'
    } else if (metrics.nodes.length > 0) {
      const nodes: number[] = []

      metrics.nodes.forEach((node) => {
        if (_.isNil(node.failureRate)) {
          return
        }

        nodes.push(node.failureRate)
      })

      medianFailureRateValue = `${median(nodes) ?? 0}%`
    }

    const numberOfFailures = _.countBy(
      metrics.nodes,
      (v) => v.failureRate && v.failureRate > 0
    ).true

    kpis = [
      {
        name: failureRateName,
        value: medianFailureRateValue,
        loading,
      },
      {
        name: 'Number of failures',
        value: numberOfFailures,
        subtitle: `${pluralize(readableViewByEnum(viewBy), numberOfFailures)}`,
        loading,
      },
    ]
  } else {
    kpis = [
      {
        name: failureRateName,
        loading,
      },
      {
        name: 'Number of failures',
        loading,
      },
    ]
  }
  return kpis
}

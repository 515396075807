import { FlakyBadge } from '@frontend/design-system'
import { stripLeadingCyDirsByRegex } from '@packages/common'
import cs from 'clsx'
import React, { FunctionComponent, HTMLProps } from 'react'
import { CopyFilePath } from '~/common/copy-file-path/CopyFilePath'
import styles from './module.SpecFile.scss'

interface SpecFileProps extends HTMLProps<HTMLDivElement> {
  className?: string
  specPath?: string | null
  isFlaky?: boolean
  copyEnabled?: boolean
  copyIconWidth?: number
}

export const SpecFile: FunctionComponent<SpecFileProps> = ({
  className,
  specPath,
  copyEnabled = false,
  copyIconWidth = 14,
  isFlaky,
  ...rest
}) => {
  /* istanbul ignore next */
  if (!specPath) {
    return null
  }

  return (
    <span {...rest} className={cs('spec-file', styles.specFile, className)}>
      <CopyFilePath
        filePath={stripLeadingCyDirsByRegex(specPath)}
        enabled={copyEnabled}
        width={copyIconWidth}
      />
      {stripLeadingCyDirsByRegex(specPath)}
      {isFlaky && (
        <span className={styles.flakyBadge}>
          <FlakyBadge />
        </span>
      )}
    </span>
  )
}

import {
  Button,
  CustomIcon,
  FilterEmptyState,
  Icon,
  Label,
} from '@frontend/design-system'
import _ from 'lodash'
import React, { FunctionComponent, ReactNode, useState, useEffect } from 'react'
import Highlighter from 'react-highlight-words'
import { components } from 'react-select'

import { MultiSelectFilter, onMultiSelectFilterChange } from '~/common/filters'
import { useProjectBranchesQuery } from '~/graphql-codegen-operations.gen'

import { MultiSelectOptionType } from '../../types'

type ProjectBranchesFilterProps = {
  id: string
  onChange: onMultiSelectFilterChange
  projectId: string
  selected: MultiSelectOptionType[]
  shouldRefetch?: boolean
}

export const ProjectBranchesFilter: FunctionComponent<ProjectBranchesFilterProps> =
  React.memo(({ id, onChange, projectId, selected, shouldRefetch }) => {
    const [branchesSearch, setBranchesSearch] = useState('')
    const [isLoading, updateIsLoading] = useState(false)

    const { data, refetch } = useProjectBranchesQuery({
      variables: {
        id: projectId,
        branchesSearch,
      },
      onCompleted() {
        updateIsLoading(false)
      },
    })

    useEffect(() => {
      if (shouldRefetch) {
        refetch()
      }
    }, [refetch, shouldRefetch])

    let branchOptions: MultiSelectOptionType[] = data
      ? data.project.branches.nodes.map((branch) => {
          return {
            label: branch.name,
            suggested: false,
            value: branch.name,
          }
        })
      : []

    if (data?.project?.hasRunsWithoutBranch) {
      branchOptions.push({
        label: 'Unknown branch',
        suggested: false,
        value: null,
      })
    }

    // sort branches
    branchOptions = _.unionBy(selected, branchOptions, 'value')

    const unselectedItems: MultiSelectOptionType[] = _.difference(
      branchOptions,
      selected
    )

    const NoOptionsMessage: FunctionComponent = () => {
      return (
        <FilterEmptyState
          filter="branch"
          icon={<CustomIcon name="branch" />}
          info="Provide git information to Cypress to filter runs by branch"
          searchQuery={branchesSearch}
          url="http://on.cypress.io/git-info"
        />
      )
    }

    const SelectedItemsHeader: FunctionComponent = () => {
      return (
        <div className="branches-filter--header h6">
          Selected
          <Button bsStyle="link" onClick={() => onChange([])}>
            Clear
          </Button>
        </div>
      )
    }

    const UnselectedItemsHeader: FunctionComponent = () => {
      if (branchesSearch) {
        return (
          <div className="branches-filter--header unselected h6">
            Search results
          </div>
        )
      }

      return (
        <div className="branches-filter--header unselected h6">
          Recently updated
        </div>
      )
    }

    const customOption = (props): ReactNode => {
      return (
        <>
          {selected.length > 0 && props.value === selected[0].value && (
            <SelectedItemsHeader />
          )}
          {unselectedItems.length > 0 &&
            unselectedItems[0].value === props.value && (
              <UnselectedItemsHeader />
            )}
          <components.Option {...props}>
            <span>
              {props.isSelected ? (
                <Icon
                  name="check-square"
                  className="filter__checkbox fa-lg primary"
                />
              ) : (
                <Icon name="square-o" className="filter__checkbox fa-lg" />
              )}{' '}
              <Highlighter
                searchWords={[branchesSearch]}
                textToHighlight={props.data.label ?? ''}
              />
            </span>
            {props.data.suggested && <Label bsStyle="default">default</Label>}
          </components.Option>
        </>
      )
    }

    return (
      <div
        data-cy="branches-filter"
        data-pendo="branches-filter"
        className="branches-filter multi-select-filter-with-categories"
      >
        <MultiSelectFilter
          id={id}
          title={<CustomIcon name="branch" alt="Branch" />}
          emptyLabel="Branch"
          placeholder="Search for a branch..."
          customOption={customOption}
          isLoading={isLoading}
          noOptionsMessage={NoOptionsMessage}
          onChange={onChange}
          onInputChange={() => updateIsLoading(true)}
          onSearch={setBranchesSearch}
          options={branchOptions}
          selected={selected}
        />
      </div>
    )
  })

import { EmptyState } from '@frontend/design-system'
import React, { FunctionComponent } from 'react'

export const NeedSingleBranchEmptyState: FunctionComponent = () => {
  return (
    <EmptyState>
      Filter on a single branch to view additional analysis.
    </EmptyState>
  )
}

import React, { FC } from 'react'
import { ApolloError } from 'apollo-client'
import { Alert } from '@frontend/design-system'
import styles from './module.CheckoutError.scss'

interface CheckoutErrorProps {
  error?: ApolloError
}

export const useFormatError = (error?: ApolloError) => {
  const formattedError = error?.graphQLErrors?.[0]?.message

  if (formattedError === 'INTERNAL_SERVER_ERROR') {
    return 'An unexpected error occurred. Please try again or contact support.'
  }

  return formattedError
}

export const CheckoutError: FC<CheckoutErrorProps> = ({ error }) => {
  const formattedError = useFormatError(error)

  if (!formattedError) {
    return null
  }

  return (
    <Alert className={styles.error} bsStyle="danger">
      {formattedError}
    </Alert>
  )
}

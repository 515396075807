import { CustomIcon } from '@frontend/design-system/src'
import React, { FunctionComponent } from 'react'
import { integrationBranchColor } from './utils'

type TestTrendBranchProps = {
  color?: string
  branchName?: string
  updateShowModal?: React.Dispatch<React.SetStateAction<boolean>>
}

export const TestTrendBranch: FunctionComponent<TestTrendBranchProps> = ({
  branchName,
  color = integrationBranchColor,
  children,
  updateShowModal,
}) => {
  return (
    <div className="test-trend-branch">
      <div className="test-trend-branch__branch-container">
        <span
          className="test-trend-branch__icon"
          style={{ background: color }}
        />
        <p className="test-trend-branch__branch-name">{branchName}</p>
        {updateShowModal && (
          <CustomIcon
            name="edit"
            className="test-trend-branch__edit-branch"
            onClick={() => updateShowModal(true)}
          />
        )}
      </div>
      <div className="test-trend-branch__stat-container">{children}</div>
    </div>
  )
}

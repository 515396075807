/*
Method takes the keys from the first objec, and verifies that they exist on the second object. 
Object do not have to be exactly the same, the second could have more keys than the first, as the purpose
of this method is to verify that all the keys for the first object are also found in the second object.
*/
export const hasAtLeastSameKeys = (first: object, second: object) => {
  const keys = Object.keys(first)
  return keys.reduce((acc, key) => {
    return acc && second[key] !== undefined
  }, true)
}

import _ from 'lodash'
import { action, observable } from 'mobx'
import Cookies from 'js-cookie'

import ReactGA from 'react-ga'
import ReactGA4 from 'react-ga4'
import * as Sentry from '@sentry/browser'
import { isEnv } from '../lib/utils'
import { setupGa, setupGa4, trackerNames, trackingIdG4 } from '../lib/tracking'

export class CurrentUser {
  @observable id
  @observable name
  @observable email
  @observable country
  @observable orgRoles
  @observable defaultOrgId
  @observable isLoaded = false
  @observable isSubmitting = false
  @observable isSaved = false
  @observable errors = {}
  @observable acceptedTerms = false

  _callbacks = []

  constructor(attrs) {
    this._update(attrs)
  }

  @action init(attrs = {}) {
    setupGa()
    setupGa4()

    if (attrs.id) {
      this.isLoaded = true
      ReactGA.set({ userId: attrs.id }, trackerNames)
      ReactGA4.set({ userId: attrs.id, send_to: trackingIdG4 })
    }
    this._update(attrs)
    Sentry.setUser({
      id: attrs.id,
      email: attrs.email,
    })
    return this
  }

  @action loaded(bool) {
    this.isLoaded = bool
  }

  @action getOrgRole(orgId) {
    if (this.orgRoles && orgId) {
      return this.orgRoles[orgId]
    }
  }

  @action clear() {
    this.isLoaded = false
    Sentry.setUser(null)
    this._update({
      id: undefined,
      name: undefined,
      email: undefined,
    })
  }

  @action _update(attrs) {
    if (!attrs) return

    _.assign(this, attrs)
  }

  logPageView = ({ pathname, search, hash }) => {
    const page = `${pathname}${search}${hash}`

    const utmCookieData = Cookies.getJSON('utm_params')
    if (utmCookieData) {
      const gaData = {
        campaignName: utmCookieData.utm_campaign,
        campaignSource: utmCookieData.utm_source,
        campaignMedium: utmCookieData.utm_medium,
        campaignId: utmCookieData.utm_id,
        campaignContent: utmCookieData.utm_content,
      }
      ReactGA.set(gaData, trackerNames)
      ReactGA4.set({ ...gaData, send_to: trackingIdG4 })
    }

    ReactGA.set(
      { page: pathname, location: `${pathname}${search}` },
      trackerNames
    )
    ReactGA4.set({
      page: pathname,
      location: `${pathname}${search}`,
      send_to: trackingIdG4,
    })
    ReactGA.pageview(page, trackerNames)
    ReactGA4.send({ hitType: 'pageview', page, send_to: trackingIdG4 })
  }
}

const currentUserModel = new CurrentUser().init(window.UserProfile)

if (isEnv('test')) {
  window.currentUserModel = currentUserModel
}

export { currentUserModel as default }

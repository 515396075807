import { useEffect, useState, useCallback } from 'react'
import _ from 'lodash'
import { useLocation } from '~/lib/hooks/useLocation'
import { useNavigate } from '@reach/router'
import { useSideBySide } from '~/common/drill-in-drawer/hooks/useSideBySide'
import { DrillInTestInfo } from '~/common/drill-in-drawer/types'
import {
  ProjectFlakyTestsQuery,
  ProjectTopFailuresQuery,
  ViewByEnum,
} from '~/graphql-codegen-operations.gen'
import { getHashesFromUrlParam } from './util'

type DrillInNode =
  | ProjectTopFailuresQuery['metrics']['projectTopFailingTestsOverTime']['nodes'][0]
  | ProjectFlakyTestsQuery['metrics']['projectFlakinessTestsOverTime']['nodes'][0]

type useDrillIn = {
  projectId: string
  viewBy: ViewByEnum
  path: 'flaky-tests' | 'top-failures'
  nodes?: DrillInNode[]
}

export function useDrillIn({ projectId, viewBy, path, nodes }: useDrillIn) {
  const { location } = useLocation()

  const navigate = useNavigate()

  const [activeDrawerId, setActiveDrawerId] = useState('')
  const { specHash, titleHash } = getHashesFromUrlParam(activeDrawerId)

  const { pageContentRef: analyticRef } = useSideBySide(
    Boolean(specHash && titleHash)
  )
  const [testInfo, setTestInfo] = useState<DrillInTestInfo | null>(null)

  const onCloseDrawer = useCallback(() => {
    setActiveDrawerId('')
    return navigate(`/projects/${projectId}/analytics/${path}`)
  }, [navigate, path, projectId])

  useEffect(() => {
    if (nodes && activeDrawerId) {
      const testData = _.find(nodes, (node) => {
        return node.id === activeDrawerId
      })

      if (!testData) {
        setTestInfo(null)
        return
      }

      const isFlaky = (() => {
        if (testData.__typename === 'ProjectTopFailingTest') {
          return testData.isFlaky
        }

        if (testData.__typename === 'ProjectFlakinessTest') {
          return true
        }

        return false
      })()

      setTestInfo(
        testData
          ? {
              id: testData.id,
              specPath: testData.specPath,
              titleParts: testData.titleParts,
              isFlaky,
            }
          : null
      )
    }
  }, [activeDrawerId, nodes, path])

  useEffect(() => {
    if (projectId) {
      const isAnalytics = location.pathname.includes(
        `/projects/${projectId}/analytics/${path}/`
      )
      const pathId =
        location.pathname.split(
          `/projects/${projectId}/analytics/${path}/`
        )[1] || ''

      if (viewBy !== 'TEST_CASE') {
        setActiveDrawerId('')
        setTestInfo(null)
        return
      }

      if (pathId !== activeDrawerId && isAnalytics) {
        const { specHash, titleHash } = getHashesFromUrlParam(pathId)

        if (Boolean(specHash && titleHash)) {
          setActiveDrawerId(pathId)
        } else {
          onCloseDrawer()
        }
      }
    }
  }, [
    activeDrawerId,
    onCloseDrawer,
    location.pathname,
    path,
    projectId,
    viewBy,
  ])

  return {
    activeDrawerId,
    testInfo,
    analyticRef,
    titleHash,
    specHash,
    onCloseDrawer,
  }
}

import cs from 'clsx'
import { FieldProps } from 'formik'
import * as React from 'react'
import styles from '../../../../design-system/src/components/module.Radio.scss'

interface RadioButtonFieldProps
  extends FieldProps,
    Omit<React.HTMLProps<HTMLInputElement>, 'form'> {
  id: string
  title: string
  description?: string
  suppressError?: boolean
}

export const RadioButtonField: React.FC<RadioButtonFieldProps> = ({
  id,
  form,
  title,
  children,
  className,
  description,
  suppressError,
  field: { name, value = '', onChange },
  ...props
}) => {
  return (
    <div
      className={cs(
        'radio privacy-radio',
        styles.radio,
        {
          disabled: props.disabled,
        },
        className
      )}
    >
      <label
        htmlFor={`${id}-radio-input`}
        className={cs({
          [styles.label]: true,
          [styles.checked]: id === value,
          [styles.disabled]: props.disabled,
        })}
      >
        <input
          id={`${id}-radio-input`}
          data-cy={`${id}-radio-input`}
          data-pendo={`${id}-radio-input`}
          value={id}
          name={name}
          type="radio"
          checked={id === value}
          onChange={onChange}
          {...props}
        />
        <span>{title}</span>
        <p className="text-muted">{description || children}</p>
      </label>
    </div>
  )
}

import { MenuItem } from '@frontend/design-system'
import stringify from 'json-stable-stringify'
import { find, map } from 'lodash'
import React, { FunctionComponent, ReactNode, SyntheticEvent } from 'react'

import { Filter } from './Filter'

interface Option {
  value: any
  label: string
}
type options = Option[]
type onSelect = (eventKey: any, e?: SyntheticEvent<{}>) => void

interface ListFilterProps {
  id: string
  value: any
  title?: ReactNode
  emptyLabel?: string
  options: options
  onSelect: onSelect
  className?: string
}

interface OptionsListProps {
  filterId: string
  options: options
  onSelect: onSelect
}

const OptionsList: FunctionComponent<OptionsListProps> = ({
  filterId,
  options,
  onSelect,
}) => {
  return (
    <>
      {map(options, ({ value, label }, i) => {
        return (
          <MenuItem
            data-cy={`${filterId}-${value}`}
            data-pendo={`${filterId}-${value}`}
            key={i}
            eventKey={value}
            onSelect={onSelect}
          >
            {label}
          </MenuItem>
        )
      })}
    </>
  )
}

export const ListFilter: FunctionComponent<ListFilterProps> = ({
  id,
  value,
  options,
  onSelect,
  title,
  emptyLabel,
  className,
}) => {
  const renderDropDown = (): ReactNode => {
    return <OptionsList filterId={id} options={options} onSelect={onSelect} />
  }

  const renderSelectedLabel = (): ReactNode => {
    const selectedOption = find(
      options,
      (option) => stringify(option.value) === stringify(value)
    ) || {
      value: '',
      label: '',
    }

    return (
      <span className="filter__selected-label">{selectedOption.label}</span>
    )
  }

  return (
    <Filter
      id={id}
      title={title}
      renderSelectedLabel={renderSelectedLabel}
      renderDropDown={renderDropDown}
      className={className}
    />
  )
}

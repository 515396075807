import { take, takeRight } from 'lodash'
import React, { FunctionComponent, useMemo } from 'react'

import { CodeSnippet } from '~/common/code-snippet/CodeSnippet'
import { TestHistoryFragment } from '~/graphql-codegen-operations.gen'

type DiffType =
  TestHistoryFragment['history']['nodes'][0]['modifications'][0]['diff']

interface TestCodeDiffProps {
  diff: DiffType
}

const isSectionModified = (diffSection?: DiffType[0]) => {
  if (!diffSection) {
    return false
  }

  return diffSection.added || diffSection.removed
}

const formatCodeDiff = (diff: DiffType) => {
  return diff.reduce((completeDiff, diffSection, idx) => {
    const previousDiffSection = diff[idx - 1]
    const nextDiffSection = diff[idx + 1]
    const isCurrentDiffSectionModified = isSectionModified(diffSection)
    const isPreviousDiffSectionModified = isSectionModified(previousDiffSection)
    const isNextDiffSectionModified = isSectionModified(nextDiffSection)

    const sections = diffSection.value.split('\n').map((line) => {
      if (line === '') {
        return line
      }

      if (isCurrentDiffSectionModified) {
        return `${diffSection.added ? '+' : '-'}${line}`
      }

      return ` ${line}`
    })

    if (
      isCurrentDiffSectionModified ||
      (isPreviousDiffSectionModified && isNextDiffSectionModified)
    ) {
      return `${completeDiff}${sections.join('\n')}`
    }

    if (isPreviousDiffSectionModified) {
      return `${completeDiff}${take(sections, 5).join('\n')}`
    }

    if (isNextDiffSectionModified) {
      return `${completeDiff}${takeRight(sections, 5).join('\n')}`
    }

    return completeDiff
  }, '')
}

export const TestCodeDiff: FunctionComponent<TestCodeDiffProps> = ({
  diff,
}) => {
  const formattedDiff = useMemo(() => formatCodeDiff(diff), [diff])
  return <CodeSnippet shouldHideLineNumbers code={formattedDiff} />
}

import { CustomIcon, Icon } from '@frontend/design-system'
import React, { FunctionComponent } from 'react'
import { MultiSelectFilter } from '~/common/filters'
import { osIcon } from '~/lib/utils'
import { MultiSelectOptionType } from '../../types'

type ProjectOperatingSystemsFilterProps = {
  id: string
  state: ProjectOperatingSystemsFilterStateProp
}

type ProjectOperatingSystemsFilterStateProp = {
  options: MultiSelectOptionType[] | undefined
  selected: MultiSelectOptionType[] | undefined
  setSelected: (value: []) => void
}

const renderLabel = (option: MultiSelectOptionType) => {
  const label = (
    <span className="os-filter__label">
      {option.labelProperties && option.labelProperties.unformattedName && (
        <Icon
          className="fa-fw"
          name={osIcon(option.labelProperties.unformattedName)}
        />
      )}
      {option.label}
    </span>
  ) as unknown

  return label as string
}

export const ProjectOperatingSystemsFilter: FunctionComponent<ProjectOperatingSystemsFilterProps> =
  React.memo(({ id, state }) => {
    const { options = [], selected = [], setSelected } = state

    return (
      <div data-cy="os-filter" data-pendo="os-filter" className="os-filter">
        <MultiSelectFilter
          id={id}
          title={<CustomIcon name="operating-system" alt="Operating system" />}
          emptyLabel="Operating System"
          selected={selected || []}
          onChange={setSelected}
          options={options || []}
          getOptionLabel={renderLabel}
        />
      </div>
    )
  })

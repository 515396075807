import { CustomIcon } from '@frontend/design-system'
import { CINameFormatted } from '@packages/common'
import React, { FunctionComponent } from 'react'

type SpecCiProps = {
  provider: string | null
  ciUrl?: string | null
  ciBuildNumberFormatted?: string | null
  hideIcon?: boolean
}

const className = 'gap-[4px] flex items-center'

export const SpecCi: FunctionComponent<SpecCiProps> = ({
  provider,
  ciUrl,
  ciBuildNumberFormatted,
  hideIcon = false,
}) => {
  if (!provider && !(ciUrl || ciBuildNumberFormatted)) {
    return (
      <span data-cy="spec-ci" className={className}>
        {!hideIcon && <CustomIcon name="ci" variant="small" />}
        <span className="text-muted">None</span>
      </span>
    )
  }
  const formattedProvider = CINameFormatted(provider) || 'Unknown'
  return (
    <span data-cy="spec-ci" className={className}>
      {!hideIcon && <CustomIcon name="ci" variant="small" />}
      {ciUrl ? (
        <a href={ciUrl} target="_blank" rel="noopener noreferrer">
          {`${formattedProvider} ${ciBuildNumberFormatted || ''}`}
        </a>
      ) : (
        <span>{`${formattedProvider} ${ciBuildNumberFormatted || ''}`}</span>
      )}
    </span>
  )
}

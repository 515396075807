export const redirectURI = ({ isLocalhost }: { isLocalhost: boolean }) => {
  let uri = 'https://cloud.cypress.io'

  if (process.env.SERVER_ENV === 'development') {
    uri = isLocalhost
      ? 'http://localhost:3000'
      : 'https://cypress-dashboard.ngrok.io'
  } else if (process.env.SERVER_ENV === 'staging') {
    uri = 'https://cloud-staging.cypress.io'
  }

  return uri
}

import React, {
  ReactElement,
  FunctionComponent,
  useMemo,
  useState,
} from 'react'
import { first, last, filter, sortBy } from 'lodash'
import { EmptyState, Icon } from '@frontend/design-system'
import { isoParse } from 'd3-time-format'
import {
  TestHistoryItemFragment,
  TestModificationTypeEnum,
  useIntegrationBranchPromptQuery,
} from '~/graphql-codegen-operations.gen'
import { TestHistoryDisabled } from '~/test-history/TestHistoryDisabled'
import { TestTrendBranch } from './TestTrendBranch'
import { TestTrendChart } from './TestTrendChart'
import { TestTrendHeader } from './TestTrendHeader'
import { YAxis, Point, PointData, StatProps } from './types'
import { IntegrationBranchPrompt } from '~/integration-branch-prompt/IntegrationBranchPrompt'
import { IntegrationBranchPromptModal } from '~/integration-branch-prompt/IntegrationBranchPromptModal'

type TestTrendProps = {
  id: string
  projectId: string
  isLoading: boolean
  commitBranchColor: string
  integrationBranchName?: string
  commitBranchName?: string
  integrationBranchPoints: PointData[]
  commitBranchPoints: PointData[]
  isEnabled: boolean
  CommitBranchStat: (props: StatProps) => ReactElement
  IntegrationBranchStat: (props: StatProps) => ReactElement
  onModificationClick(testResultId: string): void
  history: TestHistoryItemFragment[]
  formatYAxis(series: Point[]): YAxis
}

const formatData = (data: PointData[], branch?: string): Point[] => {
  const results: Point[] = []

  data.forEach((point) => {
    const formattedX = isoParse(point.x)?.valueOf()

    if (!formattedX) {
      return
    }

    results.push({ ...point, branch, x: formattedX })
  })

  return results
}

export const TestTrend: FunctionComponent<TestTrendProps> = ({
  id,
  projectId,
  isEnabled,
  isLoading,
  CommitBranchStat,
  IntegrationBranchStat,
  commitBranchColor,
  integrationBranchName,
  commitBranchName,
  formatYAxis,
  integrationBranchPoints,
  commitBranchPoints,
  onModificationClick,
  history,
}) => {
  const [showModal, updateShowModal] = useState(false)
  const [crosshairValue, setCrosshairValue] = useState<Point | null>(null)

  const [focusedIntegrationValue, setFocusedIntegrationValue] =
    useState<Point | null>(null)

  const [focusedCommitValue, setFocusedCommitValue] = useState<Point | null>(
    null
  )

  const [integrationBranchSeries, commitBranchSeries, series] = useMemo(() => {
    const integrationBranchSeries = formatData(
      integrationBranchPoints,
      integrationBranchName
    )

    const commitBranchSeries = formatData(commitBranchPoints, commitBranchName)

    const series = sortBy(
      integrationBranchSeries.concat(commitBranchSeries),
      'x'
    )

    return [integrationBranchSeries, commitBranchSeries, series]
  }, [
    integrationBranchPoints,
    integrationBranchName,
    commitBranchPoints,
    commitBranchName,
  ])

  const { data } = useIntegrationBranchPromptQuery({
    variables: { projectId },
  })

  const formatPercentage = (
    point: Point | null,
    allPoints: PointData[] | []
  ) => {
    if (!crosshairValue) {
      return null
    }

    const firstPoint = first(allPoints)
    const lastPoint = last(allPoints)

    /* istanbul ignore next */
    if (!firstPoint || !lastPoint) {
      return null
    }

    if (
      !point ||
      !(crosshairValue.x >= firstPoint.x && crosshairValue.x <= lastPoint.x)
    ) {
      return '-'
    }

    return Number(point.y)
  }

  const firstInSeries = first(series)
  const lastInSeries = last(series)
  const startDate = firstInSeries?.x
  const endDate = lastInSeries?.x

  const filteredNodes = useMemo(() => {
    const commitBranchModificationTypes = new Set<TestModificationTypeEnum>()
    const integrationBranchModificationTypes =
      new Set<TestModificationTypeEnum>()

    return filter(history, (node) => {
      const createdAt = new Date(node.run.createdAt).getTime()
      /* istanbul ignore next */
      if (!startDate || !endDate) {
        return true
      }

      if (!(createdAt >= startDate && createdAt <= endDate)) {
        return false
      }

      if (node.run.commit.branch === integrationBranchName) {
        integrationBranchModificationTypes.add(node.modificationType)
      }

      if (node.run.commit.branch === commitBranchName) {
        commitBranchModificationTypes.add(node.modificationType)
      }

      return true
    }).reverse()
  }, [integrationBranchName, commitBranchName, history, startDate, endDate])

  if (!isEnabled) {
    return <TestHistoryDisabled />
  }

  if (isLoading) {
    return (
      <div className="test-results-drawer__loader">
        <Icon name="spinner" spin />
      </div>
    )
  }

  if (series.length === 0) {
    return (
      <div data-cy={id}>
        <EmptyState>
          <p>Not enough data in the last 14 days</p>
        </EmptyState>
      </div>
    )
  }

  const defaultBranch = data?.project.defaultBranch

  return (
    <div className="test-trend" data-cy={id}>
      <TestTrendHeader
        focusedValue={crosshairValue}
        integrationBranchPoints={integrationBranchPoints}
        commitBranchPoints={commitBranchPoints}
      />
      {(commitBranchName || integrationBranchName) && (
        <div className="test-trend__summary">
          {commitBranchName && (
            <TestTrendBranch
              branchName={commitBranchName}
              color={commitBranchColor}
            >
              <CommitBranchStat
                percentage={formatPercentage(
                  focusedCommitValue,
                  commitBranchSeries
                )}
              />
            </TestTrendBranch>
          )}
          {integrationBranchName ? (
            <TestTrendBranch
              branchName={integrationBranchName}
              updateShowModal={updateShowModal}
            >
              <IntegrationBranchStat
                percentage={formatPercentage(
                  focusedIntegrationValue,
                  integrationBranchSeries
                )}
              />
            </TestTrendBranch>
          ) : (
            commitBranchName !== defaultBranch && (
              <IntegrationBranchPrompt updateShowModal={updateShowModal} />
            )
          )}
        </div>
      )}
      <TestTrendChart
        commitBranchColor={commitBranchColor}
        crosshairValue={crosshairValue}
        setCrosshairValue={setCrosshairValue}
        setFocusedIntegrationValue={setFocusedIntegrationValue}
        setFocusedCommitValue={setFocusedCommitValue}
        commitBranchSeries={commitBranchSeries}
        integrationBranchSeries={integrationBranchSeries}
        series={series}
        yAxis={formatYAxis(series)}
        integrationBranchName={integrationBranchName}
        commitBranchName={commitBranchName}
        history={filteredNodes}
        onModificationClick={onModificationClick}
      />
      <IntegrationBranchPromptModal
        projectId={projectId}
        showModal={showModal}
        updateShowModal={updateShowModal}
      />
    </div>
  )
}

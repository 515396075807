import { palette, Button, EmptyState, Panel } from '@frontend/design-system'
import cs from 'clsx'
import commaNumber from 'comma-number'
import { take } from 'lodash'
import React, { FunctionComponent, useState } from 'react'
import { LoadingContainer } from '~/project-analytics/LoadingContainer'

import { ProjectMostCommonErrorsQuery } from '~/graphql-codegen-operations.gen'
import { TestResultTitleProvider } from '~/test-results/hooks/useTitleTruncation'
import { MostCommonErrorsEmptyState } from '../MostCommonErrorsEmptyState'
import { AnalyticRowLink } from '../AnalyticRowLink/AnalyticRowLink'
import styles from '../module.AnalyticsTable.scss'

type MostCommonErrorsTableProps = {
  data?: ProjectMostCommonErrorsQuery
  colorMap: { [key: string]: string }
  isLoading: boolean
}

export const MostCommonErrorsTable: FunctionComponent<
  MostCommonErrorsTableProps
> = ({ data, colorMap, isLoading }) => {
  const PER_PAGE = 30
  const [numberOfTestsShown, setNumberOfTestsShown] = useState(PER_PAGE)

  if (!data || !data.metrics) {
    return (
      <Panel>
        <EmptyState>
          <span>Loading...</span>
        </EmptyState>
      </Panel>
    )
  }

  const loadMoreTests = () => {
    setNumberOfTestsShown(numberOfTestsShown + PER_PAGE)
  }

  const project = data.project
  const metrics = data.metrics.projectCommonErrors
  const rows = take(metrics.nodes, numberOfTestsShown) || []

  return (
    <LoadingContainer active={isLoading}>
      <Panel>
        {metrics.nodes.length < 1 ? (
          <EmptyState>
            <MostCommonErrorsEmptyState
              shouldUpdateCypressVersion={project.shouldUpdateCypressVersion5}
            />
          </EmptyState>
        ) : (
          <div
            className={cs(styles.analyticsTable, styles.mostCommonErrorsTable)}
            data-cy="most-common-errors-table"
          >
            <AnalyticRowLink dataCy="most-common-errors-table__header">
              <h6 />
              <h6>Error</h6>
              <h6>
                <span className={styles.textRight}>Impacted Tests</span>
              </h6>
            </AnalyticRowLink>

            <TestResultTitleProvider>
              {rows.map((row, i) => {
                const backgroundColor =
                  colorMap[row.error.name] || palette.red400

                return (
                  <AnalyticRowLink
                    dataCy="most-common-errors-table__row"
                    key={row.id}
                  >
                    <div>
                      <div
                        className={cs(styles.rowColor, styles.textRight)}
                        style={{ backgroundColor }}
                      />
                    </div>
                    <div>
                      <div>
                        <h5 className={styles.rowErrorName}>
                          {row.error.name}
                        </h5>
                        <p className={styles.rowErrorMessage}>
                          {row.error.message}
                        </p>
                      </div>
                    </div>
                    <div>
                      <span
                        className={cs(
                          styles.textRight,
                          styles.totalCount,
                          'text-muted'
                        )}
                      >
                        {commaNumber(row.totalResults)}
                      </span>
                    </div>
                  </AnalyticRowLink>
                )
              })}
              {metrics.nodes.length > numberOfTestsShown && (
                <div key={metrics.nodes.length} className={styles.footer}>
                  <Button bsStyle="link" onClick={loadMoreTests}>
                    Load more
                  </Button>
                </div>
              )}
            </TestResultTitleProvider>
          </div>
        )}
      </Panel>
    </LoadingContainer>
  )
}

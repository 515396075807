import cs from 'clsx'
import Prism from 'prismjs'
import React, {
  FunctionComponent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Tooltip } from '@frontend/design-system/src/components/Tooltip'
import ButtonDesign from '@cypress-design/react-button'
import { useCopyText } from '../../lib/hooks/useCopyText'
import {
  IconDocumentBlank,
  IconArrowExpand,
  IconGeneralClipboard,
} from '@cypress-design/react-icon'

Prism.plugins.NormalizeWhitespace?.setDefaults({
  'remove-trailing': true,
  'remove-indent': true,
  'left-trim': true,
  'right-trim': true,
  'tabs-to-spaces': 2,
})

type CodeSnippetProps = {
  code: string
  shouldHideLineNumbers?: boolean
}

// Legacy styling and layout for code snippets
export const CodeSnippet: FunctionComponent<CodeSnippetProps> = ({
  code,
  shouldHideLineNumbers = false,
}) => {
  useLayoutEffect(() => {
    Prism.highlightAll()
  }, [])

  return (
    <pre
      className={cs('code-snippet', {
        'line-numbers': !shouldHideLineNumbers,
      })}
    >
      <code className="language-diff-javascript diff-highlight">{code}</code>
    </pre>
  )
}

type CodeSnippetV2 = {
  code: string
  copyText: string
  withCopyBtn?: boolean
  copyBtnPendo?: string
  overlayDefaultText?: string
  fileName?: string
  variant?: 'design-system-candidate' | 'test-replay' // if this enters DS, plz update accordingly,
  language?: 'javascript' | 'yml' | 'markup'
  collapsed?: boolean
  iconOnlyCopyBtn?: boolean
  overlayClassName?: string
}

export const CopyCodeButton = ({
  copyText,
  overlayDefaultText = 'Copy code',
  copyBtnPendo,
  iconOnlyCopyBtn = false,
  overlayClassName = '',
}: {
  copyText: string
  overlayDefaultText: string
  copyBtnPendo?: string
  iconOnlyCopyBtn: boolean
  overlayClassName?: string
}) => {
  const { onCopy } = useCopyText()
  const copiedOverlay = 'Copied!'
  const [overlayText, setOverlayText] = React.useState(overlayDefaultText)
  return (
    <Tooltip
      overlayClassName={overlayClassName}
      placement="top"
      overlay={<p style={{ marginBottom: -10 }}>{overlayText}</p>}
      onVisibleChange={() => setOverlayText(overlayDefaultText)}
    >
      <div className="copy-btn-container">
        <CopyToClipboard
          text={copyText}
          onCopy={() => {
            onCopy()
            setOverlayText(copiedOverlay)
          }}
        >
          <div className="code-snippet-button-wrapper">
            <ButtonDesign
              className="code-snippet-copy-btn"
              data-pendo={copyBtnPendo}
              data-cy="code-snippet-copy-btn"
            >
              <IconGeneralClipboard
                className="copy-icon"
                name="general-clipboard"
              />{' '}
              {!iconOnlyCopyBtn && <span>Copy!</span>}
            </ButtonDesign>
          </div>
        </CopyToClipboard>
      </div>
    </Tooltip>
  )
}

// Next-gen styling and layout for code snippets
export const CodeSnippetV2: FunctionComponent<CodeSnippetV2> = ({
  code,
  copyText,
  withCopyBtn,
  copyBtnPendo,
  overlayDefaultText = 'Copy code',
  variant = '',
  fileName,
  language = 'javascript',
  collapsed,
  iconOnlyCopyBtn = false,
  overlayClassName,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed)
  const [codeSnippet, setCodeSnippet] = useState(code)
  const [showExpand, setShowExpand] = useState(false)

  useEffect(() => {
    if (isCollapsed) {
      const splitLine = 5
      const splitCode = code.split(/\r\n|\r|\n/)

      setShowExpand(splitCode.length > splitLine)
      setCodeSnippet(splitCode.slice(0, splitLine).join('\r\n'))
    } else {
      setCodeSnippet(code)
    }
  }, [code, isCollapsed])

  const numRows = React.useMemo(() => {
    const codeHeight = codeSnippet.split(/\r\n|\r|\n/).length
    const rowNumbers: JSX.Element[] = []
    for (let i = 0; i < codeHeight; i++) {
      const num = i + 1
      rowNumbers.push(
        <div key={num} className="row-num">
          {num}
        </div>
      )
    }
    return rowNumbers
  }, [codeSnippet])

  const copyButton = withCopyBtn && (
    <div className="col-copy-btn" style={{ marginTop: -4 }}>
      <CopyCodeButton
        iconOnlyCopyBtn={iconOnlyCopyBtn}
        copyText={copyText}
        copyBtnPendo={copyBtnPendo}
        overlayDefaultText={overlayDefaultText}
        overlayClassName={overlayClassName}
      />
    </div>
  )

  useLayoutEffect(() => {
    Prism.highlightAll()
  }, [codeSnippet])

  return (
    <div className={`code-snippet ${variant}`} data-cy="code-snippet">
      {fileName && (
        <div className="code-snippet-file-name">
          <div data-cy="file-name">
            <IconDocumentBlank
              size="16"
              strokeColor="gray-500"
              fillColor="gray-100"
            />
            {fileName}
          </div>
          {copyButton}
        </div>
      )}
      <div className="code-snippet-container-v2">
        <div className="col-nums">{numRows}</div>
        <div className="col-code">
          <pre className="code-snippet-v2">
            <code className={`language-${language}`}>{codeSnippet}</code>
          </pre>
          {showExpand && isCollapsed && (
            <div className="code-expand">
              <ButtonDesign
                onClick={() => setIsCollapsed(false)}
                className="expand-btn"
                variant="outline-light"
              >
                <IconArrowExpand className="expand-icon" /> Expand
              </ButtonDesign>
            </div>
          )}
        </div>
        {!fileName && copyButton}
      </div>
    </div>
  )
}

export const CodeSnippetError = ({
  frame,
  line,
  language,
}: {
  frame: string
  line: number | null
  language: string | null
}) => {
  const codeFrame = useRef(null)
  const [isHighlighted, setIsHighlighted] = useState(false)

  useLayoutEffect(() => {
    if (codeFrame.current && !isHighlighted) {
      setIsHighlighted(true)
      Prism.highlightAllUnder(codeFrame.current)
    }
  }, [isHighlighted])

  const highlightLine = Math.min(line ?? 0, 3)

  return (
    <div className="code-snippet-error">
      <pre
        ref={codeFrame}
        data-line={highlightLine}
        className="no-whitespace-normalization"
      >
        <code className={`code-frame language-${language || 'text'}`}>
          {frame}
        </code>
      </pre>
    </div>
  )
}

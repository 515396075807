import React, { useState, useEffect, FunctionComponent } from 'react'
import { JiraSelect } from './JiraSelect'
import { useDebounce } from 'use-debounce'
import styles from './module.CreateJiraIssuePrompt.scss'
import { Button, Icon } from '@frontend/design-system/src'
import {
  useJiraIssueSearchLazyQuery,
  useLinkJiraIssueMutation,
} from '../graphql-codegen-operations.gen'
import { map } from 'lodash'
import store from '~/lib/store'

type LinkJiraIssueProps = {
  orgId: string
  updateShowModal: (v: boolean) => void
  specHash: string
  titleHash: string
}

export const LinkJiraIssue: FunctionComponent<LinkJiraIssueProps> = ({
  orgId,
  specHash,
  titleHash,
  updateShowModal,
}) => {
  const [searchInput, setSearchInput] = useState<string>()
  const [selectedIssue, setSelectedIssue] = useState<
    { id: string; key: string; self: string } | undefined
  >()
  const [debouncedSearchInput] = useDebounce(searchInput, 400)

  const [jiraIssueSearch, { data, loading }] = useJiraIssueSearchLazyQuery()

  useEffect(() => {
    if (debouncedSearchInput) {
      jiraIssueSearch({
        variables: {
          orgId,
          search: debouncedSearchInput,
        },
      })
    }
  }, [orgId, debouncedSearchInput, jiraIssueSearch])

  const [linkJiraIssueMutation, { loading: isSubmitting }] =
    useLinkJiraIssueMutation({
      refetchQueries: ['DrawerJiraIssue', 'DrawerTestResultAnalyticJiraIssue'],
    })

  const handleLinkJiraIssue = () => {
    if (!selectedIssue) {
      return
    }

    linkJiraIssueMutation({
      variables: {
        input: {
          orgId,
          specHash,
          titleHash,
          issueId: selectedIssue.id,
          issueKey: selectedIssue.key,
          issueMetadataUrl: selectedIssue.self,
        },
      },
    })
      .then(() => {
        store.setNotification('Issue was successfully linked.')
        updateShowModal(false)
      })
      .catch(() => {
        store.setNotification(
          'There was an error linking the issue.',
          'failure'
        )
      })
  }

  const options = map(data?.organization?.jiraIssueSearch, (issueSearch) => ({
    label: `[${issueSearch.key}] ${issueSearch.summary}`,
    value: JSON.stringify(issueSearch),
  }))
  return (
    <>
      <JiraSelect
        label="Jira Issue"
        instanceId="jira-project-autocomplete"
        defaultOption={{
          label: '',
          value: '',
        }}
        options={options}
        isLoading={loading}
        onInputChange={setSearchInput}
        onChange={(val) => {
          const parsedValue = JSON.parse(val.value)
          setSelectedIssue(parsedValue)
        }}
        placeholder="Search a Jira issue"
      />
      <div className={styles.submitContainer}>
        <Button
          bsStyle="primary"
          type="submit"
          disabled={isSubmitting}
          className={styles.submitBtn}
          onClick={handleLinkJiraIssue}
        >
          {isSubmitting && (
            <span>
              <Icon name="refresh" className="fa-spin" />{' '}
            </span>
          )}
          Link issue
        </Button>
      </div>
    </>
  )
}

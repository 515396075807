import React, {
  FunctionComponent,
  useState,
  useCallback,
  useEffect,
} from 'react'
import { isString } from 'lodash'
import { Modal, Button } from '@frontend/design-system'
import {
  useIntegrationBranchPromptQuery,
  useProjectSettingsUpdateMutation,
} from '~/graphql-codegen-operations.gen'
import { ProjectBranchSelect } from '~/lib/form'
import store from '~/lib/store'
import styles from './module.IntegrationBranchPrompt.scss'
import { useLocation } from '@reach/router'

type IntegrationBranchProps = {
  onChangeIntegrationBranch?: () => void
  projectId: string
  showModal: boolean
  updateShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const IntegrationBranchPromptModal: FunctionComponent<
  IntegrationBranchProps
> = ({
  onChangeIntegrationBranch = () => {},
  projectId,
  showModal,
  updateShowModal,
}) => {
  const location = useLocation()

  const [branch, updateBranch] = useState<string | null>(null)
  const [defaultAnalytics, toggleDefaultAnalytics] = useState(false)

  const { data } = useIntegrationBranchPromptQuery({
    variables: { projectId },
  })
  const defaultBranch = data?.project.defaultBranch
  const hasValidDefaultBranch =
    isString(defaultBranch) && defaultBranch !== 'master'

  useEffect(() => {
    if (hasValidDefaultBranch) {
      updateBranch(defaultBranch!)
    }
  }, [hasValidDefaultBranch, defaultBranch])

  useEffect(() => {
    toggleDefaultAnalytics(data?.project.defaultBranchAnalytics ?? false)
  }, [data])

  const onHide = useCallback(() => {
    updateShowModal(false)
  }, [updateShowModal])

  const [projectSettingsMutation, { loading }] =
    useProjectSettingsUpdateMutation()

  const onSave = () => {
    projectSettingsMutation({
      variables: {
        input: {
          id: projectId,
          defaultBranch: branch,
          defaultBranchAnalytics: defaultAnalytics,
        },
      },
      refetchQueries: location.pathname.includes('analytics')
        ? [
            'DrawerTestResultAnalyticHistoricalFailureRate',
            'DrawerTestResultAnalyticHistoricalFlakiness',
          ]
        : [
            'DrawerTestResultHistory',
            'DrawerTestResultHistoricalFailureRate',
            'DrawerTestResultHistoricalPerformance',
            'DrawerTestResultHistoricalFlakiness',
          ],
    }).then(() => {
      updateShowModal(false)
      onChangeIntegrationBranch()
      store.setNotification('Project was successfully updated.')
    })
  }

  return (
    <Modal show={showModal} onHide={onHide} backdrop>
      <Modal.Header closeButton>
        <h3 className="modal-title">Set your integration branch</h3>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.body}>
          We’ll use this branch as a baseline for failure, flakiness, previous
          runs, and performance analysis.
        </div>
        <ProjectBranchSelect
          selected={branch}
          projectId={projectId}
          onChange={updateBranch}
          defaultAnalytics={defaultAnalytics}
          onToggleDefaultAnalytics={toggleDefaultAnalytics}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={!branch || loading}
          onClick={onSave}
          bsStyle="primary"
        >
          Save settings
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

import {
  CollapsingSection,
  CustomIcon,
  HorizontalList,
  Tooltip,
} from '@frontend/design-system'
import moment from 'moment'
import pluralize from 'pluralize'
import React, { FunctionComponent, useEffect, useState } from 'react'
import {
  JiraIssueFragment,
  JiraIssueAssigneeFragment,
} from '~/graphql-codegen-operations.gen'
import styles from './module.DrawerJiraIssue.scss'

type TestResultDrawerJiraIssueProps = {
  jiraIssues: JiraIssueFragment[]
}

type IssueAssigneeProps = {
  assignee?: JiraIssueAssigneeFragment | null
}

const IssueAssignee: FunctionComponent<IssueAssigneeProps> = ({ assignee }) => {
  if (!assignee) {
    return (
      <>
        <CustomIcon name="avatar" variant="small" />
        <span className={styles.assignedUser}>Unassigned</span>
      </>
    )
  }

  return (
    <>
      <img src={assignee.avatar} alt="" />
      <span className={styles.assignedUser}>{assignee.name}</span>
    </>
  )
}

export const DrawerJiraIssue: FunctionComponent<
  TestResultDrawerJiraIssueProps
> = ({ jiraIssues }) => {
  const [sectionSubtitle, setSectionSubtitle] = useState<string | undefined>()

  useEffect(() => {
    const openIssues = jiraIssues.filter(
      (jiraIssue) => jiraIssue.status !== 'Done'
    )

    if (openIssues.length) {
      setSectionSubtitle(
        `${openIssues.length} open ${pluralize('issue', openIssues.length)}`
      )
    } else {
      setSectionSubtitle('')
    }
  }, [jiraIssues])

  if (jiraIssues.length < 1) {
    return null
  }

  return (
    <div data-cy="jira-issue">
      <CollapsingSection
        title="Jira issue"
        subtitle={sectionSubtitle}
        shouldBeCollapsed
        data-pendo="test-result-drawer--jira-issue"
      >
        <div data-cy="jira-issues">
          {jiraIssues.map((jiraIssue) => (
            <a
              key={jiraIssue.key}
              className={styles.row}
              data-cy="jira-issue-row"
              href={jiraIssue.url}
              target="_blank"
            >
              <div className={styles.icon}>
                <CustomIcon name="jira" />
              </div>
              <div className={styles.issueHeader}>
                <span className={styles.issueTitle}>{jiraIssue.key}</span>
                <span className={styles.issueStatus}>{jiraIssue.status}</span>
              </div>
              <div className={styles.issueDescription}>
                <HorizontalList margin={8}>
                  <Tooltip placement="bottom" overlay="Assignee">
                    <div data-cy="jira-issue-assignee">
                      <IssueAssignee assignee={jiraIssue.assignee} />
                    </div>
                  </Tooltip>
                  <span>
                    <CustomIcon name="time" variant="small" />
                    {moment(jiraIssue.createdAt).fromNow()}
                  </span>
                </HorizontalList>
              </div>
            </a>
          ))}
        </div>
      </CollapsingSection>
    </div>
  )
}

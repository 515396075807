import { IntelligenceVariant } from './types'

export const formatPercentage = (percentage: number | null | undefined) => {
  if (percentage === undefined || percentage === null) {
    return 'N/A'
  }

  if (percentage > 0 && percentage < 1) {
    return `<1%`
  }

  if (percentage > 99 && percentage < 100) {
    return `>99%`
  }

  return `${Math.round(percentage)}%`
}

export const getProperNoun = (
  value: IntelligenceVariant,
  opts?: {
    short?: boolean
    lowercase?: boolean
  }
) => {
  let properNoun: string = ''

  switch (value) {
    case 'ui-coverage':
      properNoun = opts?.lowercase ? 'UI coverage' : 'UI Coverage'
      break
    case 'accessibility':
      properNoun = opts?.short ? 'Accessibility' : 'Cypress Accessibility'
      if (opts?.lowercase) {
        properNoun = properNoun.replace('Accessibility', 'accessibility')
      }
      break
    default:
      break
  }

  return properNoun
}

export const formatList = (list: string[]) => {
  if (list.length <= 1) {
    return list[0]
  }
  const lastItem = list.pop()
  return `${list.join(', ')} and ${lastItem}`
}

import React, { FunctionComponent } from 'react'
import { Button, PanelEmptyState } from '@frontend/design-system'

import {
  FlakyRateEmptyStateProjectFragment,
  ProjectContainerProjectFragment,
  FlakyRateEmptyStateRunFragment,
} from '~/graphql-codegen-operations.gen'

interface FlakyRateEmptyStateProps {
  project: ProjectContainerProjectFragment | FlakyRateEmptyStateProjectFragment
  run?: FlakyRateEmptyStateRunFragment
  heading?: string
  withoutShadow?: boolean
  subscriptionFeature?: 'flakeDetection' | 'flakeUX'
}

const shouldUpgradeToCypress5 = ({
  project,
  run,
}: FlakyRateEmptyStateProps) => {
  return (
    project.shouldUpdateCypressVersion5 &&
    (!run || (run && !isRunOnFlakyTestAnalysisVersion({ run })))
  )
}

const shouldEnableRetries = ({ project, run }: FlakyRateEmptyStateProps) => {
  return (
    !project.isUsingRetries &&
    (!run || (run && run.status !== 'RUNNING' && !run.isUsingRetries))
  )
}

const doesProjectSupportFlakyTestAnalysis = ({
  project,
}: {
  project: ProjectContainerProjectFragment | FlakyRateEmptyStateProjectFragment
}) => {
  return !project.shouldUpdateCypressVersion5 && project.isUsingRetries
}

const isRunOnFlakyTestAnalysisVersion = ({
  run,
}: {
  run: FlakyRateEmptyStateRunFragment
}) => {
  return parseFloat(run.cypressVersion) >= 5
}

const doesProjectSupportFlakyTestAnalysisButRunDoesNot = ({
  project,
  run,
}: {
  project: ProjectContainerProjectFragment | FlakyRateEmptyStateProjectFragment
  run: FlakyRateEmptyStateRunFragment
}) => {
  return (
    run.status !== 'RUNNING' &&
    doesProjectSupportFlakyTestAnalysis({ project }) &&
    !isRunOnFlakyTestAnalysisVersion({ run })
  )
}

export const shouldShowFlakyRateEmptyState = ({
  project,
  run,
  subscriptionFeature = 'flakeDetection',
}: FlakyRateEmptyStateProps) => {
  return (
    !project.organizationInfo.subscription.features[subscriptionFeature] ||
    shouldUpgradeToCypress5({ project, run }) ||
    shouldEnableRetries({ run, project }) ||
    (run && doesProjectSupportFlakyTestAnalysisButRunDoesNot({ project, run }))
  )
}

export const FlakyRateEmptyState: FunctionComponent<
  FlakyRateEmptyStateProps
> = ({
  project,
  run,
  heading,
  withoutShadow = true,
  subscriptionFeature = 'flakeDetection',
}) => {
  return (
    <div data-cy="flaky-rate-empty-state">
      {(() => {
        if (
          !project.organizationInfo.subscription.features[subscriptionFeature]
        ) {
          if (project.organizationInfo.viewerIsOwnerOrAdmin) {
            return (
              <PanelEmptyState
                heading={heading}
                subtitle={
                  <>
                    See the history and severity of flake across your test runs.
                    Available with <b>Team</b> plans.
                  </>
                }
                ctaLinkMessage="Learn more"
                ctaLinkUrl="http://on.cypress.io/flake-detection"
                ctaButtonMessage="Upgrade"
                ctaButtonUrl={`/organizations/${project.organizationInfo.id}/pricing`}
                withoutShadow={withoutShadow}
              />
            )
          }

          return (
            <PanelEmptyState
              heading={heading}
              subtitle={
                <>
                  See the history and severity of flake across your test runs.
                  Available with <b>Team</b> plans.
                </>
              }
              ctaLinkMessage="Learn more"
              ctaLinkUrl="http://on.cypress.io/flake-detection"
              ctaButton={
                <Button bsSize="sm" disabled>
                  Upgrade
                </Button>
              }
              withoutShadow
            />
          )
        }

        if (!project.hasRecordedRuns) {
          return (
            <PanelEmptyState
              heading={heading}
              title="Complete a test run to see flaky test analysis."
              subtitle="See which tests are flaking by enabling Test Retries in the most current version of Cypress. Test Retries was enabled in Cypress 5."
              ctaButtonUrl="https://docs.cypress.io/guides/references/migration-guide"
              ctaButtonMessage="Cypress 5 Migration"
              withoutShadow
            />
          )
        }

        if (shouldUpgradeToCypress5({ run, project })) {
          return (
            <PanelEmptyState
              heading={heading}
              title="Upgrade to the most recent version of Cypress to see flaky test analysis."
              subtitle="Test Retries must be enabled, and requires Cypress 5 or greater."
              ctaButtonUrl="https://docs.cypress.io/guides/references/migration-guide"
              ctaButtonMessage="Cypress 5 Migration"
              withoutShadow
            />
          )
        }

        if (shouldEnableRetries({ run, project })) {
          return (
            <PanelEmptyState
              heading={heading}
              title="Enable retries to see flaky test analysis."
              subtitle="See which tests are flaky by enabling test retries."
              ctaButtonUrl="https://docs.cypress.io/guides/guides/test-retries.html#Introduction"
              ctaButtonMessage="Learn how"
              withoutShadow
            />
          )
        }

        if (
          run &&
          doesProjectSupportFlakyTestAnalysisButRunDoesNot({ project, run })
        ) {
          return (
            <PanelEmptyState
              heading={heading}
              title="This run does not support flaky test analysis."
              subtitle="This project is using test retries, so future runs will show which tests are flaky."
              ctaLinkUrl="https://docs.cypress.io/guides/references/migration-guide.html#Tests-retries"
              ctaLinkMessage="Learn more"
              withoutShadow
            />
          )
        }

        /* istanbul ignore next */
        return null
      })()}
    </div>
  )
}

import React, { FunctionComponent, useEffect, useState } from 'react'
import UilCloudDownload from '@iconscout/react-unicons/icons/uil-cloud-download'
import { PanelEmptyState, Tooltip } from '@frontend/design-system'
import DocumentTitle from 'react-document-title'
import fileDownload from 'js-file-download'
import {
  formatDateRangeDateISO,
  getValuesFromMultiSelectOptions,
} from '~/common/filters'
import {
  useProjectMostCommonErrorsQuery,
  ProjectMostCommonErrorsQuery,
} from '~/graphql-codegen-operations.gen'
import { FiltersContainer } from '~/common/filters/FiltersContainer'
import { StackedLayout } from '~/common/stacked-layout'
import { ErrorBoundaryContent } from '~/lib/error-boundary'
import { sendEventCustom } from '~/lib/page-events'
import * as analytics from '~/data/projects/analytics/hooks'
import { downloadAnalyticsCSV, getAnalyticsCSVFileName } from '../analytics-api'
import Filters from '../filters/Filters'
import { Kpis } from '../Kpis'
import { MostCommonErrorsTable } from './MostCommonErrorsTable'
import { getKPIs } from './MostCommonErrorsKPI'
import { MostCommonErrorsBreakdown } from './MostCommonErrorsBreakdown'
import { useMostCommonErrors } from './useMostCommonErrors'

type MostCommonErrorsAnalyticProps = {
  projectId: string
  familyId: string
}

export const MostCommonErrorsAnalytic: FunctionComponent<
  MostCommonErrorsAnalyticProps
> = ({ projectId, familyId }) => {
  const [mostCommonErrorsAnalytic, setMostCommonErrorsAnalytic] =
    useState<ProjectMostCommonErrorsQuery>()
  const { mostCommonErrors, setMostCommonErrors } =
    analytics.useMostCommonErrors()
  const { selected: selectedErrorClasses, setSelected: setSelectedErrorClass } =
    analytics.useChartRangeMostCommonErrorsFilter(familyId)
  const { selected: branches } = analytics.useBranchesFilter(familyId)
  const { timeRange } = analytics.useTimeRangeFilter(familyId)
  const { selected: tags, matchType: tagsMatch } =
    analytics.useTagsFilter(familyId)
  const { selected: specFiles } = analytics.useSpecFilesFilter(familyId)
  const { selected: runGroups } = analytics.useRunGroupsFilter(familyId)
  const { selected: browsers } = analytics.useBrowsersFilter(familyId)
  const { selected: committers } = analytics.useCommittersFilter(familyId)
  const { selected: flaky } = analytics.useFlakyFilter(familyId)
  const { selected: cypressVersions } =
    analytics.useCypressVersionsFilter(familyId)
  const { selected: operatingSystems } =
    analytics.useOperatingSystemsFilter(familyId)

  const selectedErrorClass = selectedErrorClasses?.[0]

  analytics.useEventTracking(
    'most-common-errors',
    'Most common errors',
    familyId
  )

  // Don't add the tagsMatch parameter if there is no tags parameter.
  const tagsMatchOrUndefined = getValuesFromMultiSelectOptions(tags || [])
    ? tagsMatch
    : undefined

  const {
    data,
    loading: queryRunning,
    error,
  } = useProjectMostCommonErrorsQuery({
    variables: {
      id: projectId,
      input: {
        projectId,
        timeRange: {
          startDate: formatDateRangeDateISO(timeRange?.startDate),
          endDate: formatDateRangeDateISO(timeRange?.endDate),
        },
        branches: getValuesFromMultiSelectOptions(branches || []),
        tags: getValuesFromMultiSelectOptions(tags || []),
        tagsMatch: tagsMatchOrUndefined,
        flakyTests: getValuesFromMultiSelectOptions(flaky || []),
        specs: getValuesFromMultiSelectOptions(specFiles || []),
        runGroups: getValuesFromMultiSelectOptions(runGroups || []),
        browsers: getValuesFromMultiSelectOptions(browsers || []),
        committers: getValuesFromMultiSelectOptions(committers || []),
        cypressVersions: getValuesFromMultiSelectOptions(cypressVersions || []),
        oses: getValuesFromMultiSelectOptions(operatingSystems || []),
      },
    },
  })

  useEffect(() => {
    if (!queryRunning && data) {
      setMostCommonErrors(data)
    }
  }, [data, queryRunning, setMostCommonErrors])

  const [errors, commonErrorNames, colorMap] =
    useMostCommonErrors(mostCommonErrors)

  useEffect(() => {
    if (!selectedErrorClass) {
      setMostCommonErrorsAnalytic(mostCommonErrors)
      return
    }

    const filteredNodes = (() => {
      const nodes = mostCommonErrors?.metrics.projectCommonErrors.nodes || []
      if (selectedErrorClass === 'Other') {
        const commonErrorsSet = new Set(commonErrorNames)
        return nodes.filter(({ error }) => !commonErrorsSet.has(error.name))
      }

      return nodes.filter(({ error }) => error.name === selectedErrorClass)
    })()

    setMostCommonErrorsAnalytic({
      ...mostCommonErrors,
      project: {
        id: projectId,
        hasRecordedRuns:
          mostCommonErrorsAnalytic?.project.hasRecordedRuns || false,
        shouldUpdateCypressVersion5:
          mostCommonErrors?.project.shouldUpdateCypressVersion5 || false,
      },
      metrics: {
        projectCommonErrors: {
          nodes: filteredNodes,
        },
      },
    })
  }, [
    commonErrorNames,
    selectedErrorClass,
    mostCommonErrors,
    projectId,
    mostCommonErrorsAnalytic?.project.hasRecordedRuns,
  ])

  async function downloadCSV() {
    const fileName = getAnalyticsCSVFileName('most-common-errors', timeRange)
    const response = await downloadAnalyticsCSV({
      id: projectId,
      type: 'most-common-errors',
      params: {
        startDate: formatDateRangeDateISO(timeRange?.startDate),
        endDate: formatDateRangeDateISO(timeRange?.endDate),
        flakyTests: getValuesFromMultiSelectOptions(flaky || []),
        branches: getValuesFromMultiSelectOptions(branches || []),
        tags: getValuesFromMultiSelectOptions(tags || []),
        tagsMatch: tagsMatchOrUndefined,
        specs: getValuesFromMultiSelectOptions(specFiles || []),
        runGroups: getValuesFromMultiSelectOptions(runGroups || []),
        browsers: getValuesFromMultiSelectOptions(browsers || []),
        committers: getValuesFromMultiSelectOptions(committers || []),
        cypressVersions: getValuesFromMultiSelectOptions(cypressVersions || []),
        oses: getValuesFromMultiSelectOptions(operatingSystems || []),
      },
    })
    /* istanbul ignore next */
    sendEventCustom('Analytics', 'Export', {
      analyticID: 'most-common-errors',
      label: 'Most common errors',
    })

    if (!window.Cypress) {
      /* istanbul ignore next */
      fileDownload(response, `${fileName}.csv`)
    }
  }

  const isLoading = queryRunning || error !== undefined
  const kpis = getKPIs(mostCommonErrorsAnalytic, queryRunning)

  return (
    <DocumentTitle title="Most common errors">
      <StackedLayout title="Most common errors" className="no-content-padding">
        <div className="project-analytics">
          <div className="most-common-errors-analytic analytic">
            {(() => {
              if (
                mostCommonErrorsAnalytic?.project.hasRecordedRuns === false &&
                !error
              ) {
                return (
                  <PanelEmptyState
                    title="Complete a test run to see most common error analysis."
                    subtitle="See the most common errors by enabling Test Retries in the most current version of Cypress. Test Retries was enabled in Cypress 5."
                    ctaButtonUrl="https://docs.cypress.io/guides/references/migration-guide"
                    ctaButtonMessage="Learn how"
                  />
                )
              }

              if (
                mostCommonErrorsAnalytic?.project.shouldUpdateCypressVersion5
              ) {
                return (
                  <PanelEmptyState
                    title="Upgrade to the most recent version of Cypress to see most common error analysis."
                    subtitle="Test Retries must be enabled, and requires Cypress 5 or greater."
                    ctaButtonUrl="https://docs.cypress.io/guides/references/migration-guide"
                    ctaButtonMessage="Learn how"
                  />
                )
              }

              return (
                <>
                  <FiltersContainer
                    extra={
                      <Tooltip
                        placement="bottomLeft"
                        align={{ offset: [-12, 0] }}
                        overlay={<span>Download CSV</span>}
                      >
                        <button
                          aria-label="Download CSV"
                          onClick={downloadCSV}
                          data-cy="download-most-common-errors"
                          data-pendo="download-most-common-errors"
                          className="analytic__download"
                        >
                          <UilCloudDownload />
                        </button>
                      </Tooltip>
                    }
                  >
                    <Filters
                      familyId={familyId}
                      branches
                      tags
                      timeRange
                      runGroups
                      committers
                      specFiles
                      browsers
                      cypressVersions
                      flaky
                      operatingSystems
                    />
                  </FiltersContainer>
                  {error ? (
                    <ErrorBoundaryContent />
                  ) : (
                    <>
                      <MostCommonErrorsBreakdown
                        isLoading={isLoading}
                        selectedErrorClass={selectedErrorClass}
                        onSelect={(selected) => {
                          if (selected === selectedErrorClass) {
                            setSelectedErrorClass([])
                            return
                          }

                          setSelectedErrorClass([selected])
                        }}
                        errors={errors}
                        shouldUpdateCypressVersion={
                          mostCommonErrorsAnalytic?.project
                            .shouldUpdateCypressVersion5 || false
                        }
                      />
                      <Kpis kpis={kpis} isLoading={isLoading} />
                      <MostCommonErrorsTable
                        colorMap={colorMap}
                        data={mostCommonErrorsAnalytic}
                        isLoading={isLoading}
                      />
                    </>
                  )}
                </>
              )
            })()}
          </div>
        </div>
      </StackedLayout>
    </DocumentTitle>
  )
}

import React from 'react'
import Button from '@cypress-design/react-button'
import styles from './module.GroupList.scss'
import { SkipButton } from './SkipButton'
import { Link } from '@reach/router'

type ViewGroupedRunsProps = {
  orgId: string
  field: string
  isStepChecked: boolean
  projectId: string
}

export const ViewGroupedRuns: React.FunctionComponent<ViewGroupedRunsProps> = ({
  orgId,
  field,
  isStepChecked,
  projectId,
}) => {
  return (
    <div className={styles.actionButtons}>
      <a
        href="https://on.cypress.io/parallelization#Grouping-test-runs"
        target="_blank"
      >
        <Button variant="outline-indigo" className={styles.outlineButton}>
          Review guide on grouping runs
        </Button>
      </a>

      <Link to={`/projects/${projectId}/settings/general`}>
        <Button variant="outline-indigo" className={styles.outlineButton}>
          Configure run completion delay
        </Button>
      </Link>

      <SkipButton orgId={orgId} field={field} isStepChecked={isStepChecked} />
    </div>
  )
}

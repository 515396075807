/**
 * Return the ASCII code of the first character of the string
 * Analytics around AB tests are tied to this function. Do not update.
 */
const numberize = (key: string) => (key.length ? key.charCodeAt(0) : 0)

/**
 * key = What we want to segment our users by (i.e. orgId)
 * tests = The amount of groups to segment our users into
 * return = The segment our user is assigned to (0 indexed)
 * hot tip: if using orgId for key, use odd/even orgIds to enter E2E AB test
 */
export const abTest = (key: string | number, tests: number): number => {
  const numKey = typeof key === 'string' ? numberize(key) : key
  return numKey % tests
}

export const localStorageABTest = (
  key: string | number,
  tests: number
): number => {
  const result = localStorage.getItem(`abTest:${key}`)
  if (result) {
    return Number(result)
  }
  const newTester = Math.floor(Math.random() * tests)
  localStorage.setItem(`abTest:${key}`, newTester.toString())
  return newTester
}

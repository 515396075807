import { TimeIntervalOptions } from '@packages/common/src'
import { DatesUtilsForQueries } from '@packages/data-context/src/utils/dates'
import {
  TimeIntervalEnum,
  ProjectRunsOverTimeQuery,
  ProjectRunsOverTimeQueryVariables,
  ProjectRunStatusOverRunsQuery,
  ProjectRunStatusOverRunsQueryVariables,
  ProjectRunStatusOverRunsDocument,
  ProjectRunsOverTimeDocument,
} from '~/graphql-codegen-operations.gen'
import { StackedLayout } from '~/common/stacked-layout'
import { useNavigate } from '@reach/router'
import { parseISO } from 'date-fns'
import fileDownload from 'js-file-download'
import React, { FunctionComponent } from 'react'
import DocumentTitle from 'react-document-title'

import { FiltersContainer } from '~/common/filters/FiltersContainer'
import {
  formatDateRangeDateISO,
  getQueryStringFromFilters,
  getValuesFromMultiSelectOptions,
} from '~/common/filters'
import { ErrorBoundaryContent } from '~/lib/error-boundary'
import { sendEventCustom } from '~/lib/page-events'

import { downloadAnalyticsCSV, getAnalyticsCSVFileName } from '../analytics-api'
import { DownloadCSV } from '../DownloadCSV'
import { Kpis } from '../Kpis'
import { RunsOverTimeChart } from './RunsOverTimeChart'
import { RunsOverTimeTable } from './RunsOverTimeTable'
import Filters from '../filters/Filters'
import * as analytics from '~/data/projects/analytics/hooks'
import { getKPIs } from './RunsOverTimeKPI'
import { QueryHookOptions, useQuery } from '@apollo/react-hooks'
import { features } from '~/lib/feature-flags'

type RunsOverTimeAnalyticProps = {
  projectId: string
  familyId: string
}

export type RunsOverTimeQueryData =
  | ProjectRunsOverTimeQuery
  | ProjectRunStatusOverRunsQuery

/**
 * This hooks is to be used until we remove the 'analytics-p1' feature flag. Once
 * the feature flag is removed we should be able to directly use `useProjectRunDurationsOverRunsQuery`.
 *
 * @param byBuildAnalytics Feature flag controlling what to bucket by
 * @param options Inputs for the query
 * @returns Expected Query Data based on the feature flag.
 */
const useRunsOverTimeQuery = (
  byBuildAnalytics: boolean,
  options: QueryHookOptions<
    ProjectRunsOverTimeQuery | ProjectRunStatusOverRunsQuery,
    ProjectRunsOverTimeQueryVariables | ProjectRunStatusOverRunsQueryVariables
  >
) => {
  const queryDocument = byBuildAnalytics
    ? ProjectRunStatusOverRunsDocument
    : ProjectRunsOverTimeDocument
  return useQuery<RunsOverTimeQueryData>(queryDocument, options)
}

export const RunsOverTimeAnalytic: FunctionComponent<
  RunsOverTimeAnalyticProps
> = ({ projectId, familyId }) => {
  const navigate = useNavigate()
  const { selected: branches } = analytics.useBranchesFilter(familyId)
  const { selected: tags, matchType: tagsMatch } =
    analytics.useTagsFilter(familyId)
  const { timeInterval } = analytics.useTimeIntervalFilter(familyId)
  const { timeRange } = analytics.useTimeRangeFilter(familyId)
  const byBuildAnalytics =
    features.isEnabled('analytics-p1') &&
    features.isEnabled('analytics-p1-run-status')
  // Don't add the tagsMatch parameter if there is no tags parameter.
  const tagsMatchOrUndefined = getValuesFromMultiSelectOptions(tags || [])
    ? tagsMatch
    : undefined
  const sharedInput = {
    projectId,
    branches: getValuesFromMultiSelectOptions(branches || []),
    tags: getValuesFromMultiSelectOptions(tags || []),
    tagsMatch: tagsMatchOrUndefined,
    timeRange: {
      startDate: formatDateRangeDateISO(timeRange?.startDate),
      endDate: formatDateRangeDateISO(timeRange?.endDate),
    },
  }

  analytics.useEventTracking('runs-over-time', 'Runs over time', familyId)
  const {
    data,
    loading: queryRunning,
    error,
  } = useRunsOverTimeQuery(byBuildAnalytics, {
    variables: {
      id: projectId,
      input: {
        ...sharedInput,
        // Empty status to get all by default
        ...(byBuildAnalytics ? { status: [] } : { timeInterval }),
      },
    },
  })

  if (error) {
    return (
      <DocumentTitle title="Run status">
        <StackedLayout title="Run status" className="no-content-padding">
          <ErrorBoundaryContent />
        </StackedLayout>
      </DocumentTitle>
    )
  }

  async function downloadCSV() {
    const fileName = getAnalyticsCSVFileName('runs-over-time', timeRange)
    const response = await downloadAnalyticsCSV({
      id: projectId,
      type: 'runs-over-time',
      params: {
        branches: getValuesFromMultiSelectOptions(branches || []),
        tags: getValuesFromMultiSelectOptions(tags || []),
        tagsMatch: tagsMatchOrUndefined,
        timeInterval: (timeInterval &&
          timeInterval.toLowerCase()) as TimeIntervalEnum,
        startDate: formatDateRangeDateISO(timeRange?.startDate),
        endDate: formatDateRangeDateISO(timeRange?.endDate),
      },
    })
    /* istanbul ignore next */
    sendEventCustom('Analytics', 'Export', {
      analyticID: 'runs-over-time',
      label: 'Runs over time',
    })
    if (!window.Cypress) {
      /* istanbul ignore next */
      fileDownload(response, `${fileName}.csv`)
    }
  }

  const navigateToRunList = (
    date: string,
    status: string,
    endDate?: string
  ) => {
    const timeRangeForRuns = new DatesUtilsForQueries({
      timeRange: {
        startDate: parseISO(date),
        endDate: endDate ? parseISO(endDate) : null,
      },
      timeInterval: TimeIntervalOptions[timeInterval!],
    }).datesForFilters

    const usedFilters = {
      branches,
      status: [{ value: status, label: status }],
      timeRange: timeRangeForRuns,
      tags,
    }
    const updatedQueryString = getQueryStringFromFilters(usedFilters)
    navigate(`/projects/${projectId}/runs${updatedQueryString}`)
  }

  const isLoading = queryRunning || error !== undefined
  const kpis = getKPIs(data, queryRunning, byBuildAnalytics)

  return (
    <DocumentTitle title="Run status">
      <StackedLayout title="Run status" className="no-content-padding">
        <div className="project-analytics">
          <div className="runs-over-time-analytic analytic">
            <FiltersContainer
              extra={
                <DownloadCSV analytic="runs-over-time" onClick={downloadCSV} />
              }
            >
              <Filters
                familyId={familyId}
                branches
                tags
                timeInterval={!byBuildAnalytics}
                timeRange
              />
            </FiltersContainer>
            {error ? (
              <ErrorBoundaryContent />
            ) : (
              <>
                <RunsOverTimeChart
                  data={data}
                  isLoading={isLoading}
                  timeInterval={timeInterval}
                  navigateToRunList={navigateToRunList}
                  byBuildAnalytics={byBuildAnalytics}
                />
                <Kpis kpis={kpis} isLoading={isLoading} />
                <RunsOverTimeTable
                  data={data}
                  isLoading={isLoading}
                  timeInterval={timeInterval}
                  byBuildAnalytics={byBuildAnalytics}
                />
              </>
            )}
          </div>
        </div>
      </StackedLayout>
    </DocumentTitle>
  )
}

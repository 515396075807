import { useRef } from 'react'
import { useNavigate } from '@reach/router'
import { useSidebarService } from '../sidebarService'
import { useSidebarRouterIds } from './useSidebarRouterIds'

import { LAST_ORG_ID_KEY, LAST_ORG_ID_VISITOR } from '../constants'

export const useSidebarErrorHandler = () => {
  const errorHandledRef = useRef(false)
  const { routerOrganizationId } = useSidebarRouterIds()
  const navigate = useNavigate()
  const [{ error, isHidden }, dispatch] = useSidebarService()

  const hasError = Boolean(error?.graphQLErrors?.[0]?.extensions)

  if (!hasError || errorHandledRef.current) {
    return
  }

  const graphQLError = error?.graphQLErrors[0]
  const errorPath = graphQLError?.path
  const errorCode = graphQLError?.extensions?.code

  if (errorCode === 'UNAUTHORIZED' && !isHidden) {
    dispatch({ type: 'hideSidebar' })
  }

  if (
    errorCode === 'RESOURCE_NOT_FOUND' ||
    errorCode === 'INTERNAL_SERVER_ERROR'
  ) {
    errorHandledRef.current = true

    if (errorPath && errorPath.includes('project') && routerOrganizationId) {
      navigate(`/organizations/${routerOrganizationId}/projects`)
      return
    }

    localStorage.removeItem(LAST_ORG_ID_KEY)
    localStorage.removeItem(LAST_ORG_ID_VISITOR)
    navigate('/organizations')
    return
  }

  if (isHidden) {
    return
  }

  dispatch({ type: 'hideSidebar' })
}

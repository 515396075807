import { CustomIcon } from '@frontend/design-system'
import React, { FunctionComponent } from 'react'
import { MultiSelectFilter } from '~/common/filters'
import { MultiSelectOptionType } from '../../types'

type ProjectCypressVersionsFilterProps = {
  id: string
  state: ProjectCypressVersionsFilterStateProp
}

type ProjectCypressVersionsFilterStateProp = {
  options: MultiSelectOptionType[] | undefined
  selected: MultiSelectOptionType[] | undefined
  setSelected: (value: []) => void
}
export const ProjectCypressVersionsFilter: FunctionComponent<ProjectCypressVersionsFilterProps> =
  React.memo(({ id, state }) => {
    const { options = [], selected = [], setSelected } = state

    return (
      <div
        data-cy="cypress-versions-filter"
        data-pendo="cypress-versions-filter"
        className="cypress-versions-filter"
      >
        <MultiSelectFilter
          id={id}
          title={<CustomIcon name="cypress" alt="Cypress version" />}
          emptyLabel="Cypress Version"
          selected={selected || []}
          onChange={setSelected}
          options={options || []}
        />
      </div>
    )
  })

import React from 'react'
import Button from '@cypress-design/react-button'
import styles from './module.GroupList.scss'
import { Link } from '@reach/router'

type ViewEnterpriseReportingProps = {
  orgId: string
}

export const ViewEnterpriseReporting: React.FunctionComponent<
  ViewEnterpriseReportingProps
> = ({ orgId }) => {
  return (
    <div className={styles.actionButtons}>
      <Link to={`/organizations/${orgId}/enterprise-reporting`}>
        <Button>View Enterprise Reporting</Button>
      </Link>
    </div>
  )
}

import { useMemo } from 'react'
import { useLocation } from '@reach/router'
import currentUser from '~/auth/current-user-model'

import { LAST_ORG_ID_KEY, LAST_ORG_ID_VISITOR } from '../constants'

export const useSidebarRouterIds = () => {
  const location = useLocation()
  const path = location.pathname

  return useMemo(() => {
    let routerOrganizationId: string | undefined
    let routerProjectId: string | undefined

    const orgMatch = path.match(/\/organizations\/([a-z0-9-]+?)\/.*/)
    const projectMatch = path.match(/\/projects\/([a-z0-9-]+?)\/.*?/)

    if (orgMatch && orgMatch[1]) {
      routerOrganizationId = orgMatch[1]
      window.localStorage.setItem(LAST_ORG_ID_KEY, routerOrganizationId)
      window.localStorage.setItem(LAST_ORG_ID_VISITOR, currentUser.id)
    } else {
      routerOrganizationId = window.localStorage.getItem(LAST_ORG_ID_KEY) || ''
    }

    if (projectMatch && projectMatch[1] !== 'runs') {
      routerProjectId = projectMatch[1]
    }

    return {
      routerOrganizationId,
      routerProjectId,
    }
  }, [path])
}

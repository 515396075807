import { CustomIcon } from '@frontend/design-system'
import React, { FunctionComponent, SyntheticEvent } from 'react'

import { TimeIntervalEnum } from '~/graphql-codegen-operations.gen'

import { ListFilter } from './ListFilter'

type TimeIntervalFilterProps = {
  id: string
  value: TimeIntervalEnum
  onSelect: (eventKey: any, e?: SyntheticEvent<{}>) => void
}

const options = [
  { value: 'DAY', label: 'Daily' },
  { value: 'WEEK', label: 'Weekly' },
  { value: 'MONTH', label: 'Monthly' },
  { value: 'QUARTER', label: 'Quarterly' },
]

export const TimeIntervalFilter: FunctionComponent<TimeIntervalFilterProps> = ({
  id,
  value,
  onSelect,
}) => {
  return (
    <ListFilter
      id={id}
      title={<CustomIcon name="date-interval" alt="Time interval" />}
      value={value}
      options={options}
      onSelect={onSelect}
    />
  )
}

import { CustomIcon } from '@frontend/design-system'
import { groupBy, map } from 'lodash'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { ActionMeta } from 'react-select/src/types.js'

import { MultiSelectFilter } from '~/common/filters'
import { useRunSpecFilterLazyQuery } from '~/graphql-codegen-operations.gen'

import { MultiSelectOptionType } from '../../types'

type RunSpecFilterProps = {
  id: string
  selected: MultiSelectOptionType[]
  onChange: (
    value: MultiSelectOptionType[],
    actionMeta?: ActionMeta<MultiSelectOptionType>
  ) => void
  runId: string
}

export const RunSpecFilter: FunctionComponent<RunSpecFilterProps> = React.memo(
  ({ id, onChange, selected, runId }) => {
    const [options, setOptions] = useState<MultiSelectOptionType[] | null>(null)
    const [fetchRunSpecs] = useRunSpecFilterLazyQuery({
      onCompleted: (data) => {
        if (!data.run || options) {
          return
        }

        const formattedOptions = map(
          groupBy(data.run.uniqueSpecs, 'shortPath'),
          (specs) => ({
            label: specs[0].shortPath,
            value: JSON.stringify(specs.map(({ id }) => id.split(':')[1])),
          })
        ).sort((a, b) => {
          const firstLabel = a.label.toLowerCase()
          const secondLabel = b.label.toLowerCase()

          if (firstLabel < secondLabel) {
            return -1
          }

          if (firstLabel > secondLabel) {
            return 1
          }

          /* istanbul ignore next */
          return 0
        })

        setOptions(formattedOptions)
      },
    })

    useEffect(() => {
      fetchRunSpecs({
        variables: {
          id: runId,
        },
      })
    }, [fetchRunSpecs, runId])

    return (
      <MultiSelectFilter
        id={id}
        title={<CustomIcon name="spec" alt="Spec file" />}
        emptyLabel="Spec File"
        dropdownClassName="spec-filter__dropdown"
        selected={selected}
        onChange={onChange}
        options={options || []}
      />
    )
  }
)

import { CodeSnippetV2 } from '../../../code-snippet/CodeSnippet'
import React from 'react'
import { CopyText, DropdownButton } from '@frontend/design-system/src'
import {
  IconChevronDownSmall,
  IconNumber1,
  IconNumber2,
  IconObjectBook,
} from '@cypress-design/react-icon'
import { ymlContent } from '~/common/project-setup/ymlContent'
import { ProviderData, providerDataWithManual } from '../../providerData'
import Button from '@cypress-design/react-button'

export const TriggerARun: React.FC<{
  recordKey?: string
  dataPendoPrefix: string
  selectedCIProvider: ProviderData
  changeSelectedProviderHandler(provider: ProviderData): void
  showDropdown?: boolean
}> = ({
  recordKey = '<record-key>',
  dataPendoPrefix,
  selectedCIProvider,
  changeSelectedProviderHandler,
  showDropdown = false,
}) => {
  const { recordKeyLocation, fileName, value, title, docsHref } =
    selectedCIProvider
  const code = ymlContent[value]

  const isManualSetup = value === 'manual-setup'

  const hasGuide = value !== 'azure'
  const docsBtnText = hasGuide
    ? `Read our guide to ${isManualSetup ? 'CI' : title}`
    : `Review our examples for ${title}`

  return (
    <div id="trigger-a-run">
      {showDropdown && (
        <div id="dropdown-wrapper" className="trigger-a-run-dropdown-wrapper">
          <DropdownButton
            id="select-provider-dropdown"
            className="trigger-a-run-dropdown"
            title={
              <div className="trigger-a-run-dropdown-label" data-cy="dropdown">
                {selectedCIProvider.icon}
                <span>{selectedCIProvider.title}</span>
                <IconChevronDownSmall className="trigger-a-run-dropdown-caret" />
              </div>
            }
          >
            <div className="trigger-a-run-dropdown-list">
              {providerDataWithManual.map((provider) => {
                return (
                  <div
                    key={`provider-${provider.value}`}
                    className="trigger-a-run-dropdown-item"
                    data-cy={`dropdown-option`}
                    onClick={() => {
                      changeSelectedProviderHandler(provider)
                    }}
                  >
                    {provider.icon}
                    <span className="trigger-a-run-dropdown-row-provider-name">
                      {provider.title}
                    </span>
                  </div>
                )
              })}
            </div>
          </DropdownButton>
        </div>
      )}

      <div data-cy="step-1" id="step-1" className="trigger-a-run-step">
        <p data-cy="record-key-instruction">
          <IconNumber1 fillColor="jade-200" strokeColor="teal-600" />
          {isManualSetup ? (
            <span>
              For recording and parallelization to work, you must set your{' '}
              <code>CYPRESS_RECORD_KEY</code> in your CI provider's environment
              variables.
            </span>
          ) : (
            <span>
              Set{` `}
              <code>CYPRESS_RECORD_KEY</code> in your {recordKeyLocation}:
            </span>
          )}
        </p>
        <CopyText
          overlayDefaultText="Copy record key"
          label="CYPRESS_RECORD_KEY"
          copyText={recordKey}
          copyBtnDataCy="copy-record-key"
          dataPendo={`${dataPendoPrefix}-copy-record-key-${value}`}
          variant="bordered"
        />
      </div>

      <div data-cy="step-2" id="step-2" className="trigger-a-run-step">
        <p>
          <IconNumber2 fillColor="jade-200" strokeColor="teal-600" />
          {isManualSetup ? (
            <span>
              To record a run in CI, use this command in your build:{' '}
              <code>npx cypress run --record</code>
            </span>
          ) : (
            <span>Then add this file to your repository:</span>
          )}
        </p>
      </div>

      {!isManualSetup && (
        <CodeSnippetV2
          overlayDefaultText="Copy file content"
          copyBtnPendo={`${dataPendoPrefix}-copy-yml-file-${value}`}
          variant="design-system-candidate"
          code={code}
          copyText={code}
          fileName={fileName}
          language="yml"
          withCopyBtn
          collapsed
        />
      )}

      <div id="docs-cta-wrapper">
        <Button
          variant="outline-light"
          href={docsHref}
          target="_blank"
          size="40"
          data-cy="docs-cta-button"
          data-pendo={`${dataPendoPrefix}-docs-cta-${value}`}
        >
          <IconObjectBook strokeColor="indigo-500" fillColor="indigo-200" />
          {docsBtnText}
        </Button>
      </div>
    </div>
  )
}

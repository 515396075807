import { CustomIcon, palette } from '@frontend/design-system'
import { isSameDay, min, max } from 'date-fns'
import React, {
  FunctionComponent,
  ReactNode,
  SyntheticEvent,
  useState,
} from 'react'
import { DateRangePicker } from 'react-date-range'
import {
  allTimeUTCDateRange,
  getDateRangeLabel,
  staticUTCDates,
  staticUTCDateRanges,
  todayUTCDateRange,
  formatDate,
} from '~/lib/utils'
import { Filter } from './Filter'
import { formatDateRangeDate, TimeRangeDates } from './util'

type onSelect = (eventKey: any, e?: SyntheticEvent<{}>) => void

interface StaticDateRange {
  label: string
  isSelected: (range: TimeRangeDates) => boolean
  range: () => TimeRangeDates
}

interface TimeRangeProps {
  value: TimeRangeDates
  dateRangeOptions?: StaticDateRange[]
  onSelect: onSelect
  dateRangeId?: string | null
  minDate?: Date | null
  maxDate?: Date | null
}

export const TimeRange: FunctionComponent<TimeRangeProps> = ({
  dateRangeOptions,
  value,
  dateRangeId,
  onSelect,
  minDate,
  maxDate,
}) => {
  minDate = minDate ?? staticUTCDates.getEpoch()
  maxDate = maxDate ?? staticUTCDates.getEndOfToday()

  const onChange = (ranges: { timeRange: TimeRangeDates }) => {
    const startDate = formatDateRangeDate(ranges.timeRange.startDate)
    const endDate = formatDateRangeDate(ranges.timeRange.endDate)
    const hasSameDateRangeId = dateRangeId === ranges.timeRange.id

    if (
      hasSameDateRangeId &&
      isSameDay(formatDate(startDate), formatDate(value.startDate)) &&
      isSameDay(formatDate(endDate), formatDate(value.endDate))
    ) {
      return
    }

    onSelect({
      startDate,
      endDate,
      id: hasSameDateRangeId ? null : ranges.timeRange.id,
    })
  }

  return (
    <DateRangePicker
      showDateDisplay={false}
      inputRanges={[]}
      ranges={[{ ...value, color: palette.indigo600, key: 'timeRange' }]}
      staticRanges={dateRangeOptions}
      shownDate={maxDate}
      minDate={min([minDate, maxDate])}
      maxDate={max([minDate, maxDate])}
      onChange={onChange}
    />
  )
}

interface TimeRangeFilterProps extends TimeRangeProps {
  id: string
  title?: string
  renderDropdownContent?(): ReactNode
  shouldIncludeToday?: boolean
  shouldIncludeAllTime?: boolean
}

export const TimeRangeFilter: FunctionComponent<TimeRangeFilterProps> = ({
  id,
  value,
  onSelect,
  dateRangeOptions,
  title = 'Time Range',
  dateRangeId,
  minDate,
  maxDate,
  renderDropdownContent = () => null,
  shouldIncludeToday = false,
  shouldIncludeAllTime = false,
}) => {
  const [isOpen, toggleOpen] = useState(false)

  const staticRanges = [
    ...(shouldIncludeToday ? todayUTCDateRange : []),
    ...(dateRangeOptions ? dateRangeOptions : staticUTCDateRanges),
    ...(shouldIncludeAllTime ? allTimeUTCDateRange : []),
  ]

  const renderDropDown = () => {
    return (
      <>
        <TimeRange
          dateRangeOptions={staticRanges}
          value={value}
          dateRangeId={dateRangeId}
          onSelect={onSelect}
          minDate={minDate}
          maxDate={maxDate}
        />
        {renderDropdownContent()}
      </>
    )
  }

  const renderSelectedLabel = () => {
    const label = getDateRangeLabel(staticRanges, value)

    return <span className="filter__selected-label">{label}</span>
  }

  return (
    <Filter
      id={id}
      open={isOpen}
      emptyLabel={title}
      title={<CustomIcon name="date" alt="Time range" />}
      // @ts-ignore
      onToggle={(open: boolean, _, { source }: { source: string }) =>
        toggleOpen(source === 'select' ? true : open)
      }
      renderSelectedLabel={renderSelectedLabel}
      renderDropDown={renderDropDown}
    />
  )
}

import React, { FunctionComponent } from 'react'
import { CustomIcon } from '@frontend/design-system'
import { MultiSelectFilter } from '~/common/filters'
import { BrowserIcon } from '~/common/icon'
import { MultiSelectOptionType } from '../../types'

type ProjectBrowsersFilterProps = {
  id: string
  state: ProjectBrowsersFilterStateProp
}

type ProjectBrowsersFilterStateProp = {
  options: MultiSelectOptionType[] | undefined
  selected: MultiSelectOptionType[] | undefined
  setSelected: (value: []) => void
}

const renderLabel = (option: MultiSelectOptionType) => {
  const label = (
    <span className="browsers-filter__label">
      {option.labelProperties && option.labelProperties.unformattedName && (
        <BrowserIcon name={option.labelProperties.unformattedName} alt="" />
      )}
      {option.label}
    </span>
  ) as unknown

  return label as string
}

export const ProjectBrowsersFilter: FunctionComponent<ProjectBrowsersFilterProps> =
  React.memo(({ id, state }) => {
    const { options = [], selected = [], setSelected } = state

    return (
      <div data-cy="browsers-filter" data-pendo="browsers-filter">
        <MultiSelectFilter
          id={id}
          title={<CustomIcon name="browser" alt="Browser" />}
          emptyLabel="Browser"
          selected={selected || []}
          onChange={setSelected}
          options={options || []}
          getOptionLabel={renderLabel}
        />
      </div>
    )
  })

import { useEffect, useMemo, useRef, useState } from 'react'
import { useDrillInDrawerWidth } from '~/data/common/hooks'

export const useSideBySide = (
  isDrawerOpen: boolean = false,
  minMainContentWidth: number = 800
) => {
  const { drillInDrawerWidth: drawerWidth } = useDrillInDrawerWidth()
  const [isSideBySide, setSideBySide] = useState(false)

  const pageContentRef = useRef<HTMLDivElement | null>(null)
  const changeSideBySideRef = useRef(() => {})

  const getScrollableRef = (): HTMLElement | null =>
    document.querySelector('.stacked-layout--content')
  const scrollableRef = useRef<HTMLElement | null>(
    useMemo(() => getScrollableRef(), [])
  )

  const getAppRef = (): HTMLElement | null => document.querySelector('#app')
  const appRef = useRef<HTMLElement | null>(useMemo(() => getAppRef(), []))

  useEffect(() => {
    if (!scrollableRef.current) {
      scrollableRef.current = getScrollableRef()
    }

    if (!appRef.current) {
      appRef.current = getAppRef()
    }
  })

  useEffect(() => {
    changeSideBySideRef.current = () => {
      if (appRef.current && scrollableRef.current) {
        const pageContentLeft =
          scrollableRef.current.getBoundingClientRect().left
        const appRight = appRef.current.getBoundingClientRect().right

        const centerColumnWidth =
          appRight - pageContentLeft - (drawerWidth || 0)

        if (isSideBySide) {
          if (centerColumnWidth < minMainContentWidth) {
            setSideBySide(false)
          }
        } else {
          if (centerColumnWidth >= minMainContentWidth) {
            setSideBySide(true)
          }
        }
      }
    }
  }, [drawerWidth, isSideBySide, minMainContentWidth])

  useEffect(() => {
    if (scrollableRef.current) {
      if (isSideBySide && isDrawerOpen) {
        scrollableRef.current.style.marginRight = `${drawerWidth}px`
      } else {
        scrollableRef.current.style.marginRight = '0px'
      }
    }

    return () => {
      if (scrollableRef.current) {
        scrollableRef.current.style.marginRight = '0px'
      }
    }
  }, [drawerWidth, isDrawerOpen, isSideBySide, minMainContentWidth])

  useEffect(() => {
    function onWindowResizeEvent(e) {
      changeSideBySideRef.current()
    }

    window.addEventListener('resize', onWindowResizeEvent)

    return () => window.removeEventListener('resize', onWindowResizeEvent)
  }, [])

  useEffect(() => {
    changeSideBySideRef.current()
  }, [pageContentRef, drawerWidth, isSideBySide])

  return { pageContentRef }
}

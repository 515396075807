import { sumBy } from 'lodash'
import { ProjectFlakyTestsQuery } from '~/graphql-codegen-operations.gen'

export function binsInfo(
  nodes: ProjectFlakyTestsQuery['metrics']['projectFlakinessTestsOverTime']['chartNodes'],
  shouldBeHourly: boolean
) {
  if (shouldBeHourly) {
    return { binSize: 1, numberOfBins: nodes.length, timeWindow: 'hour' }
  }

  if (nodes.length < 32) {
    return { binSize: 1, numberOfBins: nodes.length, timeWindow: 'day' }
  }

  if (nodes.length < 92) {
    return {
      binSize: 7,
      numberOfBins: Math.ceil(nodes.length / 7),
      timeWindow: 'week',
    }
  }

  return {
    binSize: 30,
    numberOfBins: Math.ceil(nodes.length / 30),
    timeWindow: 'month',
  }
}

export type BucketData = {
  date: string
  endDate?: string
  number: number
}

export const getFlakyTestsBuckets = (
  flakyTests: ProjectFlakyTestsQuery['metrics']['projectFlakinessTestsOverTime'],
  shouldBeHourly: boolean,
  byBuildAnalytics: boolean = false
) => {
  const nodes = flakyTests.chartNodes
  const { binSize, numberOfBins } = binsInfo(
    nodes,
    shouldBeHourly || byBuildAnalytics
  )
  const buckets: BucketData[] = []

  for (let i = 0; i < numberOfBins; i++) {
    const binNodes = nodes.slice(i * binSize, (i + 1) * binSize)
    buckets.push({
      date: binNodes[0].date,
      number: sumBy(binNodes, (node) => {
        return node.ids.length
      }),
    })
  }

  return { buckets, binSize }
}

import { isDeployedEnv, isEnv, isLocalhost } from '../lib/utils'
import { sendEventException } from './page-events'
import { customTracesSampler } from './error-tracking-utils'
import type { ErrorEvent } from '@sentry/types'
import { BrowserTracing } from '@sentry/tracing'
import store from './store'

declare global {
  interface Window {
    env: any
    Sentry: any
    __testTriggerUnhandledRejection: any
  }
}

export default () => {
  if (!window.Cypress) {
    window.Sentry.init({
      dsn: 'https://fb2498a6b5484d7d98c51f95a7b2b053@sentry.io/1318394',
      enabled: !isLocalhost() && isDeployedEnv(),
      environment: window.env,
      beforeSend: (event: ErrorEvent) => {
        const { tags } = event
        // temporarily ignore Failed to fetch errors from
        // dashboardApolloClient.ts:
        if (tags?.ApolloClientOnError && tags?.network) {
          if (event.exception?.values) {
            for (const el of event.exception?.values) {
              if (el.type === 'TypeError' && el.value === 'Failed to fetch') {
                return null
              }
            }
          }
        }

        sendEventException(event.message)
        return event
      },
      ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'Loading chunk',
        'ResizeObserver loop completed with undelivered notifications',
        'Message: Not authorized',
        'INTERNAL_SERVER_ERROR',
      ],
      integrations: [new BrowserTracing()],
      profilesSampleRate: 0.1,
      tracesSampler: (samplingContext) => {
        return customTracesSampler(samplingContext, [
          { pathSubstring: 'ViewsList_accessibility', rate: 0.4 },
          { pathSubstring: 'SnapshotView_accessibility', rate: 0.4 },
          { pathSubstring: 'ViewsList_ui-coverage', rate: 0.4 },
          { pathSubstring: 'SnapshotView_ui-coverage', rate: 0.4 },
        ])
      },
    })
  } else {
    window.__testTriggerUnhandledRejection = () => {
      return Promise.reject(new Error('Unhandled Rejection'))
    }
    window.onerror = function (message, source, lineno, colno, err) {
      // eslint-disable-next-line no-console
      return console.error(err)
    }
  }

  window.onunhandledrejection = (e) => {
    if (isEnv('test') || isEnv('development')) {
      if (e.promise) {
        e.promise.catch((err) => {
          console.error(err)
        })
      } else {
        console.error(e)
      }
    }
    store.setNotification(
      'Oops. There was a problem talking to our servers.',
      'failure'
    )
  }

  window.addEventListener('unhandledrejection', function (event) {
    event.preventDefault()

    let error
    if (
      typeof PromiseRejectionEvent &&
      event instanceof PromiseRejectionEvent
    ) {
      // Native promise
      error = event.reason
    }

    if (!error) {
      return
    }

    window.Sentry.captureException(error)

    const statusCode = error.response ? error.response.status : null

    if (isEnv('test') && statusCode === 404) {
      return
    }

    if (statusCode === 401) {
      return
    }

    // eslint-disable-next-line no-console
    console.warn(error.stack)
  })
}

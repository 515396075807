import { RouteComponentProps, Router } from '@reach/router'
import isUUID from 'is-uuid'
import React, {
  FunctionComponent,
  MutableRefObject,
  createContext,
  useEffect,
  useRef,
} from 'react'
import Loader from 'react-loader'

import auth from '~/auth/auth-service'
import { chunks } from '~/chunks'
import store from '~/lib/store'
import {
  ProjectContainerQuery,
  RunContainerRunFragment,
  useRunContainerQuery,
  useRunContainerOrgQuery,
  useSetFirstViewedAtMutation,
} from '~/graphql-codegen-operations.gen'
import { userIsMemberOfOrg } from '~/lib/roles'
import { PageHeader } from '~/navs/page-header'
import NotFound from '~/not-found'
import { RunTabs } from '~/run/RunTabs'
import { RunsListItem } from '~/runs/RunsListItem'
import { features } from '~/lib/feature-flags'
import { isEitherIntelligenceProductPendingDiscoveryState } from '~/common/intelligence-utils/util'
import { getIntelligenceFeatureStatus } from '~/common/intelligence-utils/getIntelligenceFeatureStatus'
import './RunContainer.scss'
interface RunContainerProps
  extends RouteComponentProps<{ runId: string; projectId: string }> {
  project: ProjectContainerQuery['project']
}

export const RunContainer: FunctionComponent<RunContainerProps> = (props) => {
  const { runId, projectId, navigate } = props
  const [setFirstViewedAtMutation] = useSetFirstViewedAtMutation()
  const { isDisplayEnabled: accessibilityFlagEnabled } =
    getIntelligenceFeatureStatus('accessibility')
  const { isDisplayEnabled: interactivityFlagEnabled } =
    getIntelligenceFeatureStatus('ui-coverage')
  const intelligenceEnabled =
    accessibilityFlagEnabled || interactivityFlagEnabled

  const { data, error, startPolling, stopPolling } = useRunContainerQuery({
    variables: {
      buildNumber: runId!,
      buildId: runId!,
      projectId: projectId!,
      queryById: isUUID.anyNonNil(runId!),
      intelligenceEnabled,
      accessibilityFlagEnabled,
      interactivityFlagEnabled,
    },
    onCompleted(data) {
      const run = data.run || data.runByBuildNumber

      // seems to be a graphql codegen issue
      if (!run) {
        return
      }

      if (
        !run.endTime ||
        // If intelligence features are enabled, and
        // we've received a non-null discovery payload, and
        // the discovery payload is in a pending state, then
        // the polling will continue until completion or error.
        (intelligenceEnabled &&
          isEitherIntelligenceProductPendingDiscoveryState({
            accessibilityResults: run.accessibilityResults,
            uiCoverageResults: run.uiCoverageResults,
          }))
      ) {
        startPolling(5000)
      } else {
        stopPolling()
      }

      if (!run.firstViewedAt && auth.loggedIn()) {
        setFirstViewedAtMutation({
          variables: {
            runId: run.id,
          },
        })
      }
    },
  })

  useEffect(() => {
    if (!error) {
      return
    }

    const errorCode = error.graphQLErrors[0]?.extensions?.code

    if (errorCode === 'INTERNAL_SERVER_ERROR') {
      store.setNotification(`Oops. Run #${runId} does not exist.`, 'failure')
    }

    navigate!(`/projects/${projectId}/runs`)
  }, [error, projectId, runId, navigate])

  const runData = data?.run || data?.runByBuildNumber

  if (!runData) {
    return <Loader />
  }

  return <RunContainerContent {...props} runData={runData} />
}

type RunContainerContentProps = RunContainerProps & {
  runData: RunContainerRunFragment
}

export const StackedLayoutContext = createContext<MutableRefObject<any> | null>(
  null
)

const RunContainerContent: FunctionComponent<RunContainerContentProps> = (
  props
) => {
  const { runData, projectId, project, location } = props
  const stackedLayoutContentRef = useRef(null)
  const runOverviewAnalytics = features.isEnabled('run-overview-analytics')
  const accessibility = getIntelligenceFeatureStatus('accessibility')
  const uiCoverage = getIntelligenceFeatureStatus('ui-coverage')

  const enableAccessibility =
    accessibility.isDisplayEnabled || accessibility.isPreTrial
  const enableUiCoverage = uiCoverage.isDisplayEnabled || uiCoverage.isPreTrial

  const isUserMemberOfOrg = userIsMemberOfOrg(project?.organizationInfo.id)

  const { data: orgData } = useRunContainerOrgQuery({
    variables: { orgUuid: project?.organizationInfo.id },
    skip: !project || !isUserMemberOfOrg,
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [runData.id, projectId])

  if (!runData || (isUserMemberOfOrg && !orgData)) {
    return <Loader />
  }

  const childConfig = {
    org: orgData?.organization,
    project: { ...project, ...runData.project },
    run: runData,
  }

  return (
    <StackedLayoutContext.Provider value={stackedLayoutContentRef}>
      <div className="stacked-layout no-content-padding">
        <PageHeader className="stacked-layout--header run-header" title="">
          <RunsListItem
            run={runData}
            isPageHeader
            projectId={runData.project.id}
          />
          <RunTabs
            isMember={orgData?.organization.viewerIsMember}
            run={runData}
            projectId={runData.project.id}
            location={location}
            beyondDataRetention={runData.isPastDataRetention}
          />
        </PageHeader>

        <div
          ref={stackedLayoutContentRef}
          className="stacked-layout--content"
          id="run-stacked-layout"
        >
          <div id="run-show">
            <div id="run-tab-content">
              <Router primary={false}>
                <chunks.RunOverview
                  path="overview/*runOverviewParams"
                  {...childConfig}
                />
                <chunks.Specs path="specs/*specParams" {...childConfig} />
                <chunks.TestResults
                  path="test-results/*testResultParams"
                  {...childConfig}
                />
                {enableAccessibility && (
                  <chunks.AccessibilityRouter
                    path="accessibility/*"
                    {...childConfig}
                  />
                )}
                {enableUiCoverage && (
                  <chunks.InteractivityRouter
                    path="ui-coverage/*"
                    {...childConfig}
                  />
                )}
                {runOverviewAnalytics && (
                  <chunks.Errors
                    path="errors/*testResultParams"
                    {...childConfig}
                  />
                )}
                <chunks.Properties path="properties" {...childConfig} />
                <NotFound default />
              </Router>
            </div>
          </div>
        </div>
      </div>
    </StackedLayoutContext.Provider>
  )
}

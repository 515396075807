import { debounce } from 'lodash'
import * as React from 'react'

export function useOnScroll(
  {
    scrollable,
    delay = 500,
    maxWait,
  }: {
    scrollable: HTMLElement | Window | null
    delay?: number
    maxWait?: number
  },
  onScroll: () => void
) {
  const debouncedOnScroll = React.useRef(() => {})

  React.useEffect(() => {
    debouncedOnScroll.current = debounce(onScroll, delay, { maxWait })
  }, [delay, maxWait, onScroll])

  React.useEffect(() => {
    function onWindowEvent() {
      debouncedOnScroll.current()
    }

    if (!scrollable) {
      return
    }

    scrollable.addEventListener('scroll', onWindowEvent)
    window.addEventListener('resize', onWindowEvent)

    return () => {
      scrollable.removeEventListener('scroll', onWindowEvent)
      window.removeEventListener('resize', onWindowEvent)
    }
  }, [scrollable])
}

import {
  ProjectRunsOverTimeQuery,
  ProjectRunStatusOverRunsQuery,
} from '~/graphql-codegen-operations.gen'
import commaNumber from 'comma-number'
import { sum } from 'lodash'
import { RunsOverTimeQueryData } from './RunsOverTimeAnalytic'

export const getKPIs = (
  runsOverTime: RunsOverTimeQueryData | undefined,
  queryRunning: boolean,
  byBuildAnalytics: boolean
) => {
  if (byBuildAnalytics) {
    return getKPIsV2(
      runsOverTime as ProjectRunStatusOverRunsQuery,
      queryRunning
    )
  }

  let kpis
  const metrics = (runsOverTime as ProjectRunsOverTimeQuery)?.metrics
    .projectRunsOverTimeGroupedByStatus
  const loading = queryRunning && !metrics
  if (!loading) {
    kpis = [
      {
        name: 'Total runs',
        value: commaNumber(metrics.count),
        deltaChange: metrics.totalCountDeltaChange,
      },
      {
        name: 'Average per day',
        value: commaNumber(metrics.averagePerDay.toFixed(2)),
        deltaChange: metrics.averagePerDayDeltaChange,
      },
      {
        name: 'Passed runs',
        value: commaNumber(metrics.countPassed),
      },
      {
        name: 'Failed runs',
        value: commaNumber(metrics.countFailed),
      },
    ]
  } else {
    kpis = [
      {
        name: 'Total runs',
        deltaChange: null,
        loading,
      },
      {
        name: 'Average per day',
        deltaChange: null,
        loading,
      },
      {
        name: 'Passed runs',
        loading,
      },
      {
        name: 'Failed runs',
        loading,
      },
    ]
  }
  return kpis
}

export const getKPIsV2 = (
  data: ProjectRunStatusOverRunsQuery | undefined,
  queryRunning: boolean
) => {
  let kpis
  const loading = queryRunning
  if (data) {
    const metrics = data.metrics.projectRunDurationsOverRuns
    const totalCount = sum(metrics.nodes.map((a) => a.count))
    const totalPassed = sum(metrics.nodes.map((a) => a.countPassed))
    const totalFailed = sum(metrics.nodes.map((a) => a.countFailed))
    kpis = [
      {
        name: 'Total runs',
        value: commaNumber(totalCount),
        deltaChange: metrics.totalCountDeltaChange,
      },
      {
        name: 'Passed runs',
        value: commaNumber(totalPassed),
      },
      {
        name: 'Failed runs',
        value: commaNumber(totalFailed),
      },
    ]
  } else {
    kpis = [
      {
        name: 'Total runs',
        deltaChange: null,
        loading,
      },
      {
        name: 'Passed runs',
        loading,
      },
      {
        name: 'Failed runs',
        loading,
      },
    ]
  }
  return kpis
}

export const SLACK_APP_NOTIFICATION_SCOPES = [
  'ALL_RUNS',
  'UNSUCCESSFUL_RUNS',
  'SUCCESSFUL_RUNS',
] as const

export const SLACK_APP_FAILED_NOTIFICATION_OPTION = {
  value: 'UNSUCCESSFUL_RUNS',
  label: 'Failed runs',
}

export const SLACK_APP_PASSED_NOTIFICATION_OPTION = {
  value: 'SUCCESSFUL_RUNS',
  label: 'Passed runs',
}

export const SLACK_APP_ALL_NOTIFICATION_OPTIONS = [
  SLACK_APP_FAILED_NOTIFICATION_OPTION,
  SLACK_APP_PASSED_NOTIFICATION_OPTION,
]

export const SLACK_APP_INSTALL_SCOPES = ['ORGANIZATION', 'PROJECT'] as const

import axios, { AxiosRequestConfig } from 'axios'
import _ from 'lodash'

import auth from '../auth/auth-service'

axios.defaults.baseURL = '/api'
axios.defaults.headers.post['Content-Type'] = 'application/json'

export const onStatus = (code: number, cb: (err?: Error) => void) => {
  return function (error: Error & { response?: { status?: number } }) {
    if (error.response && error.response.status === code) {
      return cb(error)
    }
    throw error
  }
}

const xhr = (
  options: Partial<AxiosRequestConfig & { autoLogout: boolean }> = {}
) => {
  options = _.defaults(options, {
    autoLogout: true,
    headers: {},
  })

  const promise = Promise.resolve(axios(options))
    .then((result) => result.data)
    .catch(
      onStatus(401, (error) => {
        /* istanbul ignore if */
        if (options.autoLogout) {
          auth.logout()
        }

        // bubble up and notify anyone
        throw error
      })
    )

  return promise
}

xhr.onStatus = onStatus

export default xhr

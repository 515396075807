import { CustomIcon } from '@frontend/design-system'
import React, { FunctionComponent } from 'react'
import cs from 'clsx'
import styles from './module.CommitBranch.scss'
import { queryRunsByBranchName } from '~/common/urlOperations'
import { Link } from '@reach/router'
import { features } from '~/lib/feature-flags'

type CommitBranchProps = {
  branchName: string | null
  projectId: string | null
  hideIcon?: boolean
}

export const CommitBranch: FunctionComponent<CommitBranchProps> = ({
  branchName,
  projectId,
  hideIcon = false,
}) => {
  const branchSummaryPage = features.isEnabled('branch-summary')

  if (!branchName) {
    return (
      <div className={styles.container}>
        {!hideIcon && <CustomIcon name="branch" variant="small" />}
        <span className="commit-branch__name">
          <span className="italic">Unknown</span>
        </span>
      </div>
    )
  }

  const branchRedirectUrl = branchSummaryPage
    ? `/projects/${projectId}/branches/${encodeURIComponent(branchName)}`
    : `/projects/${projectId}/runs?${queryRunsByBranchName(
        branchName === 'Unknown branch' ? null : branchName
      )}`

  return (
    <div className={styles.container}>
      {!hideIcon && <CustomIcon name="branch" variant="small" />}
      <span className={cs(['commit-branch__name', styles.name])}>
        <Link to={branchRedirectUrl} data-cy="branch-link">
          {branchName}
        </Link>
      </span>
    </div>
  )
}

import { Panel } from '../Panel'
import React, { FunctionComponent, ReactNode } from 'react'
import Button from '@cypress-design/react-button'
import { IconActionDeleteMedium } from '@cypress-design/react-icon'

type FilteredListEmptyStateProps = {
  children?: ReactNode
  loading?: boolean
  hasFilterData?: boolean
  onClearFilterClick?: () => void
}

const FilteredListEmptyState: FunctionComponent<
  FilteredListEmptyStateProps
> = ({ children, hasFilterData = false, onClearFilterClick = () => {} }) => {
  return (
    <Panel.Body className="empty-state filtered-list-empty-state">
      {!hasFilterData ? (
        <>
          <h2>No results during this time period</h2>
          <span className="subtitle">
            Try changing your date range for more results
          </span>
        </>
      ) : (
        <>
          <h2>No results matching your filters</h2>
          <span className="subtitle">
            Try changing your date range or removing filters for more results
          </span>
          <Button
            onClick={onClearFilterClick}
            variant="outline-dark"
            className="btn btn-primary"
            data-cy="clear-filters-button"
          >
            <IconActionDeleteMedium className="mr-2" strokeColor="white" />
            Clear filters
          </Button>
        </>
      )}
      <img src="/img/empty-filter-cactus.svg" alt="" />
      {children}
    </Panel.Body>
  )
}

export { FilteredListEmptyState }

export const isHashUuid = (uuid: string) =>
  uuid.match(/(\d|\w){8}-(\d|\w){4}-(\d|\w){4}-(\d|\w){4}-(\d|\w){12}/)

const verifyHash = (hash: string) => (isHashUuid(hash) ? hash : null)

export const getHashesFromUrlParam = (param: string | null) => {
  const specHash = param?.slice(0, 36) ?? ''
  const titleHash = param?.slice(37) ?? ''

  return {
    specHash: verifyHash(specHash),
    titleHash: verifyHash(titleHash),
  }
}

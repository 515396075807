import { TimeIntervalOptions, TimeIntervalOptionsValue } from '@packages/common'
import type { gqlTypes } from '@packages/types'
import {
  addDays,
  addHours,
  addMonths,
  addQuarters,
  addWeeks,
  differenceInDays,
  differenceInHours,
  differenceInMonths,
  differenceInQuarters,
  differenceInWeeks,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  format,
  startOfDay,
  startOfHour,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  subDays,
  isAfter,
} from 'date-fns'

export type DateFilters = {
  timeRange?: gqlTypes.TimeRangeInput | null
  timeInterval?: TimeIntervalOptionsValue
}

export function formatedStartOfTheDay(date: Date) {
  return format(startOfDay(date), 'yyyy-MM-dd HH:mm:ss')
}

export function formatedEndOfTheDay(date: Date) {
  return format(endOfDay(date), 'yyyy-MM-dd HH:mm:ss')
}

export function isOlderThan(timestampUTC: Date, days: number) {
  const diffDays = differenceInDays(new Date(), new Date(timestampUTC))
  return diffDays > days
}

export class DatesUtilsForQueries {
  defaultStartDate: Date
  defaultEndDate: Date
  startDate: Date
  endDate: Date
  timeInterval?: TimeIntervalOptionsValue
  constructor(protected dateFilters?: DateFilters) {
    this.defaultStartDate = subDays(new Date(), 90)
    this.defaultEndDate = new Date()
    this.startDate = dateFilters?.timeRange?.startDate || this.defaultStartDate
    this.endDate =
      dateFilters?.timeRange?.endDate ||
      (isAfter(this.startDate, this.defaultEndDate)
        ? addDays(this.startDate, 1)
        : this.defaultEndDate)
    this.timeInterval = dateFilters?.timeInterval
  }

  get defaultDates() {
    return {
      defaultStartDate: this.defaultStartDate,
      defaultEndDate: this.defaultEndDate,
    }
  }

  get datesForQueries() {
    const intervalSize = differenceInDays(this.endDate, this.startDate)
    // Add one more day to substract to make the dates ranges match.
    // eg. start day and end day are the same date, so the diff should be
    // 0, we want to compare with the previous day.
    const previousStartDate = formatedStartOfTheDay(
      subDays(this.startDate, intervalSize + 1)
    )
    const previousEndDate = formatedEndOfTheDay(subDays(this.startDate, 1))

    return {
      previousStartDate,
      previousEndDate,
      startDate: formatedStartOfTheDay(this.startDate),
      endDate: formatedEndOfTheDay(this.endDate),
      startDateTime: this.startDate,
      endDateTime: this.endDate,
    }
  }

  get intervalSizeForNodes() {
    const { intervalSize } = this.startPointForNodesIterator
    return intervalSize
  }

  nodeDate(i: number) {
    const { startDate } = this.startPointForNodesIterator

    if (this.timeInterval === TimeIntervalOptions.HOUR) {
      return addHours(startDate, i)
    }

    if (this.timeInterval === TimeIntervalOptions.WEEK) {
      return addWeeks(startDate, i)
    }
    if (this.timeInterval === TimeIntervalOptions.MONTH) {
      return addMonths(startDate, i)
    }
    if (this.timeInterval === TimeIntervalOptions.QUARTER) {
      return addQuarters(startDate, i)
    }
    // By default timeInterval is DAY
    return addDays(startDate, i)
  }

  get datesForFilters() {
    let startDate = startOfDay(this.startDate)
    let endDate = endOfDay(this.endDate)

    if (this.timeInterval === TimeIntervalOptions.DAY) {
      startDate = startOfDay(this.startDate)
      endDate = endOfDay(this.startDate)
    } else if (this.timeInterval === TimeIntervalOptions.WEEK) {
      startDate = startOfWeek(this.startDate, { weekStartsOn: 1 })
      endDate = endOfWeek(this.startDate, { weekStartsOn: 1 })
    } else if (this.timeInterval === TimeIntervalOptions.MONTH) {
      startDate = startOfMonth(this.startDate)
      endDate = endOfMonth(this.startDate)
    } else if (this.timeInterval === TimeIntervalOptions.QUARTER) {
      startDate = startOfQuarter(this.startDate)
      endDate = endOfQuarter(this.startDate)
    }

    return {
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
    }
  }

  private get startPointForNodesIterator() {
    let startDate = startOfDay(this.startDate)
    let endDate = endOfDay(this.endDate)
    let intervalSize = differenceInDays(endDate, startDate)

    if (this.timeInterval === TimeIntervalOptions.HOUR) {
      startDate = startOfHour(startDate)
      endDate = startOfHour(endDate)
      intervalSize = differenceInHours(endDate, startDate)
    } else if (this.timeInterval === TimeIntervalOptions.WEEK) {
      startDate = startOfWeek(this.startDate, { weekStartsOn: 1 })
      endDate = endOfWeek(this.endDate, { weekStartsOn: 1 })
      intervalSize = differenceInWeeks(endDate, startDate)
    } else if (this.timeInterval === TimeIntervalOptions.MONTH) {
      startDate = startOfMonth(this.startDate)
      endDate = endOfMonth(this.endDate)
      intervalSize = differenceInMonths(endDate, startDate)
    } else if (this.timeInterval === TimeIntervalOptions.QUARTER) {
      startDate = startOfQuarter(this.startDate)
      endDate = endOfQuarter(this.endDate)
      intervalSize = differenceInQuarters(endDate, startDate)
    }

    return { intervalSize, startDate, endDate }
  }
}

import _ from 'lodash'
import type { CIProvider } from '@packages/types'
import parser from 'git-url-parse'

export const CINameLookup = {
  appveyor: 'AppVeyor',
  buildkite: 'Buildkite',
  circle: 'CircleCI',
  gitlab: 'GitLab',
  teamcity: 'TeamCity',
  teamfoundation: 'Team Foundation',
  travis: 'Travis CI',
  githubActions: 'GitHub Actions',
  codefresh: 'Codefresh',
  jenkins: 'Jenkins',
}

export interface CIParams {
  cypressCiBuildUrl?: string
  cypressPullRequestId?: string
  cypressPullRequestUrl?: string
}

export interface CICircleParams extends CIParams {
  circleWorkflowId: string
  buildUrl: string
}

export interface CIGitlabParams extends CIParams {
  buildId: string
  projectUrl: string
  ciJobId: string
  ciProjectUrl: string
  ciBuildId: string
  ciPipelineId: string
}

export interface CITravisParams extends CIParams {
  repoSlug: string
  travisRepoSlug: string
  buildId: string
  travisBuildId: string
}

export interface CIAppveyor extends CIParams {
  accountName: string
  appveyorAccountName: string
  projectSlug: string
  appveyorProjectSlug: string
  buildVersion: string
  appveyorBuildVersion: string
}

export interface CIGithubActions extends CIParams {
  githubAction?: string
  githubEventName?: string
  githubRepository?: string
  githubRunAttempt?: string
  githubRunId?: string
  githubWorkflow?: string
}

export interface CICodeShip extends CIParams {
  ciBuildId: string
  ciRepoName: string
  ciBuildUrl: string
  ciBuildNumber: string
  ciPullRequest: string
}

export interface CICodefresh extends CIParams {
  cfBuildId: string
  cfBuildUrl: string
  cfCurrentAttempt: string
  cfStepName: string
  cfPipelineName: string
  cfPipelineTriggerId: string
  cfPullRequestId: string
  cfPullRequestIsFork: string
  cfPullRequestNumber: string
  cfPullRequestTarget: string
}

export interface CIAzure extends CIParams {
  systemPlanid: string
}

type AnyCIParams =
  | Partial<CIParams>
  | Partial<CICircleParams>
  | Partial<CIGitlabParams>
  | Partial<CITravisParams>
  | Partial<CIAppveyor>
  | Partial<CIGithubActions>
  | Partial<CICodeShip>
  | Partial<CICodefresh>
  | Partial<CIAzure>

export interface CIInfo {
  provider: keyof typeof CINameLookup | string | null
  params: AnyCIParams | null
  remoteOrigin: string | null
}

export const CINameFormatted = (ciName: CIProvider | string | null) => {
  if (!ciName) return null

  return CINameLookup[ciName as CIProvider] || _.capitalize(ciName)
}

export const getCiProvider = function (ci: CIInfo | null): string | null {
  if (ci == null) {
    ci = {} as CIInfo
  }

  // If we already have a provider then just return that
  if (ci.provider) {
    return ci.provider
  }

  const detectedProvider = detectCiProvider(ci)
  if (detectedProvider) {
    return detectedProvider
  }

  return null
}

export const detectCiProvider = function (ci: CIInfo | null): string | null {
  if (ci == null) {
    ci = {} as CIInfo
  }

  // Use the cypressCiBuildUrl to attempt to detect the CI provider.
  const buildUrl = ci.params?.cypressCiBuildUrl
  if (buildUrl) {
    const ciNames = Object.keys(CINameLookup)
    const pattern = new RegExp(ciNames.join('|'), 'g')
    const match = buildUrl.match(pattern)
    if (match && match.length >= 1) {
      return match[0]
    }
  }

  return null
}

export const getCiUrl = function (ci: CIInfo | null): string | null {
  if (ci == null) {
    ci = {} as CIInfo
  }

  return getParamWithUserSetDefaults(ciUrlParamMap, ci)
}

export const getPullRequestNumber = function (ci: CIInfo | null) {
  if (ci == null) {
    ci = {} as CIInfo
  }

  const pullRequestNumber = getParamWithUserSetDefaults(
    pullRequestIdParamMap,
    ci
  )
  if (pullRequestNumber) {
    const val = String(pullRequestNumber).replace(/[^a-zA-Z0-9]/g, '')
    return /\d+/.test(val) ? val : null
  }

  return null
}

export const getProviderParamKey = function (paramMap: any, ci: CIInfo) {
  const { provider, params } = ci

  if (!params) {
    return null
  }

  return paramMap[provider!]
}

export const getParam = function (paramMap: any, ci: CIInfo) {
  const providerParamKey = getProviderParamKey(paramMap, ci)
  const { params, remoteOrigin } = ci

  if (!providerParamKey) {
    return null
  }

  if (_.isFunction(providerParamKey)) {
    return providerParamKey(params, remoteOrigin) || null
  }

  if (_.isArray(providerParamKey)) {
    return (
      _.chain(providerParamKey)
        .map(_.propertyOf(params as any))
        .compact()
        .first()
        .value() || null
    )
  }

  if (_.isString(providerParamKey)) {
    return (params as any)[providerParamKey] || null
  }

  return null
}

export const getParamWithUserSetDefaults = function (
  paramMap: typeof pullRequestIdParamMap | typeof ciUrlParamMap,
  ci: CIInfo
) {
  // If user provided pull request is available, we use that.
  // https://docs.cypress.io/guides/references/changelog#12-3-0
  return (
    getParam(paramMap, ci) ||
    getParam(paramMap, { ...ci, provider: 'cypressUserProvided' }) ||
    null
  )
}

/**
 * IMPORTANT!
 *
 * TODO: Duplicated from '@packages/util/origin' to avoid circular dependency.  Do not modify.
 */
const getRepoUrl = (remoteOrigin: string | null) => {
  if (remoteOrigin == null) {
    remoteOrigin = ''
  }

  try {
    return parser(remoteOrigin)
      .toString('https')
      .replace(/\.git$/, '')
  } catch (error) {
    return null
  }
}

// maps CI name to a property or a function that returns the build URL
const ciUrlParamMap = {
  cypressUserProvided: 'cypressCiBuildUrl',

  // {"buildUrl":"https://circleci.com/gh/cypress-io/cypress-example-todomvc/55"}
  circle(params: CICircleParams) {
    if (params.circleWorkflowId) {
      return `https://circleci.com/workflow-run/${params.circleWorkflowId}`
    }
    return params.buildUrl || null
  },

  codeFresh: ['cfBuildUrl', 'buildUrl'],

  // {"buildUrl":"https://app.codeship.com/projects/134609/builds/21592408"}
  codeshipBasic: ['ciBuildUrl', 'buildUrl'],

  // to handle old data that did not distinguish between codeshipBasic and codeship
  // keep old logic for codeship
  codeship: ['ciBuildUrl', 'buildUrl'],

  jenkins: 'buildUrl',

  // {buildUrl: "https://drone.company.com/org/project-name/34"}
  drone: ['droneBuildLink', 'buildUrl'],

  // {buildUrl: "https://app.shippable.com/github/<user>/<repo>/runs/5"}
  shippable: 'buildUrl',

  buildkite: 'buildkiteBuildUrl',

  bamboo: ['bambooResultsUrl', 'bambooBuildResultsUrl'],

  // {"buildId":"176423350","repoSlug":"cypress-io/cypress-example-todomvc"}
  travis(params: CITravisParams) {
    if (params.repoSlug == null) {
      params.repoSlug = params.travisRepoSlug
    }
    if (params.buildId == null) {
      params.buildId = params.travisBuildId
    }
    return `https://travis-ci.com/${params.repoSlug}/builds/${params.buildId}`
  },

  // "{"buildId":"99526","projectUrl":"https://gitlab.skypicker.com/frontend/frontend"}"
  gitlab(params: CIGitlabParams) {
    if (params.projectUrl == null) {
      params.projectUrl = params.ciProjectUrl
    }
    if (params.buildId == null) {
      params.buildId = params.ciJobId || params.ciBuildId
    }
    return `${params.projectUrl}/builds/${params.buildId}`
  },

  appveyor(params: CIAppveyor) {
    if (params.accountName == null) {
      params.accountName = params.appveyorAccountName
    }
    if (params.projectSlug == null) {
      params.projectSlug = params.appveyorProjectSlug
    }
    if (params.buildVersion == null) {
      params.buildVersion = params.appveyorBuildVersion
    }
    return `https://ci.appveyor.com/project/${params.accountName}/${params.projectSlug}/build/${params.buildVersion}`
  },

  githubActions(params: CIGithubActions, remoteOrigin: string) {
    if (params.githubRepository && params.githubRunId) {
      const [owner, repo] = params.githubRepository.split('/')

      // If user provided ci data is available we'll use that.
      // https://docs.cypress.io/guides/references/changelog#12-3-0
      if (params.cypressCiBuildUrl) {
        return params.cypressCiBuildUrl
      }

      // If we can get a repository url from our remote origin then use that url as our prefix url
      const urlPrefix =
        getRepoUrl(remoteOrigin) || `https://github.com/${owner}/${repo}`
      return `${urlPrefix}/actions/runs/${params.githubRunId}`
    }

    return null
  },
}

// Returns pull request number (as string) if there is one
const pullRequestIdParamMap = {
  azure: 'systemPullrequestPullrequestnumber',
  cypressUserProvided: 'cypressPullRequestId',
  appveyor: 'appveyorPullRequestNumber',
  bamboo: null,
  buildkite: 'buildkitePullRequest',
  circle({ circlePullRequest: pr }: { circlePullRequest: string }) {
    return _.last(pr != null ? pr.split('/') : undefined)
  },
  codeFresh: 'cfPullRequestId',
  codeship: 'ciPullRequest',
  codeshipBasic: 'ciPullRequest',
  codeshipPro: null,
  drone: 'dronePullRequest',
  gitlab: null,
  jenkins: ['ghprbPullId', 'changeId'],
  semaphore: ['pullRequestNumber', 'semaphoreGitPrNumber'],
  shippable({ pullRequest }: { pullRequest: string }) {
    if (pullRequest === 'false') {
      return null
    }
    return pullRequest
  },
  snap: null,
  teamcity: null,
  teamfoundation: null,
  travis({ travisPullRequest: pr }: { travisPullRequest: 'string' }) {
    if ((pr != null ? pr.toLowerCase() : undefined) === 'false') {
      return null
    }
    return pr
  },
  wercker: null,
}

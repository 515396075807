import { Link } from '@reach/router'
import clsx from 'clsx'
import * as React from 'react'

interface ActivatableLinkProps extends React.HTMLProps<HTMLLinkElement> {
  to?: string | undefined
  as?: string
  replace?: boolean
  usePartialComparison?: boolean
  activeClassName?: string
}

export const ActivatableLink: React.FC<ActivatableLinkProps> = ({
  usePartialComparison = true,
  activeClassName,
  className,
  ...props
}) => {
  if (props.to) {
    return (
      // @ts-ignore
      <Link
        {...props}
        getProps={({ isCurrent, isPartiallyCurrent, location }) => {
          let isActive = usePartialComparison ? isPartiallyCurrent : isCurrent

          if (!isActive && props.as && location.pathname) {
            isActive = location.pathname.includes(props.as)
          }
          return {
            className: isActive ? clsx(activeClassName, className) : className,
          }
        }}
      />
    )
  }
  return (
    // @ts-ignore
    <a className={className} {...props} />
  )
}

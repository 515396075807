import { Button } from '@frontend/design-system'
import UilAngleLeft from '@iconscout/react-unicons/icons/uil-angle-left'
import UilAngleRight from '@iconscout/react-unicons/icons/uil-angle-right'
import cs from 'clsx'
import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'

import { useOnScroll } from '~/lib/hooks/useOnScroll'

type ScrollableMenuContainerProps = {
  children: ReactNode
  className?: string
  childSelector?: string
  childExpandedClassName?: string
}

export const ScrollableMenuContainer: FunctionComponent<
  ScrollableMenuContainerProps
> = ({
  children,
  className,
  childSelector = '.dropdown',
  childExpandedClassName = 'open',
}) => {
  const [isLeftButtonShown, setLeftButtonShown] = useState(false)
  const [isRightButtonShown, setRightButtonShown] = useState(false)
  const scrollableRef = useRef<HTMLDivElement | null>(null)
  const lastChildRef = useRef<HTMLDivElement | null>(null)

  const containerChildExpandedClassName = 'child-open'

  const onScroll = () => {
    if (scrollableRef.current && lastChildRef.current) {
      if (scrollableRef.current.scrollLeft === 0) {
        setLeftButtonShown(false)
      } else {
        setLeftButtonShown(true)
      }

      if (
        scrollableRef.current.getBoundingClientRect().right >=
        lastChildRef.current.getBoundingClientRect().right
      ) {
        setRightButtonShown(false)
      } else {
        setRightButtonShown(true)
      }
    }
  }

  function animateScrolling(scrollFrom, scrollTo) {
    // https://stackoverflow.com/a/44484186
    const DURATION = 300
    const distance = scrollTo - scrollFrom

    const initialPos = scrollFrom
    const pos = initialPos + distance
    const basePos = (initialPos + pos) * 0.5
    const difference = initialPos - basePos
    const startTime = performance.now()

    function step() {
      if (!scrollableRef.current) return
      let normalizedTime = (performance.now() - startTime) / DURATION
      if (normalizedTime > 1) normalizedTime = 1

      scrollableRef.current.scrollLeft =
        basePos + difference * Math.cos(normalizedTime * Math.PI)
      if (normalizedTime < 1) window.requestAnimationFrame(step)
    }
    window.requestAnimationFrame(step)
  }

  const scrollMenu = (direction) => {
    if (!scrollableRef.current || !lastChildRef.current) return

    const scrollFrom = scrollableRef.current.scrollLeft
    let scrollTo
    if (direction === 'left') {
      scrollTo = Math.max(
        0,
        scrollableRef.current.scrollLeft -
          scrollableRef.current.getBoundingClientRect().width * 0.75
      )
    } else {
      scrollTo = Math.min(
        lastChildRef.current.getBoundingClientRect().right,
        scrollableRef.current.scrollLeft +
          scrollableRef.current.getBoundingClientRect().width * 0.75
      )
    }

    animateScrolling(scrollFrom, scrollTo)
  }

  useEffect(() => {
    // when the contents change, determine if buttons should be visible
    lastChildRef.current = document.querySelector(
      '.scrollable-menu-container__items > div:last-child'
    )
    onScroll()
  }, [children])

  // Observe class attribute changes in child elements (menu items) to ensure the container is aware when children are expanded.
  useEffect(() => {
    // Use a variable to track the number of children expanded because children mutations can collide.
    let childExpandedCount = 0

    // Create a MutationObserver to observe class changes in child elements
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'class'
        ) {
          const targetElement =
            mutation.target instanceof Element ? mutation.target : null

          // The targetElement must match the elements we are interested in.
          if (
            targetElement &&
            targetElement !== scrollableRef.current &&
            targetElement.matches(childSelector)
          ) {
            if (targetElement.classList.contains(childExpandedClassName)) {
              childExpandedCount++
            } else {
              childExpandedCount--
            }

            // If our container is collapsed or expanded but it shouldn't be then correct.
            const isChildOpenClassAdded =
              scrollableRef.current?.classList.contains(
                containerChildExpandedClassName
              )
            if (isChildOpenClassAdded && childExpandedCount === 0) {
              scrollableRef.current?.classList.remove(
                containerChildExpandedClassName
              )
            }
            if (!isChildOpenClassAdded && childExpandedCount > 0) {
              scrollableRef.current?.classList.add(
                containerChildExpandedClassName
              )
            }
          }
        }
      })
    })

    // Start observing changes in class attributes of child elements
    if (scrollableRef.current) {
      observer.observe(scrollableRef.current, {
        attributes: true,
        attributeFilter: ['class'],
        subtree: true,
      })
    }

    // Cleanup function to disconnect the observer when component unmounts
    return () => {
      observer.disconnect()
    }
  }, [childSelector, childExpandedClassName])

  useOnScroll(
    {
      scrollable: scrollableRef.current,
      delay: 50,
    },
    onScroll
  )

  return (
    <div className={cs('scrollable-menu-container', className)}>
      <div ref={scrollableRef} className="scrollable-menu-container__items">
        {children}
      </div>
      {isLeftButtonShown && (
        <Button
          data-cy="scroll-filters-left"
          className="scrollable-menu-container__button-left"
          onClick={() => scrollMenu('left')}
        >
          <UilAngleLeft />
        </Button>
      )}
      {isRightButtonShown && (
        <Button
          data-cy="scroll-filters-right"
          className="scrollable-menu-container__button-right"
          onClick={() => scrollMenu('right')}
        >
          <UilAngleRight />
        </Button>
      )}
    </div>
  )
}

import { uniqueId } from 'lodash'
import { action, computed, observable } from 'mobx'
import { durationFormatted } from '@packages/common'

export default class Timer {
  @observable milliseconds

  constructor(initialMilliseconds = 0) {
    this.milliseconds = initialMilliseconds
    this.id = uniqueId()
  }

  @action reset() {
    this.milliseconds = 0
  }

  @computed get display() {
    return durationFormatted(this.milliseconds)
  }
}

import { CiProviderEnum } from '~/graphql-codegen-operations.gen'

interface CiProviderInfo {
  key: CiProviderEnum | 'default'
  title: string
  link: string
}

export const ciProviderData: Partial<Record<CiProviderEnum, CiProviderInfo>> = {
  AWS_CODEBUILD: {
    key: 'AWS_CODEBUILD',
    title: 'AWS CodeBuild',
    link: 'https://on.cypress.io/aws-codebuild',
  },
  BITBUCKET: {
    key: 'BITBUCKET',
    title: 'Bitbucket Pipelines',
    link: 'https://on.cypress.io/bitbucket-pipelines',
  },
  CIRCLECI: {
    key: 'CIRCLECI',
    title: 'CircleCI',
    link: 'https://on.cypress.io/setup-ci-circleci',
  },
  GITHUB_ACTIONS: {
    key: 'GITHUB_ACTIONS',
    title: 'GitHub Actions',
    link: 'https://on.cypress.io/github-actions',
  },
  GITLAB: {
    key: 'GITLAB',
    title: 'GitLab CI',
    link: 'https://on.cypress.io/gitlab-ci',
  },
  AZURE: {
    key: 'AZURE',
    title: 'Azure',
    link: 'https://on.cypress.io/setup-ci-azure-pipelines',
  },
}

export const defaultCiProviderInfo: CiProviderInfo = {
  key: 'default',
  title: 'CI',
  link: 'https://on.cypress.io/continuous-integration',
}

import { Banner } from '@frontend/design-system'
import { Link } from '@reach/router'
import moment from 'moment'
import React, { FunctionComponent } from 'react'

import { AppBannerOrgFragment } from '~/graphql-codegen-operations.gen'
import { standardDateFormatted } from './utils'
import { IconActionDeleteXlarge } from '@cypress-design/react-icon'

type GracePeriodBannerProps = {
  closeBanner: () => void
  org: AppBannerOrgFragment
}

export const GracePeriodBanner: FunctionComponent<GracePeriodBannerProps> = ({
  closeBanner,
  org,
}) => {
  if (
    !org.gracePeriodUntil ||
    moment().isAfter(org.gracePeriodUntil) ||
    org.plan.usageCalculation === 'V2_ALL_TESTS'
  ) {
    return null
  }

  return (
    <Banner variant="success" className="grace-period-banner">
      <span>
        <i className="fa fa-calendar" /> You have unlimited test results until{' '}
        <strong>{standardDateFormatted(org.gracePeriodUntil)}</strong>.{' '}
        {org.viewerIsOwnerOrAdmin ? (
          <span>
            <Link to={`/organizations/${org.id}/pricing`}>
              Upgrade your plan
            </Link>
          </span>
        ) : (
          <span>Ask your admin to upgrade your plan</span>
        )}{' '}
        to continue recording tests after this date.
      </span>
      <span className="upgrade-section pull-right">
        {org.viewerIsOwnerOrAdmin && (
          <Link
            to={`/organizations/${org.id}/pricing`}
            className="btn btn-outline btn-upgrade"
          >
            Upgrade Now
          </Link>
        )}
        <button
          type="button"
          aria-label="Close"
          className="banner--close btn btn-link"
          onClick={closeBanner}
        >
          <IconActionDeleteXlarge />
        </button>
      </span>
    </Banner>
  )
}

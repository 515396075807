import React, { FunctionComponent, useMemo } from 'react'
import { min, max, first, last } from 'lodash'
import { format } from 'date-fns'
import { LineMarkSeriesPoint } from 'react-vis'
import { getUTCDateAsLocalDate } from '~/lib/utils'
import { PointData } from './types'

type TestTrendHeaderProps = {
  focusedValue?: LineMarkSeriesPoint | null
  integrationBranchPoints: PointData[]
  commitBranchPoints: PointData[]
}

const getDateLabels = (date: number) => {
  const formattedDate = getUTCDateAsLocalDate(date)

  return {
    day: format(formattedDate, 'd'),
    month: format(formattedDate, 'MMM'),
    year: format(formattedDate, 'y'),
    full: format(formattedDate, 'MMM d, y'),
    time: `${format(formattedDate, 'MMM d, y h:mm:ss a')} UTC`,
  }
}

export const TestTrendHeader: FunctionComponent<TestTrendHeaderProps> = ({
  focusedValue,
  integrationBranchPoints,
  commitBranchPoints,
}) => {
  const header = useMemo(() => {
    const formatPoint = (point: PointData) => new Date(point.x).getTime()

    const integrationBranchTimes = integrationBranchPoints.map(formatPoint)
    const commitBranchTimes = commitBranchPoints.map(formatPoint)

    const minDate = min([
      first(integrationBranchTimes),
      first(commitBranchTimes),
    ])

    const maxDate = max([last(integrationBranchTimes), last(commitBranchTimes)])

    /* istanbul ignore next */
    if (!minDate || !maxDate) {
      return null
    }

    const minDateLabels = getDateLabels(minDate)
    const maxDateLabels = getDateLabels(maxDate)

    if (minDateLabels.full === maxDateLabels.full) {
      return minDateLabels.full
    }

    if (
      minDateLabels.month === maxDateLabels.month &&
      minDateLabels.year === maxDateLabels.year
    ) {
      return `${minDateLabels.month} ${minDateLabels.day} - ${maxDateLabels.day}, ${minDateLabels.year}`
    }

    if (minDateLabels.year === maxDateLabels.year) {
      return `${minDateLabels.month} ${minDateLabels.day} - ${maxDateLabels.month} ${maxDateLabels.day}, ${minDateLabels.year}`
    }

    return `${minDateLabels.full} - ${maxDateLabels.full}`
  }, [integrationBranchPoints, commitBranchPoints])

  /* istanbul ignore next */
  if (!focusedValue && !header) {
    return null
  }

  return (
    <h6 className="test-trend-header">
      {focusedValue ? getDateLabels(Number(focusedValue.x)).time : header}
    </h6>
  )
}

import React from 'react'
import Button from '@cypress-design/react-button'
import styles from './module.GroupList.scss'
import { SkipButton } from './SkipButton'
import { Alert } from './Alert'
import { Link } from '@reach/router'

type ViewBranchReviewProps = {
  orgId: string
  field: string
  hasReportedBranches: boolean
  hasRecordedRun: boolean
  isStepChecked: boolean
  projectId: string
}

export const ViewBranchReview: React.FunctionComponent<
  ViewBranchReviewProps
> = ({
  orgId,
  field,
  hasReportedBranches,
  hasRecordedRun,
  isStepChecked,
  projectId,
}) => {
  if (!hasReportedBranches && hasRecordedRun) {
    return <Alert />
  }

  return (
    <div className={styles.actionButtons}>
      <Link to={`/projects/${projectId}/branches`}>
        <Button>View Branches</Button>
      </Link>
      <SkipButton orgId={orgId} field={field} isStepChecked={isStepChecked} />
    </div>
  )
}

import { CustomIcon } from '@frontend/design-system'
import React, { FunctionComponent } from 'react'
import { SidebarOrganizationQuery } from '~/graphql-codegen-operations.gen'
import { NavItem } from './nav-item'
import { features } from '~/lib/feature-flags'
import OnboardingProgressBar from './OnboardingProgressBar'

type Organization = SidebarOrganizationQuery['organizationOrDefault']

const OrgNav: FunctionComponent<{
  organization: Organization
  useNewOnboarding: boolean
}> = ({ organization, useNewOnboarding }) => {
  if (!organization) {
    return <div className="nav-container" />
  }

  return (
    <nav
      data-cy="org-nav"
      data-pendo="org-nav"
      className="nav-container"
      aria-label={`${organization.name} navigation`}
    >
      <ul className="nav-list">
        {useNewOnboarding && (
          <li className="nav-item-bottom-border">
            <NavItem
              hideLabel={true}
              label="trial-progress"
              to={`/organizations/${organization?.id}/trial-progress`}
            >
              <OnboardingProgressBar organizationId={organization.id} />
            </NavItem>
          </li>
        )}
        <li>
          <div className="projects-nav-header">
            <NavItem
              icon="book"
              label="Projects"
              to={`/organizations/${organization.id}/projects`}
              addon
            ></NavItem>
          </div>
        </li>
        {organization.viewerIsOwnerOrAdmin && (
          <li>
            <NavItem
              icon="dollar"
              label="Billing &amp; Usage"
              to={`/organizations/${organization.id}/billing`}
            />
          </li>
        )}
        <li>
          <NavItem
            icon="plug"
            label="Integrations"
            to={`/organizations/${organization.id}/integrations`}
          />
        </li>
        <li>
          <NavItem
            icon="user-circle-o"
            label="Users"
            to={`/organizations/${organization.id}/users`}
          />
        </li>
        <li>
          <NavItem
            data-cy="side-nav-organization-settings"
            data-pendo="side-nav-organization-settings"
            icon={
              <CustomIcon name="nav-settings" size="x16" className="cy-icon" />
            }
            label="Organization Settings"
            to={`/organizations/${organization.id}/settings`}
          />
        </li>
        {features.isEnabled('metabase-static-embedding') && (
          <li>
            <NavItem
              data-cy="side-nav-enterprise-reporting"
              data-pendo="side-nav-enterprise-reporting"
              icon={
                <CustomIcon
                  name="general-office-building"
                  size="x16"
                  className="enterprise-reporting-icon"
                />
              }
              label="Enterprise Reporting"
              to={`/organizations/${organization.id}/enterprise-reporting`}
            />
          </li>
        )}
      </ul>
    </nav>
  )
}

export { OrgNav }

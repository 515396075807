import {
  Button,
  CustomIcon,
  EmptyState,
  Icon,
  IconButton,
  Panel,
  Tag,
  Tooltip,
} from '@frontend/design-system'
import { take } from 'lodash'
import React, { FunctionComponent, useState } from 'react'
import { readableViewByEnum } from '~/lib/utils-ts'
import { LoadingContainer } from '~/project-analytics/LoadingContainer'
import cs from 'clsx'

import styles from '../module.AnalyticsTable.scss'

import {
  ProjectFlakyTestsQuery,
  ViewByEnum,
} from '~/graphql-codegen-operations.gen'
import { SpecFile } from '~/specs/stats/SpecFile'
import { FlakyLabel } from '~/common/flaky-label'
import { TestResultTitleProvider } from '~/test-results/hooks/useTitleTruncation'
import { TestTitle } from '~/test-results/TestTitle'
import { AnalyticsProgressBar } from '../AnalyticsProgressBar'
import { LastRun } from '../LastRun/LastRun'
import { AnalyticRowLink } from '../AnalyticRowLink/AnalyticRowLink'
import { IconChevronRightLarge } from '@cypress-design/react-icon'

type FlakyTestsTableProps = {
  data?: ProjectFlakyTestsQuery
  activeDrawerId?: string
  isLoading: boolean
  viewBy: ViewByEnum
}

interface ViewByRowProps {
  row: ProjectFlakyTestsQuery['metrics']['projectFlakinessTestsOverTime']['nodes'][0]
  viewBy: ViewByEnum
}

const ViewByRow: FunctionComponent<ViewByRowProps> = ({ row, viewBy }) => {
  if (viewBy === 'BRANCH') {
    return <span>{row.branch}</span>
  }

  if (viewBy === 'SPEC') {
    return (
      <div className={styles.viewByRow}>
        <SpecFile specPath={row.specPath} />
      </div>
    )
  }

  if (viewBy === 'TAG' && row.tag) {
    return (
      <div className={styles.viewByRow}>
        <Tag backgroundColor={row.tag.color}>{row.tag.name}</Tag>
      </div>
    )
  }

  return (
    <>
      <TestTitle id={row.id} titleParts={row.titleParts || []} isFlaky />
      <SpecFile specPath={row.specPath} />
    </>
  )
}

const SeverityDescription = () => {
  return (
    <div className={styles.severityDescription} data-cy="severity-tooltip">
      <div className={styles.severityRow}>
        <span>Severity</span>
        <span>Flaky rate</span>
      </div>
      <div className={cs([styles.severityRow, styles.tooltipLowSeverity])}>
        <span>Low</span>
        <span>0-10%</span>
      </div>
      <div className={cs([styles.severityRow, styles.tooltipMediumSeverity])}>
        <span>Medium</span>
        <span>11-50%</span>
      </div>
      <div className={cs([styles.severityRow, styles.tooltipHighSeverity])}>
        <span>High</span>
        <span>51-100%</span>
      </div>
    </div>
  )
}

export const FlakyTestsTable: FunctionComponent<FlakyTestsTableProps> = ({
  data,
  activeDrawerId,
  viewBy,
  isLoading,
}) => {
  const PER_PAGE = 30
  const [numberOfTestsShown, setNumberOfTestsShown] = useState(PER_PAGE)

  if (!data || !data.metrics) {
    return (
      <Panel>
        <EmptyState>
          <span>Loading...</span>
        </EmptyState>
      </Panel>
    )
  }

  const loadMoreTests = () => {
    setNumberOfTestsShown(numberOfTestsShown + PER_PAGE)
  }

  const metrics = data.metrics.projectFlakinessTestsOverTime
  const rows = take(metrics.nodes, numberOfTestsShown) || []
  const rowHasDrillIn = viewBy === 'TEST_CASE'
  const gridTemplateColumns = rowHasDrillIn
    ? 'auto 1fr auto auto auto auto auto'
    : 'auto 1fr auto auto auto auto'

  return (
    <LoadingContainer active={isLoading}>
      <Panel>
        {metrics.nodes.length < 1 ? (
          <EmptyState>
            <span>No data for this time range.</span>
          </EmptyState>
        ) : (
          <div
            className={cs(styles.analyticsTable, styles.flakyTestsTable)}
            data-cy="flaky-tests-table"
            style={{ gridTemplateColumns }}
          >
            <AnalyticRowLink dataCy="top-failures-table__header">
              <h6 />
              <h6>{readableViewByEnum(viewBy)}</h6>
              <h6 />
              <h6>Last flaky</h6>
              <h6 className={cs([styles.textRight, styles.flakyRateHeader])}>
                Flaky rate
              </h6>
              <h6 className={styles.textRight}>
                <Tooltip
                  overlay={<SeverityDescription />}
                  placement="left"
                  overlayClassName={styles.severityTooltip}
                >
                  <span>
                    Severity <Icon name="info" />
                  </span>
                </Tooltip>
              </h6>
              {rowHasDrillIn && <h6 />}
            </AnalyticRowLink>

            <TestResultTitleProvider>
              {rows.map((row, i) => {
                return (
                  <AnalyticRowLink
                    isEnabled={rowHasDrillIn}
                    to={`/projects/${data.project.id}/analytics/flaky-tests/${row.id}`}
                    isActive={activeDrawerId === row.id}
                    dataCy="flaky-tests-table__row"
                    key={row.id}
                  >
                    <div>
                      <span className={styles.textRight}>{i + 1}</span>
                    </div>
                    <div>
                      <div className={styles.viewByRowContainer}>
                        <ViewByRow row={row} viewBy={viewBy} />
                      </div>
                    </div>
                    <div className={styles.mutedTestContainer}>
                      {Boolean(row.activeMuteOrchestration) && (
                        <Tooltip
                          placement="top"
                          overlay="Cypress will ignore the status of this test because it is muted."
                        >
                          <CustomIcon name="mute-test" />
                        </Tooltip>
                      )}
                    </div>
                    <div>
                      <LastRun lastRun={row.lastFlakyRun} />
                    </div>
                    <div>
                      <div
                        className={cs([
                          styles.textRight,
                          styles.title,
                          styles.flakyRate,
                        ])}
                      >
                        <div>
                          {row.totalFlakyRuns}/{row.totalRuns}
                        </div>
                        <AnalyticsProgressBar
                          rate={(row.totalFlakyRuns / row.totalRuns) * 100}
                          includeRate={false}
                          className="progress-bar-flakiness"
                          includeTooltip={true}
                        />
                      </div>
                    </div>
                    <div>
                      <div className={styles.title}>
                        <FlakyLabel severity={row.severity} />
                      </div>
                    </div>
                    {rowHasDrillIn && (
                      <div className={styles.chevron}>
                        <IconButton
                          IconComponent={IconChevronRightLarge}
                          aria-label="Open test results"
                        />
                      </div>
                    )}
                  </AnalyticRowLink>
                )
              })}
              {metrics.nodes.length > numberOfTestsShown && (
                <div
                  key={metrics.nodes.length}
                  className={styles.footer}
                  style={{ gridColumn: rowHasDrillIn ? '1 / 7' : '1 / 6' }}
                >
                  <Button bsStyle="link" onClick={loadMoreTests}>
                    Load more
                  </Button>
                </div>
              )}
            </TestResultTitleProvider>
          </div>
        )}
      </Panel>
    </LoadingContainer>
  )
}

import { atom } from 'recoil'

const globalHasInitializedFromRouter = atom({
  key: 'globalHasInitializedFromRouter',
  default: false,
})

export default {
  globalHasInitializedFromRouter,
}

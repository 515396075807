import React from 'react'
import { IconActionTestReplay } from '@cypress-design/react-icon'
import Button from '@cypress-design/react-button'
import styles from './module.GroupList.scss'
import { SkipButton } from './SkipButton'
import {
  OrganizationOnboardingQuery,
  RunsListItemFragment,
} from '../graphql-codegen-operations.gen'
import { RunsListItem } from '../runs/RunsListItem'
import { Link } from '@reach/router'

type ReviewLatestRunProps = {
  orgId: string
  field: string
  latestFailedOrFlakyRun?: RunsListItemFragment | null
  latestRun: OrganizationOnboardingQuery['organization']['latestRun']
  isStepChecked: boolean
}

export const ReviewLatestRun: React.FunctionComponent<ReviewLatestRunProps> = ({
  orgId,
  field,
  latestFailedOrFlakyRun,
  latestRun,
  isStepChecked,
}) => {
  if (latestFailedOrFlakyRun) {
    return (
      <div>
        <div className={styles.latestFailedOrFlakyRun}>
          <RunsListItem
            run={latestFailedOrFlakyRun}
            projectId={latestFailedOrFlakyRun.project.id}
          />
        </div>
        <div className={styles.actionButtons}>
          <Link
            to={`/projects/${latestFailedOrFlakyRun.project.id}/runs/${latestFailedOrFlakyRun.buildNumber}/overview`}
          >
            <Button>Review run #{latestFailedOrFlakyRun.buildNumber}</Button>
          </Link>

          <SkipButton
            orgId={orgId}
            field={field}
            isStepChecked={isStepChecked}
          />
        </div>
      </div>
    )
  }

  if (latestRun) {
    return (
      <div className={styles.actionButtons}>
        <Link
          to={`/projects/${latestRun.project.id}/runs/${latestRun.buildNumber}/overview`}
        >
          <Button>
            <IconActionTestReplay className="mr-2" />
            Review latest run
          </Button>
        </Link>

        <SkipButton orgId={orgId} field={field} isStepChecked={isStepChecked} />
      </div>
    )
  }

  return (
    <div className={styles.actionButtons}>
      <SkipButton orgId={orgId} field={field} isStepChecked={isStepChecked} />
    </div>
  )
}

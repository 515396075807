import { CustomIcon } from '@frontend/design-system'
import React, { FunctionComponent, useEffect, useState } from 'react'

import { MultiSelectFilter, onMultiSelectFilterChange } from '~/common/filters'
import { useRunGroupFilterLazyQuery } from '~/graphql-codegen-operations.gen'

import { MultiSelectOptionType } from '../../types'

type RunGroupFilterProps = {
  id: string
  selected: MultiSelectOptionType[]
  onChange: onMultiSelectFilterChange
  runId: string
}

export const RunGroupFilter: FunctionComponent<RunGroupFilterProps> =
  React.memo(({ id, onChange, selected, runId }) => {
    const [options, setOptions] = useState<MultiSelectOptionType[] | null>(null)
    const [fetchRunGroups] = useRunGroupFilterLazyQuery({
      onCompleted: (data) => {
        if (!data.run || options) {
          return
        }

        const formattedOptions = data.run.uniqueGroups.map(({ name }) => ({
          label: name,
          value: name,
        }))

        setOptions(formattedOptions)
      },
    })

    useEffect(() => {
      fetchRunGroups({
        variables: {
          id: runId,
        },
      })
    }, [fetchRunGroups, runId])

    return (
      <MultiSelectFilter
        id={id}
        title={<CustomIcon name="run-group" alt="Run group" />}
        emptyLabel="Run Group"
        selected={selected}
        onChange={onChange}
        options={options || []}
      />
    )
  })

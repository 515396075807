import { ContentLoader, palette } from '@frontend/design-system'
import React, { FunctionComponent } from 'react'

import { PageHeader } from '@frontend/dashboard/src/navs/page-header'

type LoaderPageProps = {
  showLoaders?: boolean
}

export const LoaderPage: FunctionComponent<LoaderPageProps> = ({
  /* istanbul ignore next */
  showLoaders = false,
}) => {
  return (
    <div
      className={`loader-page stacked-layout ${
        showLoaders ? 'stacked-layout--is-loading' : ''
      }`}
    >
      <PageHeader className="stacked-layout--header">
        <ContentLoader
          width={300}
          height={32}
          className="stacked-layout--loader"
          primaryColor={palette.gray100}
          secondaryColor={palette.gray200}
        />
      </PageHeader>
    </div>
  )
}

import React, { FunctionComponent, useState } from 'react'
import moment from 'moment'
import { useMembershipInvitationLinkCreateMutation } from '~/graphql-codegen-operations.gen'
import styles from './module.InviteLink.scss'
import pluralize from 'pluralize'
import Button from '@cypress-design/react-button'
import { IconObjectChainLink } from '@cypress-design/react-icon'

import { CustomIcon, Tooltip } from '@frontend/design-system'

interface InviteLinkProps {
  id?: string
  isOnboarding?: boolean
}

export const InviteLink: FunctionComponent<InviteLinkProps> = ({
  id,
  isOnboarding,
}) => {
  const [invitationLinkCopyText, setInvitationLinkCopyText] = useState('')
  const [invitationLinkCreate] = useMembershipInvitationLinkCreateMutation()

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

  const processInvitationLink = (invitationLink) => {
    const invitationLinkExpiresDays = Math.ceil(
      moment(invitationLink.expiresAt).diff(moment(), 'days', true)
    )

    setInvitationLinkCopyText(
      `Link copied. Expires in ${invitationLinkExpiresDays} ${pluralize(
        'day',
        invitationLinkExpiresDays
      )}.`
    )
  }

  const handleInvitationLinkCopy = async () => {
    let baseUrl = `https://cloud.cypress.io`
    if (window.env === 'staging') {
      baseUrl = `https://cloud-staging.cypress.io`
    }
    /**
     * The isSafari check is necessary because Safari handles the Clipboard API differently than
     * Chromium-based browsers. For whatever reason, async clipboard operations cannot be awaited
     * prior to being copied.
     *
     * FOR SAFARI ONLY, run all async operations in a promise whose result you assign to the
     * CLipBoardItem
     *
     * See the warning at the bottom of the linked docs section in this comment.
     *
     * @see https://web.dev/async-clipboard/#write()
     *
     */
    if (isSafari) {
      const clipboardItem = new ClipboardItem({
        'text/plain': new Promise(async (resolve) => {
          const result = await invitationLinkCreate({
            variables: {
              orgId: id,
            },
          })

          const invitationLink =
            result.data?.membershipInvitationLinkCreate.invitationLinks[0]

          /**
           * We have to return an empty string to the clipboard if something bad happens, otherwise the
           * return type for the ClipBoardItem is incorrect.
           */
          if (!invitationLink) {
            setInvitationLinkCopyText('Something went wrong')
            resolve(new Blob([``]))
          }

          processInvitationLink(invitationLink)

          const copyText = `${baseUrl}/invitation/${
            invitationLink!.invitationToken
          }`
          resolve(new Blob([copyText]))
        }) as Promise<Blob>,
      })
      navigator.clipboard.write!([clipboardItem])
    } else {
      // Non-Safari browser copy link logic
      const result = await invitationLinkCreate({
        variables: {
          orgId: id,
        },
      })

      const invitationLink =
        result.data?.membershipInvitationLinkCreate.invitationLinks[0]

      if (invitationLink) {
        processInvitationLink(invitationLink)

        const copyText = `${baseUrl}/invitation/${invitationLink.invitationToken}`
        navigator.clipboard.writeText(copyText)
      } else {
        setInvitationLinkCopyText('Something went wrong')
      }
    }
  }

  if (isOnboarding) {
    return (
      <Tooltip
        placement="bottom"
        align={{
          offset: [0, -7],
        }}
        overlay={<span>{invitationLinkCopyText}</span>}
        visible={!!invitationLinkCopyText}
      >
        <Button
          variant="outline-indigo"
          className={styles.outlineButton}
          onClick={handleInvitationLinkCopy}
          onMouseOut={() => setInvitationLinkCopyText('')}
        >
          <IconObjectChainLink className="mr-2" />
          Copy invite link
        </Button>
      </Tooltip>
    )
  }

  return (
    <Tooltip
      placement="bottom"
      align={{
        offset: [0, -7],
      }}
      overlay={<span>{invitationLinkCopyText}</span>}
      visible={!!invitationLinkCopyText}
    >
      <span
        onClick={handleInvitationLinkCopy}
        onMouseOut={() => setInvitationLinkCopyText('')}
        data-cy="copy-invite-link"
        className={`btn btn-link copy-invite-link ${styles.container}`}
      >
        <CustomIcon name="link" variant="small" />
        Copy invite link
      </span>
    </Tooltip>
  )
}

import { NavigateFn } from '@reach/router'
import Cookies from 'js-cookie'
import _ from 'lodash'

const defaultRoute = '/organizations'

function getFullPath(location: Location) {
  return _.compact([location.pathname, location.search]).join('')
}

function shouldHitServer(routeToVisit: string) {
  const nonClientSideRouteable = ['/invitation', '/latest-run-overview']
  return nonClientSideRouteable.some((v) => routeToVisit.includes(v))
}

export function saveCurrentRoute(location: Location) {
  localStorage.setItem('routeBeforeLogin', getFullPath(location))
}

export function navigateToDefaultRoute(
  navigate: NavigateFn,
  location: Location
) {
  let routeToVisit = defaultRoute

  const localStorageRoute = localStorage.getItem('routeBeforeLogin')
  if (localStorageRoute) {
    if (localStorageRoute !== '/login') {
      routeToVisit = localStorageRoute
    }
    localStorage.removeItem('routeBeforeLogin')
  }

  const cookieRoute = Cookies.get('routeBeforeLogin')
  if (cookieRoute) {
    if (cookieRoute !== '/login') {
      routeToVisit = cookieRoute
    }
    Cookies.remove('routeBeforeLogin', { path: '' })
  }

  // only update our hash history if we are not currently
  // on this pathname
  if (routeToVisit !== getFullPath(location)) {
    if (shouldHitServer(routeToVisit)) {
      location.replace(routeToVisit)
    } else {
      navigate(routeToVisit, { replace: true })
    }
  }
}

import React from 'react'

export const useCopyText = (defaultCopyText: string = 'Copy to clipboard') => {
  const [copyState, setCopyState] = React.useState({
    copied: false,
    copyText: defaultCopyText,
  })
  const onCopy = () => {
    setCopyState({
      copied: true,
      copyText: 'Copied!',
    })
  }
  const resetText = () =>
    setTimeout(() => {
      setCopyState({
        ...copyState,
        copyText: defaultCopyText,
      })
    }, 250)

  return {
    onCopy,
    resetText,
    copyState,
  }
}

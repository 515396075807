import { capitalize, filter, join, map, split } from 'lodash'
import { palette } from '@frontend/design-system'
import {
  RunStatusEnum,
  RunInstanceStatusEnum,
  Screenshot,
  TestResultStateEnum,
  ViewByEnum,
} from '@frontend/dashboard/src/graphql-codegen-operations.gen'
import {
  RunGroupStatusesValue,
  RunInstanceStatusesValue,
  RunStatusesValue,
  TestResultStatesValue,
} from '@packages/common/src/enums'

export const readableRunStatusEnum = (status: RunStatusEnum) => {
  switch (status) {
    case 'TIMEDOUT':
      return 'Timed Out'
    case 'ERRORED':
      return 'Errored'
    case 'PASSED':
      return 'Passed'
    case 'NOTESTS':
      return 'No Tests'
    case 'OVERLIMIT':
      return 'Over Limit'
    case 'RUNNING':
      return 'Running'
    case 'FAILED':
      return 'Failed'
    case 'CANCELLED':
      return 'Canceled'
    default:
      return 'Unknown'
  }
}

export const readableViewByEnum = (viewBy: ViewByEnum) => {
  switch (viewBy) {
    case 'SPEC':
      return 'Spec File'
    default:
      return join(map(split(viewBy, '_'), capitalize), ' ')
  }
}

export const testResultStateDotColor = (status: TestResultStateEnum) => {
  switch (status) {
    case 'PASSED':
      return palette.jade400
    case 'FAILED':
      return palette.red400
    case 'PENDING':
      return palette.gray800
    case 'RUNNING':
      return palette.indigo400
    case 'SKIPPED':
      return palette.gray400
    default:
      return palette.orange400
  }
}

export const readableTestResultStateEnum = (status: TestResultStateEnum) => {
  switch (status) {
    case 'PASSED':
      return 'Passed'
    case 'FAILED':
      return 'Failed'
    case 'PENDING':
      return 'Pending'
    case 'SKIPPED':
      return 'Skipped'
    case 'ERRORED':
      return 'Errored'
    case 'RUNNING':
      return 'Running'
    case 'TIMEDOUT':
      return 'Timed out'
    case 'CANCELLED':
      return 'Canceled'
    default:
      return 'Unknown'
  }
}

export const readableTestTypeEnum = (testType: string) => {
  switch (testType) {
    case 'E2E':
      return 'E2E'
    case 'COMPONENT':
      return 'Component'
    default:
      return 'Unknown'
  }
}

export function runStatusEnumToStatus(
  runStatus: RunStatusEnum | null | undefined
) {
  switch (runStatus) {
    case 'FAILED':
      return 'failed'
    case 'TIMEDOUT':
      return 'timedOut'
    case 'ERRORED':
      return 'errored'
    case 'PASSED':
      return 'passed'
    case 'NOTESTS':
      return 'noTests'
    case 'OVERLIMIT':
      return 'overLimit'
    case 'RUNNING':
      return 'running'
    case 'CANCELLED':
      return 'canceled'
    default:
      return undefined
  }
}

export function instanceStatusEnumToStatus(
  instanceStatus: RunInstanceStatusEnum | null | undefined
) {
  switch (instanceStatus) {
    case 'UNCLAIMED':
      return 'unclaimed'
    case 'FAILED':
      return 'failed'
    case 'TIMEDOUT':
      return 'timedOut'
    case 'ERRORED':
      return 'errored'
    case 'PASSED':
      return 'passed'
    case 'NOTESTS':
      return 'noTests'
    case 'RUNNING':
      return 'running'
    case 'CANCELLED':
      return 'canceled'
    default:
      return undefined
  }
}

type Status =
  | RunGroupStatusesValue
  | RunInstanceStatusesValue
  | RunStatusesValue
  | TestResultStatesValue
  | 'canceled'
  | null
  | undefined

export const getStatusDisplayInfoV2 = (status: Status) => {
  switch (status) {
    case 'unclaimed':
      return {
        iconName: 'queued',
        color: palette.gray100,
        windiColor: 'gray-100',
        iconSpin: false,
      }
    case 'passed':
      return {
        iconName: 'passed',
        color: palette.jade400,
        windiColor: 'jade-400',
        iconSpin: false,
      }
    case 'running':
      return {
        iconName: 'running',
        color: palette.indigo400,
        windiColor: 'indigo-400',
        iconSpin: true,
      }
    case 'failed':
      return {
        iconName: 'failed',
        color: palette.red400,
        windiColor: 'red-400',
        iconSpin: false,
      }
    case 'errored':
      return {
        iconName: 'errored',
        color: palette.orange400,
        windiColor: 'orange-400',
        iconSpin: false,
      }
    case 'noTests':
      return {
        iconName: 'canceled',
        color: palette.gray400,
        windiColor: 'gray-400',
        iconSpin: false,
      }
    case 'timedOut':
      return {
        iconName: 'errored',
        color: palette.orange400,
        windiColor: 'orange-400',
        iconSpin: false,
      }
    case 'overLimit':
      return {
        iconName: 'errored',
        color: palette.orange400,
        windiColor: 'orange-400',
        iconSpin: false,
      }
    case 'canceled':
      return {
        iconName: 'canceled',
        color: palette.gray300,
        windiColor: 'gray-300',
        iconSpin: false,
      }
    case 'skipped':
      return {
        iconName: 'skipped',
        color: palette.gray400,
        windiColor: 'gray-400',
        iconSpin: false,
      }
    case 'pending':
      return {
        iconName: 'pending',
        color: palette.gray300,
        windiColor: 'gray-300',
        iconSpin: false,
      }
    default:
      return {
        iconName: 'placeholder',
        color: palette.gray300,
        windiColor: 'gray-300',
        iconSpin: false,
      }
  }
}

// note: this will be removed when V1 is phased out
export const getStatusDisplayInfo = (status: string | null | undefined) => {
  switch (status) {
    case 'unclaimed':
      return {
        iconName: 'clock-o',
        color: palette.gray600,
        windiColor: 'gray-600',
        iconSpin: false,
      }
    case 'passed':
      return {
        iconName: 'check',
        color: palette.jade400,
        windiColor: 'jade-400',
        iconSpin: false,
      }
    case 'running':
      return {
        iconName: 'refresh',
        color: palette.indigo400,
        windiColor: 'indigo-400',
        iconSpin: true,
      }
    case 'failed':
      return {
        iconName: 'times',
        color: palette.red400,
        windiColor: 'red-400',
        iconSpin: false,
      }
    case 'errored':
      return {
        iconName: 'exclamation-triangle',
        color: palette.orange400,
        windiColor: 'orange-400',
        iconSpin: false,
      }
    case 'noTests':
      return {
        iconName: 'ban',
        color: palette.orange400,
        windiColor: 'orange-400',
        iconSpin: false,
      }
    case 'timedOut':
      return {
        iconName: 'hourglass-end',
        color: palette.orange400,
        windiColor: 'orange-400',
        iconSpin: false,
      }
    case 'overLimit':
      return {
        iconName: 'exclamation-triangle',
        color: palette.orange400,
        windiColor: 'orange-400',
        iconSpin: false,
      }
    case 'canceled':
      return {
        iconName: 'ban',
        color: palette.orange400,
        windiColor: 'orange-400',
        iconSpin: false,
      }
    case 'skipped':
      return {
        iconName: 'ban',
        iconSpin: false,
      }
    case 'pending':
      return {
        iconName: 'circle-o-notch',
        iconSpin: false,
      }
    case null:
      return {
        iconName: 'terminal',
        iconSpin: false,
      }
    default:
      return {
        iconName: 'spinner',
        color: palette.orange400,
        windiColor: 'orange-400',
        iconSpin: false,
      }
  }
}

export function isVisible(el: HTMLElement) {
  // @ts-ignore
  const rect = el.getBoundingClientRect()
  const elemTop = rect.top
  const elemBottom = rect.bottom
  // @ts-ignore
  const isVisible = elemTop >= 0 && elemBottom <= window.innerHeight

  return isVisible
}

function screenshotsWithUrl(screenshots: Screenshot[]) {
  return filter(
    screenshots,
    ({ publicThumbnailUrl, fullResolutionUrl }) =>
      Boolean(publicThumbnailUrl) || Boolean(fullResolutionUrl)
  )
}

export function getLightboxScreenshots(screenshots: Screenshot[]) {
  return map(screenshotsWithUrl(screenshots), (screenshot, i) => {
    return {
      index: i,
      id: screenshot.id,
      w: screenshot.width,
      h: screenshot.height,
      src: screenshot.fullResolutionUrl,
      title: screenshot.title,
    }
  })
}

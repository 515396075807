import React, { FunctionComponent, ReactNode, useState } from 'react'
import cs from 'clsx'
import { BetaBadge } from './badges/BetaBadge'
import styles from './module.CollapsingSection.scss'
import { isBoolean } from 'lodash'
import {
  IconChevronUpLarge,
  IconChevronDownLarge,
} from '@cypress-design/react-icon'

type CollapsingSectionProps = {
  title: ReactNode
  isBeta?: boolean
  shouldBeCollapsed?: boolean
  subtitle?: ReactNode | null
  isCollapsedSection?: boolean
  setIsCollapsedSection?: (v: boolean) => void
}

export const CollapsingSection: FunctionComponent<CollapsingSectionProps> = ({
  title,
  isBeta,
  shouldBeCollapsed = false,
  subtitle,
  isCollapsedSection,
  setIsCollapsedSection,
  children,
  ...props
}) => {
  const [isCollapsed, setIsCollapsed] = useState(shouldBeCollapsed)

  const collapsedSection = isBoolean(isCollapsedSection)
    ? isCollapsedSection
    : isCollapsed

  return (
    <div data-cy="collapsing-section" className={styles.container} {...props}>
      {/* a11y markup according to https://www.w3.org/WAI/ARIA/apg/patterns/accordion/ */}
      {/* Note: one day should move to `Accordion` component from DS, but would require design approval and would need to be made accessible */}
      <h5 className={styles.header}>
        <button
          data-cy="collapsing-header"
          aria-controls={`collapsing-section-${title}`}
          aria-expanded={!collapsedSection}
          onClick={() =>
            setIsCollapsedSection
              ? setIsCollapsedSection(!collapsedSection)
              : setIsCollapsed(!isCollapsed)
          }
        >
          <div className={styles.title}>
            {title}
            {isBeta && <BetaBadge />}
          </div>
          <div
            className={cs([
              styles.subtitleContainer,
              { [styles.subtitleContainerMaxWidth]: Boolean(subtitle) },
            ])}
          >
            {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
            <div className={styles.icon}>
              {collapsedSection ? (
                <IconChevronDownLarge />
              ) : (
                <IconChevronUpLarge />
              )}
            </div>
          </div>
        </button>
      </h5>
      {!collapsedSection && (
        <div id={`collapsing-section-${title}`}>{children}</div>
      )}
    </div>
  )
}

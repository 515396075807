import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { DurationDisplay } from './DurationDisplay'
import TimerStore from './duration-timer-store'

import ErrorBoundaryDisplay from '~/lib/error-boundary'

type TimerDisplayProps = {
  startTime: moment.MomentInput
}

@observer
class TimerDisplayBase extends Component<TimerDisplayProps> {
  timerStore: any = undefined

  constructor(props) {
    super(props)

    this.timerStore = new TimerStore(this.props.startTime)
  }

  componentDidMount() {
    this.timerStore.startTimer()
  }

  componentWillUnmount() {
    this.timerStore.resetTimer()
  }

  render() {
    const { startTime, ...rest } = this.props

    return (
      <DurationDisplay
        duration={this.timerStore.mainDisplay}
        formattingDisabled
        {...rest}
      />
    )
  }
}

// HOC to ensure error boundary is present to catch timer errors
const TimerDisplay = (props) => {
  return (
    <ErrorBoundaryDisplay size="small">
      <TimerDisplayBase {...props} />
    </ErrorBoundaryDisplay>
  )
}

export default TimerDisplay

import React, { FunctionComponent, useRef } from 'react'
import {
  FlexibleWidthXYPlot,
  HorizontalGridLines,
  LineSeries,
  LineMarkSeries,
  XAxis,
  YAxis,
  Crosshair,
} from 'react-vis'
import { first, last } from 'lodash'
import { palette } from '@frontend/design-system'
import { TestHistoryFragment } from '~/graphql-codegen-operations.gen'
import { useTimeSeriesAxis } from '~/lib/chart-utils'
import { TestTrendModifications } from './TestTrendModifications'
import { Point, YAxis as YAxisType, OnSetChartValue } from './types'
import { integrationBranchColor } from './utils'

type TestTrendChartProps = {
  commitBranchColor: string
  history: TestHistoryFragment['history']['nodes']
  onModificationClick(testResultId: string): void
  integrationBranchName?: string
  commitBranchName?: string
  commitBranchSeries: Point[]
  integrationBranchSeries: Point[]
  series: Point[]
  yAxis: YAxisType
  crosshairValue: Point | null
  setCrosshairValue: OnSetChartValue
  setFocusedIntegrationValue: OnSetChartValue
  setFocusedCommitValue: OnSetChartValue
}

export const TestTrendChart: FunctionComponent<TestTrendChartProps> = ({
  history,
  yAxis,
  integrationBranchName,
  commitBranchColor,
  commitBranchName,
  commitBranchSeries,
  integrationBranchSeries,
  series,
  onModificationClick,
  setCrosshairValue,
  setFocusedIntegrationValue,
  setFocusedCommitValue,
  crosshairValue,
}) => {
  const firstInSeries = first(series)
  const lastInSeries = last(series)
  const chartRef = useRef<FlexibleWidthXYPlot<any> | null>(null)
  const xAxis = useTimeSeriesAxis(
    series.map((d) => d.x),
    null,
    { chartRef, day: { formatSpecifier: '%-m/%-d' } }
  )

  const lineSeriesStyle = { strokeWidth: 3 }
  const commitBranchLineSeriesProps = {
    data: commitBranchSeries,
    color: commitBranchColor,
    style: lineSeriesStyle,
  }

  return (
    <>
      <FlexibleWidthXYPlot
        height={180}
        ref={chartRef}
        yDomain={yAxis.domain}
        className="test-trend-chart"
        onMouseLeave={() => {
          setCrosshairValue(null)
        }}
      >
        <HorizontalGridLines tickValues={yAxis.tickValues} />
        <XAxis
          height={5}
          tickTotal={xAxis.tickTotal}
          tickFormat={xAxis.tickFormat}
          tickValues={xAxis.tickValues}
        />
        <YAxis
          hideLine
          tickValues={yAxis.tickValues}
          tickFormat={yAxis.tickFormat}
        />

        {(() => {
          if (commitBranchSeries.length === 0) {
            return null
          }

          if (commitBranchSeries.length === 1) {
            return <LineMarkSeries {...commitBranchLineSeriesProps} />
          }

          return <LineSeries {...commitBranchLineSeriesProps} />
        })()}

        <LineSeries
          data={series}
          onNearestX={(value, { index }) => {
            /* istanbul ignore next */
            if (value.x === crosshairValue?.x) {
              return
            }

            const setOtherValue = (
              points: Point[],
              onSet: (point: Point) => void,
              branch?: string
            ) => {
              /* istanbul ignore next */
              if (points.length < 2) {
                return
              }

              const firstPoint = first(points)
              const lastPoint = last(points)

              /* istanbul ignore next */
              if (!firstPoint || !lastPoint || !firstInSeries) {
                return
              }

              if (value.x < firstPoint.x || value.x > lastPoint.x) {
                return
              }

              let leftPoint: Point | null = null
              let rightPoint: Point | null = null

              for (let i = index; i >= 0; --i) {
                if (series[i] && series[i].branch === branch) {
                  leftPoint = series[i]
                  break
                }
              }

              for (let j = index; j < series.length; ++j) {
                if (series[j] && series[j].branch === branch) {
                  rightPoint = series[j]
                  break
                }
              }

              /* istanbul ignore next */
              if (!leftPoint || !rightPoint) {
                return
              }

              const zeroPoint = firstInSeries.x

              const slope =
                (rightPoint.y - leftPoint.y) /
                (rightPoint.x - zeroPoint - (leftPoint.x - zeroPoint))

              const yIntercept = leftPoint.y - slope * (leftPoint.x - zeroPoint)

              const newY = Math.round(
                slope * (value.x - zeroPoint) + yIntercept
              )

              onSet({ branch, x: value.x, y: newY })
            }

            if (value.branch === commitBranchName) {
              setFocusedCommitValue(value)
              setOtherValue(
                integrationBranchSeries,
                setFocusedIntegrationValue,
                integrationBranchName
              )
            }

            if (value.branch === integrationBranchName) {
              setFocusedIntegrationValue(value)
              setOtherValue(
                commitBranchSeries,
                setFocusedCommitValue,
                commitBranchName
              )
            }

            setCrosshairValue(value)
          }}
          color="transparent"
        />

        {integrationBranchSeries.length > 0 && (
          <LineSeries
            data={integrationBranchSeries}
            style={lineSeriesStyle}
            color={integrationBranchColor}
          />
        )}

        {crosshairValue && (
          <Crosshair
            className="test-trend-chart__crosshair"
            style={{
              line: {
                background: 'transparent',
                border: `1px dashed ${palette.gray100}`,
              },
            }}
            values={[crosshairValue]}
          >
            {() => null}
          </Crosshair>
        )}
      </FlexibleWidthXYPlot>
      {firstInSeries?.x && lastInSeries?.x && (
        <TestTrendModifications
          history={history}
          onClick={onModificationClick}
          startDate={firstInSeries.x}
          endDate={lastInSeries.x}
        />
      )}
    </>
  )
}

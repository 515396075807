import { action, observable } from 'mobx'

class Store {
  @observable action
  @observable active
  @observable message
  @observable variant
  @observable multiline

  @action setNotification(message, variant = 'success') {
    this.clearNotification()
    this.id = Math.random()
    this.active = true
    this.message = message
    this.variant = variant
    this.multiline = false
  }

  @action clearNotification() {
    this.active = false
    this.id = null
    this.action = null
    this.message = null
    this.onClick = null
    this.variant = null
    this.multiline = false
  }
}

export default new Store()

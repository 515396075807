import React from 'react'
import { IconTechnologyDashboardFail } from '@cypress-design/react-icon'
import Button from '@cypress-design/react-button'
import { ProtocolCaptureErrorEnum } from '~/graphql-codegen-operations.gen'
import styles from './module.TestReplayModal.scss'

const messages = {
  POST_PROCESS:
    'There was a problem loading this Test Replay. Our team has been notified.',
  UPLOAD:
    'There was a problem uploading this Test Replay. Our team has been notified.',
  TOO_LARGE: 'This Test Replay is too large to process.',
}

const fallback =
  'There was a problem with this Test Replay. Our team has been notified.'

export const GracefulErrorView: React.FC<{
  hasPaidPlan?: boolean
  errorType?: ProtocolCaptureErrorEnum | null
}> = ({ hasPaidPlan, errorType }) => {
  return (
    <div
      className={styles.errorContentOverride}
      data-cy="test-replay-modal-error"
    >
      <div className={styles.errorContentWrapper}>
        <IconTechnologyDashboardFail
          strokeColor="gray-400"
          fillColor="gray-800"
        />
        <h2>Something went wrong</h2>
        <span data-cy="error-message-text">
          {messages[errorType ?? ''] ?? fallback} If the problem persists,
          please {hasPaidPlan ? 'contact support' : 'try again later'}.
        </span>
        <a
          data-cy="button-link"
          // Note: empty path induces reload of full URL as is:
          href={hasPaidPlan ? 'mailto:support@cypress.io' : ''}
        >
          <Button>{hasPaidPlan ? 'Contact support' : 'Refresh page'}</Button>
        </a>
      </div>
    </div>
  )
}

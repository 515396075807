import currentUser from '../auth/current-user-model'
import projectsStore from '../projects/projects-store'

function findRoleByOrgId(orgId?: string) {
  return currentUser.getOrgRole(orgId) || projectsStore.findRoleByOrgId(orgId)
}

export const roleIsOwnerOrAdmin = (orgId?: string) => {
  const role = findRoleByOrgId(orgId)

  return role === 'owner' || role === 'admin'
}

export const userIsMemberOfOrg = (orgId?: string) => {
  const role = findRoleByOrgId(orgId)

  return role === 'owner' || role === 'admin' || role === 'member'
}

export const getRoleDescription = (role, isDefaultOrg = false) => {
  switch (role) {
    case 'member':
      return 'Can see projects, runs, and keys for this organization.'
    case 'admin':
      return 'Can also manage users and billing for this organization.'
    case 'owner':
      return `Can also transfer or delete projects${
        isDefaultOrg ? '' : ' and delete this organization'
      }.`
    default:
      return ''
  }
}

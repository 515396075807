import React from 'react'
import Button from '@cypress-design/react-button'
import styles from './module.GroupList.scss'
import { Link } from '@reach/router'

type ViewTopFlakyTestsProps = {
  projectId: string
}

export const ViewTopFlakyTests: React.FunctionComponent<
  ViewTopFlakyTestsProps
> = ({ projectId }) => {
  return (
    <div className={styles.actionButtons}>
      <Link to={`/projects/${projectId}/analytics/flaky-tests`}>
        <Button>View top flaky tests</Button>
      </Link>
    </div>
  )
}

import React, { FunctionComponent, useState, useEffect } from 'react'
import { Button } from '@frontend/design-system'
import { useNavigate, useLocation } from '@reach/router'
import { parse } from 'query-string'
import { IconObjectPaperAirplane } from '@cypress-design/react-icon'
import cs from 'clsx'

import { sendEventModal } from '~/lib/page-events'
import { CustomPlanModal } from '../CustomPlanModal/CustomPlanModal'

interface CustomPlanCTAProps {
  title?: string
  orgId: string
  block?: boolean
  className?: string
  bsStyle?: string
  isCurrentCustomer?: boolean
  onModalToggle?(isToggled: boolean): void
}

export const CustomPlanCTA: FunctionComponent<CustomPlanCTAProps> = ({
  title,
  orgId,
  block,
  className,
  bsStyle,
  isCurrentCustomer,
  onModalToggle = () => {},
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const qs = parse(location.search)
  const shouldContactSales = qs.contactSales === 'true'
  const [isModalOpen, setModalOpen] = useState(shouldContactSales)

  useEffect(() => {
    if (shouldContactSales) {
      setModalOpen(true)
    }
  }, [shouldContactSales])

  return (
    <>
      <CustomPlanModal
        isCurrentCustomer={isCurrentCustomer}
        isOpen={isModalOpen}
        onClose={() => {
          setModalOpen(false)
          onModalToggle(false)
          if (shouldContactSales) {
            navigate(`/organizations/${orgId}/billing`)
          }
        }}
      />
      <Button
        data-pendo="custom-plan-contact-sales"
        block={block}
        data-cy="cy-custom-plan-cta-button"
        className={cs(className, !title && 'inline-flex gap-x-4 items-center')}
        bsStyle={bsStyle}
        onClick={() => {
          sendEventModal(`/organizations/${orgId}/billing/choose_plan/custom`)
          setModalOpen(true)
          onModalToggle(true)
        }}
      >
        {!title && <IconObjectPaperAirplane />}
        {title ?? 'Contact us'}
      </Button>
    </>
  )
}

import React from 'react'
import Button from '@cypress-design/react-button'
import styles from './module.GroupList.scss'
import { Link } from '@reach/router'

type EnableAutoCancellationProps = {
  projectId: string
  isCompleted: boolean
}

export const EnableAutoCancellation: React.FunctionComponent<
  EnableAutoCancellationProps
> = ({ projectId, isCompleted }) => {
  return (
    <div className={styles.actionButtons}>
      <Link to={`/projects/${projectId}/settings/general`}>
        <Button>{isCompleted ? 'Manage' : 'Enable'}</Button>
      </Link>
    </div>
  )
}

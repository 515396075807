import { HorizontalList, RunStats } from '@frontend/design-system'
import React from 'react'
import styles from './module.MainHeader.scss'
import {
  IconDocumentBlank,
  IconStatusCancelledOutline,
  IconStatusErroredOutline,
  IconStatusFailedOutline,
  IconStatusPassedOutline,
  IconStatusRunningOutline,
  IconTechnologyBranchH,
  IconTechnologyServer,
} from '@cypress-design/react-icon'
import {
  RunStatusEnum,
  TestReplayRunFragment,
} from '~/graphql-codegen-operations.gen'
import cs from 'clsx'
import { OSIcon } from '../osIcon/OSIcon'
import { LayeredLogoIcons } from '../layeredLogoIcons/LayeredLogoIcons'
// import { Link } from '@reach/router'
import Tooltip from '@cypress-design/react-tooltip'
import { CommitAuthor } from '~/run/commit/CommitAuthor'
import { durationFormattedFull } from '~/lib/utils'
import moment from 'moment'
import { useRowWidthLimit } from '~/lib/hooks/useRowWidthLimit'
import gql from 'graphql-tag'

function getStatusStyle(status: RunStatusEnum) {
  if (status === 'PASSED') return 'runStatusPassed'
  if (status === 'FAILED') return 'runStatusFailed'
  if (status === 'RUNNING') return 'runStatusRunning'
  if (status === 'CANCELLED') return 'runStatusCanceled'
  return 'runStatusOther'
}

const RunStatusIconOutline: React.FC<{
  status: RunStatusEnum
  darkTheme?: boolean
}> = ({ status, darkTheme }) => {
  if (status === 'PASSED') return <IconStatusPassedOutline size="16" />
  if (status === 'FAILED') return <IconStatusFailedOutline size="16" />
  if (status === 'RUNNING')
    return (
      <IconStatusRunningOutline
        className={cs({ [styles.darkTheme]: darkTheme })}
        size="16"
      />
    )
  if (status === 'CANCELLED') return <IconStatusCancelledOutline size="16" />
  // If status is none of the conditionals, then we
  // show the same error icon for all other states.
  return <IconStatusErroredOutline size="16" />
}

gql`
  fragment TestReplayRun on Run {
    id
    status
    buildNumber
    totalPassed
    totalFailed
    totalSkipped
    totalPending
    totalFlakyTests
    commit {
      authorAvatar
      authorName
      branch
      message
    }
    totalDuration
    runningDuration
    createdAt
    project {
      id
      organizationInfo {
        id
      }
    }
  }
`

export type MainHeaderSpec = {
  title: string
  os: string
  group: string
  browser: string
}

const RunTooltip: React.FC<{
  run: TestReplayRunFragment
}> = ({ run }) => {
  const { row1Ref, row2Ref, widthLimit } = useRowWidthLimit()

  return (
    <div
      data-cy="runInfoTooltip"
      className={cs(styles[`${cs(getStatusStyle(run.status))}`])}
    >
      <div className={cs(styles.tooltip)}>
        <div className={cs(styles.listItem, styles.basicDetails)} ref={row1Ref}>
          <div className={cs(styles.listItem, styles.runBuildNumber)}>
            <RunStatusIconOutline status={run.status} />
            <span>#{run.buildNumber}</span>
          </div>

          <RunStats
            passed={run.totalPassed}
            failed={run.totalFailed}
            skipped={run.totalSkipped}
            pending={run.totalPending}
            showTooltip={false}
            expanded
            className={cs(styles.runStats)}
          />
          {!!run.totalFlakyTests && (
            <div className={cs(styles.listItem, styles.flakeCountBadge)}>
              <div className={styles.flakeCount}>{run.totalFlakyTests}</div>
              <div className={styles.flakeLabel}>Flaky</div>
            </div>
          )}
        </div>

        {/* This row should only be as large as the 
        largest between row1Ref and row2Ref */}
        <div
          data-cy="commit-message"
          className={cs(styles.listItem, styles.commitMessage)}
          style={{ width: widthLimit }}
        >
          <span title={run.commit.message ?? undefined}>
            {run.commit.message}
          </span>
        </div>

        <div
          data-cy="commit-info"
          className={cs(styles.listItem, styles.commitAuthor)}
          ref={row2Ref}
        >
          <CommitAuthor
            shouldDisplayTooltip={false}
            authorAvatar={run.commit.authorAvatar}
            authorName={run.commit.authorName}
            withSpacing
          />
          <div className={cs(styles.dotDivider)}>·</div>
          <span className={cs(styles.elapsedTime)}>
            {durationFormattedFull(
              run.totalDuration ?? run.runningDuration ?? 0,
              2,
              true
            )}{' '}
            ({moment(run.createdAt).fromNow()})
          </span>
        </div>
      </div>
    </div>
  )
}

export const MainHeader = ({
  run,
  spec,
}: // onHide,
{
  run: TestReplayRunFragment
  spec: MainHeaderSpec
  // onHide: () => void // TODO: handle redirects in the future
}) => {
  const { group, os, browser } = spec
  const capOs = `${os?.[0]?.toUpperCase() ?? ''}${os?.slice?.(1) ?? ''}`

  return (
    <HorizontalList className={styles.container}>
      <div className={cs(styles[`${cs(getStatusStyle(run.status))}`])}>
        <Tooltip
          interactive
          popper={<RunTooltip run={run} />}
          // help prevent tooltip from leaving page:
          placement="bottom-end"
        >
          {/* <Link
            onClick={onHide} 
            className={cs(styles.listItem, styles.runInfo)}
            to={`/projects/${run.project.id}/runs/${run.buildNumber}/overview`}
          > */}

          {/* temp wrapper for demo; avoid link behavior */}
          <div className={cs(styles.listItem, styles.runInfo)}>
            <div
              className={cs(styles.runBuildNumber, styles.listItem)}
              data-cy="run-info-container"
            >
              <RunStatusIconOutline status={run.status} darkTheme={true} />
              <span className={cs(styles.textPadding)}>#{run.buildNumber}</span>
            </div>
            <div className={styles.divider} />
            {!!run.commit.branch && (
              <div
                data-cy="branch-name"
                className={cs(styles.listItem, styles.branchName)}
              >
                <IconTechnologyBranchH />
                <span>{run.commit.branch}</span>
              </div>
            )}
          </div>
          {/* </Link> */}
        </Tooltip>
      </div>
      <div
        data-cy="spec-title"
        className={cs(styles.listItem, styles.specName)}
      >
        <IconDocumentBlank fillColor="gray-900" strokeColor="gray-500" />
        <span className={styles.textPadding}>{spec.title}</span>
      </div>
      {!!group && (
        <div data-cy="spec-group" className={cs(styles.listItem)}>
          <IconTechnologyServer fillColor="gray-900" strokeColor="gray-500" />
          <span className={styles.textPadding}>{group}</span>
        </div>
      )}
      {os && (
        <div data-cy="spec-os" className={cs(styles.listItem, styles.os)}>
          <OSIcon os={capOs} />
          <span className={styles.textPadding}>{capOs}</span>
        </div>
      )}
      {browser && (
        <div
          data-cy="spec-browser"
          className={cs(styles.listItem, styles.browser)}
        >
          <LayeredLogoIcons browsers={[browser]} />
          <span className={styles.textPadding}>{browser}</span>
        </div>
      )}
    </HorizontalList>
  )
}

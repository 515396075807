import React, { FunctionComponent } from 'react'

import { ViewByEnum } from '~/graphql-codegen-operations.gen'
import { readableViewByEnum } from '~/lib/utils-ts'
import { ListFilter } from '../ListFilter'

type AnalyticsViewByFilterProps = {
  id: string
  onSelect: (value: ViewByEnum) => void
  value: ViewByEnum
  options?: ViewByEnum[]
}

const defaultOptions = ['TEST_CASE', 'SPEC', 'TAG', 'BRANCH'] as const

export const AnalyticsViewByFilter: FunctionComponent<AnalyticsViewByFilterProps> =
  React.memo(({ id, value, onSelect, options }) => {
    const displayOptions = (options || defaultOptions).map((status) => ({
      value: status,
      label: readableViewByEnum(status),
    }))

    return (
      <ListFilter
        id={id}
        title=""
        value={value}
        onSelect={onSelect}
        options={displayOptions}
      />
    )
  })

import React, { FunctionComponent } from 'react'

import { HubSpotModal } from '~/common/hub-spot-modal'
import { useCustomPlanInquiryQuery } from '~/graphql-codegen-operations.gen'
import { hubSpotFormIds } from '~/lib/constants'

const changeInput = ($form: JQuery, name: string, value: string) => {
  $form.find(`input[name="${name}"]`).val(value).change()
}

interface CustomPlanModalProps {
  onClose(): void
  isOpen: boolean
  isCurrentCustomer?: boolean
}

export const CustomPlanModal: FunctionComponent<CustomPlanModalProps> = ({
  onClose,
  isOpen,
  isCurrentCustomer,
}) => {
  const { data } = useCustomPlanInquiryQuery()
  const firstName = data?.me?.firstName ?? ''
  const lastName = data?.me?.lastName ?? ''
  const email = data?.me?.email ?? ''

  const onOpen = ($form: JQuery) => {
    if (firstName) {
      changeInput($form, 'firstname', firstName)
    }

    if (lastName) {
      changeInput($form, 'lastname', lastName)
    }

    if (email) {
      changeInput($form, 'email', email)
    }
  }

  if (!isOpen) {
    return null
  }

  return (
    <HubSpotModal
      title="Contact us"
      isLoading={!data}
      formId={
        isCurrentCustomer
          ? hubSpotFormIds.customPlanMemberContact
          : hubSpotFormIds.customPlanCandidateContact
      }
      onOpen={onOpen}
      onClose={onClose}
    />
  )
}

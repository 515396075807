import React, { FunctionComponent, useEffect, useState } from 'react'
import { CollapsingSection, Icon } from '@frontend/design-system'
import { useDrawerTestResultAnalyticMostCommonErrorsQuery } from '~/graphql-codegen-operations.gen'
import * as analytics from '~/data/projects/analytics/hooks'
import { getValuesFromMultiSelectOptions } from '~/common/filters'
import { MostCommonError } from './MostCommonError/MostCommonError'
import { MostCommonErrorsEmptyState } from '~/project-analytics/MostCommonErrorsEmptyState'
import styles from './module.DrillInMostCommonErrors.scss'
import { join, orderBy } from 'lodash'
import { CreateJiraIssuePrompt } from '~/jira-issue-prompt/CreateJiraIssuePrompt'
import { redirectURI } from '~/integrations/integrationUtils'

type DrillInMostCommonErrorsProps = {
  familyId: string
  projectId: string
  activeDrawerId: string | null
  specHash: string | null
  titleHash: string | null
  testTitleParts: string[]
  orgId?: string
  showModal: boolean
  updateShowModal: React.Dispatch<React.SetStateAction<boolean>>
  analyticsPath: 'top-failures' | 'flaky-tests'
}

export const DrillInMostCommonErrors: FunctionComponent<
  DrillInMostCommonErrorsProps
> = ({
  activeDrawerId,
  familyId,
  projectId,
  specHash,
  titleHash,
  testTitleParts,
  orgId,
  showModal,
  updateShowModal,
  analyticsPath,
}) => {
  const [jiraIssueDescription, setJiraIssueDescription] = useState<string>('')
  const { selected: branches } = analytics.useBranchesFilter(familyId)
  const { timeRange } = analytics.useTimeRangeFilter(familyId)
  const { selected: tags } = analytics.useTagsFilter(familyId)
  const { selected: runGroups } = analytics.useRunGroupsFilter(familyId)
  const { selected: browsers } = analytics.useBrowsersFilter(familyId)
  const { selected: committers } = analytics.useCommittersFilter(familyId)
  const { selected: cypressVersions } =
    analytics.useCypressVersionsFilter(familyId)
  const { selected: operatingSystems } =
    analytics.useOperatingSystemsFilter(familyId)

  const { data, loading } = useDrawerTestResultAnalyticMostCommonErrorsQuery({
    skip: !specHash || !titleHash,
    variables: {
      projectId,
      specHash: specHash!,
      titleHash: titleHash!,
      input: {
        timeRange: timeRange!,
        branches: getValuesFromMultiSelectOptions(branches || []),
        tags: getValuesFromMultiSelectOptions(tags || []),
        runGroups: getValuesFromMultiSelectOptions(runGroups || []),
        committers: getValuesFromMultiSelectOptions(committers || []),
        cypressVersions: getValuesFromMultiSelectOptions(cypressVersions || []),
        browsers: getValuesFromMultiSelectOptions(browsers || []),
        oses: getValuesFromMultiSelectOptions(operatingSystems || []),
      },
    },
  })

  const errorNodes = React.useMemo(
    () => data?.testResultAnalytic.mostCommonErrors.nodes || [],
    [data]
  )

  useEffect(() => {
    const uri = redirectURI({ isLocalhost: true })
    // TODO: PASS ALL THE FILTERS (?)
    const descriptionTitle = `[CYPRESS-ANALYTICS|${uri}/projects/${projectId}/analytics/${analyticsPath}/${activeDrawerId}]`
    let descriptionBody
    if (errorNodes.length) {
      const [mostCommonError] = orderBy(errorNodes, 'totalResults', 'desc')
      descriptionBody = `
h2. Most common error
${mostCommonError.error.name}
${mostCommonError.error.message}
      `
    }
    const description = `${descriptionTitle}

    ${descriptionBody ?? ''}
    `

    setJiraIssueDescription(description)
  }, [activeDrawerId, analyticsPath, errorNodes, projectId])

  return (
    <>
      <CollapsingSection
        title="Most common errors"
        data-cy="drill-in-most-common-errors"
        data-pendo="test-result-drawer--most-common-errors"
      >
        {(() => {
          if (loading) {
            return (
              <div className="test-results-drawer__loader">
                <Icon name="spinner" spin />
              </div>
            )
          }

          if (!errorNodes.length) {
            return (
              <div className={styles.empty}>
                <MostCommonErrorsEmptyState
                  shouldUpdateCypressVersion={
                    data?.project.shouldUpdateCypressVersion5 || false
                  }
                />
              </div>
            )
          }

          return errorNodes.map((error, i) => (
            <MostCommonError
              key={error.id}
              error={error}
              isLast={i === errorNodes.length - 1}
            />
          ))
        })()}
      </CollapsingSection>
      {showModal && orgId && specHash && titleHash && (
        <CreateJiraIssuePrompt
          summary={join(testTitleParts, ' > ')}
          orgId={orgId}
          showModal={showModal}
          updateShowModal={updateShowModal}
          specHash={specHash}
          titleHash={titleHash}
          description={jiraIssueDescription}
        />
      )}
    </>
  )
}

import React from 'react'
import { Nav } from '@frontend/design-system'
import cs from 'clsx'

export const StackedLayoutTabBar: React.FC<{
  className?: string
  'data-cy'?: string
}> = ({ className, children, ...rest }) => (
  <Nav
    bsStyle="tabs"
    data-cy={rest['data-cy']}
    className={cs('nav nav-tabs', className)}
  >
    {children}
  </Nav>
)

import React from 'react'
import { CIProviderEnum } from '@packages/types/src/allNexusTypes.gen'
import { providerGridData, ProviderData } from '../providerData'

import styles from './module.CIProviderGrid.scss'

const CIProviderGrid = (props: {
  dataPendoPrefix: string
  selectedProviderKey?: CIProviderEnum
  onSelectProvider: (selectedProvider: ProviderData) => void
}) => {
  return (
    <div>
      <div
        className={styles.ciProviderGrid}
        role="radiogroup"
        aria-labelledby="progressive-setup-step-heading"
      >
        {providerGridData.map((option) => {
          const classNames = [styles.providerItem]
          if (option.databaseKey === props.selectedProviderKey) {
            classNames.push(styles.selected)
          }

          return (
            <label className={classNames.join(' ')} key={option.databaseKey}>
              <div className={styles.icon}>{option.gridIcon}</div>
              <div className={styles.itemLabel}>{option.title}</div>
              <input
                type="radio"
                name="ci-provider-grid"
                value={option.databaseKey}
                onChange={() => props.onSelectProvider(option)}
                checked={props.selectedProviderKey === option.databaseKey}
                data-pendo={`${props.dataPendoPrefix}-ci-provider-grid-${option.value}`}
              />
            </label>
          )
        })}
      </div>
    </div>
  )
}

export default CIProviderGrid

import { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import { useMountedRef } from '@frontend/dashboard/src/lib/hooks/useMountedRef'
import type { NavigateFn } from '@reach/router'
import queryString from 'query-string'

/**
 * NOTE:
 *
 * This hook should only be invoked within
 * its corresponding React.Context provider.
 *
 * We don't have a routing library in TestReplay, so we use this change-aware hook
 * to keep track of changes in the url, in particular to the query params in the url.
 * Ideally we move to use a third party library for this in the future:
 */
type StringMap = Record<string, string>
type TestReplaySearchParams = {
  // test_result id (used only by some pages)
  replayTestId?: string
  // Attempt Number
  att?: number
  // Timestamp
  ts?: number
  // Pin id
  pc?: string
}

// an object that must contain all TestReplay params
// as undefined values in the object so we can cleanup:
export type CleanupSearchParams = {
  [K in keyof Required<TestReplaySearchParams>]: undefined
}

export type TestReplayParamKeys = keyof TestReplaySearchParams
export const REPLAY_PARAMS: TestReplayParamKeys[] = [
  'replayTestId',
  'att',
  'ts',
  'pc',
]

// cleanup all search params when
// the modal is closed (on un-mount):
const useCleanupSearchParams = (
  setSearchParams: (newParams: Partial<TestReplaySearchParams>) => void
) => {
  const setSearchRef = useRef(setSearchParams)
  useEffect(() => {
    setSearchRef.current = setSearchParams
  })
  useEffect(() => {
    return () => {
      // strict typescript check to ensure we
      // remove all test-replay search params:
      const cleanup: CleanupSearchParams = {
        replayTestId: undefined,
        att: undefined,
        ts: undefined,
        pc: undefined,
      }

      setTimeout(() => {
        // Bootstrap's Modal component is messing with our
        // cleanup when the modal is closed via the Esc key.
        // SetTimeout enforces cleanup as the last call in
        // the stack otherwise Bootstrap modal overwrites
        // these changes when it invokes its own onHide:
        setSearchRef.current(cleanup)
      })
    }
  }, [])
}

function areSameParams(
  newParams: Partial<TestReplaySearchParams>,
  currParams: queryString.ParsedQuery<string>
) {
  return Object.entries(newParams).every(
    ([newKey, newVal]) => currParams[newKey] === newVal?.toString()
  )
}

export const useSearchParams = (navigate: NavigateFn) => {
  const mountedRef = useMountedRef()
  const [currParams, setCurrParams] = useState(
    queryString.parse(window.location.search)
  )

  useEffect(() => {
    const handleSearchChange = () => {
      const params = queryString.parse(window.location.search)
      setCurrParams(params)
    }
    window.addEventListener('popstate', handleSearchChange)
    return () => {
      window.removeEventListener('popstate', handleSearchChange)
    }
  }, [])

  const setSearchParams = useCallback(
    (nextParams: Partial<TestReplaySearchParams>) => {
      // ensure you lookup params at the time of invocation;
      // using the ones from state might be stale due to sequencing:
      const prevParams = queryString.parse(window.location.search)

      if (areSameParams(nextParams, prevParams)) {
        return
      }
      const url = new URL(window.location.href)
      const totalParams = {
        ...prevParams,
        ...nextParams,
      } as StringMap

      // Hopefully handles any query params that are added
      // over time, unless we move to a 3rd party library.
      Object.keys(totalParams).forEach((key) => {
        const value = totalParams[key]
        url.searchParams.delete(key)
        if (Array.isArray(value)) {
          value.forEach((item) => {
            url.searchParams.append(key, item)
          })
        } else if (value !== null && value !== undefined) {
          url.searchParams.set(key, String(value))
        }
      })
      // prevent memory leak:
      if (mountedRef.current) {
        setCurrParams(queryString.parse(url.search))
      }
      // updates url without
      // refreshing browser:
      navigate(`${url.pathname}${url.search}`, {
        replace: true,
      })
    },
    [mountedRef, navigate]
  )

  const testReplayParams = useMemo<TestReplaySearchParams>(() => {
    return {
      replayTestId: currParams[
        'replayTestId'
      ] as TestReplaySearchParams['replayTestId'],
      att: currParams['att'] ? Number(currParams['att']) : undefined,
      ts: currParams['ts'] ? Number(currParams['ts']) : undefined,
      pc: currParams['pc'] as TestReplaySearchParams['pc'],
    }
  }, [currParams])

  useCleanupSearchParams(setSearchParams)

  return {
    testReplayParams,
    setSearchParams,
  }
}

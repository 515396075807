import React from 'react'
import Button from '@cypress-design/react-button'
import styles from './module.GroupList.scss'
import { CustomIcon } from '@frontend/design-system'
import { Link } from '@reach/router'

type MessagingPlatformProps = {
  orgId: string
  isCompleted: boolean
}

export const MessagingPlatform: React.FunctionComponent<
  MessagingPlatformProps
> = ({ orgId, isCompleted }) => {
  if (isCompleted) {
    return (
      <Link to={`/organizations/${orgId}/integrations`}>
        <Button>Manage integrations</Button>
      </Link>
    )
  }

  return (
    <div className={styles.actionButtons}>
      <Link to={`/organizations/${orgId}/integrations`}>
        <Button variant="outline-indigo" className={styles.outlineButton}>
          <CustomIcon
            name="brand-mark-slack-color"
            size="x48"
            height={16}
            width={16}
            className="mr-2"
          />
          Integrate Slack
        </Button>
      </Link>
      <Link to={`/organizations/${orgId}/integrations/teams`}>
        <Button variant="outline-indigo" className={styles.outlineButton}>
          <CustomIcon
            name="logo-integration-microsoft-teams"
            size="x24"
            height={16}
            width={16}
            className="mr-2"
          />
          Integrate MS Teams
        </Button>
      </Link>
    </div>
  )
}

import queryString from 'query-string'
import { history } from '~/lib/history'
import Cookie from 'js-cookie'
import _ from 'lodash'

type OperationName = 'orgCreated'

export const handleTestRunnerRequest = (search: string) => {
  const query = queryString.parse(search)

  if (!query) {
    return
  }

  const {
    port,
    utm_content,
    utm_campaign,
    utm_medium,
    utm_source,
    cypressVersion,
    machineId,
    ...rest
  } = query

  if (port) {
    sessionStorage.setItem('testRunnerNotificationPort', port as string)
  }

  if (utm_content || utm_campaign || utm_medium || utm_source) {
    sessionStorage.setItem(
      'testRunnerUtmParams',
      JSON.stringify({
        source: utm_source,
        medium: utm_medium,
        cohort: utm_content,
        campaign: utm_campaign,
        cypressVersion,
        machineId,
      })
    )
  }

  if (!_.isEmpty(rest)) {
    const qs = queryString.stringify(rest)

    history.navigate(`${window.location.pathname}?${qs}`, { replace: true })
  }
}

export const notifyTestRunner = async (operationName: OperationName) => {
  const testRunnerNotificationPort = sessionStorage.getItem(
    'testRunnerNotificationPort'
  )

  if (!testRunnerNotificationPort || !operationName) {
    return
  }

  try {
    await fetch(
      `http://127.0.0.1:${testRunnerNotificationPort}/cloud-notification?operationName=${operationName}`
    )
  } catch {}
}

export const maybeNotifyTestRunnerAuth = () => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  const trAuthRedirect = Cookie.get('tr-auth-redirect')

  if (trAuthRedirect) {
    const cookieOptions = { path: '/' }
    if (window.env !== 'development' && window.env !== 'test') {
      cookieOptions['domain'] = '.cypress.io'
    }

    Cookie.remove('tr-auth-redirect', cookieOptions)

    if (isSafari) {
      window.location.replace(trAuthRedirect)
    } else {
      fetch(trAuthRedirect, {
        mode: 'no-cors',
      }).catch((e) => console.error(e))
    }
  }
}

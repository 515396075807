import _ from 'lodash'
import { ProjectTopFailuresQuery } from '~/graphql-codegen-operations.gen'

export const TopFailuresBuckets = (
  topFailures: ProjectTopFailuresQuery['metrics']['projectTopFailingTestsOverTime']
) => {
  const buckets = {
    '0': 0,
    '1': 0,
    '2': 0,
    '3': 0,
    '4': 0,
    '5': 0,
    '6': 0,
    '7': 0,
    '8': 0,
    '9': 0,
    '10': 0,
    '11': 0,
    '12': 0,
    '13': 0,
    '14': 0,
    '15': 0,
    '16': 0,
    '17': 0,
    '18': 0,
    '19': 0,
  }

  const nodes = topFailures.nodes

  if (!nodes) {
    return buckets
  }

  for (let i = nodes.length; i--; ) {
    const node = nodes[i]
    if (!node || _.isNil(node.failureRate)) {
      continue
    } else if (node.failureRate <= 5) {
      buckets['0']++
    } else if (node.failureRate <= 10) {
      buckets['1']++
    } else if (node.failureRate <= 15) {
      buckets['2']++
    } else if (node.failureRate <= 20) {
      buckets['3']++
    } else if (node.failureRate <= 25) {
      buckets['4']++
    } else if (node.failureRate <= 30) {
      buckets['5']++
    } else if (node.failureRate <= 35) {
      buckets['6']++
    } else if (node.failureRate <= 40) {
      buckets['7']++
    } else if (node.failureRate <= 45) {
      buckets['8']++
    } else if (node.failureRate <= 50) {
      buckets['9']++
    } else if (node.failureRate <= 55) {
      buckets['10']++
    } else if (node.failureRate <= 60) {
      buckets['11']++
    } else if (node.failureRate <= 65) {
      buckets['12']++
    } else if (node.failureRate <= 70) {
      buckets['13']++
    } else if (node.failureRate <= 75) {
      buckets['14']++
    } else if (node.failureRate <= 80) {
      buckets['15']++
    } else if (node.failureRate <= 85) {
      buckets['16']++
    } else if (node.failureRate <= 90) {
      buckets['17']++
    } else if (node.failureRate <= 95) {
      buckets['18']++
    } else if (node.failureRate <= 100) {
      buckets['19']++
    }
  }
  return buckets
}

import { find } from 'lodash'
import moment from 'moment'
import { utcHours, utcDays, utcMonths, utcMinutes } from 'd3-time'
import { utcFormat } from 'd3-time-format'
import { useLayoutEffect, MutableRefObject, useState } from 'react'
import { RVTickFormat, FlexibleWidthXYPlot } from 'react-vis'
import {
  endOfHour,
  endOfMinute,
  differenceInCalendarDays,
  endOfDay,
  endOfMonth,
  max,
  min,
  differenceInHours,
} from 'date-fns'
import { durationFormatted } from '@packages/common'
import { TimeIntervalEnum } from '~/graphql-codegen-operations.gen'

// Creates nice boundaries and tick values for the provided durations. The
// `INTERVAL_BOUNDARIES` const defines the ideal interval sizes (in ms).
// This will attempt to find the best "nice" interval for the data and resize
// the upper boundary accordingly.
export const INTERVAL_BOUNDARIES = [
  100,
  200,
  500,
  1000,
  5000,
  10000,
  30000,
  60000,
  2500 * 60,
  5000 * 60,
  10000 * 60,
  15000 * 60,
  30000 * 60,
  60000 * 60,
]

export const formatTimeIntervalAxis = (
  msIntervals: number[],
  tickCount = 5
) => {
  const max = Math.max(...msIntervals)
  const exactInterval = max / tickCount

  // Find the smallest "nice" interval that fits the exact interval. If none of
  // the nice intervals fit, just use the exact value
  const niceInterval =
    find(INTERVAL_BOUNDARIES, (b) => exactInterval < b) || exactInterval
  const niceMax = niceInterval * tickCount
  const ticks: number[] = []

  for (let t = 0; t < niceMax; t += niceInterval) {
    ticks.push(t)
  }

  ticks.push(niceMax)

  return {
    tickFormat: (value: number) => {
      if (value === 0) return '0'
      return durationFormatted(value, { includeMs: true })
    },
    domain: [0, niceMax],
    tickValues: ticks,
    tickCount,
  }
}

export const isValidTick = (
  i: number,
  tickLength: number,
  tickTotal: number
) => {
  const step = Math.round(tickLength / tickTotal) || 1
  return i % step === 0
}

export const useTimeSeriesAxis = (
  series: Array<number | string>,
  timeInterval: TimeIntervalEnum | null,
  options: {
    chartRef: MutableRefObject<FlexibleWidthXYPlot<any> | null>
    labelWidth?: number
    day?: { formatSpecifier?: string }
  }
) => {
  const [tickTotal, updateTickTotal] = useState(30)
  const { chartRef, labelWidth } = options

  useLayoutEffect(() => {
    const updateWidth = () => {
      const width: number =
        // @ts-ignore
        chartRef?.current?.container?.getBoundingClientRect().width ?? 0
      updateTickTotal(width ? Math.round(width / (labelWidth || 60)) : 30)
    }

    updateWidth()

    window.addEventListener('resize', updateWidth)

    return () => {
      window.removeEventListener('resize', updateWidth)
    }
  }, [chartRef, labelWidth])

  const timestamps: Date[] = series.map((d) => moment.utc(d).toDate())
  const endTimestamps: Date[] = timeInterval
    ? series.map((d) => {
        return (
          moment
            .utc(d)
            // @ts-ignore
            .add(moment.duration(1, timeInterval).as('hours'), 'hours')
            // @ts-ignore
            .endOf(timeInterval)
            .toDate()
        )
      })
    : []

  const allTimestamps = timestamps.concat(endTimestamps)
  const minTime = min(allTimestamps)
  const maxTime = max(allTimestamps)
  const domain = [minTime, maxTime]
  const widthHours = Math.abs(differenceInHours(minTime, maxTime))
  const widthDays = Math.abs(differenceInCalendarDays(minTime, maxTime))
  let ticks: Date[] = []
  let formatSpecifier = '%b %d, %Y'
  const dayFormatSpecifier = options?.day?.formatSpecifier || '%b %d'

  if (!timeInterval && widthHours <= 1) {
    ticks = utcMinutes(domain[0], endOfMinute(domain[1]))
    formatSpecifier = '%-I:%M%p'
  } else if (!timeInterval && widthDays <= 1) {
    ticks = utcHours(domain[0], endOfHour(domain[1]))
    formatSpecifier = '%-I%p'
  } else if (timeInterval === 'DAY' || (!timeInterval && widthDays < 10)) {
    ticks = utcDays(domain[0], endOfDay(domain[1]))
    formatSpecifier = dayFormatSpecifier
  } else if (timeInterval === 'WEEK' || (!timeInterval && widthDays < 45)) {
    ticks = utcDays(domain[0], endOfDay(domain[1]), 7)
    formatSpecifier = dayFormatSpecifier
  } else if (timeInterval === 'MONTH' || (!timeInterval && widthDays < 365)) {
    ticks = utcMonths(domain[0], endOfMonth(domain[1]))
    formatSpecifier = '%b %Y'
  } else {
    ticks = utcMonths(domain[0], endOfMonth(domain[1]), 3) // quarters
    formatSpecifier = '%b %Y'
  }

  const tickFormat = (val, i: number, scale, tickTotal: number) => {
    const isValid = isValidTick(i, ticks.length, tickTotal)
    return !isValid ? '' : utcFormat(formatSpecifier)(val)
  }

  return {
    tickTotal,
    tickFormat: tickFormat as RVTickFormat,
    domain,
    tickValues: ticks,
  }
}

/**
 * Removes outliers from a list based using the interquartile range.
 * A convenient definition of an outlier is a point which falls more than 1.5 times the interquartile range
 * above the third quartile or below the first quartile.
 *
 * @param someArray Array to remove outliers
 * @returns New Array without outliers
 *
 * 1, 2, 3, 4, 5, 6, 7
 */
export const filterOutliers = <T extends { x: number; y: number }>(
  series: Array<T>
): T[] => {
  if (series.length < 4) return series
  const sortedSeries = series.slice().sort((a, b) => a.y - b.y)
  const medianIndex = sortedSeries.length / 2
  const q1Series = sortedSeries.slice(0, Math.floor(medianIndex))
  const q3Series = sortedSeries.slice(
    Math.ceil(medianIndex),
    sortedSeries.length
  )

  const getQuartileFromSeries = (series: T[]) => {
    const medianIndex = series.length / 2
    const isOdd = medianIndex % 2 !== 0
    // Average the two middle points
    if (!isOdd) {
      return (
        0.5 *
        (series[Math.floor(medianIndex)].y + series[Math.ceil(medianIndex)].y)
      )
    }
    return series[Math.floor(medianIndex)].y
  }
  const quartile1 = getQuartileFromSeries(q1Series)
  const quartile3 = getQuartileFromSeries(q3Series)

  const quartileRange: number = quartile3 - quartile1
  const maxValue = quartile3 + quartileRange * 1.5
  const minValue = quartile1 - quartileRange * 1.5
  return series.filter((point) => point.y >= minValue && point.y <= maxValue)
}

/**
 * Simple moving averages calculate the average of a range of values by the number of periods within that range.
 * @param someArray Array with data to build a simple moving average
 * @param window How many datapoints to look back to build the moving average
 * @returns New array of length - window with SMA values.
 */
export const simpleMovingAverage = <T extends { x: number; y: number }>(
  dataSeries: Array<T>,
  window = 5
): T[] => {
  if (!dataSeries || dataSeries.length < window) {
    return []
  }
  let index = window - 1
  const length = dataSeries.length + 1
  const simpleMovingAverages: Array<T> = []
  while (++index < length) {
    const windowSlice = dataSeries.slice(index - window, index)
    const sum = windowSlice.reduce((prev, curr) => prev + curr.y, 0)
    simpleMovingAverages.push({
      x: index,
      y: sum / window,
    } as T)
  }
  return simpleMovingAverages
}

import React from 'react'

import { SidebarMembershipInviteInfoFragment } from '~/graphql-codegen-operations.gen'

export const OrgNavPendingInvitationItem: React.FunctionComponent<{
  invitation: SidebarMembershipInviteInfoFragment
  onAccept: Function
  onReject: Function
}> = ({ invitation, onAccept, onReject }) => (
  <li className="notification-item">
    <div>
      <b>
        {invitation.invitingUser ? invitation.invitingUser.name : 'An Admin'}
      </b>{' '}
      invited you to join the organization <b>{invitation.organization.name}</b>
    </div>
    <div className="invitation-buttons">
      <button
        data-cy="pending-invitations-nav-accept"
        data-pendo="pending-invitations-nav-accept"
        className="btn btn-default"
        onClick={() => onAccept(invitation)}
      >
        Accept
      </button>
      <button
        data-cy="pending-invitations-nav-reject"
        data-pendo="pending-invitations-nav-reject"
        className="btn btn-light"
        onClick={() => onReject(invitation)}
      >
        Reject
      </button>
    </div>
  </li>
)

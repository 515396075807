import {
  Button,
  CustomIcon,
  TestResultStatusIcon,
  palette,
  TestResultModificationTypeBadge,
} from '@frontend/design-system'
import cs from 'clsx'
import { lowerCase } from 'lodash'
import React, { FunctionComponent, useState, useEffect } from 'react'

import { TestHistoryItemFragment } from '~/graphql-codegen-operations.gen'
import { CommitHorizontalList } from '~/common/CommitHorizontalList'
import { TestCodeDiff } from '~/test-history/TestCodeDiff'

type TestHistoryItemProps = {
  item: TestHistoryItemFragment
  hasNoHistoryOnCurrentBranch: boolean
  isFocused: boolean
  onTestResultExpand(): void
  isOnParentBranch: boolean
  isTopChildBranchCommit: boolean
  isBottomChildBranchCommit: boolean
  previousBranch: string | null
  projectId: string
}

type CommitGraphProps = {
  isOnParentBranch: boolean
  isTopChildBranchCommit: boolean
  isBottomChildBranchCommit: boolean
}

type NoHistoryOnCurrentBranchProps = {
  runCommitBranch?: string
}

const CommitGraph: FunctionComponent<CommitGraphProps> = ({
  isTopChildBranchCommit = false,
  isBottomChildBranchCommit = false,
  isOnParentBranch = false,
}) => {
  // NOTE: blue line is just a css border
  return (
    <>
      {!isOnParentBranch && !isBottomChildBranchCommit && (
        <svg className="commit-graph-child-bottom" viewBox="0 0 2 48">
          <line
            x1="1"
            y1="0"
            x2="1"
            y2="48"
            stroke={palette.purple600}
            strokeWidth="2"
          />
        </svg>
      )}
      {!isOnParentBranch && !isTopChildBranchCommit && (
        <svg className="commit-graph-child-top" viewBox="0 0 2 40">
          <line
            x1="1"
            y1="0"
            x2="1"
            y2="40"
            stroke={palette.purple600}
            strokeWidth="2"
          />
        </svg>
      )}
      {!isOnParentBranch && !isBottomChildBranchCommit && (
        <svg className="commit-graph-child-bottom" viewBox="0 0 2 48">
          <line
            x1="1"
            y1="0"
            x2="1"
            y2="48"
            stroke={palette.purple600}
            strokeWidth="2"
          />
        </svg>
      )}
      {isBottomChildBranchCommit && (
        <svg className="commit-graph-branch" viewBox="0 0 17 32">
          <path
            d="M0,31 L7.5,25.1961 C12.1675,22.2613 15,17.1345 15,11.6211 L15,0"
            fill="none"
            stroke={palette.purple600}
            strokeWidth="2"
          />
        </svg>
      )}
      <svg className="bullet" viewBox="0 0 6 6">
        <circle
          cx="3"
          cy="3"
          r="3"
          fill={isOnParentBranch ? palette.indigo600 : palette.purple600}
        />
      </svg>
    </>
  )
}

export const NoHistoryOnCurrentBranch: FunctionComponent<
  NoHistoryOnCurrentBranchProps
> = ({ runCommitBranch }) => {
  return (
    <div className="test-history-item on-child-branch">
      <div className="test-history__branch">[{runCommitBranch}]</div>
      <div className="test-history__modification-and-diff">
        <div className="test-history__modification">
          <div className="test-history__commit-graph">
            <svg className="commit-graph-branch" viewBox="0 0 17 32">
              <path
                d="M0,31 L7.5,25.1961 C12.1675,22.2613 15,17.1345 15,11.6211 L15,0"
                fill="none"
                stroke={palette.purple600}
                strokeWidth="2"
              />
            </svg>
          </div>
          <div className="empty-state-text">No changes on this branch</div>
        </div>
      </div>
    </div>
  )
}

export const TestHistoryItem: FunctionComponent<TestHistoryItemProps> = ({
  item,
  isFocused,
  isOnParentBranch,
  onTestResultExpand,
  isTopChildBranchCommit,
  isBottomChildBranchCommit,
  previousBranch,
  projectId,
}) => {
  const [isExpanded, setExpanded] = useState(false)
  const testState = item.testResult.state
  const testStatus = testState ? lowerCase(testState) : null

  useEffect(() => {
    if (!isFocused) {
      return
    }

    setExpanded(true)
  }, [isFocused])

  return (
    <div
      id={`test-history-item-${item.testResult.id}`}
      className={cs('test-history__item', {
        'on-parent-branch': isOnParentBranch,
        'on-child-branch': !isOnParentBranch,
        'bottom-child-commit': isBottomChildBranchCommit,
      })}
    >
      {item.run.commit.branch !== previousBranch && (
        <div className="test-history__branch">[{item.run.commit.branch}]</div>
      )}
      <div className="test-history__modification-and-diff">
        <div className="test-history__modification">
          <div className="test-history__commit-graph">
            <CommitGraph
              isOnParentBranch={isOnParentBranch}
              isTopChildBranchCommit={isTopChildBranchCommit}
              isBottomChildBranchCommit={isBottomChildBranchCommit}
            />
          </div>
          <div className="test-history__modification-details">
            <div className="test-history__info">
              <TestResultStatusIcon
                buildNumber={item.run.buildNumber}
                status={testStatus}
                hasTooltip
              />
              <div className="test-history__commit-summary">
                {item.run.commit.summary}
              </div>
              <TestResultModificationTypeBadge
                modificationType={item.modificationType}
              />
              {item.modifications.length > 0 ? (
                <Button
                  bsStyle="link"
                  onClick={() => {
                    setExpanded(!isExpanded)
                    onTestResultExpand()
                  }}
                  data-pendo="expand-test-history-diff"
                >
                  {isExpanded ? (
                    <CustomIcon
                      name="chevron-down"
                      alt="Hide diff"
                      className="chevron-icon expanded"
                    />
                  ) : (
                    <CustomIcon
                      name="chevron-down"
                      alt="See diff"
                      className="chevron-icon collapsed"
                    />
                  )}
                </Button>
              ) : (
                <div style={{ width: '16px' }} />
              )}
            </div>
            <CommitHorizontalList projectId={projectId} run={item.run} />
          </div>
        </div>
        <div>
          {item.modifications.map(({ diff }, idx) => {
            return (
              <div
                key={idx}
                className="test-history__diff"
                style={{ display: isExpanded ? 'block' : 'none' }}
              >
                <TestCodeDiff diff={diff} />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

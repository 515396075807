import { Avatar, Icon, OrgLogo, Tooltip } from '@frontend/design-system'
import { Link } from '@reach/router'
import cs from 'clsx'
import _ from 'lodash'
import React, { useEffect, useRef } from 'react'

import {
  SidebarMembershipInviteInfoFragment,
  SidebarOrgInfoFragment,
  SidebarUserDataFragment,
  useOrgsListAcceptMembershipInvitationMutation,
  useOrgsListRejectMembershipInvitationMutation,
  useSidebarOrgSwitcherNotificationsLazyQuery,
} from '~/graphql-codegen-operations.gen'
import { useKeyDown } from '~/lib/hooks/useKeyDown'
import { useLocation } from '~/lib/hooks/useLocation'
import { Pendo } from '~/lib/pendo'
import store from '~/lib/store'

import { OrgNavPendingInvitationItem } from './organization-pending-invitation'
import { OrganizationSwitcherSummary } from './organization-switcher-summary'
import PendingGithubInstallationItem from './pending-github-installation'
import { useSidebarService } from './sidebarService'
import { FullStory } from '~/lib/fullstory'

interface DataProps {
  userData: SidebarUserDataFragment['me']
  currentOrg: SidebarOrgInfoFragment | null
}

export interface SwitcherSummaryProps extends DataProps {
  inMenu?: boolean
  onClick: () => void
}

const OrganizationSwitcherMenu: React.FC<
  Omit<SwitcherSummaryProps, 'inMenu'>
> = ({ userData, currentOrg: currentOrganization, onClick }) => {
  const [state, dispatch] = useSidebarService()
  const { navigate } = useLocation()
  const visible = state.isSidebarMenuOpen
  const orgSwitcherComponent = useRef<HTMLDivElement | null>(null)
  const orgId = currentOrganization?.id
  const orgName = currentOrganization?.name

  const [membershipInvitationAccept] =
    useOrgsListAcceptMembershipInvitationMutation()
  const [membershipInvitationReject] =
    useOrgsListRejectMembershipInvitationMutation()
  const [fetchSidebarNotifications, sidebarNotificationsData] =
    useSidebarOrgSwitcherNotificationsLazyQuery()

  const stop = sidebarNotificationsData.stopPolling
  const start = sidebarNotificationsData.startPolling
  React.useEffect(() => {
    start?.(5000)
    return () => stop?.()
  }, [start, stop, orgId])

  useEffect(() => {
    if (!orgId || !orgName) {
      return
    }

    Pendo.identify({
      id: orgId,
      name: orgName,
    })

    FullStory.identify({
      id: orgId,
      name: orgName,
    })
  }, [orgId, orgName])

  function onAcceptInvitation(invitation: SidebarMembershipInviteInfoFragment) {
    return membershipInvitationAccept({
      variables: {
        invitationId: invitation.id,
      },
    }).then(({ data }) => {
      if (
        data &&
        data.membershipInvitationAccept.__typename === 'LegacyApiResponseError'
      ) {
        store.setNotification(
          data.membershipInvitationAccept.message,
          'failure'
        )
      } else if (
        data &&
        data.membershipInvitationAccept.__typename === 'User'
      ) {
        store.setNotification('Invitation Accepted')
        navigate(`/organizations/${invitation.organization.id}`)
      }
    })
  }

  function onRejectInvitation(invitation: SidebarMembershipInviteInfoFragment) {
    return membershipInvitationReject({
      variables: {
        invitationId: invitation.id,
      },
    }).then(() => store.setNotification('Invitation Rejected'))
  }

  const handleClick = React.useCallback(
    (e: MouseEvent) => {
      if (
        orgSwitcherComponent.current &&
        !orgSwitcherComponent.current.contains(e.target as any)
      ) {
        dispatch({ type: 'closeSidebarMenu' })
      }
    },
    [dispatch]
  )

  useKeyDown({
    Escape: () => visible && dispatch({ type: 'closeSidebarMenu' }),
  })

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [handleClick])

  const _showNewModal = () => {
    dispatch({ type: 'closeSidebarMenu' })
    navigate('/organizations/new')
  }

  const onManageProfile = () => {
    dispatch({ type: 'closeSidebarMenu' })
  }

  const pendingInstallations =
    sidebarNotificationsData?.data?.me?.pendingGithubAppInstall
  const membershipInvitations =
    sidebarNotificationsData?.data?.me?.membershipInvitations

  const invitationCount = userData?.membershipInvitations.totalCount ?? 0

  const hasPendingInstallations =
    userData &&
    userData.pendingGithubAppInstall &&
    userData.pendingGithubAppInstall.totalCount > 0

  // reload invitation data any time
  // the invitation count changes:
  useEffect(() => {
    if (invitationCount > 0) {
      fetchSidebarNotifications()
    }
  }, [fetchSidebarNotifications, invitationCount])

  if (visible && !sidebarNotificationsData.called) {
    fetchSidebarNotifications()
  }

  if (!visible && sidebarNotificationsData.stopPolling) {
    sidebarNotificationsData.stopPolling()
  }

  return (
    <div
      id="organization-switcher-menu"
      className={visible ? 'visible' : ''}
      ref={orgSwitcherComponent}
    >
      <OrganizationSwitcherSummary
        inMenu
        onClick={onClick}
        userData={userData}
        currentOrg={currentOrganization}
      />
      <div className="organization-switcher-menu--content">
        {currentOrganization &&
          currentOrganization.__typename === 'PublicOrganization' && (
            <section className="organization-switcher--section">
              <p className="organization-switcher--section-text">
                You're viewing a project that{' '}
                {currentOrganization.isPersonalOrganization ? (
                  <strong>{currentOrganization.name}</strong>
                ) : (
                  <span>
                    the <strong>{currentOrganization.name}</strong> organization
                  </span>
                )}{' '}
                has made public.
              </p>
            </section>
          )}

        {!userData ? (
          <section className="organization-switcher--section">
            <Link
              to="/login"
              className="nav-link organization-switcher--section-link"
            >
              Sign in to the Cypress Cloud
            </Link>
          </section>
        ) : (
          <>
            {hasPendingInstallations && (
              <section
                data-cy="pending-notifications-nav"
                data-pendo="pending-notifications-nav"
                className="organization-switcher--section"
              >
                <p
                  data-cy="pending-notifications-nav-header"
                  data-pendo="pending-notifications-nav-header"
                  className="organization-switcher--section-header"
                >
                  Pending GitHub Installations
                </p>
                <div className="pending-notifications-wrapper">
                  <div className="pending-notifications-indicator-column">
                    <i className="fa fa-circle indicator-circle" />
                  </div>

                  <div className="pending-notifications-nav-list-container">
                    <ul
                      className="notification-list"
                      data-cy="pending-installation-nav-list"
                      data-pendo="pending-installation-nav-list"
                    >
                      {pendingInstallations &&
                        pendingInstallations.nodes.map(
                          (pendingInstallation, i) => {
                            return (
                              <PendingGithubInstallationItem
                                key={i}
                                {...pendingInstallation}
                                organizations={userData.organizations}
                              />
                            )
                          }
                        )}
                    </ul>
                  </div>
                </div>
              </section>
            )}
            {invitationCount > 0 && (
              <section
                data-cy="pending-notifications-nav"
                data-pendo="pending-notifications-nav"
                className="organization-switcher--section"
              >
                <p
                  data-cy="pending-notifications-nav-header"
                  data-pendo="pending-notifications-nav-header"
                  className="organization-switcher--section-header"
                >
                  Pending Invitations
                </p>
                <div className="pending-notifications-wrapper">
                  <div className="pending-notifications-indicator-column">
                    <i className="fa fa-circle indicator-circle" />
                  </div>

                  <div className="pending-notifications-nav-list-container">
                    <ul
                      className="notification-list"
                      data-cy="pending-invitations-nav-list"
                      data-pendo="pending-invitations-nav-list"
                    >
                      {membershipInvitations &&
                        membershipInvitations.nodes.map((invitation) => (
                          <OrgNavPendingInvitationItem
                            key={invitation.id}
                            invitation={invitation}
                            onAccept={onAcceptInvitation}
                            onReject={onRejectInvitation}
                          />
                        ))}
                    </ul>
                  </div>
                </div>
              </section>
            )}

            <section
              className="organization-switcher--section"
              data-cy="choose-organization"
              data-pendo="choose-organization"
            >
              <h6
                id="organization-switcher-menu--org-header"
                className="organization-switcher--section-header"
              >
                Your organizations
              </h6>
              <ul
                className="nav-list"
                role="menu"
                aria-labelledby="organization-switcher-menu--org-header"
              >
                {_.orderBy(userData.organizations.nodes, 'name').map((org) => {
                  return (
                    <li
                      key={org.id}
                      role="none"
                      onClick={() => dispatch({ type: 'closeSidebarMenu' })}
                    >
                      <Link
                        className={cs('nav-link', 'media', {
                          active:
                            org.id ===
                            (currentOrganization && currentOrganization.id),
                        })}
                        role="menuitem"
                        to={`/organizations/${org.id}/projects`}
                      >
                        <OrgLogo org={org} logo={org.logo} />
                        <div className="title">
                          <div>{org.name}</div>
                          {org.isMyPersonalOrganization && (
                            <div className="subtitle">
                              For your personal projects
                            </div>
                          )}
                        </div>
                        {org.id ===
                          (currentOrganization && currentOrganization.id) && (
                          <Tooltip overlay="You are currently viewing this organization">
                            <Icon name="check-circle" className="blue" />
                          </Tooltip>
                        )}
                      </Link>
                    </li>
                  )
                })}
                <li role="none" onClick={_showNewModal}>
                  <a className="nav-link media" role="menuitem">
                    <img
                      alt=""
                      src="/img/new-organization-icon.svg"
                      width={32}
                      height={32}
                    />
                    <div className="title">Create new organization&hellip;</div>
                  </a>
                </li>
              </ul>
            </section>

            <section className="organization-switcher--section">
              <h6 className="organization-switcher--section-header">
                Your account
              </h6>
              <div className="media">
                <Avatar entity={userData} size="x32" />
                <div className="title">
                  Signed in as <strong>{userData.name}</strong>
                </div>
              </div>
              <ul className="nav-list" role="menu">
                <li>
                  <Link
                    className="nav-link"
                    to="/profile"
                    onClick={onManageProfile}
                  >
                    Manage profile
                  </Link>
                </li>

                <li>
                  <a className="nav-link" href="/logout">
                    Sign out
                  </a>
                </li>
              </ul>
            </section>
          </>
        )}

        <section className="organization-switcher--footer">
          <ul>
            <li>
              <a
                className="nav-link"
                href="https://on.cypress.io/terms-of-use"
                target="_blank"
                rel="noopener"
              >
                Terms of Use
              </a>
            </li>
            <li>
              <a
                className="nav-link"
                href="https://on.cypress.io/privacy-policy"
                target="_blank"
                rel="noopener"
              >
                Privacy Policy
              </a>
            </li>
          </ul>
        </section>
      </div>
    </div>
  )
}

export const OrganizationSwitcher = ({ userData, currentOrg }: DataProps) => {
  const [, dispatch] = useSidebarService()

  return (
    <div className="organization-switcher-container">
      <OrganizationSwitcherSummary
        userData={userData}
        currentOrg={currentOrg}
        onClick={() => dispatch({ type: 'openSidebarMenu' })}
      />
      <OrganizationSwitcherMenu
        userData={userData}
        currentOrg={currentOrg}
        onClick={() => dispatch({ type: 'closeSidebarMenu' })}
      />
    </div>
  )
}

import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'
const pageHeaderQuery = '.page-header--content'

export const useDrawerMargin = () => {
  const pageHeaderRef = useRef<HTMLDivElement | null>(
    useMemo(() => document.querySelector(pageHeaderQuery), [])
  )

  const [marginTop, setMarginTop] = useState(
    pageHeaderRef.current
      ? pageHeaderRef.current.getBoundingClientRect().bottom
      : 0
  )

  const update = useCallback(() => {
    const pageHeaderBottom =
      pageHeaderRef.current?.getBoundingClientRect().bottom || 0
    if (marginTop !== pageHeaderBottom) {
      setMarginTop(pageHeaderBottom)
    }
  }, [marginTop])

  useLayoutEffect(() => {
    if (!pageHeaderRef.current) {
      pageHeaderRef.current = document.querySelector(pageHeaderQuery)
    }

    update()

    return () => {
      pageHeaderRef.current = null
    }
  })

  return {
    marginTop,
  }
}

import pluralize from 'pluralize'
import { ProjectPastBuildRunAnalyticsQuery } from '~/graphql-codegen-operations.gen'
import { durationFormattedFull } from '~/lib/utils'
import { mean } from 'lodash'

export const getKPIs = (
  queryData: ProjectPastBuildRunAnalyticsQuery | undefined,
  queryRunning: boolean
) => {
  let loading = queryRunning
  if (queryData) {
    const pastRuns = queryData?.project?.runs?.nodes
    loading = queryRunning && !pastRuns

    return [
      {
        name: 'Average run duration',
        value: durationFormattedFull(
          pastRuns.reduce((agg, e) => agg + (e.totalDuration || 0), 0) /
            pastRuns.length,
          2,
          true
        ),
        loading,
      },
      {
        name: 'Typical concurrency',
        value: Math.round(mean(pastRuns.map((e) => e.machineCount || 0))),
        subtitle: pluralize(
          'machine',
          Math.round(mean(pastRuns.map((e) => e.machineCount || 0)))
        ),
        loading,
        tooltipInfo:
          'The median number of machines that were used for parallelizing for these runs',
      },
      {
        name: 'Time saved from parallelization',
        value: durationFormattedFull(
          pastRuns.reduce(
            (agg, e) => agg + (e.totalSavedByParallelization || 0),
            0
          ),
          2,
          true
        ),
        loading,
      },
    ]
  }
  return [
    {
      name: 'Average run duration',
      loading,
    },
    {
      name: 'Typical concurrency ℹ',
      loading,
      tooltipInfo:
        'The median number of machines that were used for parallelizing for these runs',
    },
    {
      name: 'Time saved from parallelization',
      loading,
    },
  ]
}

import { CustomIcon, StatusIcon } from '@frontend/design-system'
import React, { FunctionComponent } from 'react'

import { readableRunStatusEnum } from '~/lib/utils-ts'

import { MultiSelectFilter } from '../MultiSelectFilter'
import { MultiSelectOptionType, onMultiSelectFilterChange } from '../types'

type RunStatusFilterProps = {
  id: string
  onChange: onMultiSelectFilterChange
  selected: MultiSelectOptionType[]
}

const renderLabel = (option: MultiSelectOptionType) => {
  const label = (
    <span>
      <StatusIcon runStatusEnum={option.value} />
      {option.label}
    </span>
  ) as unknown

  return label as string
}

const options = (
  [
    'PASSED',
    'FAILED',
    'RUNNING',
    'ERRORED',
    'NOTESTS',
    'TIMEDOUT',
    'OVERLIMIT',
    'CANCELLED',
  ] as const
).map((status) => ({
  value: status,
  label: readableRunStatusEnum(status),
}))

export const RunStatusFilter: FunctionComponent<RunStatusFilterProps> =
  React.memo(({ id, selected, onChange }) => {
    return (
      <div
        data-cy="run-status-filter"
        data-pendo="run-status-filter"
        className="run-status-filter"
      >
        <MultiSelectFilter
          id={id}
          title={<CustomIcon name="status" alt="Status" />}
          emptyLabel="Status"
          selected={selected}
          isSearchable={false}
          onChange={onChange}
          getOptionLabel={renderLabel}
          options={options}
        />
      </div>
    )
  })

import { Tooltip } from '@frontend/design-system'
import { browserNameFormatted, browserVersionFormatted } from '@packages/common'
import React, { FunctionComponent } from 'react'
import { BrowserIcon } from '~/common/icon'

type SpecBrowserProps = {
  browserName?: string
  browserVersion?: string | null
  isTooltip?: boolean
}

export const SpecBrowser: FunctionComponent<SpecBrowserProps> = ({
  browserName,
  browserVersion,
  isTooltip = true,
}) => {
  /* istanbul ignore next */
  if (!browserName) {
    return null
  }

  const formattedBrowserName = browserNameFormatted(browserName)
  const formattedBrowserVersion = browserVersionFormatted(browserVersion)

  const style = {
    display: 'inline-flex',
    alignItems: 'center',
    gap: '4px',
  }

  if (!isTooltip) {
    return (
      <span style={style}>
        <BrowserIcon name={browserName} alt="" />
        {`${formattedBrowserName} ${formattedBrowserVersion}`}
      </span>
    )
  }

  return (
    <span style={style}>
      <Tooltip placement="top" overlay={<span>{formattedBrowserName}</span>}>
        <BrowserIcon name={browserName} alt="" />
      </Tooltip>
      {formattedBrowserVersion}
    </span>
  )
}

import React from 'react'

export const useScrollToReference = () => {
  const scrolledRef = React.useRef<any>(null)
  const executeScroll = React.useCallback(() => {
    scrolledRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [scrolledRef])
  return [scrolledRef, executeScroll] as [
    React.MutableRefObject<any>,
    VoidFunction
  ]
}

import React, { FunctionComponent } from 'react'
import * as analytics from '~/data/projects/analytics/hooks'
import { RouteComponentProps } from '@reach/router'
import { staticUTCDates } from '~/lib/utils'
import { timeRangeAsInput } from '~/common/filters'

type AnalyticsGroupTwoContainerProps = {
  projectId: any
  familyId: string
  orgId: string
  component: any
} & RouteComponentProps

export const AnalyticsGroupTwoContainer: FunctionComponent<
  AnalyticsGroupTwoContainerProps
> = ({ projectId, familyId, orgId, component: Component }) => {
  const defaultFilters = {
    timeRange: timeRangeAsInput({
      startDate: staticUTCDates.getStartOf7DaysAgo(),
      endDate: staticUTCDates.getEndOfToday(),
    }),
  }
  // Init URL data importing, and Data Exporting to URL
  analytics.useSyncRouterWithFilter(familyId)
  analytics.useSyncFiltersWithRouter(familyId)

  // Filter Search Setup
  analytics.useBranchesSearch(projectId, familyId)
  analytics.useTagsSearch(projectId, familyId)
  analytics.useTagsEditor(projectId, familyId)
  analytics.useRunGroupsSearch(projectId, familyId)
  analytics.useCommittersSearch(projectId, familyId)

  analytics.useProjectAnalyticsFilterOptionsInitialization({
    projectId,
    familyId,
  })
  const { filterDefaultValuesInitialized, filtersInitialized } =
    analytics.useProjectAnalyticsFilterDefaultsInitialization({
      projectId,
      familyId,
      overrides: defaultFilters,
    })

  return filterDefaultValuesInitialized && filtersInitialized ? (
    <Component projectId={projectId} familyId={familyId} orgId={orgId} />
  ) : null
}

import { isNil, kebabCase } from 'lodash'
import { observer } from 'mobx-react'
import React, { Component, isValidElement } from 'react'
import { Badge } from '@frontend/design-system'
import { StackedLayoutTab } from '~/common/stacked-layout/stacked-layout-tabs/StackedLayoutTab'

@observer
class RunTab extends Component {
  _isActiveRun = () => {
    const { location } = this.props

    // The run url when active should match either:
    // - "/projects/:projectId/runs/:runId/failures"
    // - "/projects/:projectId/runs/:runBuildNumber/failures"
    const urlRe = new RegExp(
      `\/projects\/${this.props.projectId}\/runs\/(${this.props.runIds.join(
        '|'
      )})\/${this.props.path}`
    )

    return location.pathname.match(urlRe) ? 'active' : ''
  }

  render() {
    const { url, runIds, title, badge } = this.props

    return (
      <StackedLayoutTab
        url={url}
        active={runIds && this._isActiveRun()}
        data-cy={`run-tab-${kebabCase(title)}`}
      >
        <>
          {' '}
          {title}
          {!isNil(badge) && isValidElement(badge) ? (
            <span data-cy="run-tab-icon" className="run-tab-icon">
              {badge}
            </span>
          ) : (
            <Badge className="run-tab-badge run-tab-badge-gray">{badge}</Badge>
          )}
        </>
      </StackedLayoutTab>
    )
  }
}

export { RunTab }

import React, { FunctionComponent } from 'react'
import { Icon, CollapsingSection } from '@frontend/design-system'
import * as analytics from '~/data/projects/analytics/hooks'
import {
  LatestFlakinessFragment,
  useDrawerTestResultAnalyticLatestFlakinessQuery,
  TestResultScreenshotFragment,
} from '~/graphql-codegen-operations.gen'
import { getValuesFromMultiSelectOptions } from '~/common/filters'
import { LatestTests } from '~/project-analytics/drill-in/LatestTests/LatestTests'

type useDrillInLatestFlakinessProps = {
  specHash: string | null
  titleHash: string | null
  familyId: string
  onScreenshotClick: (screenshots: TestResultScreenshotFragment[]) => void
  onVideoClick: (video: LatestFlakinessFragment) => void
  onReplayClick?: (capture: LatestFlakinessFragment) => void
  projectId: string
}

export const DrillInLatestFlakiness: FunctionComponent<
  useDrillInLatestFlakinessProps
> = ({
  specHash,
  titleHash,
  familyId,
  onScreenshotClick,
  onVideoClick,
  onReplayClick,
  projectId,
}) => {
  const { selected: branches } = analytics.useBranchesFilter(familyId)
  const { timeRange } = analytics.useTimeRangeFilter(familyId)

  const { data, loading } = useDrawerTestResultAnalyticLatestFlakinessQuery({
    skip: !specHash || !titleHash || !timeRange,
    variables: {
      projectId,
      specHash: specHash!,
      titleHash: titleHash!,
      input: {
        branches: getValuesFromMultiSelectOptions(branches || []),
        timeRange: timeRange!,
      },
    },
  })

  return (
    <CollapsingSection
      title="Latest flaky runs"
      data-cy="drill-in-latest-flakiness"
      data-pendo="test-result-drawer--latest-flakiness"
    >
      {(() => {
        if (loading) {
          return (
            <div className="test-results-drawer__loader">
              <Icon name="spinner" spin />
            </div>
          )
        }

        return (
          <LatestTests
            latestTests={data?.testResultAnalytic?.latestFlakiness || []}
            onScreenshotClick={onScreenshotClick}
            onVideoClick={onVideoClick}
            onReplayClick={onReplayClick}
            projectId={projectId}
            linkTo="flakiness"
          />
        )
      })()}
    </CollapsingSection>
  )
}

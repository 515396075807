import {
  ContentLoader,
  Icon,
  Panel,
  TextEmptyState,
  Tooltip,
  palette,
} from '@frontend/design-system'
import { kebabCase } from 'lodash'
import cs from 'clsx'
import React, { FunctionComponent } from 'react'
import { LoadingContainer } from '~/project-analytics/LoadingContainer'

import {
  MetricDeltaChange,
  MetricDeltaChangeDirectionEnum,
} from '~/graphql-codegen-operations.gen'

type KpiType = {
  name?: string
  value?: string | number
  subtitle?: string
  deltaChange?: MetricDeltaChange
  loading?: boolean
  errored?: boolean
  tooltipInfo?: string
  className?: string
}

type KpiProps = {
  kpi: KpiType
  isLoading?: boolean
}

type KpisProps = {
  kpis: Array<KpiType>
  isLoading: boolean
  'data-cy'?: string
}

type KpiDirectionIconProps = {
  color: string
  direction: MetricDeltaChangeDirectionEnum
}

const KpiDirectionIcon: FunctionComponent<KpiDirectionIconProps> = ({
  color,
  direction,
}) => {
  const rotation =
    direction === 'UNCHANGED' ? 90 : direction === 'NEGATIVE' ? 180 : 0

  return (
    <svg
      width="10"
      height="17"
      viewBox="0 0 11 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform={`rotate(${rotation})`}
    >
      {(direction === 'POSITIVE' || direction === 'NEGATIVE') && (
        <path
          d="M9.5 7.5L5.5 3.5L1.5 7.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
      <path
        d="M5.5 16.5L5.5 3.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}

const KpiContent: FunctionComponent<KpiProps> = ({ kpi }) => {
  if (kpi.value === 0 || kpi.value === '0') {
    return (
      <>
        <span>0</span>
        {kpi.subtitle && <span className="kpi--subtitle">{kpi.subtitle}</span>}
      </>
    )
  }
  if (!kpi.loading && !kpi.errored && !kpi.value) {
    return <TextEmptyState />
  }
  return (
    <>
      <span>{kpi.value}</span>
      <span className="kpi--subtitle">{kpi.subtitle}</span>
    </>
  )
}

export const Kpi: FunctionComponent<KpiProps> = ({
  kpi,
  isLoading = false,
}) => {
  const color = kpi.deltaChange
    ? kpi.deltaChange.direction === 'UNCHANGED'
      ? palette.gray600
      : kpi.deltaChange.direction === 'NEGATIVE'
      ? palette.red600
      : palette.jade400
    : palette.gray600

  return (
    <LoadingContainer active={isLoading}>
      <Panel className={cs('kpi', kpi.className)}>
        <div data-cy={`kpi-${kebabCase(kpi.name)}`}>
          <div className="kpi--name">
            {kpi.errored && <Icon className="danger" name="warning" />}
            {kpi.tooltipInfo ? (
              <Tooltip overlay={kpi.tooltipInfo} placement="top">
                <span>
                  {kpi.name} <Icon name="info" />
                </span>
              </Tooltip>
            ) : (
              <span>{kpi.name}</span>
            )}
          </div>
          <div className="kpi--value h2">
            {kpi.errored && <span>&mdash;</span>}
            {kpi.loading && !kpi.value && (
              <ContentLoader className="loader" height={40} width={80}>
                <rect rx="20" ry="20" width={80} height={40} />
              </ContentLoader>
            )}
            <KpiContent kpi={kpi} />
          </div>
          {kpi.hasOwnProperty('deltaChange') && kpi.loading && (
            <div className="kpi--change">
              <ContentLoader className="loader" height={32} width={64}>
                <rect rx="16" ry="16" width={64} height={32} />
              </ContentLoader>
            </div>
          )}
          {kpi.deltaChange && (
            <Tooltip
              overlay="As compared to the previous period"
              placement="bottom"
            >
              <div className="kpi--change">
                <div className="kpi--change-direction">
                  {kpi.deltaChange.direction && (
                    <KpiDirectionIcon
                      color={color}
                      direction={kpi.deltaChange.direction}
                    />
                  )}
                </div>
                {kpi.deltaChange.direction !== 'UNCHANGED' && (
                  <div className="kpi--change-percentage" style={{ color }}>
                    {Math.abs(Math.round(kpi.deltaChange.percentage))}%
                  </div>
                )}
              </div>
            </Tooltip>
          )}
        </div>
      </Panel>
    </LoadingContainer>
  )
}

export const Kpis: FunctionComponent<KpisProps> = ({
  kpis,
  isLoading,
  'data-cy': dataCy,
}) => {
  return (
    <div data-cy={dataCy} className="kpis">
      {kpis.map((kpi, i) => {
        return <Kpi kpi={kpi} key={i} isLoading={isLoading} />
      })}
    </div>
  )
}

import _ from 'lodash'
import { observable } from 'mobx'

class Projects {
  @observable projects = []

  findRoleByOrgId(id) {
    let project = _.find(this.projects, ['orgId', id])

    if (project) return project.role
  }
}

export default new Projects()

import React, { FunctionComponent, useState } from 'react'
import { Icon, CollapsingSection } from '@frontend/design-system'
import * as analytics from '~/data/projects/analytics/hooks'
import { TestHistory } from '~/test-history/TestHistory'
import {
  FlakyRateEmptyStateProjectFragment,
  CommitBranchFragment,
  useDrawerTestResultAnalyticHistoryQuery,
} from '~/graphql-codegen-operations.gen'
import { DrillInFailureRate } from '../FailureRate/DrillInFailureRate'
import { DrillInFlakiness } from '../Flakiness/DrillInFlakiness'
import { NeedSingleBranchEmptyState } from '../NeedSingleBranchEmptyState'

type DrillInHistoryProps = {
  familyId: string
  projectId: string
  specHash: string | null
  titleHash: string | null
  project?: FlakyRateEmptyStateProjectFragment
  commitDetails?: CommitBranchFragment
}

export const DrillInHistory: FunctionComponent<DrillInHistoryProps> = ({
  specHash,
  titleHash,
  projectId,
  project,
  familyId,
  commitDetails,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true)

  const [focusedTestResultId, setfocusedTestResultId] = useState<string | null>(
    null
  )

  const { selected: branches } = analytics.useBranchesFilter(familyId)
  const selectedBranch = branches?.[0]?.value
  const isSingleBranchSelected = branches?.length === 1

  const { data, loading } = useDrawerTestResultAnalyticHistoryQuery({
    skip: !specHash || !titleHash || !isSingleBranchSelected || isCollapsed,
    variables: {
      projectId,
      specHash: specHash!,
      titleHash: titleHash!,
      input: { commitBranch: selectedBranch },
    },
  })

  const isHistoryDisabled = selectedBranch === ''

  return (
    <div data-cy="drill-in-history">
      <CollapsingSection
        title="Test code history"
        isCollapsedSection={isCollapsed}
        setIsCollapsedSection={setIsCollapsed}
        data-pendo="test-result-drawer--history"
      >
        {(() => {
          if (loading) {
            return (
              <div className="test-results-drawer__loader">
                <Icon name="spinner" spin />
              </div>
            )
          }

          if (!isSingleBranchSelected) {
            return <NeedSingleBranchEmptyState />
          }

          return (
            <TestHistory
              hasDuplicateTest={
                data?.testResultAnalytic?.hasDuplicateTest || false
              }
              focusedTestResultId={focusedTestResultId}
              onTestResultExpand={() => setfocusedTestResultId(null)}
              isEnabled={!isHistoryDisabled}
              history={data?.testResultAnalytic?.history || []}
              projectId={projectId}
              runCommitBranch={commitDetails?.branch || undefined}
            />
          )
        })()}
      </CollapsingSection>

      <DrillInFailureRate
        familyId={familyId}
        projectId={projectId}
        specHash={specHash}
        titleHash={titleHash}
        onModificationClick={setfocusedTestResultId}
        isHistoryEnabled={!isHistoryDisabled}
        history={data?.testResultAnalytic?.history || []}
        isSingleBranchSelected={isSingleBranchSelected}
      />

      <DrillInFlakiness
        familyId={familyId}
        projectId={projectId}
        project={project}
        onModificationClick={setfocusedTestResultId}
        isHistoryEnabled={!isHistoryDisabled}
        history={data?.testResultAnalytic?.history || []}
        specHash={specHash}
        titleHash={titleHash}
        isSingleBranchSelected={isSingleBranchSelected}
      />
    </div>
  )
}

import { ProjectMostCommonErrorsQuery } from '~/graphql-codegen-operations.gen'
import { getTotalFailures } from './utils'

export const getKPIs = (
  projectMostCommonErrors: ProjectMostCommonErrorsQuery | undefined,
  loading: boolean
) => {
  if (!projectMostCommonErrors) {
    return [
      {
        name: 'Number of failures',
        loading,
      },
      {
        name: 'Number of errors',
        loading,
      },
    ]
  }

  const metrics = projectMostCommonErrors.metrics.projectCommonErrors
  const numberOfErrors = metrics.nodes.length
  const numberOfFailures = getTotalFailures(
    projectMostCommonErrors.metrics.projectCommonErrors.nodes
  )

  return [
    {
      name: 'Number of failures',
      value: numberOfFailures,
      subtitle: 'test cases',
      loading,
    },
    {
      name: 'Number of errors',
      value: numberOfErrors,
      loading,
    },
  ]
}

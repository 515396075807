import React from 'react'
import Button from '@cypress-design/react-button'
import styles from './module.GroupList.scss'
import { SkipButton } from './SkipButton'
import { Link } from '@reach/router'

type EnableGitProviderProps = {
  orgId: string
  field: string
  isCompleted: boolean
  isStepChecked: boolean
}

export const EnableGitProvider: React.FunctionComponent<
  EnableGitProviderProps
> = ({ orgId, field, isCompleted, isStepChecked }) => {
  return (
    <div className={styles.actionButtons}>
      <Link to={`/organizations/${orgId}/integrations`}>
        <Button>
          {isCompleted
            ? 'Manage integrations'
            : 'Enable git provider integrations'}
        </Button>
      </Link>
      <SkipButton orgId={orgId} field={field} isStepChecked={isStepChecked} />
    </div>
  )
}

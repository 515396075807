import { ContentLoader, palette } from '@frontend/design-system'
import React from 'react'

export const SidebarNavLoader = () => (
  <ContentLoader
    primaryColor={palette.gray700}
    secondaryColor={palette.gray500}
  >
    <circle cx={36} cy={24} r={12} />
    <rect x="64" y="16" width="192" height="16" rx="8" ry="8" />
    <circle cx={36} cy={64} r={12} />
    <rect x="64" y="56" width="192" height="16" rx="8" ry="8" />
    <circle cx={36} cy={104} r={12} />
    <rect x="64" y="96" width="192" height="16" rx="8" ry="8" />
  </ContentLoader>
)

import { EmptyState, Panel, TextEmptyState } from '@frontend/design-system'
import cs from 'clsx'
import commaNumber from 'comma-number'
import { format, parseISO } from 'date-fns'
import _ from 'lodash'
import pluralize from 'pluralize'
import React, { FunctionComponent } from 'react'
import { LoadingContainer } from '~/project-analytics/LoadingContainer'

import { ProjectTestSuiteSizeOverTimeQuery } from '~/graphql-codegen-operations.gen'
import { AnalyticRowLink } from '../AnalyticRowLink/AnalyticRowLink'
import { AnalyticsProgressBar } from '../AnalyticsProgressBar'
import styles from '../module.AnalyticsTable.scss'

type TestSuiteSizeTableProps = {
  data?: ProjectTestSuiteSizeOverTimeQuery
  isLoading: boolean
}

export const TestSuiteSizeTable: FunctionComponent<TestSuiteSizeTableProps> = ({
  data,
  isLoading,
}) => {
  const nodes = data?.metrics.projectTestSuiteSizeOverTime.nodes || []
  const metrics = React.useMemo(() => _.reverse(_.cloneDeep(nodes)), [nodes])

  if (!data || !data.metrics) {
    return (
      <Panel>
        <EmptyState>
          <span>Loading...</span>
        </EmptyState>
      </Panel>
    )
  }

  return (
    <LoadingContainer active={isLoading}>
      <Panel>
        {metrics.length < 1 ? (
          <EmptyState>
            <span>No data for this time range.</span>
          </EmptyState>
        ) : (
          <div
            className={cs(styles.analyticsTable, styles.testSuiteSizeTable)}
            data-cy="test-suite-size-table"
          >
            <AnalyticRowLink dataCy="test-suite-size-table__header">
              <h6>Date</h6>
              <h6>
                <span className={styles.textRight}>Total test cases</span>
              </h6>
              <h6>
                <span className={styles.textRight}>Spec files</span>
              </h6>
              <h6>
                <span className={styles.textRight}>Total runs</span>
              </h6>
              <h6>
                <span className={styles.textRight}>Failure rate</span>
              </h6>
            </AnalyticRowLink>
            {metrics.map((row, idx) => {
              return (
                <AnalyticRowLink
                  dataCy="test-suite-size-table__row"
                  key={`${row.timeSeriesPointStartDatetime}-${idx}`}
                >
                  <div>
                    {format(parseISO(row.timeSeriesPointStartDatetime), 'PP')}
                  </div>
                  <div>
                    <span className={styles.textRight}>
                      {row.totalTests ? (
                        <>
                          {row.totalTests} {pluralize('test', row.totalTests)}
                        </>
                      ) : (
                        <TextEmptyState />
                      )}
                    </span>
                  </div>
                  <div>
                    <span className={styles.textRight}>
                      {row.totalSpecs ? (
                        <>
                          {row.totalSpecs} {pluralize('spec', row.totalSpecs)}
                        </>
                      ) : (
                        <TextEmptyState />
                      )}
                    </span>
                  </div>
                  <div>
                    <span
                      className={cs(styles.textRight, {
                        'text-muted': row.totalRuns === 0,
                      })}
                    >
                      {commaNumber(row.totalRuns ?? 0)}
                    </span>
                  </div>
                  <div>
                    <AnalyticsProgressBar rate={row.failureRate} />
                  </div>
                </AnalyticRowLink>
              )
            })}
          </div>
        )}
      </Panel>
    </LoadingContainer>
  )
}

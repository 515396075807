import { CustomIcon, Tooltip } from '@frontend/design-system'
import React from 'react'

const TestRecording = ({ label, isV2Plan = false, isUnderlined = false }) => {
  return (
    <Tooltip
      placement="bottom"
      overlay={
        <span>
          The <strong>limit of total test results</strong> that you can record
          to the Cypress Cloud
          {isV2Plan ? ' before incurring additional charges' : ''}.
        </span>
      }
    >
      {isUnderlined ? (
        <em>{label}</em>
      ) : (
        <span>
          {label} <CustomIcon name="info" variant="small" />
        </span>
      )}
    </Tooltip>
  )
}

export { TestRecording }

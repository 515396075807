import {
  FlakyBadge,
  Icon,
  TestResultStatusIcon,
  Tooltip,
} from '@frontend/design-system'
import { join, last, lowerCase } from 'lodash'
import React, { FunctionComponent } from 'react'

import { SpecFile } from '~/specs/stats/SpecFile'
import { DrillInDrawerCloseBtn } from './DrillInDrawerCloseBtn'
import styles from './module.TestDrawerHeader.scss'
import { DrillInTestInfo } from './types'

type TestDrawerHeaderProps = {
  loading?: boolean
  onClose?: () => void
  testInfo?: DrillInTestInfo | null
  shouldAddShadow?: boolean
}

type TestDrawerHeaderTitleProps = {
  isFlaky?: boolean
  titleParts?: DrillInTestInfo['titleParts'] | null
}

const TestDrawerHeaderTitle: FunctionComponent<TestDrawerHeaderTitleProps> = ({
  isFlaky = false,
  titleParts,
}) => {
  if (!titleParts) {
    return null
  }

  if (titleParts.length < 2) {
    return (
      <>
        <b data-cy="test-drawer-header__title">{last(titleParts)}</b>{' '}
        {isFlaky && <FlakyBadge />}
      </>
    )
  }

  return (
    <Tooltip
      id="test-drawer-header__title"
      overlay={join(titleParts, ' > ')}
      placement="bottom"
    >
      <span>
        <b data-cy="test-drawer-header__title">{last(titleParts)}</b>{' '}
        {isFlaky && <FlakyBadge />}
      </span>
    </Tooltip>
  )
}

export const TestDrawerHeader: FunctionComponent<TestDrawerHeaderProps> = ({
  loading,
  onClose = () => {},
  testInfo,
  shouldAddShadow,
}) => {
  const titleParts = testInfo?.titleParts
  const status = testInfo?.status
  const specPath = testInfo?.specPath
  const isFlaky = testInfo?.isFlaky

  if (loading) {
    return (
      <div className="test-results-drawer__loader">
        <Icon name="spinner" spin />
      </div>
    )
  }

  return (
    <div
      className={`${styles.testDrawerHeader} ${
        shouldAddShadow ? styles.testDrawerHeaderShadow : ''
      }`}
      data-cy="test-drawer-header"
    >
      <DrillInDrawerCloseBtn onClose={onClose} />
      <div className={styles.titleContainer}>
        <div className={styles.title} data-cy="test-drawer-header-title">
          {status && (
            <TestResultStatusIcon
              status={lowerCase(status)}
              hasTooltip
              className={styles.status}
            />
          )}
          <TestDrawerHeaderTitle isFlaky={isFlaky} titleParts={titleParts} />
        </div>
        <SpecFile
          className={styles.specFile}
          copyEnabled
          specPath={specPath}
          style={{ marginLeft: status ? '20px' : '0px' }}
        />
      </div>
    </div>
  )
}

import { first, isNil, last, sortBy } from 'lodash'
import { ProjectSlowestTestsQuery } from '~/graphql-codegen-operations.gen'

function numBins(metric: number[], minBins = 2, maxBins = 500) {
  const h = binWidth(metric)
  const ulim = Math.max(...metric)
  const llim = Math.min(...metric)

  if (h <= (ulim - llim) / metric.length) {
    return minBins
  }

  const result = Math.ceil((ulim - llim) / h)
  return Math.min(Math.max(result, minBins), maxBins)
}

function binWidth(metric: number[]) {
  return 2 * iqr(metric) * Math.pow(metric.length, -1 / 3)
}

function iqr(metric: number[]) {
  const q1 = metric[Math.floor(metric.length / 4)]
  const q3 = metric[Math.floor((metric.length * 3) / 4)]

  return q3 - q1
}

export const getSlowestTestsBuckets = (
  slowestTests: ProjectSlowestTestsQuery['metrics']['projectSlowestTests']
) => {
  const nodes = sortBy(slowestTests.nodes.map((node) => node.medianDuration))
  const numberOfBins = numBins(nodes)
  let minVal = first(nodes)
  const maxVal = last(nodes)

  /* istanbul ignore next */
  if (isNil(minVal) || isNil(maxVal)) {
    return { buckets: {}, step: 0 }
  }

  if (minVal === maxVal) {
    minVal = Math.max(0, minVal - 5000)
  }

  const step = Math.round((maxVal - minVal) / numberOfBins)

  const buckentEndpoints = (() => {
    let endpoint = minVal
    const endpoints = [endpoint]

    while (endpoint < maxVal) {
      endpoint = endpoint + step
      endpoints.push(endpoint)
    }

    return endpoints
  })()

  const buckets = {}

  buckentEndpoints.forEach((bucketEndpoint, i) => {
    buckets[bucketEndpoint] = 0
  })

  for (let i = nodes.length; i--; ) {
    const node = nodes[i]

    if (isNil(node)) {
      continue
    }

    buckentEndpoints.some((bucketEndpoint, i) => {
      const nextEndPoint = buckentEndpoints[i + 1] || Infinity
      const fitsBucket = node <= nextEndPoint
      if (fitsBucket) {
        ++buckets[bucketEndpoint]
      }

      return fitsBucket
    })
  }

  return { buckets, step }
}

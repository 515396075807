import React, { ReactNode } from 'react'
import { CustomIcon } from '@frontend/design-system'
import { CIProviderEnum } from '@packages/types/src/allNexusTypes.gen'

export type ProviderData = {
  value:
    | 'github'
    | 'gitlab'
    | 'circleci'
    | 'azure'
    | 'aws'
    | 'bitbucket'
    | 'local-run'
    | 'manual-setup'
  databaseKey: CIProviderEnum
  title: string
  fileName: string
  docsHref: string
  recordKeyLocation: string
  icon: ReactNode
  gridIcon: ReactNode
}

export const providerData: ProviderData[] = [
  {
    databaseKey: 'GITHUB_ACTIONS',
    value: 'github',
    title: 'GitHub Actions',
    fileName: '.github/workflows/cypress.yml',
    docsHref: 'https://on.cypress.io/setup-ci-github-actions',
    recordKeyLocation: 'GitHub repo → Settings → Secrets → Actions',
    icon: <CustomIcon name="technology-github" size="x16" />,
    gridIcon: <CustomIcon name="technology-github" size="x48" />,
  },
  {
    databaseKey: 'GITLAB',
    value: 'gitlab',
    title: 'GitLab CI',
    fileName: '.gitlab-ci.yml',
    docsHref: 'https://on.cypress.io/setup-ci-gitlab',
    recordKeyLocation: 'GitLab repo → Settings → CI/CD → Variables',
    icon: <CustomIcon name="technology-gitlab" size="x16" />,
    gridIcon: <CustomIcon name="technology-gitlab" size="x48" />,
  },
  {
    databaseKey: 'CIRCLECI',
    value: 'circleci',
    title: 'CircleCI',
    fileName: '.circleci/config.yml',
    docsHref: 'https://on.cypress.io/setup-ci-circleci',
    recordKeyLocation: 'CircleCI → Project Settings → Environment Variables',
    icon: (
      <CustomIcon
        name="technology-circle-ci"
        style={{ color: '#161616' }}
        size="x16"
      />
    ),
    gridIcon: <CustomIcon name="technology-circle-ci" size="x48" />,
  },
  {
    databaseKey: 'AZURE',
    value: 'azure',
    title: 'Azure',
    fileName: 'azure-pipelines.yml',
    docsHref: 'https://on.cypress.io/setup-ci-azure-pipelines',
    recordKeyLocation: 'Azure DevOps pipeline → Edit → Variables',
    icon: <CustomIcon name="technology-azure" size="x16" />,
    gridIcon: <CustomIcon name="technology-azure" size="x48" />,
  },
  {
    databaseKey: 'BITBUCKET',
    value: 'bitbucket',
    title: 'Bitbucket',
    fileName: 'bitbucket-pipelines.yml',
    docsHref: 'https://on.cypress.io/setup-ci-bitbucket-pipelines',
    recordKeyLocation:
      'Bitbucket repo → Repository Settings → Pipelines/Repository Variables',
    icon: <CustomIcon name="technology-bitbucket" size="x16" />,
    gridIcon: <CustomIcon name="technology-bitbucket" size="x48" />,
  },
  {
    databaseKey: 'AWS_CODEBUILD',
    value: 'aws',
    title: 'AWS CodeBuild',
    fileName: 'buildspec.yml',
    docsHref: 'https://on.cypress.io/setup-ci-aws-codebuild',
    recordKeyLocation:
      'AWS Codebuild project → Build details → Environment → Additional configuration → Environment variables',
    icon: <CustomIcon name="technology-amazon-codebuild" size="x16" />,
    gridIcon: <CustomIcon name="technology-amazon-codebuild" size="x48" />,
  },
]

export const providerGridData: ProviderData[] = [
  ...providerData,
  {
    databaseKey: 'OTHER',
    value: 'manual-setup',
    title: 'Manual setup',
    fileName: '',
    docsHref: 'https://on.cypress.io/ci',
    recordKeyLocation: '',
    icon: <CustomIcon name="technology-terminal" size="x48" />,
    gridIcon: <CustomIcon name="technology-terminal" size="x48" />,
  },
]

export const providerDataWithManual: ProviderData[] = [
  ...providerData,
  {
    databaseKey: 'OTHER',
    value: 'manual-setup',
    title: 'Manual setup',
    fileName: '',
    docsHref: 'https://on.cypress.io/ci',
    recordKeyLocation: '',
    icon: <CustomIcon name="technology-terminal" size="x16" />,
    gridIcon: <CustomIcon name="technology-terminal" size="x48" />,
  },
]

import { CustomIcon } from '@frontend/design-system'
import React, { FunctionComponent } from 'react'
import { MultiSelectFilter } from '~/common/filters'
import { MultiSelectOptionType } from '../../types'

type ProjectSpecFilesFilterProps = {
  id: string
  state: ProjectSpecFilesFilterStateProp
}

type ProjectSpecFilesFilterStateProp = {
  options: MultiSelectOptionType[] | undefined
  selected: MultiSelectOptionType[] | undefined
  setSelected: (value: []) => void
}

const renderLabel = (option: MultiSelectOptionType) => {
  const label = (
    <span className="spec-paths-filter__label">{option.label}</span>
  ) as unknown

  return label as string
}

export const ProjectSpecFilesFilter: FunctionComponent<ProjectSpecFilesFilterProps> =
  React.memo(({ id, state }) => {
    const { options = [], selected = [], setSelected } = state

    return (
      <div
        data-cy="spec-paths-filter"
        data-pendo="spec-paths-filter"
        className="spec-paths-filter"
      >
        <MultiSelectFilter
          id={id}
          title={<CustomIcon name="spec" alt="Spec file" />}
          emptyLabel="Spec File"
          selected={selected || []}
          onChange={setSelected}
          options={options || []}
          getOptionLabel={renderLabel}
        />
      </div>
    )
  })

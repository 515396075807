import { lowerCase, max, maxBy, slice, sortBy, values } from 'lodash'
import React, { FunctionComponent, useState, useMemo, useRef } from 'react'
import pluralize from 'pluralize'
import {
  FlexibleWidthXYPlot,
  Hint,
  HorizontalGridLines,
  XAxis,
  YAxis,
  VerticalRectSeries,
  RVTickFormat,
} from 'react-vis'
import {
  ChartEmptyState,
  LoaderChart,
  Panel,
  Tooltip,
  palette,
} from '@frontend/design-system'
import { durationFormatted } from '@packages/common'
import {
  ProjectSlowestTestsQuery,
  ViewByEnum,
} from '~/graphql-codegen-operations.gen'
import { readableViewByEnum } from '~/lib/utils-ts'
import { useTimeSeriesAxis, isValidTick } from '~/lib/chart-utils'
import ErrorBoundaryDisplay from '~/lib/error-boundary'
import { LoadingContainer } from '~/project-analytics/LoadingContainer'
import { getSlowestTestsBuckets } from './util'

type SlowestTestsChart = {
  data?: ProjectSlowestTestsQuery
  isLoading: boolean
  setChartRange: (chartRangeMin: number, chartRangeMax: number) => void
  chartRange?: number[]
  viewBy: ViewByEnum
}

type SlowestTestsChartBodyProps = {
  metrics: ProjectSlowestTestsQuery['metrics']['projectSlowestTests']
  setChartRange: (chartRangeMin: number, chartRangeMax: number) => void
  chartRange?: number[]
  viewBy: string
}

type SlowestTestsChartTooltipProps = {
  focusedValue: SlowestTestsChartDataPointProps & { width: number }
  viewBy: string
}

type SlowestTestsChartDataPointProps = {
  x0: number
  x: number
  y: number
  y0: number
  value: number
}

function formatDataForChart(
  bucketKeys: number[],
  buckets: object,
  step: number
): Array<SlowestTestsChartDataPointProps> {
  const data =
    bucketKeys.length === 1 ? bucketKeys : slice(sortBy(bucketKeys), 0, -1)
  const largestDataPoint = max(values(buckets)) || 0
  const MIN_RECT_HEIGHT_PX = 4
  const CHART_HEIGHT = 250
  const minRectHeightVal =
    largestDataPoint / (CHART_HEIGHT / MIN_RECT_HEIGHT_PX)

  return data.map((d) => ({
    x0: d,
    x: d + step,
    y: buckets[d] === 0 ? 0 : Math.max(buckets[d], minRectHeightVal),
    y0: 0,
    value: buckets[d] ?? 0,
  }))
}

function formatDataForInvisibleRects(
  data: SlowestTestsChartDataPointProps[]
): Array<SlowestTestsChartDataPointProps> {
  const largestDataPoint = maxBy(data, 'y')
  return data.map((d) => ({
    x0: d.x0,
    x: d.x,
    y: largestDataPoint?.y || 0,
    y0: 0,
    value: d.value,
  }))
}

const SlowestTestsChartTooltip: FunctionComponent<
  SlowestTestsChartTooltipProps
> = ({ focusedValue, viewBy }) => {
  return (
    <table>
      <thead>
        <tr>
          <th colSpan={3}>
            {durationFormatted(focusedValue.x0, { includeMs: true })} -{' '}
            {durationFormatted(focusedValue.x, { includeMs: true })} median
            duration
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{viewBy}</td>
          <td>{focusedValue.value}</td>
        </tr>
      </tbody>
    </table>
  )
}

const SlowestTestsChartBody: FunctionComponent<SlowestTestsChartBodyProps> = ({
  metrics,
  setChartRange,
  chartRange,
  viewBy,
}) => {
  const { buckets, step } = useMemo(
    () => getSlowestTestsBuckets(metrics),
    [metrics]
  )
  const bucketKeys = sortBy(Object.keys(buckets).map(Number))

  const [focusedValue, setFocusedValue] =
    useState<null | SlowestTestsChartDataPointProps>(null)

  const clearFocus = () => {
    setFocusedValue(null)
  }

  const data = formatDataForChart(bucketKeys, buckets, step)
  const invisibleRects = formatDataForInvisibleRects(data)

  const dataWithColor = data.map((d) => {
    let color = palette.purple500
    if (chartRange?.length && chartRange[0] !== d.x0) {
      color = palette.gray100
    } else if (focusedValue && focusedValue.x0 === d.x0) {
      color = palette.purple600
    }

    return {
      ...d,
      color,
    }
  })

  const bucketLength = bucketKeys.length
  const tickLabelIndexes = useMemo(() => {
    const result = new Set<number>()
    const maxTicks = 15

    if (!bucketLength) {
      return null
    }

    const delta = Math.floor(bucketLength / maxTicks)

    if (delta < 2) {
      return null
    }

    for (let i = 0; i < bucketLength; i = i + delta) {
      result.add(i)
    }

    return result
  }, [bucketLength])

  const chartRef = useRef<FlexibleWidthXYPlot<any> | null>(null)
  const xAxis = useTimeSeriesAxis([], null, { chartRef, labelWidth: 80 })

  const tickFormat = (val: number, i: number, scale, tickTotal: number) => {
    const isValid = isValidTick(i, bucketKeys.length, tickTotal)

    if (!isValid) {
      return ''
    }

    const formattedDuration = durationFormatted(val, { includeMs: true })

    if (!tickLabelIndexes) {
      return formattedDuration
    }

    if (!tickLabelIndexes.has(i)) {
      return ''
    }

    return formattedDuration
  }

  return (
    <Panel.Body>
      <FlexibleWidthXYPlot
        ref={chartRef}
        className="slowest-tests-chart"
        height={300}
        onMouseLeave={clearFocus}
      >
        <HorizontalGridLines />
        <XAxis
          tickTotal={xAxis.tickTotal}
          tickFormat={tickFormat as RVTickFormat}
          tickValues={bucketKeys}
        />

        <YAxis />

        <VerticalRectSeries
          colorType="literal"
          data={dataWithColor}
          stroke="white"
          key="vertical-rect-series"
          style={{ cursor: 'pointer' }}
        />

        {focusedValue && (
          <Hint
            value={{
              x: (focusedValue.x0 + focusedValue.x) / 2,
              y: focusedValue.value,
            }}
            align={{ horizontal: 'auto', vertical: 'top' }}
          >
            <Tooltip
              id="slowest-tests-chart__tooltip"
              overlay={
                <SlowestTestsChartTooltip
                  viewBy={viewBy}
                  focusedValue={focusedValue as any}
                />
              }
              overlayStyle={{ pointerEvents: 'none' }}
              placement="top"
              visible
            >
              <span />
            </Tooltip>
          </Hint>
        )}

        {/* a series of invisible rects covering the full column so hovering anywhere on the column will display the tooltip */}
        <VerticalRectSeries
          colorType="literal"
          color="#00000000"
          data={invisibleRects}
          stroke="white"
          onValueMouseOver={(val: any) => setFocusedValue(val)}
          onValueMouseOut={clearFocus}
          key="invisible-rect-series"
          onValueClick={(val: any) => setChartRange(val.x0, val.x)}
          style={{ cursor: 'pointer' }}
        />
      </FlexibleWidthXYPlot>
    </Panel.Body>
  )
}

export const SlowestTestsChart: FunctionComponent<SlowestTestsChart> = ({
  data,
  isLoading,
  setChartRange,
  viewBy,
  chartRange,
}) => {
  const readableViewBy = pluralize(readableViewByEnum(viewBy)).toLowerCase()

  const Heading = (
    <Panel.Heading>
      <Panel.Title>Distribution of {readableViewBy} by duration</Panel.Title>
    </Panel.Heading>
  )

  if (!data || !data.metrics) {
    return (
      <Panel className="analytic__chart slowest-tests-chart">
        {Heading}
        <Panel.Body>
          <LoaderChart />
        </Panel.Body>
      </Panel>
    )
  }

  const metrics = data.metrics.projectSlowestTests
  const hasData = metrics && metrics.nodes.length > 0

  return (
    <LoadingContainer active={isLoading}>
      <ErrorBoundaryDisplay>
        <Panel className="analytic__chart slowest-tests-chart">
          {Heading}
          {hasData ? (
            <SlowestTestsChartBody
              metrics={metrics}
              viewBy={readableViewBy}
              setChartRange={setChartRange}
              chartRange={chartRange}
            />
          ) : (
            <ChartEmptyState>
              <p>No {lowerCase(readableViewBy)} match the supplied filters.</p>
            </ChartEmptyState>
          )}
        </Panel>
      </ErrorBoundaryDisplay>
    </LoadingContainer>
  )
}

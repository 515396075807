import { Icon } from '@frontend/design-system'
import { Link } from '@reach/router'
import React, { FunctionComponent } from 'react'

interface SidebarFooterProps {
  orgId: string
}

export const SidebarFooter: FunctionComponent<SidebarFooterProps> = ({
  orgId,
}) => {
  return (
    <div>
      <div className="sidebar-footer__nav-logo">
        <Link
          data-cy="nav-logo"
          data-pendo="nav-logo"
          to={`/organizations/${orgId}`}
        >
          <img src="/img/cypress-logo.svg" alt="Cypress Cloud home" />
        </Link>
      </div>
      <div className="sidebar-footer__links-container">
        <h4 className="sidebar-footer__link-container">
          <a
            href="https://on.cypress.io/support"
            target="_blank"
            rel="noopener noreferrer"
            className="sidebar-footer__link"
          >
            <Icon
              className="sidebar-footer__link-icon"
              name="question-circle-o"
            />
            Support
          </a>
        </h4>
        <h4 className="sidebar-footer__link-container">
          <a
            href="https://on.cypress.io/features-dashboard"
            target="_blank"
            rel="noopener noreferrer"
            className="sidebar-footer__link"
          >
            <Icon className="sidebar-footer__link-icon" name="book" />
            Documentation
          </a>
        </h4>
      </div>
      <div
        className={'sidebar-footer__pendo-target-test-replay-enabled'}
        data-pendo={'target-test-replay-enabled'}
      />
    </div>
  )
}

import { ContentLoader, palette } from '@frontend/design-system'
import React from 'react'

export const SidebarOrgSwitcherLoader = () => (
  <ContentLoader
    width={320}
    height={96}
    primaryColor={palette.gray700}
    secondaryColor={palette.gray500}
  >
    <circle cx="40" cy="40" r="24" />
    <rect x="72" y="24" width="192" height="16" rx="8" ry="8" />
    <rect x="72" y="48" width="124" height="12" rx="6" ry="6" />
  </ContentLoader>
)

import {
  ApolloClient,
  InMemoryCache,
  defaultDataIdFromObject,
} from '@apollo/client'
import { ApolloLink, Operation, Observable } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { createUploadLink } from 'apollo-upload-client'
import { RetryLink } from 'apollo-link-retry'
import { features } from '~/lib/feature-flags'

import { maybeNotifyTestRunnerAuth } from '../common/notify-test-runner/notifyTestRunner'
import introspectionQueryResultData from '../graphql-codegen-fragment-types.gen.json'
import * as Sentry from '@sentry/browser'

let operationCache = new Map<string, any>()

// Exported for stubbing in component test
export const locationInfo = {
  reload() {
    window.location.reload()
  },
  get documentHidden() {
    return document.visibilityState === 'hidden'
  },
  needsReload: false,
}

document.addEventListener('visibilitychange', () => {
  if (!locationInfo.documentHidden) {
    // When we go from hidden -> visible again, we'll trigger a refresh of the page if
    // we've determined the GraphQL API has changed since they were last here
    if (locationInfo.needsReload) {
      locationInfo.reload()
    }
    // When the page becomes visible, we'll also clear out the operationCache just to ensure
    // we're populating with fresh data
    operationCache = new Map()
  }
})

const validateVersion = (operation: Operation) => {
  const ctx = operation.getContext()
  if (typeof ctx.response?.headers?.get === 'function') {
    const versionHeader = ctx.response.headers.get('X-VERSION-HASH')
    const currentVersion = window.__serverVersion
    if (!currentVersion || !versionHeader) return
    if (currentVersion === versionHeader) return
    // Mark the page as being in a state of "needing reload", but don't reload unless
    // we detect an error or gain focus again after being in an unfocused state.
    // The first piece removes a janky experience where the user is clicking around and
    // the page refreshes for no reason - the second fixes the main issue, where the
    // app is getting errors but nothing is forcing a reload
    locationInfo.needsReload = true
  }
  return true
}

const refreshLink: ApolloLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    validateVersion(operation)
    return data
  })
})

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: 5000,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: (error, _operation) => !!error,
  },
})

// Keeps a cache of operation responses, guarding against polling for requests
// if the page is hidden, without needing to implement this logic everywhere in the applcation
// via a custom usePolling hook.
const hiddenPagePollingGuard: ApolloLink = new ApolloLink(
  (operation, forward) => {
    const response = operationCache.get(operation.toKey())
    if (response && locationInfo.documentHidden) {
      return Observable.of(response)
    }
    return forward(operation).map((data) => {
      operationCache.set(operation.toKey(), data)
      validateVersion(operation)
      return data
    })
  }
)

// Add the pathname header for tracing purpose to the headers
const traceableHeaderMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'x-cy-pathname': window.location.pathname,
    },
  }))

  return forward(operation)
})

const httpLink = ApolloLink.from([
  hiddenPagePollingGuard,
  new ApolloLink((operation, forward) => {
    return forward(operation).map((r) => {
      maybeNotifyTestRunnerAuth()
      return r
    })
  }),
  onError(({ graphQLErrors, networkError, operation, forward }) => {
    /* istanbul ignore if */
    if (graphQLErrors) {
      graphQLErrors.map((err) => {
        const { message, locations, path } = err
        const alert = `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations
        )}, Path: ${path}`
        console.log(alert)
        Sentry.withScope((scope) => {
          scope.setTag('graphql', true)
          scope.setTag('gqlOperation', operation.operationName)
          const field = path?.[path?.length - 1]
          if (field) Sentry.setTag('gqlField', field)
          scope.setTag('ApolloClientOnError', true)
          Sentry.captureMessage(alert, 'error')
        })
      })

      if (locationInfo.needsReload) {
        locationInfo.reload()
      }
    }
    /* istanbul ignore if */
    if (networkError) {
      console.log(`[Network error]: ${networkError}`)

      if (features.isEnabled('debug-apollo-link-network-error')) {
        // @ts-ignore
        console.log(`[Network error] - bodyText: ${networkError.bodyText}`)
      }

      Sentry.withScope((scope) => {
        scope.setTag('network', true)
        scope.setTag('ApolloClientOnError', true)

        const tempElement = document.createElement('div')
        // @ts-ignore
        tempElement.innerHTML = networkError.bodyText

        // Find the element containing the Ray ID
        const liElements = tempElement.querySelectorAll('li')

        let cfRayId: string | null = null
        liElements.forEach((li) => {
          if (li?.textContent?.includes('Ray ID:')) {
            cfRayId = li.textContent.split(':')[1].trim()
          }
        })

        console.info(`id: ${cfRayId}`)

        scope.setTag('cfRayId', cfRayId)

        Sentry.captureException(networkError, {
          extra: {
            gqlOperation: operation.operationName,
            gqlVariables: operation.variables,
            cfRayId,
          },
        })
      })
    }
  }),
  retryLink,
  refreshLink,
  traceableHeaderMiddleware,
  createUploadLink({ uri: '/graphql', credentials: 'same-origin' }),
])

export const dashboardApolloClient = new ApolloClient({
  link: httpLink,
  assumeImmutableResults: true,
  cache: new InMemoryCache({
    possibleTypes: introspectionQueryResultData.possibleTypes,
    // `dataIdFromObject` is deprecated in future verison of Apollo; use TypePolicies when we upgrade
    dataIdFromObject(responseObject) {
      switch (responseObject.__typename) {
        case 'Discovery_AccessibilityViewNode':
          // @ts-ignore
          return `${responseObject.__typename}:${responseObject.id}:${responseObject.status}`
        default:
          return defaultDataIdFromObject(responseObject)
      }
    },
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
})

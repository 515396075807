import React, { FunctionComponent } from 'react'
import store from '~/lib/store'
import { IconButton } from '@frontend/design-system'
import {
  OverlayPopoverPlanFragment,
  useTestOrchestrationCreateMutation,
  useTestOrchestrationRemoveMutation,
} from '~/graphql-codegen-operations.gen'
import OverlayCTA from '~/common/overlay-cta/OverlayCTA'

type ActionBarMuteProps = {
  isBeingMuted: boolean
  projectId: string
  specHash: string
  testResultId?: string | null
  titleHash?: string | null
  disabled: boolean
  plan?: OverlayPopoverPlanFragment
  orgId: string
  viewerIsOwnerOrAdmin?: boolean
}

export const ActionBarMute: FunctionComponent<ActionBarMuteProps> = ({
  isBeingMuted,
  projectId,
  specHash,
  testResultId,
  titleHash,
  disabled,
  plan,
  orgId,
  viewerIsOwnerOrAdmin,
}) => {
  const actionUrl = window.location.href
  const [orchestrationCreateMutation] = useTestOrchestrationCreateMutation()
  const [orchestrationRemoveMutation] = useTestOrchestrationRemoveMutation()

  const muteTest = () => {
    orchestrationCreateMutation({
      variables: {
        input: {
          action: 'MUTE',
          actionUrl,
          type: 'TEST',
          projectId,
          specHash,
          titleHash,
          testResultId,
        },
      },
    })
      .then((result) => {
        const isResultMuted = Boolean(
          result.data?.testOrchestrationCreate.testResult
            ?.activeMuteOrchestration
        )
        const isAnalyticMuted = Boolean(
          result.data?.testOrchestrationCreate.testResultAnalytic
            ?.activeMuteOrchestration
        )
        if (isResultMuted || isAnalyticMuted) {
          store.setNotification('Muted for all future runs')
        }
      })
      .catch()
  }

  const unmuteTest = () => {
    orchestrationRemoveMutation({
      variables: {
        input: {
          action: 'MUTE',
          actionUrl,
          type: 'TEST',
          projectId,
          specHash,
          titleHash,
          testResultId,
        },
      },
    })
      .then((result) => {
        const isResultMuted = Boolean(
          result.data?.testOrchestrationRemove.testResult
            ?.activeMuteOrchestration
        )
        const isAnalyticMuted = Boolean(
          result.data?.testOrchestrationRemove?.testResultAnalytic
            ?.activeMuteOrchestration
        )
        if (!isResultMuted && !isAnalyticMuted) {
          store.setNotification('Unmuted for all future runs')
        }
      })
      .catch()
  }

  return (
    <OverlayCTA
      placement="bottom"
      orgId={orgId}
      plan={plan}
      learnMoreUri="https://on.cypress.io/flake-mitigation"
      title="Mute test"
      description={
        <span>
          Silence problem tests with a single click. Available with{' '}
          <b>Business</b> plans.
        </span>
      }
      hideOverlay={!disabled}
      viewerIsOwnerOrAdmin={viewerIsOwnerOrAdmin}
    >
      <div>
        <IconButton
          icon="mute-test"
          data-cy="action-bar-mute"
          isActive={isBeingMuted}
          isCircle
          onClick={isBeingMuted ? unmuteTest : muteTest}
          text={isBeingMuted ? 'Unmute test' : 'Mute test'}
          isCustomCyIconButton
          disabled={disabled}
        />
      </div>
    </OverlayCTA>
  )
}

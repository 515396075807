import { CustomIcon } from '@frontend/design-system/src'
import React, { FunctionComponent } from 'react'

interface OSIconProps {
  os: string
}

export const OSIcon: FunctionComponent<OSIconProps> = ({ os }) => {
  if (os.toLowerCase().includes('linux')) {
    return (
      <CustomIcon data-cy="os-logo-linux" name="logo-os-linux" size="x16" />
    )
  }
  if (os.toLowerCase().includes('mac')) {
    return <CustomIcon data-cy="os-logo-mac" name="logo-os-mac" size="x16" />
  }
  if (os.toLowerCase().includes('windows')) {
    return (
      <CustomIcon data-cy="os-logo-windows" name="logo-os-windows" size="x16" />
    )
  }
  return (
    <CustomIcon data-cy="os-logo-generic" name="technology-os" size="x16" />
  )
}

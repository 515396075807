import cs from 'clsx'
import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { Drawer } from '~/common/drawer'

import { useDrawerMargin } from './hooks/useDrawerMargin'
import { useDrawerResizeable } from './hooks/useDrawerResizeable'
import { TestDrawerHeader } from '~/common/drill-in-drawer/TestDrawerHeader'
import { DrillInTestInfo } from './types'
import { useOnScroll } from '~/lib/hooks/useOnScroll'

type DrillInDrawerProps = {
  isOpen: boolean
  children?: ReactNode
  className?: string
  drawerKey?: string
  isContentLoading?: boolean
  onClose?: () => void
  testInfo?: DrillInTestInfo | null
}

export const DrillInDrawer: FunctionComponent<DrillInDrawerProps> = ({
  children,
  className,
  drawerKey,
  isContentLoading,
  isOpen,
  onClose = () => {},
  testInfo,
}) => {
  const [shouldAddShadow, setShouldAddShadow] = useState(false)
  const scrollableParentRef = useRef<HTMLDivElement | null>(null)
  const scrollableRef = useRef<HTMLDivElement | null>(null)

  const { resizeableRef, drawerWidth } = useDrawerResizeable(
    450,
    drawerKey ? `${drawerKey}width` : 'drillInDrawerWidth'
  )

  const { marginTop } = useDrawerMargin()

  // if a new item is loaded in the drawer, reset the scroll to the top
  useEffect(() => {
    if (isContentLoading && scrollableRef.current) {
      scrollableRef.current.scrollTop = 0
    }
  }, [isContentLoading, scrollableRef])

  const updateShadowState = useCallback(() => {
    setShouldAddShadow(Boolean(scrollableParentRef.current?.scrollTop))
  }, [])

  useOnScroll(
    {
      scrollable: scrollableParentRef.current,
      delay: 100,
    },
    updateShadowState
  )

  return (
    <Drawer isOpen={isOpen}>
      <div
        className={cs('drill-in-drawer', className)}
        style={{
          height: `calc(100% - ${marginTop}px)`,
          marginTop,
          width: drawerWidth,
        }}
        ref={scrollableParentRef}
      >
        <TestDrawerHeader
          loading={!testInfo || isContentLoading}
          onClose={onClose}
          testInfo={testInfo}
          shouldAddShadow={shouldAddShadow}
        />
        <div ref={scrollableRef} className="drill-in-drawer__inner">
          {children}
        </div>
        <div
          ref={resizeableRef}
          className={cs(`drill-in-drawer--resizable`, {
            draggable: isOpen,
          })}
        />
      </div>
    </Drawer>
  )
}

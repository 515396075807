import { Button, Icon } from '@frontend/design-system'
import React, { FunctionComponent } from 'react'

interface FilterResetProps {
  id: string
  onClick(): void
}

export const FilterReset: FunctionComponent<FilterResetProps> = ({
  id,
  onClick,
}) => (
  <div>
    <Button
      data-pendo={`${id}-filter-reset`}
      data-cy={`${id}-filter-reset`}
      bsSize="sm"
      bsStyle="link"
      onClick={onClick}
    >
      <Icon name="times" /> Reset Filters
    </Button>
  </div>
)

import React, { FunctionComponent } from 'react'
import { Filter } from '~/common/filters/Filter'

type LoadingFilterProps = {
  id: string
  title: string
}

export const LoadingFilter: FunctionComponent<LoadingFilterProps> = ({
  id,
  title,
}) => {
  const renderSelectedLabel = () => {
    return <span className="filter__selected-label">Loading</span>
  }
  const noop = () => []

  return (
    <Filter
      id={`loading${id}`}
      open={false}
      title={title}
      // @ts-ignor
      renderSelectedLabel={renderSelectedLabel}
      renderDropDown={noop}
    />
  )
}

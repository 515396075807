import {
  ChartEmptyState,
  LoaderChart,
  Panel,
  Tooltip,
  palette,
} from '@frontend/design-system'
import pluralize from 'pluralize'
import React, { FunctionComponent, useState } from 'react'
import {
  FlexibleWidthXYPlot,
  Hint,
  HorizontalGridLines,
  XAxis,
  YAxis,
  VerticalRectSeries,
} from 'react-vis'
import {
  ProjectTopFailuresQuery,
  ViewByEnum,
} from '~/graphql-codegen-operations.gen'
import { readableViewByEnum } from '~/lib/utils-ts'
import { TopFailuresBuckets } from './TopFailuresBuckets'
import { lowerCase, maxBy, values } from 'lodash'
import { LoadingContainer } from '~/project-analytics/LoadingContainer'

type TopFailuresChart = {
  data?: ProjectTopFailuresQuery
  isLoading: boolean
  setChartRange: (chartRangeMin: number, chartRangeMax: number) => void
  chartRange?: number[]
  viewBy: ViewByEnum
}

type TopFailuresChartBodyProps = {
  metrics: ProjectTopFailuresQuery['metrics']['projectTopFailingTestsOverTime']
  setChartRange: (chartRangeMin: number, chartRangeMax: number) => void
  chartRange?: number[]
  viewBy: ViewByEnum
}

type TopFailuresChartTooltipProps = {
  focusedValue: TopFailuresChartDataPointProps & { width: number }
  viewBy: ViewByEnum
}

type TopFailuresChartDataPointProps = {
  x0: number
  x: number
  y: number
  y0: number
  value: number
}

function formatDataForChart(
  data: object
): Array<TopFailuresChartDataPointProps> {
  const largestDataPoint = maxBy(values(data)) || 0
  const MIN_RECT_HEIGHT_PX = 4
  const CHART_HEIGHT = 250
  const minRectHeightVal =
    largestDataPoint / (CHART_HEIGHT / MIN_RECT_HEIGHT_PX)
  return Object.keys(data).map((d) => {
    return {
      x0: parseInt(d, 10),
      x: parseInt(d, 10) + 1,
      y: data[d] === 0 ? 0 : Math.max(data[d], minRectHeightVal),
      y0: 0,
      value: data[d] ?? 0,
    }
  })
}

function formatDataForInvisibleRects(
  data: TopFailuresChartDataPointProps[]
): Array<TopFailuresChartDataPointProps> {
  const largestDataPoint = maxBy(data, 'y')
  return data.map((d) => ({
    x0: d.x0,
    x: d.x,
    y: largestDataPoint?.y || 0,
    y0: 0,
    value: d.value,
  }))
}

const TopFailuresChartTooltip: FunctionComponent<
  TopFailuresChartTooltipProps
> = ({ focusedValue, viewBy }) => {
  return (
    <table>
      <thead>
        <tr>
          <th colSpan={3}>
            {focusedValue.x0 * 5}% - {focusedValue.x * 5}% failure rate
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{pluralize(readableViewByEnum(viewBy))}</td>
          <td>{focusedValue.value}</td>
        </tr>
      </tbody>
    </table>
  )
}

const TopFailuresChartBody: FunctionComponent<TopFailuresChartBodyProps> = ({
  metrics,
  setChartRange,
  chartRange,
  viewBy,
}) => {
  const metricsGroupedByBucket = TopFailuresBuckets(metrics)

  const [focusedValue, setFocusedValue] =
    useState<null | TopFailuresChartDataPointProps>(null)

  const clearFocus = () => {
    setFocusedValue(null)
  }

  const data = formatDataForChart(metricsGroupedByBucket)
  const invisibleRects = formatDataForInvisibleRects(data)

  const dataWithColor = data.map((d) => {
    let color = palette.red500
    if (chartRange?.length && chartRange[0] !== d.x0 * 5) {
      color = palette.gray100
    } else if (focusedValue && focusedValue.x0 === d.x0) {
      color = palette.red600
    }

    return {
      ...d,
      color,
    }
  })

  return (
    <Panel.Body>
      <FlexibleWidthXYPlot
        className="top-failures-chart"
        height={300}
        onMouseLeave={clearFocus}
      >
        <HorizontalGridLines />
        <XAxis tickFormat={(v) => `${v * 5}%`} />
        <YAxis />

        <VerticalRectSeries
          colorType="literal"
          data={dataWithColor}
          stroke="white"
          key="vertical-rect-series"
          style={{ cursor: 'pointer' }}
        />

        {focusedValue && (
          <Hint
            value={{
              x: (focusedValue.x0 + focusedValue.x) / 2,
              y: focusedValue.value,
            }}
            align={{ horizontal: 'auto', vertical: 'top' }}
          >
            <Tooltip
              id="top-failures-chart__tooltip"
              overlay={
                <TopFailuresChartTooltip
                  focusedValue={focusedValue as any}
                  viewBy={viewBy}
                />
              }
              overlayStyle={{ pointerEvents: 'none' }}
              placement="top"
              visible
            >
              <span />
            </Tooltip>
          </Hint>
        )}

        {/* a series of invisible rects covering the full column so hovering anywhere on the column will display the tooltip */}
        <VerticalRectSeries
          colorType="literal"
          color="#00000000"
          data={invisibleRects}
          stroke="white"
          onValueMouseOver={(val: any) => setFocusedValue(val)}
          onValueMouseOut={clearFocus}
          key="invisible-rect-series"
          onValueClick={(val: any) => setChartRange(val.x0 * 5, val.x * 5)}
          style={{ cursor: 'pointer' }}
        />
      </FlexibleWidthXYPlot>
    </Panel.Body>
  )
}

export const TopFailuresChart: FunctionComponent<TopFailuresChart> = ({
  data,
  isLoading,
  setChartRange,
  chartRange,
  viewBy,
}) => {
  const readableViewBy = pluralize(readableViewByEnum(viewBy)).toLowerCase()

  const Heading = (
    <Panel.Heading>
      <Panel.Title>Number of {readableViewBy} by failure rate</Panel.Title>
    </Panel.Heading>
  )

  if (!data || !data.metrics) {
    return (
      <Panel className="analytic__chart top-failures-chart">
        {Heading}
        <Panel.Body>
          <LoaderChart />
        </Panel.Body>
      </Panel>
    )
  }

  const metrics = data.metrics.projectTopFailingTestsOverTime
  const hasData = metrics && metrics.nodes.length > 0

  return (
    <LoadingContainer active={isLoading}>
      <Panel className="analytic__chart top-failures-chart">
        {Heading}
        {hasData ? (
          <TopFailuresChartBody
            metrics={metrics}
            setChartRange={setChartRange}
            chartRange={chartRange}
            viewBy={viewBy}
          />
        ) : (
          <ChartEmptyState>
            <p>No {lowerCase(readableViewBy)} match the supplied filters.</p>
          </ChartEmptyState>
        )}
      </Panel>
    </LoadingContainer>
  )
}

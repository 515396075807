import { isEqual, isNil, mapValues, negate, pickBy, isString } from 'lodash'
import queryString from 'query-string'
import { useEffect, useRef, useState } from 'react'

import { useLocation } from '~/lib/hooks/useLocation'
import { timeRangeAsInput } from '../util'

export const removeNullifiedFilters = (filters: {}) =>
  pickBy(filters, negate(isNil))

export function getFiltersFromQueryString(queryParams: string) {
  const query = queryString.parse(queryParams)

  return mapValues(removeNullifiedFilters(query), (val) => {
    try {
      const parsedVal = JSON.parse(val)
      return parsedVal
    } catch {
      return val
    }
  })
}

export function getQueryStringFromFilters(filters: Record<string, any>) {
  if (filters.timeRange) {
    filters = { ...filters, timeRange: timeRangeAsInput(filters.timeRange) }
  }
  const queryParams = mapValues(removeNullifiedFilters(filters), (val) => {
    if (isString(val)) {
      return val
    }

    return JSON.stringify(val)
  })

  const stringifiedQueryParams = queryString.stringify(queryParams)

  if (stringifiedQueryParams) {
    return `?${stringifiedQueryParams}`
  }

  return ''
}

export function useSyncFiltersWithRouter(
  filters: {},
  onSync: (filters: {}) => void,
  {
    isNavigationSyncDisabled = false,
    isFiltering,
  }: { isNavigationSyncDisabled?: boolean; isFiltering: boolean }
) {
  const { navigate, location } = useLocation()
  const navigationSyncRef = useRef(() => {})
  const filterSyncRef = useRef(() => {})
  const [hasInitiallySyncedWithFilters, updateHasInitiallySyncedWithFilters] =
    useState(false)

  useEffect(() => {
    navigationSyncRef.current = () => {
      if (isNavigationSyncDisabled) {
        return
      }

      const updatedQueryString = getQueryStringFromFilters(filters)
      const currentUrl = `${location.pathname}${location.search}`
      const updatedUrl = `${location.pathname}${updatedQueryString}`
      if (currentUrl === updatedUrl) {
        return
      }
      navigate(`${location.pathname}${updatedQueryString}`, { replace: true })
    }
  }, [
    filters,
    navigate,
    location.pathname,
    location.search,
    isNavigationSyncDisabled,
  ])

  useEffect(() => {
    filterSyncRef.current = () => {
      const updatedFilterState = getFiltersFromQueryString(location.search)
      if (
        hasInitiallySyncedWithFilters &&
        isEqual(updatedFilterState, removeNullifiedFilters(filters))
      ) {
        return
      }

      onSync(updatedFilterState)
      updateHasInitiallySyncedWithFilters(true)
    }
  }, [
    filters,
    navigate,
    location.pathname,
    location.search,
    onSync,
    hasInitiallySyncedWithFilters,
  ])

  useEffect(() => {
    if (isFiltering) {
      return
    }

    navigationSyncRef.current()
  }, [isFiltering])

  useEffect(() => {
    filterSyncRef.current()
  }, [location.search])
}

import { EmptyState, TestResultStatusIcon } from '@frontend/design-system'
import { lowerCase, isNil } from 'lodash'
import React, { FunctionComponent } from 'react'
import {
  LatestFailureFragment,
  LatestFlakinessFragment,
  TestResultScreenshotFragment,
} from '~/graphql-codegen-operations.gen'
import { CommitHorizontalList } from '~/common/CommitHorizontalList'
import { ArtifactControls } from '../../../common/artifacts/ArtifactControls'

type LatestTestsProps = {
  latestTests: LatestFailureFragment[] | LatestFlakinessFragment[]
  onScreenshotClick: (screenshots: TestResultScreenshotFragment[]) => void
  onVideoClick: (video: LatestFailureFragment) => void
  onReplayClick?: (
    capture: LatestFailureFragment | LatestFlakinessFragment
  ) => void
  projectId: string
  linkTo?: 'flakiness' | 'failure'
}

export const LatestTests: FunctionComponent<LatestTestsProps> = ({
  latestTests,
  onScreenshotClick,
  onVideoClick,
  onReplayClick,
  projectId,
  linkTo,
}) => {
  if (!latestTests || latestTests.length < 1) {
    return <EmptyState>No runs match the supplied filters.</EmptyState>
  }

  return (
    <div>
      {latestTests.map((item, i) => (
        <div key={i} className="latest-test-item" data-cy="latest-test-item">
          <TestResultStatusIcon
            buildNumber={item.run.buildNumber}
            status={lowerCase(item.state)}
          />
          <div>
            <div className="latest-test-item__commit-summary">
              {item.run.commit.summary}
            </div>
            <CommitHorizontalList
              projectId={projectId}
              run={item.run}
              linkTo={linkTo}
              testResultId={item.id}
            />
          </div>
          <ArtifactControls
            variant="small"
            onScreenshotClick={
              item.screenshots.nodes.length > 0
                ? () => onScreenshotClick(item.screenshots.nodes)
                : undefined
            }
            onVideoClick={
              Boolean(
                !isNil(item.video) &&
                  item.video.videoUrl &&
                  !isNil(item.videoTimestamp)
              )
                ? () => onVideoClick(item)
                : undefined
            }
            onReplayClick={
              onReplayClick
                ? () => {
                    onReplayClick(item)
                  }
                : undefined
            }
            frag={item}
            forceHideChevron
            forceHideOutput
          />
        </div>
      ))}
    </div>
  )
}

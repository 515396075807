import type { ProtocolCaptureStatusEnum } from '~/graphql-codegen-operations.gen'

export const isTestReplayModalPresentable = (
  capture?: { status: ProtocolCaptureStatusEnum } | null
) => {
  return (
    capture?.status &&
    ['AVAILABLE', 'PENDING', 'AWAITING_UPLOAD'].includes(capture.status)
  )
}

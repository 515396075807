import React from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import * as Sentry from '@sentry/browser'
declare global {
  interface Window {
    Sentry: any
  }
}

/*
  Importing <ErrorBoundaryContent/> is an anti-pattern, goal is to phase this out.
  When we directly render <ErrorBoundaryContent/> instead of wrapping the code in
  <ErrorBoundaryDisplay>, we do not capture the error itself, so we lose information
  to send to Sentry
*/
export const ErrorBoundaryContent: React.FC<{
  size?: 'small' | 'large'
}> = ({ size }) => {
  if (size === 'small') {
    return <span className="italic">Oops. Something went wrong!</span>
  }
  return (
    <div className="empty">
      Oops. Something went wrong!
      <br />
      <small>
        We have been notified of this error. You may also refresh the page or
        try again later.
      </small>
    </div>
  )
}

const ErrorBoundaryDisplay: React.FC<{
  children?: React.ReactNode
  fallbackRender?: (props: FallbackProps) => React.ReactNode
  size?: 'small' | 'large'
}> = (props) => {
  const { children, size = 'large' } = props

  const onError = (error: Error, info: any) => {
    if (window.Sentry) {
      Sentry.withScope((scope) => {
        Object.keys(info).forEach((key) => {
          scope.setExtra(key, info[key])
        })

        Sentry.captureException(error)
      })
    }
  }

  const fallbackRender =
    props.fallbackRender || (() => <ErrorBoundaryContent size={size} />)

  return (
    <ErrorBoundary fallbackRender={fallbackRender} onError={onError}>
      {children}
    </ErrorBoundary>
  )
}

export default ErrorBoundaryDisplay

import React from 'react'

/**
 * This is an accessibility tool for unsighted users. It allows them to skip over having the nav content read out and pushes focus to the main page content.
 * To see it, go to cloud.cypress.io and hit [tab].
 */
export const SkipNavLink = ({ target }) => {
  const onClick = (e) => {
    e.preventDefault()

    const targetEl = document.getElementById(target)
    if (!targetEl) {
      return
    }

    const removeTabIndex = () => {
      targetEl.removeAttribute('tabindex')
    }
    targetEl.addEventListener('blur', removeTabIndex)
    targetEl.addEventListener('focusout', removeTabIndex)
    targetEl.setAttribute('tabindex', -1)
    targetEl.focus()

    return false
  }

  return (
    <a className="skip-nav-link" href={`#${target}`} onClick={onClick}>
      Skip to main content
    </a>
  )
}

import cs from 'clsx'
import React, { FunctionComponent } from 'react'
import SVG, { Props as SVGProps } from 'react-inlinesvg'

import styles from './module.CustomIcons.scss'

// view docs at
// https://github.com/cypress-io/cypress-services/blob/develop/docs/custom-icons.md

interface CustomIconProps extends Partial<SVGProps> {
  name: string
  size?: 'x8' | 'x12' | 'x16' | 'x20' | 'x24' | 'x32' | 'x40' | 'x48' | 'x64'
  variant?: 'small'
  className?: string
  width?: number
  height?: number
  alt?: string
  spin?: boolean
}

export const CustomIcon: FunctionComponent<CustomIconProps> = ({
  name,
  className,
  size,
  variant,
  width,
  height,
  alt,
  spin = false,
  ...props
}) => {
  const fullName = variant
    ? `${name}-${variant}`
    : size
    ? `${name}_${size}`
    : `${name}`
  const nameClass = name ? `cy-${name}-icon` : ''
  const px: number | undefined = size && Number(size.replace('x', ''))

  const iconWidth: number = width || px || 20
  const iconHeight: number = iconWidth

  return (
    <SVG
      {...props}
      src={require(`@DS/lib/img/icons/${fullName}.svg`)}
      className={cs('cy-icon', className, nameClass, styles.customIcon, {
        [styles.spin as string]: spin,
      })}
      width={iconWidth}
      height={iconHeight}
      title={alt || undefined}
      onError={console.log}
    />
  )
}

export interface CustomSVGIconProps {
  SVGComponent: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >
  name?: string
  className?: string
  width?: number
  height?: number
  alt?: string
  spin?: boolean
}

export const CustomSVGIcon: FunctionComponent<CustomSVGIconProps> = ({
  SVGComponent,
  name,
  className,
  width,
  height,
  alt,
  spin = false,
}) => {
  const nameClass = name ? `cy-${name}-icon` : ''
  const iconWidth: number = width || 20
  const iconHeight: number = height || iconWidth
  return (
    <SVGComponent
      className={cs(
        'cy-icon',
        className,
        nameClass,
        styles.customIcon,
        spin && styles.spin
      )}
      width={iconWidth}
      height={iconHeight}
    />
  )
}

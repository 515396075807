import { useEffect, useRef, RefObject, useState } from 'react'

export function useHover<T extends HTMLElement>(): [RefObject<T>, boolean] {
  const [value, setValue] = useState(false)
  const ref = useRef<T>(null)
  const handleMouseEnter = () => setValue(true)
  const handleMouseLeave = () => setTimeout(() => setValue(false), 0)
  const node = ref.current

  useEffect(() => {
    if (!node) {
      return
    }

    node.addEventListener('mouseenter', handleMouseEnter)
    node.addEventListener('mouseleave', handleMouseLeave)

    return () => {
      node.removeEventListener('mouseenter', handleMouseEnter)
      node.removeEventListener('mouseleave', handleMouseLeave)
    }
  }, [node])

  return [ref, value]
}

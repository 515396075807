import React, { FunctionComponent } from 'react'

type ChartLegendProps = {
  items: Array<ChartLegendItemProps>
}

export type ChartLegendItemProps = {
  color: string
  name: string
  title: string
  filterValue?: string
}

const ChartLegend: FunctionComponent<ChartLegendProps> = ({ items }) => {
  return (
    <div className="chart-legend">
      {items.map((item) => {
        return (
          <div key={item.title} className="chart-legend--item">
            <div
              className="chart-legend--marker"
              style={{ backgroundColor: item.color }}
            />
            {item.title}
          </div>
        )
      })}
    </div>
  )
}

export { ChartLegend }

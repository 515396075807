import React from 'react'
import styles from './module.Chip.scss'
import cs from 'clsx'
import { IconDotOutlineSmall } from '@cypress-design/react-icon'

export type ChipVariant =
  | 'enabled'
  | 'applied'
  | 'not-applied'
  | 'disabled'
  | 'errored'

export const Chip: React.FC<{
  'data-cy'?: string
  value?: React.ReactNode
  color?: 'red' | 'jade' | 'gray' | 'purple' | 'indigo'
  variant?: ChipVariant
}> = ({ value, color, variant, ...rest }) => {
  // Since we compute the values of the colors dynamically,
  // tailwind jit can't infer the classes we need to keep.
  // We use the wind-keep class to prevent them from being purged.
  //
  // <wind-keep fillColor="current" strokeColor="current"/>
  // <wind-keep fillColor="red-500" strokeColor="red-500"/>
  // <wind-keep fillColor="jade-500" strokeColor="jade-500" />
  // <wind-keep fillColor="gray-500" strokeColor="gray-500" />
  // <wind-keep fillColor="purple-500" strokeColor="purple-500" />
  // <wind-keep fillColor="indigo-500" strokeColor="indigo-500" />

  switch (variant) {
    case 'enabled':
      color = 'jade'
      value = 'Enabled'
      break
    case 'applied':
      color = 'jade'
      value = 'Applied'
      break
    case 'disabled':
      color = color || 'gray'
      value = 'Disabled'
      break
    case 'not-applied':
      color = 'gray'
      value = 'Not applied'
      break
    case 'errored':
      color = 'red'
      value = 'Errored'
      break
    default:
      break
  }

  return (
    <div
      data-cy={rest['data-cy'] ?? 'cloud-status-chip'}
      className={cs(styles.cloudStatusChip, styles[color ?? 'gray'])}
    >
      <IconDotOutlineSmall
        fillColor={color ? `${color}-500` : undefined}
        strokeColor={color ? `${color}-500` : undefined}
      />
      {value}
    </div>
  )
}

export const getChipVariant: {
  (props: {
    enabled: boolean
    applied?: boolean
    errored?: boolean
  }): ChipVariant
} = ({ enabled, applied, errored = false }) => {
  let variant: ChipVariant = 'disabled'

  if (errored) {
    variant = 'errored'
  } else if (enabled) {
    variant = 'enabled'
    if (typeof applied === 'boolean') {
      variant = applied ? 'applied' : 'not-applied'
    }
  }

  return variant
}

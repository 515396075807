import { ApolloError } from 'apollo-client'

import {
  OrderByDirection,
  ProjectOrderField,
  SidebarProjectQuery,
} from '@frontend/dashboard/src/graphql-codegen-operations.gen'
import {
  Action,
  createReducerService,
} from '@frontend/dashboard/src/lib/createReducerService'
import { palette } from '@frontend/design-system'

const defaultOrderProjectsBy = {
  field: 'NAME' as ProjectOrderField,
  direction: 'ASC' as OrderByDirection,
}

const LAST_ORG_ORDER_PROJECTS_BY_KEY = 'lastOrganizationOrderProjectsBy'
type Organization = SidebarProjectQuery['project']['organizationInfo']

type SidebarData = {
  userData: SidebarProjectQuery['me']
  currentProject?: SidebarProjectQuery['project']
  currentOrg: Organization | null
  projectId?: string
}

type SidebarState = {
  isHidden: boolean
  isSidebarMenuOpen: boolean
  isSidebarOpen: boolean
  projectsOrder: typeof defaultOrderProjectsBy
  data: SidebarData | null
  error: ApolloError | null
}

type SidebarAction =
  | Action<'openSidebarMenu'>
  | Action<'closeSidebarMenu'>
  | Action<'toggleSidebar'>
  | Action<'showSidebar'>
  | Action<'hideSidebar'>
  | Action<'changeProjectsOrder', { order: typeof defaultOrderProjectsBy }>
  | Action<'updateData', { payload: SidebarData }>
  | Action<'updateError', { payload: ApolloError | null }>

export const [SidebarStateProvider, useSidebarService] = createReducerService<
  SidebarState,
  SidebarAction
>(
  (state, action) => {
    switch (action.type) {
      case 'openSidebarMenu': {
        return { ...state, isSidebarMenuOpen: true }
      }
      case 'closeSidebarMenu': {
        return { ...state, isSidebarMenuOpen: false }
      }
      case 'toggleSidebar': {
        return { ...state, isSidebarOpen: !state.isSidebarOpen }
      }
      case 'showSidebar': {
        return { ...state, isHidden: false, error: null }
      }
      case 'hideSidebar': {
        return { ...state, isHidden: true }
      }
      case 'changeProjectsOrder': {
        window.localStorage.setItem(
          LAST_ORG_ORDER_PROJECTS_BY_KEY,
          JSON.stringify(action.order)
        )

        return {
          ...state,
          projectsOrder: action.order,
        }
      }
      case 'updateData': {
        return { ...state, data: action.payload }
      }
      case 'updateError': {
        return { ...state, error: action.payload }
      }
      default: {
        return state
      }
    }
  },
  {
    isHidden: false,
    isSidebarMenuOpen: false,
    isSidebarOpen: window.innerWidth > Number(palette.screenSm),
    projectsOrder:
      JSON.parse(
        window.localStorage.getItem(LAST_ORG_ORDER_PROJECTS_BY_KEY) || 'null'
      ) || defaultOrderProjectsBy,
    data: null,
    error: null,
  },
  'SidebarState'
)

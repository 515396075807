import { Avatar, Tooltip } from '@frontend/design-system/src'
import React, { FunctionComponent } from 'react'
import cs from 'clsx'

type CommitAuthorProps = {
  authorEmail?: string | null
  authorAvatar?: string | null
  authorName: string | null | undefined
  unknownText?: string
  shouldDisplayTooltip?: boolean
  withSpacing?: boolean
}

const CommitterRow: FunctionComponent<CommitAuthorProps> = ({
  authorName,
  authorAvatar,
  authorEmail,
  unknownText,
  withSpacing = false,
}) => {
  return (
    <div className={cs('commit-author', withSpacing && 'with-spacing')}>
      <Avatar
        className="commit-author__avatar"
        entity={{ avatar: authorAvatar || undefined, email: authorEmail }}
        size="x16"
      />
      <div className="commit-author__name">
        {authorName || <span className="italic">{unknownText}</span>}
      </div>
    </div>
  )
}

export const CommitAuthor: FunctionComponent<CommitAuthorProps> = ({
  authorName,
  authorAvatar,
  authorEmail,
  unknownText = 'Unknown',
  shouldDisplayTooltip = true,
  withSpacing = false,
}) => {
  const row = (
    <CommitterRow
      authorName={authorName}
      authorAvatar={authorAvatar}
      authorEmail={authorEmail}
      unknownText={unknownText}
      withSpacing={withSpacing}
    />
  )
  return shouldDisplayTooltip ? (
    <Tooltip placement="bottom" overlay="Committer">
      <div>{row}</div>
    </Tooltip>
  ) : (
    row
  )
}

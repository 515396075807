import {
  Avatar,
  Button,
  CustomIcon,
  FilterEmptyState,
  Icon,
  Label,
} from '@frontend/design-system'
import _ from 'lodash'
import React, { FunctionComponent, ReactNode } from 'react'
import Highlighter from 'react-highlight-words'
import { components } from 'react-select'
import { MultiSelectFilter } from '~/common/filters'
import { MultiSelectOptionType } from '../../types'

type ProjectCommittersFilterProps = {
  id: string
  state: ProjectCommittersFilterStateProp
}

type ProjectCommittersFilterStateProp = {
  search: string | undefined
  loading: boolean
  options: MultiSelectOptionType[] | undefined
  selected: MultiSelectOptionType[] | undefined
  setSearch: (value: string) => void
  setLoading: (value: boolean) => void
  setSelected: (value: []) => void
}

export const ProjectCommittersFilterGlobal: FunctionComponent<ProjectCommittersFilterProps> =
  React.memo(({ id, state }) => {
    const {
      options = [],
      search,
      setSearch,
      loading,
      setLoading,
      selected = [],
      setSelected,
    } = state

    const onClickClear = () => setSelected([])
    const onInputChange = () => setLoading(true)

    function renderLabel(option: MultiSelectOptionType) {
      const label = (
        <span className="committers-filter__label">{option.label}</span>
      ) as unknown

      return label as string
    }

    const committerOptions = _.unionBy(selected, options, 'value')

    const unselectedItems: MultiSelectOptionType[] = _.difference(
      committerOptions,
      selected
    )

    const NoOptionsMessage: FunctionComponent = () => {
      return (
        <FilterEmptyState
          filter="committer"
          icon={<CustomIcon name="branch" />}
          info="Provide git information to Cypress to filter runs by committer"
          searchQuery={search}
          url="http://on.cypress.io/git-info"
        />
      )
    }

    const SelectedItemsHeader: FunctionComponent = () => {
      return (
        <div className="committers-filter--header h6">
          Selected
          <Button bsStyle="link" onClick={onClickClear}>
            Clear
          </Button>
        </div>
      )
    }

    const UnselectedItemsHeader: FunctionComponent = () => {
      if (search) {
        return (
          <div className="committers-filter--header unselected h6">
            Search results
          </div>
        )
      }

      return (
        <div className="committers-filter--header unselected h6">
          Recently committed
        </div>
      )
    }

    const customOption = (props): ReactNode => {
      return (
        <>
          {selected.length > 0 && props.value === selected[0].value && (
            <SelectedItemsHeader />
          )}
          {unselectedItems.length > 0 &&
            unselectedItems[0].value === props.value && (
              <UnselectedItemsHeader />
            )}
          <components.Option {...props}>
            <span>
              {props.isSelected ? (
                <Icon
                  name="check-square"
                  className="filter__checkbox fa-lg primary"
                />
              ) : (
                <Icon name="square-o" className="filter__checkbox fa-lg" />
              )}{' '}
              {props.data.labelProperties &&
                props.data.labelProperties.avatar && (
                  <Avatar
                    className="user-avatar"
                    entity={props.data.labelProperties}
                    size="x13"
                  />
                )}
              <Highlighter
                searchWords={[search]}
                textToHighlight={props.data.label}
              />{' '}
              (
              <Highlighter
                searchWords={[search]}
                textToHighlight={props.data.value}
              />
              )
            </span>
            {props.data.suggested && <Label bsStyle="default">default</Label>}
          </components.Option>
        </>
      )
    }

    return (
      <div
        data-cy="committers-filter"
        data-pendo="committers-filter"
        className="committers-filter multi-select-filter-with-categories"
      >
        <MultiSelectFilter
          id={id}
          title={<CustomIcon name="avatar" alt="Committer" />}
          emptyLabel="Committer"
          placeholder="Search for a committer..."
          selected={selected}
          isLoading={loading}
          onChange={setSelected}
          onInputChange={onInputChange}
          onSearch={setSearch}
          options={committerOptions}
          customOption={customOption}
          getOptionLabel={renderLabel}
          noOptionsMessage={NoOptionsMessage}
        />
      </div>
    )
  })

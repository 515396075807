import { useEffect } from 'react'
import {
  RunsListQuery,
  useUpdateCiProvidersMutation,
} from '~/graphql-codegen-operations.gen'
import { ProviderData } from '../providerData'

export async function useCommitIntendedCIProvider({
  projectId,
  currentStep,
  selectedCIProvider,
  supportedCIProviders,
}: {
  projectId: string
  currentStep: number
  selectedCIProvider: ProviderData | undefined
  supportedCIProviders: RunsListQuery['ciProviders']
}) {
  const [updateCIProviders] = useUpdateCiProvidersMutation()

  useEffect(() => {
    if (currentStep !== 3 || !selectedCIProvider) {
      return
    }

    const referenceProvider = supportedCIProviders.find(
      (provider) => provider.key === selectedCIProvider.databaseKey
    )

    if (!referenceProvider || !referenceProvider.id) {
      return
    }
    async function commitCIProvider() {
      try {
        await updateCIProviders({
          variables: {
            projectSlug: projectId,
            ciProviders: [referenceProvider!.id],
          },
        })
      } catch (e) {
        // Continue if ci provider update fails, we do not want this write
        // to be reflected in the setup workflow.
      }
    }
    commitCIProvider()
  }, [
    currentStep,
    selectedCIProvider,
    supportedCIProviders,
    updateCIProviders,
    projectId,
  ])
}

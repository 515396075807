import { RouteComponentProps } from '@reach/router'
import React from 'react'

import { SidebarLoader } from './loading/SidebarLoader'
import { SidebarContent } from './SidebarContent'
import { SidebarFooter } from './SidebarFooter'
import { useSidebarService } from './sidebarService'
import { SkipNavLink } from './skip-nav-link'
import { useSidebarRouterIds } from './SidebarNavHooks/useSidebarRouterIds'
import { useUpdateSidebarData } from './SidebarNavHooks/useUpdateSidebarData'
import { useSidebarProject } from './SidebarNavHooks/useSidebarProject'
import { useSidebarErrorHandler } from './SidebarNavHooks/useSidebarErrorHandler'

interface SideNavProps extends RouteComponentProps {
  className: string
}

export const SideNav: React.FC<SideNavProps> = ({ className }) => {
  const { routerOrganizationId } = useSidebarRouterIds()
  const [{ data, isHidden, projectsOrder }] = useSidebarService()
  const sidebarProject = useSidebarProject()

  useUpdateSidebarData()
  useSidebarErrorHandler()

  if (isHidden) {
    return null
  }

  return (
    <div className={`side-nav-container ${className || ''}`}>
      <SkipNavLink target="main" />
      {!data ? (
        <SidebarLoader />
      ) : (
        <SidebarContent
          orderProjectsBy={projectsOrder}
          userData={data.userData}
          projectId={data.projectId}
          currentProject={sidebarProject}
          currentOrg={data.currentOrg}
        />
      )}
      <SidebarFooter orgId={routerOrganizationId} />
    </div>
  )
}

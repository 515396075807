import qs from 'query-string'
import {
  TimeRangeInput,
  ProjectRunsOverTimeGroupedByStatusInput,
  ProjectRunDurationsOverTimeInput,
  ProjectTestSuiteSizeOverTimeInput,
  ProjectTopFailingTestsOverTimeInput,
  ProjectSlowestTestsInput,
  ProjectCommonErrorsInput,
  ViewByEnum,
  ProjectFlakinessTestsOverTimeInput,
} from '~/graphql-codegen-operations.gen'
import xhr from '../lib/xhr'

interface DateFilter {
  startDate: TimeRangeInput['startDate']
  endDate: TimeRangeInput['endDate']
}

type AnalyticParams<T> = Omit<T, 'projectId' | 'timeRange'> & DateFilter

export type DownloadFlakyTestsParams = {
  viewBy?: ViewByEnum | null
  startDate?: Date | string | null
  endDate?: Date | string | null
  branches?: string[] | null
  tags?: string[] | null
  specs?: string[] | null
  runGroups?: string[] | null
  browsers?: string[] | null
  cypressVersions?: string[] | null
  oses?: string[] | null
}

interface CSVAnalyticsRouteParams {
  'runs-over-time': AnalyticParams<ProjectRunsOverTimeGroupedByStatusInput>
  'run-durations': AnalyticParams<ProjectRunDurationsOverTimeInput>
  'test-suite-size': AnalyticParams<ProjectTestSuiteSizeOverTimeInput>
  'top-failures': AnalyticParams<ProjectTopFailingTestsOverTimeInput>
  'slowest-tests': AnalyticParams<ProjectSlowestTestsInput>
  'most-common-errors': AnalyticParams<ProjectCommonErrorsInput>
  'flaky-tests': AnalyticParams<ProjectFlakinessTestsOverTimeInput>
}

export function getAnalyticsCSVFileName(
  fileName: string,
  timeRange: TimeRangeInput | undefined
) {
  return [
    fileName,
    timeRange?.startDate.replace(/-/g, ''),
    timeRange?.endDate.replace(/-/g, ''),
  ]
    .filter((name) => !!name)
    .join('-')
}

export function downloadAnalyticsCSV<
  Type extends keyof CSVAnalyticsRouteParams
>({
  id,
  type,
  params,
}: {
  id: string
  type: Type
  params: CSVAnalyticsRouteParams[Type]
}) {
  return xhr({
    baseURL: '/',
    method: 'get',
    url: `download/analytics/${id}/${type}`,
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'bracket' })
    },
  })
}

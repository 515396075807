import { history } from './history'

export const navigationGuards: (() => boolean)[] = []

/**
 * Add a navigation guard function that will be called before navigating
 * @param handler function that returns true if navigation should proceed false otherwise
 * @returns teardown function
 */
export function addNavigationGuard(handler: () => boolean) {
  navigationGuards.push(handler)

  return () => {
    removeNavigationGuard(handler)
  }
}

/**
 * Remove a navigation guard
 * @param handler function to remove
 */
export function removeNavigationGuard(handler: () => boolean) {
  const index = navigationGuards.indexOf(handler)

  if (index !== -1) {
    navigationGuards.splice(index, 1)
  }
}

function preventDefaultNavigation() {
  return !navigationGuards.every((handler) => handler())
}

// NOTE: these 2 steps have to run before the App is mounted
// to prevent the default navigation behavior
// We run it outside of any function for that reason.
const originalNavigate = history.navigate

// @ts-expect-error - we're overriding the navigate method of the history object
history.navigate = (to, options) => {
  if (preventDefaultNavigation()) {
    return
  }
  originalNavigate(to, options)
}

export function setupNavigationGuards() {
  const beforeUnload = (event: BeforeUnloadEvent) => {
    if (preventDefaultNavigation()) {
      event.preventDefault()
    }
  }

  window.addEventListener('beforeunload', beforeUnload)

  return () => {
    window.removeEventListener('beforeunload', beforeUnload)
  }
}

import { uniqBy } from 'lodash'
import React, { FunctionComponent, useEffect, useState } from 'react'

import { CustomIcon } from '@frontend/design-system'
import { BrowserIcon } from '~/common/icon'
import { useRunBrowserFilterLazyQuery } from '~/graphql-codegen-operations.gen'

import { MultiSelectFilter } from '../../MultiSelectFilter'
import { MultiSelectOptionType, onMultiSelectFilterChange } from '../../types'

type RunBrowserFilterProps = {
  id: string
  onChange: onMultiSelectFilterChange
  runId: string
  selected: MultiSelectOptionType[]
}

const renderLabel = (option: MultiSelectOptionType) => {
  const label = (
    <span className="browsers-filter__label">
      <BrowserIcon className="mr-[4px]" name={option.value} alt="" />
      {option.label}
    </span>
  ) as unknown

  return label as string
}

export const RunBrowserFilter: FunctionComponent<RunBrowserFilterProps> =
  React.memo(({ id, onChange, selected, runId }) => {
    const [options, setOptions] = useState<MultiSelectOptionType[] | null>(null)
    const [fetchRunBrowsers] = useRunBrowserFilterLazyQuery({
      onCompleted: (data) => {
        if (!data.run || options) {
          return
        }

        const formattedOptions = data.run.uniqueBrowsers.map(
          ({ unformattedName, formattedName }) => ({
            label: formattedName,
            value: unformattedName,
          })
        )

        const uniqueOptions = uniqBy(formattedOptions, 'value')

        setOptions(uniqueOptions)
      },
    })

    useEffect(() => {
      fetchRunBrowsers({
        variables: {
          id: runId,
        },
      })
    }, [fetchRunBrowsers, runId])

    return (
      <MultiSelectFilter
        id={id}
        title={<CustomIcon name="browser" alt="Browser" />}
        emptyLabel="Browser"
        selected={selected}
        onChange={onChange}
        getOptionLabel={renderLabel}
        options={options || []}
      />
    )
  })

/*
 Caution: indentation matters here
  - Ensure the closing backtick is on the same line as the content
  - Only include leading indentation where you want it to also show up in the code block
*/
type YmlContent = {
  aws: string
  azure: string
  bitbucket: string
  circleci: string
  github: string
  gitlab: string
}

export const ymlContent: YmlContent = {
  aws: `version: 0.2
# Runs jobs in parallel
# https://docs.cypress.io/guides/guides/parallelization
batch:
  fast-fail: false
  build-list:
    # Uses 2 parallel instances
    - identifier: build1
    - identifier: build2
phases:
  install:
    runtime-versions:
      nodejs: latest
    commands:
      # Set COMMIT_INFO variables to send Git specifics to Cypress Cloud when recording
      # https://docs.cypress.io/guides/continuous-integration/introduction#Git-information
      - export COMMIT_INFO_BRANCH="$(git rev-parse HEAD | xargs git name-rev | cut -d' ' -f2 | sed 's/remotes\/origin\///g')"
      - export COMMIT_INFO_MESSAGE="$(git log -1 --pretty=%B)"
      - export COMMIT_INFO_EMAIL="$(git log -1 --pretty=%ae)"
      - export COMMIT_INFO_AUTHOR="$(git log -1 --pretty=%an)"
      - export COMMIT_INFO_SHA="$(git log -1 --pretty=%H)"
      - export COMMIT_INFO_REMOTE="$(git config --get remote.origin.url)"
      - npm ci --prefer-offline
  build:
    commands:
      # Starts web server for E2E tests - replace with your own server invocation
      # https://docs.cypress.io/guides/continuous-integration/introduction#Boot-your-server
      - npm start &
      - npx wait-on 'http-get://localhost:3000' # Waits for above
      # Records to Cypress Cloud 
      # https://docs.cypress.io/guides/cloud/projects#Set-up-a-project-to-record
      # For recording and parallelization to work you must set your CYPRESS_RECORD_KEY
      # in your AWS Codebuild project → Build details → Environment →
      # Additional configuration → Environment variables
      - npx cypress run --record --parallel --ci-build-id $CODEBUILD_INITIATOR`,
  azure: `# To configure triggers for Azure CI see
# https://docs.microsoft.com/en-us/azure/devops/pipelines/build/triggers?view=azure-devops&tabs=yaml#tags
jobs:
  - job: Cypress_tests
    pool:
      vmImage: 'ubuntu-latest'
    # Runs tests in parallel https://docs.cypress.io/guides/guides/parallelization
    # https://learn.microsoft.com/en-us/azure/devops/pipelines/process/phases?view=azure-devops&tabs=yaml
    strategy:
      parallel: 2
    steps:
      - task: NodeTool@0
      # Caches dependencies using npm lock file as key
      # https://docs.cypress.io/guides/continuous-integration/introduction#Caching
      - task: CacheBeta@1
        inputs:
          key: npm | package-lock.json
          path: /home/vsts/.npm
          restoreKeys: npm | package-lock.json
      - task: CacheBeta@1
        inputs:
          key: cy | package-lock.json
          path: /home/vsts/.cache/Cypress
          restoreKeys: cy | package-lock.json
      - script: npm ci --prefer-offline
      # Starts web server for E2E tests - replace with your own server invocation
      # https://docs.cypress.io/guides/continuous-integration/introduction#Boot-your-server
      - script: npm start &
      - script: npx wait-on 'http-get://localhost:3000' # Waits for above
      # Runs tests in parallel and records to Cypress Cloud
      # https://docs.cypress.io/guides/cloud/projects#Set-up-a-project-to-record
      # https://docs.cypress.io/guides/guides/parallelization
      - script: npx cypress run --record --parallel --ci-build-id $BUILD_BUILDNUMBER
        # For recording and parallelization to work you must set your CYPRESS_RECORD_KEY
        # in Azure DevOps → Your Pipeline → Edit → Variables
        env:
          CYPRESS_RECORD_KEY: $(CYPRESS_RECORD_KEY)`,
  bitbucket: `# Uses official Cypress docker image for dependencies
  # https://docs.cypress.io/guides/continuous-integration/introduction#Official-Cypress-Docker-Images
  image: cypress/base:20.9.0
  test: &test
    name: Cypress Tests
    caches:
      - node
      - cypress
    script:
      # Starts web server for E2E tests - replace with your own server invocation
      # https://docs.cypress.io/guides/continuous-integration/introduction#Boot-your-server
      - npm start &
      - npx wait-on 'http-get://localhost:3000' # Waits for above
        # Records to Cypress Cloud 
        # https://docs.cypress.io/guides/cloud/projects#Set-up-a-project-to-record
        # For recording and parallelization to work you must set your CYPRESS_RECORD_KEY
        # in your Bitbucket repo → Repository Settings → Pipelines/Repository Variables
      - npx cypress run --record --parallel --ci-build-id $BITBUCKET_BUILD_NUMBER
  pipelines:
    default:
      - step:
          name: Install dependencies
          # Uses built-in Bitbucket caching mechanism for dependencies
          # https://docs.cypress.io/guides/continuous-integration/introduction#Caching
          caches:
            - npm
            - cypress
            - node
          script:
            - npm ci --prefer-offline
      # Runs jobs in parallel
      # https://docs.cypress.io/guides/guides/parallelization
      - parallel:
          # Uses 2 parallel instances
          - step:
              <<: *test
          - step:
              <<: *test
  definitions:
    caches:
      npm: $HOME/.npm
      cypress: $HOME/.cache/Cypress`,
  circleci: `version: 2.1
# Uses the official Cypress Orb
# https://circleci.com/developer/orbs/orb/cypress-io/cypress
orbs:
  cypress: cypress-io/cypress@3
workflows:
  build:
    jobs:
      - cypress/run:
          # For recording and parallelization to work you must set your CYPRESS_RECORD_KEY
          # in CircleCI → Project Settings → Environment Variables
          # Records in parallel to Cypress Cloud 
          # https://docs.cypress.io/guides/guides/parallelization
          parallelism: 2 # Uses 2 parallel instances
          # Starts web server for E2E tests - replace with your own server invocation
          # https://docs.cypress.io/guides/continuous-integration/introduction#Boot-your-server
          start-command: npm start
          cypress-command: 'npx cypress run --record --parallel'`,
  github: `name: Cypress Tests
on: [push]
jobs:
  cypress-run:
    runs-on: ubuntu-latest
    # Runs tests in parallel with matrix strategy https://docs.cypress.io/guides/guides/parallelization
    # https://docs.github.com/en/actions/using-jobs/using-a-matrix-for-your-jobs
    # Also see warning here https://github.com/cypress-io/github-action#parallel
    strategy:
      fail-fast: false # https://github.com/cypress-io/github-action/issues/48
      matrix:
        containers: [1, 2] # Uses 2 parallel instances
    steps:
      - name: Checkout
        uses: actions/checkout@v4
      - name: Cypress run
        # Uses the official Cypress GitHub action https://github.com/cypress-io/github-action
        uses: cypress-io/github-action@v6
        with:
          # Starts web server for E2E tests - replace with your own server invocation
          # https://docs.cypress.io/guides/continuous-integration/introduction#Boot-your-server
          start: npm start
          wait-on: 'http://localhost:3000' # Waits for above
          # Records to Cypress Cloud 
          # https://docs.cypress.io/guides/cloud/projects#Set-up-a-project-to-record
          record: true
          parallel: true # Runs test in parallel using settings above
        env:
          # For recording and parallelization to work you must set your CYPRESS_RECORD_KEY
          # in GitHub repo → Settings → Secrets → Actions
          CYPRESS_RECORD_KEY: \${{ secrets.CYPRESS_RECORD_KEY }}
          # Creating a token https://docs.github.com/en/authentication/keeping-your-account-and-data-secure/creating-a-personal-access-token
          GITHUB_TOKEN: \${{ secrets.GITHUB_TOKEN }}`,
  gitlab: `stages:
  - test
# For recording and parallelization to work you must set your CYPRESS_RECORD_KEY
# in GitLab repo → Settings → CI/CD → Variables
variables:
  CYPRESS_RECORD_KEY: $CYPRESS_RECORD_KEY
# Caches dependencies using npm lock file as key
# https://docs.cypress.io/guides/continuous-integration/introduction#Caching
cache:
  key:
    files:
      - package-lock.json
  paths:
    - .npm/ # Moves npm cache inside project per GitLab restrictions
test:
  # Uses official Cypress docker image for dependencies
  # https://docs.cypress.io/guides/continuous-integration/introduction#Official-Cypress-Docker-Images
  image: cypress/browsers:node-20.9.0-chrome-118.0.5993.88-1-ff-118.0.2-edge-118.0.2088.46-1
  stage: test
  parallel: 2 # Uses 2 parallel instances
  script:
    - npm ci --cache .npm --prefer-offline
    # Starts web server for E2E tests - replace with your own server invocation
    # https://docs.cypress.io/guides/continuous-integration/introduction#Boot-your-server
    - npm start &
    - npx wait-on 'http-get://localhost:3000' # Waits for above
    # Runs tests in parallel and records to Cypress Cloud
    # https://docs.cypress.io/guides/cloud/projects#Set-up-a-project-to-record
    # https://docs.cypress.io/guides/guides/parallelization
    - npx cypress run --record --parallel --browser chrome`,
}

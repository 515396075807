import {
  ChartEmptyState,
  LoaderChart,
  Panel,
  Tooltip,
  palette,
} from '@frontend/design-system'
import { isoParse, utcFormat } from 'd3-time-format'
import { format } from 'date-fns'
import pluralize from 'pluralize'
import React, { FunctionComponent, useState, useRef } from 'react'
import {
  AreaSeries,
  FlexibleWidthXYPlot,
  Hint,
  HorizontalGridLines,
  LineMarkSeries,
  LineSeries,
  XAxis,
  YAxis,
} from 'react-vis'
import {
  ProjectTestSuiteSizeOverTimeQuery,
  TimeIntervalEnum,
} from '~/graphql-codegen-operations.gen'
import ErrorBoundaryDisplay from '~/lib/error-boundary'
import { getUTCDateAsLocalDate } from '~/lib/utils'
import { useTimeSeriesAxis } from '~/lib/chart-utils'
import { LoadingContainer } from '~/project-analytics/LoadingContainer'
import moment from 'moment'

type TestSuiteSizeChartProps = {
  data?: ProjectTestSuiteSizeOverTimeQuery
  isLoading: boolean
  timeInterval?: TimeIntervalEnum | null
  navigateToRunList: (date: string) => void
  byBuildAnalytics: boolean
}

type TestSuiteSizeChartBodyProps = {
  metrics: ProjectTestSuiteSizeOverTimeQuery['metrics']['projectTestSuiteSizeOverTime']
  timeInterval?: TimeIntervalEnum | null
  navigateToRunList: (date: string) => void
  byBuildAnalytics: boolean
}

type TestSuiteSizeChartTooltipProps = {
  focusedValue: TestSuiteSizeChartDataPointProps
  byBuildAnalytics: boolean
}

type TestSuiteSizeChartDataPointProps = {
  x: number
  y: number
  fullData: TestSuiteSizeChartBodyProps['metrics']['nodes'][number]
  hasData: boolean
}

function formatDataForChart(
  data: TestSuiteSizeChartBodyProps['metrics']['nodes'],
  byBuildAnalytics: boolean
): Array<TestSuiteSizeChartDataPointProps> {
  return data.map((d, idx) => {
    return {
      x: byBuildAnalytics
        ? idx
        : isoParse(d.timeSeriesPointStartDatetime)!.valueOf(),
      y: d.totalTests ?? 0,
      fullData: d,
      hasData: Boolean(d.totalTests),
    }
  })
}

const TestSuiteSizeChartTooltip: FunctionComponent<
  TestSuiteSizeChartTooltipProps
> = ({ focusedValue, byBuildAnalytics }) => {
  return (
    <table>
      <thead>
        <tr>
          <th colSpan={3}>
            {format(
              getUTCDateAsLocalDate(
                byBuildAnalytics
                  ? focusedValue.fullData.timeSeriesPointStartDatetime
                  : focusedValue.x
              ),
              'PP'
            )}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div className="bullet color-bullet" />
          </td>
          <td>{pluralize('Unique test', focusedValue.y)}</td>
          <td>{focusedValue.y}</td>
        </tr>
        <tr>
          <td>
            <div className="bullet " />
          </td>
          <td>{pluralize('Spec', focusedValue.fullData.totalSpecs ?? 0)}</td>
          <td>{focusedValue.fullData.totalSpecs || 0}</td>
        </tr>
        <tr>
          <td>
            <div className="bullet" />
          </td>
          <td>{pluralize('Run', focusedValue.fullData.totalRuns!)}</td>
          <td>{focusedValue.fullData.totalRuns}</td>
        </tr>
      </tbody>
    </table>
  )
}

const TestSuiteSizeChartBody: FunctionComponent<
  TestSuiteSizeChartBodyProps
> = ({ metrics, timeInterval, navigateToRunList, byBuildAnalytics }) => {
  const [focusedValue, setFocusedValue] =
    useState<null | TestSuiteSizeChartDataPointProps>(null)

  const clearFocus = () => {
    setFocusedValue(null)
  }

  const series = formatDataForChart(metrics.nodes, byBuildAnalytics)
  const chartRef = useRef<FlexibleWidthXYPlot<any> | null>(null)
  const xAxis = useTimeSeriesAxis(
    series.map((d) => d.x),
    timeInterval ?? null,
    { chartRef }
  )
  const yDomain = [0, Math.max(...metrics.nodes.map((d) => d.totalTests || 0))]

  const formatByBuildTicks = React.useCallback(
    (tickValue) => {
      const value = series.find((d) => (d.x as number) === tickValue)
      return value && value.fullData.timeSeriesPointStartDatetime
        ? utcFormat('%b %d')(
            moment.utc(value.fullData.timeSeriesPointStartDatetime).toDate()
          )
        : ''
    },
    [series]
  )

  // Create a special series that "interpolates" over dates with missing data
  const isDefined = (d: any) => d.hasData
  const undefinedSeries = series.filter(isDefined)
  const hasUndefinedValues = undefinedSeries.length < series.length

  const handleClick = () =>
    focusedValue?.fullData.timeSeriesPointStartDatetime &&
    navigateToRunList(focusedValue.fullData.timeSeriesPointStartDatetime)

  return (
    <Panel.Body>
      <FlexibleWidthXYPlot
        className="test-suite-size-chart"
        height={300}
        ref={chartRef}
        yDomain={yDomain}
        onMouseLeave={clearFocus}
      >
        <HorizontalGridLines />
        {!byBuildAnalytics ? (
          <XAxis
            tickTotal={xAxis.tickTotal}
            tickFormat={xAxis.tickFormat}
            tickValues={xAxis.tickValues}
          />
        ) : (
          <XAxis tickFormat={formatByBuildTicks} />
        )}
        <YAxis hideLine />

        {hasUndefinedValues && (
          <AreaSeries
            color={palette.gray200}
            data={undefinedSeries}
            opacity={0.1}
            key="missing-area-series"
          />
        )}
        {hasUndefinedValues && (
          <LineSeries
            color={palette.gray200}
            data={undefinedSeries}
            style={{ strokeDasharray: '4 4' }}
            key="missing-line-series"
            className="test-suite-size-chart--missing-data-series"
          />
        )}

        <AreaSeries
          color={palette.indigo600}
          data={series}
          onNearestX={(val: any) => setFocusedValue(val)}
          opacity={0.2}
          key="area-series"
          getNull={isDefined}
        />
        <LineMarkSeries
          color={palette.indigo600}
          data={series}
          markStyle={{ stroke: 'white' }}
          getSize={() => 3}
          key="line-series"
          getNull={isDefined}
          onSeriesClick={handleClick}
          style={{ cursor: 'pointer' }}
        />
        {focusedValue && (
          <Hint
            value={focusedValue}
            align={{ horizontal: 'left', vertical: 'top' }}
          >
            <Tooltip
              id="test-suite-size-chart__tooltip"
              overlay={
                <TestSuiteSizeChartTooltip
                  focusedValue={focusedValue}
                  byBuildAnalytics={byBuildAnalytics}
                />
              }
              placement="top"
              visible
            >
              <span />
            </Tooltip>
          </Hint>
        )}
      </FlexibleWidthXYPlot>
    </Panel.Body>
  )
}

export const TestSuiteSizeChart: FunctionComponent<TestSuiteSizeChartProps> = ({
  data,
  isLoading,
  timeInterval,
  navigateToRunList,
  byBuildAnalytics,
}) => {
  if (!data || !data.metrics) {
    return (
      <Panel className="analytic__chart test-suite-size-chart">
        <Panel.Heading>
          <Panel.Title>Test suite size over time</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <LoaderChart />
        </Panel.Body>
      </Panel>
    )
  }

  const metrics = data.metrics.projectTestSuiteSizeOverTime
  const hasData = metrics && metrics.totalTests > 0

  return (
    <LoadingContainer active={isLoading}>
      <ErrorBoundaryDisplay>
        <Panel
          data-cy="test-suite-size-chart"
          className="analytic__chart test-suite-size-chart"
        >
          <Panel.Heading>
            <Panel.Title>Test suite size over time</Panel.Title>
          </Panel.Heading>
          {hasData ? (
            <TestSuiteSizeChartBody
              byBuildAnalytics={byBuildAnalytics}
              metrics={metrics}
              timeInterval={timeInterval}
              navigateToRunList={navigateToRunList}
            />
          ) : (
            <ChartEmptyState>
              <p>No runs match the supplied filters.</p>
            </ChartEmptyState>
          )}
        </Panel>
      </ErrorBoundaryDisplay>
    </LoadingContainer>
  )
}

import _ from 'lodash'
import {
  ProjectFlakyTestsKpiQuery,
  ProjectFlakyTestsQuery,
  ViewByEnum,
} from '~/graphql-codegen-operations.gen'
import pluralize from 'pluralize'
import { readableViewByEnum } from '~/lib/utils-ts'

export const getKPIs = (
  overallFlakiness: ProjectFlakyTestsKpiQuery | undefined,
  overallFlakinessLoading: boolean,
  projectFlakyTests: ProjectFlakyTestsQuery | undefined,
  loading: boolean,
  viewBy: ViewByEnum
) => {
  let kpis

  if (projectFlakyTests) {
    const groupedBySeverity = _.groupBy(
      projectFlakyTests?.metrics.projectFlakinessTestsOverTime.nodes,
      'severity'
    )

    const highSeverityValue = groupedBySeverity.HIGH?.length ?? 0
    const mediumSeverityValue = groupedBySeverity.MEDIUM?.length ?? 0
    const lowSeverityValue = groupedBySeverity.LOW?.length ?? 0

    kpis = [
      {
        name: 'Overall flakiness',
        value: overallFlakiness
          ? `${overallFlakiness.metrics.projectFlakinessTestsOverTime.overallFlakiness}%`
          : '',
        subtitle: `${pluralize(readableViewByEnum(viewBy), 0)}`, // always plural for percent
        loading: overallFlakinessLoading,
      },
      {
        name: 'High severity',
        value: highSeverityValue,
        subtitle: `${pluralize(readableViewByEnum(viewBy), highSeverityValue)}`,
        loading,
        className: 'flaky-tests-kpi-high-severity',
      },
      {
        name: 'Medium severity',
        value: mediumSeverityValue,
        subtitle: `${pluralize(
          readableViewByEnum(viewBy),
          mediumSeverityValue
        )}`,
        loading,
        className: 'flaky-tests-kpi-medium-severity',
      },
      {
        name: 'Low severity',
        value: lowSeverityValue,
        subtitle: `${pluralize(readableViewByEnum(viewBy), lowSeverityValue)}`,
        loading,
        className: 'flaky-tests-kpi-low-severity',
      },
    ]
  } else {
    kpis = [
      {
        name: 'Overall flakiness',
        subtitle: 'Test cases',
        loading: overallFlakinessLoading,
      },
      {
        name: 'High severity',
        subtitle: 'Test cases',
        loading,
        className: 'flaky-tests-kpi-high-severity',
      },
      {
        name: 'Medium severity',
        subtitle: 'Test cases',
        loading,
        className: 'flaky-tests-kpi-medium-severity',
      },
      {
        name: 'Low severity',
        subtitle: 'Test cases',
        loading,
        className: 'flaky-tests-kpi-low-severity',
      },
    ]
  }
  return kpis
}

import { InputGroup, Form, FormControl } from '@frontend/design-system'
import {
  PendingGithubAppInstall,
  useSetInstallationToOrganizationMutation,
} from '~/graphql-codegen-operations.gen'
import _ from 'lodash'
import React, { useState } from 'react'

import { useLocation } from '~/lib/hooks/useLocation'

type UserOrgs = {
  __typename?: 'OrganizationConnection'
  nodes: Array<{
    __typename?: 'Organization'
    id: string
    name: string
    logo: string | null
    isMyPersonalOrganization: boolean
  }>
}

interface PendingGithubInstallationItemProps {
  id: PendingGithubAppInstall['id']
  accountLogin: PendingGithubAppInstall['accountLogin']
  accountType: PendingGithubAppInstall['accountType']
  organizations: UserOrgs
}

export const PendingGithubInstallationItem: React.FC<
  PendingGithubInstallationItemProps
> = ({ id, accountLogin, accountType, organizations }) => {
  const { navigate } = useLocation()
  const [selectedOrg, selectOrg] = useState<string | undefined>()
  const [setInstallationToOrganization] =
    useSetInstallationToOrganizationMutation()

  function onConfirm() {
    if (selectedOrg) {
      setInstallationToOrganization({
        variables: {
          input: {
            orgId: selectedOrg,
            installationId: id,
          },
        },
      })

      navigate(`/organizations/${selectedOrg}/integrations`)
    }
  }

  return (
    <li className="notification-item">
      <div>
        You installed the Cypress GitHub app into the <b>{accountLogin}</b>{' '}
        GitHub {accountType.toLowerCase()}. Select a Cypress organization to
        complete the installation.
      </div>
      <div>
        <Form inline style={{ marginRight: '12px' }}>
          <InputGroup bsClass="pending-installation">
            <FormControl
              componentClass="select"
              value={selectedOrg}
              onChange={(e) => {
                const target = e.target as HTMLSelectElement

                selectOrg(
                  target.value === 'not-valid-option' ? undefined : target.value
                )
              }}
            >
              <option value="not-valid-option">
                Select an organization...
              </option>
              {_.map(organizations.nodes, (org) => (
                <option key={org.id} value={org.id}>
                  {org.name}
                </option>
              ))}
            </FormControl>
            <button
              data-cy="pending-installation-nav-confirm"
              data-pendo="pending-installation-nav-confirm"
              className="btn btn-primary"
              onClick={onConfirm}
              disabled={!selectedOrg}
            >
              Confirm
            </button>
          </InputGroup>
        </Form>
      </div>
    </li>
  )
}

export default PendingGithubInstallationItem

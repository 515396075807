import {
  EmptyState,
  Panel,
  TextEmptyState,
  Tooltip,
} from '@frontend/design-system'
import {
  TimeIntervalEnum,
  ProjectRunDurationsOverTimeQuery,
  ProjectRunDurationsOverRunsQuery,
} from '~/graphql-codegen-operations.gen'
import cs from 'clsx'
import commaNumber from 'comma-number'
import { format, parseISO } from 'date-fns'
import _ from 'lodash'
import pluralize from 'pluralize'
import React, { FunctionComponent } from 'react'
import { AnalyticRowLink } from '../AnalyticRowLink/AnalyticRowLink'
import { LoadingContainer } from '~/project-analytics/LoadingContainer'

import { durationFormattedFull } from '~/lib/utils'

import { AnalyticsProgressBar } from '../AnalyticsProgressBar'
import styles from '../module.AnalyticsTable.scss'

type RunDurationTableProps = {
  data?: ProjectRunDurationsOverTimeQuery | ProjectRunDurationsOverRunsQuery
  isLoading: boolean
  timeInterval?: TimeIntervalEnum
}

export const RunDurationTable: FunctionComponent<RunDurationTableProps> = ({
  data,
  isLoading,
  timeInterval,
}) => {
  if (!data || !data.metrics) {
    return (
      <Panel>
        <EmptyState>
          <span>Loading...</span>
        </EmptyState>
      </Panel>
    )
  }

  const metrics = _.reverse(
    _.cloneDeep(data.metrics.projectRunDurationsOverTime.nodes)
  )

  return (
    <LoadingContainer active={isLoading}>
      <Panel>
        {metrics.length < 1 ? (
          <EmptyState>
            <span>No data for this time range.</span>
          </EmptyState>
        ) : (
          <div
            className={cs(styles.analyticsTable, styles.runDurationTable)}
            data-cy="run-duration-table"
          >
            <AnalyticRowLink dataCy="run-duration-table__header">
              {timeInterval === 'DAY' ? (
                <h6>Date</h6>
              ) : (
                <h6>{timeInterval} beginning</h6>
              )}
              <h6>
                <span className={styles.textRight}>Average runtime</span>
              </h6>
              <h6>
                <span className={styles.textRight}>
                  <Tooltip
                    overlay="The median number of machines that were used for parallelizing for these runs"
                    placement="top"
                  >
                    <span>Typical concurrency ℹ</span>
                  </Tooltip>
                </span>
              </h6>
              <h6>
                <span className={styles.textRight}>
                  Time saved from parallelization
                </span>
              </h6>
              <h6>
                <span className={styles.textRight}>Total runs</span>
              </h6>
              <h6>
                <span className={styles.textRight}>Failure rate</span>
              </h6>
            </AnalyticRowLink>

            {metrics.map((row, index) => {
              return (
                <AnalyticRowLink
                  dataCy="run-duration-table__row"
                  key={`${row.timeSeriesPointStartDatetime}-${index}`}
                >
                  <div>
                    {format(parseISO(row.timeSeriesPointStartDatetime), 'PP')}
                  </div>
                  <div>
                    {row.averageRuntime ? (
                      <span className={styles.textRight}>
                        {durationFormattedFull(row.averageRuntime * 1000, 2)}
                      </span>
                    ) : (
                      <span className={styles.textRight}>
                        <TextEmptyState />
                      </span>
                    )}
                  </div>
                  <div>
                    <span className={styles.textRight}>
                      {row.medianConcurrency ? (
                        <>
                          {Math.round(row.medianConcurrency)}{' '}
                          {pluralize(
                            'machine',
                            Math.round(row.medianConcurrency)
                          )}
                        </>
                      ) : (
                        <TextEmptyState />
                      )}
                    </span>
                  </div>
                  <div>
                    <span className={styles.textRight}>
                      {row.parallelizationSavings ? (
                        <span>
                          {durationFormattedFull(
                            row.parallelizationSavings * 1000,
                            2
                          )}
                        </span>
                      ) : (
                        <TextEmptyState />
                      )}
                    </span>
                  </div>
                  <div>
                    <span
                      className={cs(styles.textRight, {
                        'text-muted': row.totalRuns === 0,
                      })}
                    >
                      {commaNumber(row.totalRuns)}
                    </span>
                  </div>
                  <div>
                    <AnalyticsProgressBar rate={row.failureRate} />
                  </div>
                </AnalyticRowLink>
              )
            })}
          </div>
        )}
      </Panel>
    </LoadingContainer>
  )
}

import { ProjectTestSuiteSizeOverTimeQuery } from '~/graphql-codegen-operations.gen'

export const getKPIs = (
  testSuiteSizeOverTime: ProjectTestSuiteSizeOverTimeQuery | undefined,
  queryRunning: boolean
) => {
  let kpis
  let loading = queryRunning
  if (testSuiteSizeOverTime) {
    const metrics = testSuiteSizeOverTime.metrics.projectTestSuiteSizeOverTime
    loading = queryRunning && !metrics

    kpis = [
      {
        name: 'Total test cases',
        value: metrics.totalTests,
        deltaChange: metrics.totalTestsDeltaChange,
        loading,
      },
      {
        name: 'Spec files',
        value: metrics.totalSpecs,
        deltaChange: metrics.specFilesDeltaChange,
        loading,
      },
    ]
  } else {
    kpis = [
      {
        name: 'Total test cases',
        loading,
      },
      {
        name: 'Spec files',
        loading,
      },
    ]
  }
  return kpis
}

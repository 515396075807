import React, { FunctionComponent } from 'react'

type TestHistoryDisabledProps = {
  isPlural?: boolean
}

export const TestHistoryDisabled: FunctionComponent<
  TestHistoryDisabledProps
> = ({ isPlural = false }) => {
  return (
    <div className="test-history__no-git-info">
      <div>
        Provide git commit information to the Cypress app to learn how
        {isPlural ? ' your test cases are ' : ' this test case is '}
        evolving.
      </div>
      <div>
        <a
          href="https://on.cypress.io/git-info"
          target="_blank"
          data-pendo="test-history-learn-more"
        >
          Learn more
        </a>
      </div>
    </div>
  )
}

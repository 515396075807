import { CustomIcon } from '@frontend/design-system'
import React, { FunctionComponent } from 'react'
import { MultiSelectFilter } from '~/common/filters'
import { MultiSelectOptionType } from '../../types'

type ProjectRunGroupsFilterProps = {
  id: string
  state: ProjectRunGroupsFilterStateProp
}

type ProjectRunGroupsFilterStateProp = {
  search: string | undefined
  loading: boolean
  options: MultiSelectOptionType[] | undefined
  selected: MultiSelectOptionType[] | undefined
  setSearch: (value: string) => void
  setLoading: (value: boolean) => void
  setSelected: (value: []) => void
}

export const ProjectRunGroupsFilter: FunctionComponent<ProjectRunGroupsFilterProps> =
  React.memo(({ id, state }) => {
    const {
      options = [],
      setSearch,
      loading,
      setLoading,
      selected = [],
      setSelected,
    } = state

    const onInputChange = () => setLoading(true)
    return (
      <div data-pendo="run-groups-filter" className="run-groups-filter">
        <MultiSelectFilter
          id={id}
          title={<CustomIcon name="run-group" alt="Run group" />}
          emptyLabel="Run Group"
          onChange={setSelected}
          isLoading={loading}
          onInputChange={onInputChange}
          onSearch={setSearch}
          options={options}
          placeholder="Search for a run group..."
          selected={selected}
        />
      </div>
    )
  })

import React, { FunctionComponent, ReactNode } from 'react'
import gql from 'graphql-tag'
import { isTestReplayModalPresentable } from '~/common/test-replay/testReplayUtils'
import { TestReplayOverlayFragment } from '../../graphql-codegen-operations.gen'
import OverlayCTA from '../overlay-cta/OverlayCTA'
import { CHROMIUM_BROWSERS } from '@packages/common'

gql`
  fragment TestReplayOverlay on TestResult {
    id
    state
    capture {
      status
    }
    run {
      id
      ...RunArtifactControls
      configTestReplayEnabled
      cypressVersion
    }
    instance {
      browser {
        formattedName
      }
    }
  }
`

type TestReplayOverlayProps = {
  frag?: TestReplayOverlayFragment | null
  tooltipDisabled?: boolean
  children: ReactNode
}

export const TestReplayOverlay: FunctionComponent<TestReplayOverlayProps> = ({
  frag,
  tooltipDisabled,
  children,
}) => {
  if (!frag) {
    return null
  }

  const canPresentTestReplay = isTestReplayModalPresentable(frag.capture)

  if (canPresentTestReplay || tooltipDisabled) {
    // When the replay can be presented, we do not present
    // any supportive tooltips
    return children as any
  }

  const title = 'Test Replay is not available'
  let message = (
    <span>There was an error uploading the Test Replay for this test.</span>
  )
  let learnMoreUri: string | undefined =
    'https://on.cypress.io/replay-troubleshoot'

  if (frag.capture?.status !== 'ERRORED') {
    // An errored capture will present the default tooltip messaging.
    // If the capture status isn't errored, or the capture doesn't otherwise exist,
    // we perform additional checks for more granular error messaging.

    if (!frag.run.configTestReplayEnabled) {
      message = <span>Test Replay was disabled for this run.</span>
      learnMoreUri = 'https://on.cypress.io/replay-opt-out'
    } else if (parseFloat(frag.run.cypressVersion) < 13) {
      message = (
        <span>
          Test Replay is not supported in Cypress {frag.run.cypressVersion}.
          Upgrade to Cypress 13.0.0 or above to use Test Replay.
        </span>
      )
    } else if (
      !CHROMIUM_BROWSERS.includes(
        frag.instance.browser.formattedName.toLowerCase()
      )
    ) {
      message = (
        <span>
          Test Replay is not supported in {frag.instance.browser.formattedName}.
        </span>
      )
    } else if (frag.state === 'ERRORED') {
      message = <span>This test errored.</span>
      learnMoreUri = undefined
    } else if (frag.state === 'RUNNING') {
      message = <span>This test is running.</span>
      learnMoreUri = undefined
    } else if (frag.state === 'PENDING') {
      message = <span>This test is pending.</span>
      learnMoreUri = undefined
    } else if (frag.state === 'CANCELLED') {
      message = <span>This test was canceled.</span>
      learnMoreUri = undefined
    } else if (frag.state === 'SKIPPED') {
      message = <span>This test was skipped.</span>
      learnMoreUri = undefined
    } else if (frag.state === 'TIMEDOUT') {
      message = <span>This test timed out.</span>
      learnMoreUri = undefined
    }
  }

  return (
    <OverlayCTA
      placement="bottom"
      title={title}
      description={message}
      learnMoreUri={learnMoreUri}
      hideButton
    >
      <div>{children}</div>
    </OverlayCTA>
  )
}

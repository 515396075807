import { useMemo, useState } from 'react'
import { sortBy, groupBy } from 'lodash'
import { palette } from '@frontend/design-system'
import { ProjectMostCommonErrorsQuery } from '~/graphql-codegen-operations.gen'
import { getTotalFailures } from './utils'
import { ColorBreakDownSectionType } from '~/common/color-breakdown/types'

const colors = [
  { color: palette.purple500, hoverColor: palette.purple600 },
  { color: palette.jade300, hoverColor: palette.jade400 },
  { color: palette.orange300, hoverColor: palette.orange400 },
  { color: palette.indigo500, hoverColor: palette.indigo600 },
  { color: palette.red400, hoverColor: palette.red500 },
]

const getPercentageOfFailures = (failures: number, totalFailures: number) =>
  Number(failures / totalFailures) * 100

export const useMostCommonErrors = (
  data?: ProjectMostCommonErrorsQuery
): [ColorBreakDownSectionType[], string[], { [key: string]: string }] => {
  const [mostCommonErrors, setMostCommonErrors] = useState<string[]>([])
  const [errorColors, setErrorColors] = useState<{ [key: string]: string }>({})

  const errors = useMemo(() => {
    const result: ColorBreakDownSectionType[] = []

    const nodes = data?.metrics.projectCommonErrors.nodes || []
    const totalFailuresForAllErrors = getTotalFailures(
      data?.metrics.projectCommonErrors.nodes || []
    )

    const groupedErrors = sortBy(
      groupBy(nodes, 'error.name'),
      getTotalFailures
    ).reverse()

    const commonErrorNames: string[] = []
    let percentageTotal = 0

    for (let i = 0; i < groupedErrors.length; ++i) {
      if (i >= 4) {
        break
      }

      const errorGroup = groupedErrors[i]
      const totalFailures = getTotalFailures(errorGroup)
      const errorName = errorGroup[0].error.name

      let percentage = getPercentageOfFailures(
        totalFailures,
        totalFailuresForAllErrors
      )

      commonErrorNames.push(errorName)

      if (i === groupedErrors.length - 1) {
        percentage = 100 - percentageTotal
      } else {
        percentageTotal += percentage
      }

      result.push({
        ...colors[i],
        id: errorName,
        total: totalFailures,
        percentage,
      })
    }

    let allOtherFailures = 0

    for (let i = 4; i < groupedErrors.length; ++i) {
      const errorGroup = groupedErrors[i]
      const totalFailures = getTotalFailures(errorGroup)

      allOtherFailures += totalFailures
    }

    if (allOtherFailures) {
      result.push({
        total: allOtherFailures,
        percentage: 100 - percentageTotal,
        id: 'Other',
        ...colors[4],
      })
    }

    setMostCommonErrors(commonErrorNames)

    const errorColors = {}

    commonErrorNames.forEach((name, i) => {
      errorColors[name] = colors[i].color
    })

    setErrorColors(errorColors)

    return result
  }, [data])

  return [errors, mostCommonErrors, errorColors]
}

import { isFuture, parseISO } from 'date-fns'
import React, { FunctionComponent, useEffect, useState } from 'react'

import {
  useAppBannersQuery,
  AppBannerOrgFragment,
} from '~/graphql-codegen-operations.gen'
import { GracePeriodBanner } from '~/lib/GracePeriodBanner'
import { useLocation } from '~/lib/hooks/useLocation'
import { userIsMemberOfOrg } from '~/lib/roles'
import { useSidebarService } from '~/navs/sidebarService'
import { OverRecordingsNotice } from '~/notifications/OverRecordingsNotice'

type OrgBannersProps = {
  org: AppBannerOrgFragment
}

const OrgBanners: FunctionComponent<OrgBannersProps> = ({ org }) => {
  const [gracePeriodBannerClosedByUser, setGracePeriodBannerClosedByUser] =
    useState(false)
  const { location } = useLocation()

  useEffect(() => {
    const gracePeriodBannerHidden = JSON.parse(
      localStorage.getItem('hideGracePeriodBanner') || 'null'
    )

    if (gracePeriodBannerHidden) {
      setGracePeriodBannerClosedByUser(true)
    }
  }, [setGracePeriodBannerClosedByUser])

  const isOnGracePeriod = isFuture(parseISO(org.gracePeriodUntil))

  const isOnOrgPage = location.pathname.startsWith('/organizations')
  const isOnProjectPage = location.pathname.startsWith('/projects')
  const isOnBillingPage =
    location.pathname.endsWith('billing') ||
    location.pathname.endsWith('pricing')

  const shouldShowGracePeriodBanner =
    location &&
    isOnGracePeriod &&
    (isOnOrgPage || isOnProjectPage) &&
    !gracePeriodBannerClosedByUser &&
    !isOnBillingPage

  const shouldShowOverageBanner =
    location &&
    (isOnOrgPage || isOnProjectPage) &&
    !isOnBillingPage &&
    !shouldShowGracePeriodBanner

  const closeGracePeriodBanner = () => {
    localStorage.setItem('hideGracePeriodBanner', 'true')
    setGracePeriodBannerClosedByUser(true)
  }

  return (
    <>
      {org && shouldShowOverageBanner && (
        <OverRecordingsNotice asBanner orgId={org.id} />
      )}

      {org && shouldShowGracePeriodBanner && (
        <GracePeriodBanner closeBanner={closeGracePeriodBanner} org={org} />
      )}
    </>
  )
}

export const AppBanners: FunctionComponent = () => {
  const [sidebarState] = useSidebarService()

  const orgId = sidebarState.data?.currentOrg?.id
  const shouldSkip = !orgId || !userIsMemberOfOrg(orgId)

  const { data } = useAppBannersQuery({
    variables: {
      organizationId: orgId,
    },
    skip: shouldSkip,
  })

  if (!data || shouldSkip) {
    return null
  }

  return (
    <div className="app--banners">
      {orgId && <OrgBanners org={data.organization} />}
    </div>
  )
}

import getSymbolFromCurrency from 'currency-symbol-map'
import {
  endOfDay,
  format,
  isSameDay,
  parseISO,
  startOfDay,
  subDays,
} from 'date-fns'
import escapeHTML from 'escape-html'
import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import pluralize from 'pluralize'
import React from 'react'
import Cookies from 'js-cookie'

export { pluralize }

const titleDivider = ' /// '

const osIconLookup = {
  win32: 'windows',
  darwin: 'apple',
  mac: 'apple',
  linux: 'linux',
}

const invoiceStatusLookup = {
  paid: 'check green',
  posted: 'money green',
  payment_due: 'money green',
  not_paid: 'ban red',
  voided: 'ban text-muted',
  pending: 'history text-muted',
}

/**
 * Returns a Date object that represents a UTC timestamp as if it was in the client's timezone
 */
export const getUTCDateAsLocalDate = (milliseconds) => {
  const localDate = new Date(milliseconds)
  const UTCDateComponents = moment
    .utc(localDate.toISOString())
    .format('YYYY M D H m s')
    .split(' ')

  const UTCYear = Number(UTCDateComponents[0])
  const UTCMonth = Number(UTCDateComponents[1] - 1)
  const UTCDay = Number(UTCDateComponents[2])
  const UTCHours = Number(UTCDateComponents[3])
  const UTCMinutes = Number(UTCDateComponents[4])
  const UTCSeconds = Number(UTCDateComponents[5])

  return new Date(UTCYear, UTCMonth, UTCDay, UTCHours, UTCMinutes, UTCSeconds)
}

const getTodayUTCDate = () => getUTCDateAsLocalDate(new Date().getTime())

const epochUTCDate = getUTCDateAsLocalDate(0)
const endOfEpochUTCDate = getUTCDateAsLocalDate(2147483647000)

export const staticUTCDates = {
  getEpoch: () => endOfDay(epochUTCDate),
  getEndOfEpoch: () => startOfDay(endOfEpochUTCDate),
  getStartOf1YearAgo: () => startOfDay(subDays(getTodayUTCDate(), 365)),
  getStartOf90DaysAgo: () => startOfDay(subDays(getTodayUTCDate(), 90)),
  getStartOf30DaysAgo: () => startOfDay(subDays(getTodayUTCDate(), 30)),
  getStartOf7DaysAgo: () => startOfDay(subDays(getTodayUTCDate(), 7)),
  getStartOfToday: () => startOfDay(getTodayUTCDate()),
  getEndOfToday: () => endOfDay(getTodayUTCDate()),
}

export const formatDate = (date) => (_.isString(date) ? parseISO(date) : date)

const staticRangeHandler = {
  isSelected(range) {
    const definedRange = this.range()

    if (definedRange.id && range.id) {
      return definedRange.id === range.id
    }

    return (
      isSameDay(formatDate(range.startDate), definedRange.startDate) &&
      isSameDay(formatDate(range.endDate), definedRange.endDate)
    )
  },
}

export function createStaticRanges(ranges) {
  return ranges.map((range) => ({ ...staticRangeHandler, ...range }))
}

const DateRanges = [
  {
    label: 'Last 7 Days',
    range: () => ({
      id: 'last_7_days',
      startDate: staticUTCDates.getStartOf7DaysAgo(),
      endDate: staticUTCDates.getEndOfToday(),
    }),
  },
  {
    label: 'Last 30 Days',
    range: () => ({
      id: 'last_30_days',
      startDate: staticUTCDates.getStartOf30DaysAgo(),
      endDate: staticUTCDates.getEndOfToday(),
    }),
  },
  {
    label: 'Last 3 Months',
    range: () => ({
      id: 'last_3_months',
      startDate: staticUTCDates.getStartOf90DaysAgo(),
      endDate: staticUTCDates.getEndOfToday(),
    }),
  },
  {
    label: 'Last 12 Months',
    range: () => ({
      id: 'last_12_months',
      startDate: staticUTCDates.getStartOf1YearAgo(),
      endDate: staticUTCDates.getEndOfToday(),
    }),
  },
]

export const staticUTCDateRanges = createStaticRanges(DateRanges)

export const todayUTCDateRange = createStaticRanges([
  {
    label: 'Today',
    range: () => ({
      id: 'today',
      startDate: staticUTCDates.getStartOfToday(),
      endDate: staticUTCDates.getEndOfToday(),
    }),
  },
])

export const allTimeUTCDateRange = createStaticRanges([
  {
    label: 'All Time',
    range: () => ({
      id: 'all_time',
      startDate: staticUTCDates.getEpoch(),
      endDate: staticUTCDates.getEndOfEpoch(),
    }),
  },
])

export const normalizeTestTitle = (title) => {
  // transform string w/ '///' into array
  return title.split(titleDivider)
}

export const getInlineTestTitle = (testTitle) => {
  if (!testTitle) {
    return ''
  }

  if (testTitle && _.isString(testTitle)) {
    testTitle = normalizeTestTitle(testTitle)
  }

  return testTitle.join(' > ')
}

export const isEnv = (env) => window.env === env
export const isLocalhost = () =>
  window.location.hostname === 'localhost' ||
  window.location.hostname === '0.0.0.0'
export const isDeployedEnv = () => isEnv('production') || isEnv('staging')
export const isFeatureEnabled = (location, feature) => {
  const { query } = location

  if (query[feature]) {
    return query[feature] === 'true'
  }

  return !isDeployedEnv()
}

export const addLeadingElipsis = (str) => {
  return '...'.concat(str)
}

export const addTrailingElipsis = (str) => {
  return str.concat('...')
}

export const osIcon = (osName) => {
  if (!osName) return ''

  return osIconLookup[osName] || 'desktop'
}

export const invoiceStatusIcon = (invoiceStatus) => {
  if (!invoiceStatus) return ''

  return invoiceStatusLookup[invoiceStatus] || ''
}

export const getStatusContent = (status) => {
  switch (status) {
    case 'overLimit':
      return 'Over Plan Limit'
    default:
      return _.startCase(status)
  }
}

export const getDateRangeLabel = (staticRanges, date) => {
  const dateFormat = 'MMM d, yyyy'

  const predefinedDateRange = staticRanges.find((range) =>
    range.isSelected(date)
  )

  if (predefinedDateRange) {
    return predefinedDateRange.label
  }

  const formattedStartDate = format(date.startDate, dateFormat)
  const formattedEndDate = format(date.endDate, dateFormat)

  if (formattedStartDate === formattedEndDate) {
    return formattedStartDate
  }

  return `${formattedStartDate} - ${formattedEndDate}`
}

export const symbolFromCurrencyCode = (code) => {
  if (!code) return ''

  return getSymbolFromCurrency(code) || code
}

export const standardDateFormatted = (date) => {
  const isThisYear = moment().isSame(date, 'year')

  return moment(date).format(`MMM D${isThisYear ? '' : ', YYYY'}`)
}

export const longDateFormatted = (date) => {
  return moment(date).format('MMM D, YYYY [at] hh:mma')
}

export const daysUntil = (date) => {
  return moment(date).fromNow()
}

export const convertCentsToDollarsWithDecimals = (amount) => {
  if (!_.isFinite(amount)) return ''

  // convert to dollars & add ',' at thousandths
  return numeral(amount * 0.01).format('0,0.00')
}

export const convertCentsToWholeDollars = (amount) => {
  if (!_.isFinite(amount)) return ''

  // convert to dollars & add ',' at thousandths
  return numeral(amount * 0.01).format('0,0')
}

export const durationFormattedLong = (durationInMs) => {
  const duration = moment.duration(durationInMs)

  if (duration.years()) {
    return `${duration.years()} ${pluralize('year', duration.years())}`
  }

  if (duration.months()) {
    return `${duration.months()} ${pluralize('month', duration.months())}`
  }

  if (duration.days()) {
    return `${duration.days()} ${pluralize('day', duration.days())}`
  }

  if (duration.hours()) {
    return `${duration.hours()} ${pluralize('hour', duration.hours())}`
  }

  if (duration.minutes()) {
    return `${duration.minutes()} ${pluralize('minute', duration.minutes())}`
  }

  if (duration.seconds()) {
    return `${duration.seconds()} ${pluralize('second', duration.seconds())}`
  }

  return `${duration.milliseconds()} ${pluralize(
    'millisecond',
    duration.milliseconds()
  )}`
}

/**
 *
 * @param {number | moment.Duration} durationInMs
 * @param {number | null | undefined} inclusion When zero or positive: establish max amount of segments to preserve from results starting from largest. If negative: establish what units to ignore, starting from smallest. Includes all segments if no inclusion is specified.
 * @param {boolean} compact If date strings should be shortened
 * @returns {string}
 *
 * @example Positive "inclusion":
 * durationFormattedFull(6166000, 2) // 1 hour, 42 minutes
 * durationFormattedFull(6166000) // 1 hour, 42 minutes, 46 seconds
 * durationFormattedFull(6166000, 2, true) // 1h 42m
 * durationFormattedFull(6166350, 0) // '' (empty string)
 *
 * @example Negative "inclusion":
 * durationFormattedFull(6166350, undefined, true) // 1h 42m 46s 350ms
 * durationFormattedFull(6166350, -1, true) // 1h 42m 46s
 * durationFormattedFull(6166350, -2, true) // 1h 42m
 * durationFormattedFull(6166350, -3, true) // 1h
 *
 * @example Important detail about negative "inclusion": it only removes segments
 * based on unit type, not actual results. So in these cases we are simply
 * establishing that secs and milliseconds never be shown:
 *
 * durationFormattedFull(6166000, -1, true) // 1h 42m
 * durationFormattedFull(6166000, -2, true) // 1h 42m
 */
export const durationFormattedFull = (
  durationInMs,
  inclusion,
  compact = false
) => {
  let durationComponents = []
  const duration = moment.duration(durationInMs)

  const all = typeof inclusion !== 'number'

  const includeMilliseconds = (all || inclusion > -1) && duration.milliseconds()
  const includeSeconds = (all || inclusion > -2) && duration.seconds() > 0
  const includeMinutes = (all || inclusion > -3) && duration.minutes() > 0
  const includeHours = (all || inclusion > -4) && duration.hours() > 0
  const includeDays = (all || inclusion > -5) && duration.days() > 0
  const includeMonths = (all || inclusion > -6) && duration.months() > 0

  if (Math.floor(duration.asYears())) {
    if (compact) {
      durationComponents.push(`${duration.years()}y`)
    } else {
      durationComponents.push(
        `${duration.years()} ${pluralize('year', duration.years())}`
      )
    }
  }

  if (
    Math.floor(duration.asMonths()) &&
    (includeMonths ||
      includeDays ||
      includeHours ||
      includeMinutes ||
      includeSeconds ||
      includeMilliseconds)
  ) {
    if (compact) {
      durationComponents.push(`${duration.months()}mo`)
    } else {
      durationComponents.push(
        `${duration.months()} ${pluralize('month', duration.months())}`
      )
    }
  }

  if (
    Math.floor(duration.asDays()) &&
    (includeDays ||
      includeHours ||
      includeMinutes ||
      includeSeconds ||
      includeMilliseconds)
  ) {
    if (compact) {
      durationComponents.push(`${duration.days()}d`)
    } else {
      durationComponents.push(
        `${Math.floor(duration.days())} ${pluralize(
          'day',
          Math.floor(duration.days())
        )}`
      )
    }
  }

  if (
    Math.floor(duration.asHours()) &&
    (includeHours || includeMinutes || includeSeconds || includeMilliseconds)
  ) {
    if (compact) {
      durationComponents.push(`${duration.hours()}h`)
    } else {
      durationComponents.push(
        `${duration.hours()} ${pluralize('hour', duration.hours())}`
      )
    }
  }

  if (
    Math.floor(duration.asMinutes()) &&
    (includeMinutes || includeSeconds || includeMilliseconds)
  ) {
    if (compact) {
      durationComponents.push(`${duration.minutes()}m`)
    } else {
      durationComponents.push(
        `${duration.minutes()} ${pluralize('minute', duration.minutes())}`
      )
    }
  }

  if (
    Math.floor(duration.asSeconds()) &&
    (includeSeconds || includeMilliseconds)
  ) {
    if (compact) {
      durationComponents.push(`${duration.seconds()}s`)
    } else {
      durationComponents.push(
        `${duration.seconds()} ${pluralize('second', duration.seconds())}`
      )
    }
  }

  if (includeMilliseconds) {
    if (compact) {
      durationComponents.push(`${Math.round(duration.milliseconds())}ms`)
    } else {
      durationComponents.push(
        `${Math.round(duration.milliseconds())} ${pluralize(
          'millisecond',
          Math.round(duration.milliseconds())
        )}`
      )
    }
  }

  if (typeof inclusion === 'number' && inclusion >= 0) {
    durationComponents = durationComponents.slice(0, inclusion)
  }

  if (compact) {
    return durationComponents.join(' ')
  }

  return durationComponents.join(', ')
}

export const formatDurationInMinutes = (durationInMs) => {
  const duration = moment.duration(durationInMs)

  const includeMinutes = duration.minutes() > 0

  let formattedString = ``
  if (includeMinutes) {
    formattedString = `${duration.minutes()} ${pluralize(
      'minute',
      duration.minutes()
    )}`
  } else {
    formattedString = '1 minute'
  }

  return formattedString
}

export const average = (val, totalCount) => {
  if (!val || !totalCount) return null

  return Math.round(val / totalCount)
}

export const getPreconditionMessages = (preconditions = {}, options = {}) => {
  return _.chain(preconditions)
    .pick(_.keys(options))
    .map((value, key) => {
      let precondition = options[key]

      return {
        key,
        message: precondition.onMessage(value),
        requiresCheck: precondition.requiresCheck,
      }
    })
    .value()
}

export function formatTestTitle(title) {
  if (!title) return null

  let titleArray

  if (_.isString(title)) {
    titleArray = normalizeTestTitle(title)
  } else {
    titleArray = title
  }

  return _.map(titleArray, (title, i, titleArray) => {
    return (
      <span key={i}>
        {title}
        {i < titleArray.length - 1 ? (
          <i key={i} className="fa fa-chevron-right"></i>
        ) : null}
      </span>
    )
  })
}

export function getTestCaption(name, testTitle) {
  const joinedTestTitle = getInlineTestTitle(testTitle)

  return escapeHTML(`
    ${joinedTestTitle ? joinedTestTitle : ''}
    ${name && testTitle ? ' | ' : ''}
    ${name ? name : ''}
  `)
}

export function truncateWordByChars(
  word,
  maxCharsLength,
  truncateDirection = 'trailing'
) {
  if (word.length <= maxCharsLength) return word

  let truncatedWord = ''

  if (truncateDirection === 'trailing') {
    truncatedWord = word.slice(0, maxCharsLength)

    return addTrailingElipsis(truncatedWord)
  }

  truncatedWord = word.slice(word.length - maxCharsLength, word.length)

  return addLeadingElipsis(truncatedWord)
}

export function getAmountDifference(amount, potentialAmount) {
  // This is used to show estimate versus actual run for
  // each spec during a run
  if (amount === potentialAmount) {
    return {
      direction: 'same',
      percent: 0,
      duration: 0,
    }
  }

  if (amount < potentialAmount) {
    return {
      direction: 'slower',
      percent: Math.floor((potentialAmount / amount - 1) * 100),
      duration: potentialAmount - amount,
    }
  }

  return {
    direction: 'faster',
    percent: Math.floor((amount / potentialAmount - 1) * 100),
    duration: amount - potentialAmount,
  }
}

export function getAmountDifferenceForRecommendation(amount, potentialAmount) {
  if (amount === potentialAmount) {
    return {
      direction: '',
      percent: 0,
      duration: 0,
    }
  }

  if (amount < potentialAmount) {
    return {
      direction: 'adds',
      percent: Math.floor((1 - potentialAmount / amount) * 100),
      duration: potentialAmount - amount,
    }
  }

  return {
    direction: 'saves',
    percent: Math.floor((1 - potentialAmount / amount) * 100),
    duration: amount - potentialAmount,
  }
}

export function inGracePeriod(org) {
  if (!org) return false

  if (org.customGracePeriod) {
    return moment().isSameOrBefore(org.gracePeriodEndsAt)
  }

  if (org.gracePeriodUntil) {
    return moment().isSameOrBefore(org.gracePeriodUntil)
  }
}

export function reachedUserLimit(org, filledSeats, totalSeats) {
  if (inGracePeriod(org)) {
    // if they are in grace period, do not restrict
    return false
  }

  if (!totalSeats || !filledSeats) {
    // if missing values or user limit is null (unlimited), then limit not reached
    return false
  }

  return filledSeats >= totalSeats
}

export function storeUtmParamsOnCookie(qs, overrideCookie = true) {
  const {
    utm_source,
    utm_medium,
    utm_campaign,
    utm_id,
    utm_term,
    utm_content,
  } = qs

  // remove any undefined values
  const utmAsJson = JSON.stringify({
    utm_source,
    utm_medium,
    utm_campaign,
    utm_id,
    utm_term,
    utm_content,
  })

  const numDefinedUtmParams = Object.keys(JSON.parse(utmAsJson)).length
  // if there are 0 utm params, we don't want to override any existing cookie
  if (overrideCookie && numDefinedUtmParams > 0) {
    Cookies.set('utm_params', utmAsJson)
  }
}

import { ProgressBar, Tooltip } from '@frontend/design-system'
import React, { FunctionComponent } from 'react'

interface AnalyticsProgressBarProps {
  rate: number | null
  includeRate?: boolean
  className?: string
  includeTooltip?: boolean
}

const AnalyticsProgressBar: FunctionComponent<AnalyticsProgressBarProps> = ({
  rate,
  includeRate = true,
  className,
  includeTooltip,
}) => {
  const roundedRate = rate?.toFixed(2).replace(/\.00$/, '')
  const progressBarClassName = className ? className : 'progress-bar-danger'

  return (
    <div className="analytics-progress-bar">
      {includeRate && (
        <div className="analytics-progress-bar__percentage">
          {`${Math.round(rate || 0)}%`}
        </div>
      )}
      {includeTooltip ? (
        <Tooltip
          placement="top"
          overlay={`${roundedRate}%`}
          overlayClassName="analytics-progress-bar__tooltip"
        >
          <ProgressBar>
            <ProgressBar className={progressBarClassName} now={rate || 0} />
          </ProgressBar>
        </Tooltip>
      ) : (
        <ProgressBar>
          <ProgressBar className={progressBarClassName} now={rate || 0} />
        </ProgressBar>
      )}
    </div>
  )
}

export { AnalyticsProgressBar }

import { RouteComponentProps } from '@reach/router'
import * as React from 'react'

import { useSidebarService } from './navs/sidebarService'

export const NotFound: React.FC<RouteComponentProps> = () => {
  const [, sidebarStateDispatch] = useSidebarService()

  React.useEffect(() => {
    sidebarStateDispatch({ type: 'hideSidebar' })
    return () => sidebarStateDispatch({ type: 'showSidebar' })
  }, [sidebarStateDispatch])

  return (
    <div id="not-found">
      <div className="login-img-wrapper">
        <img src="/img/cypress-logo.svg" alt="" />
      </div>
      <div className="not-found-content">
        <h1>404</h1>
        <h2>Whoops, we couldn't find this page.</h2>
        <a href="https://on.cypress.io/cloud">Go back to the cloud</a>
      </div>
    </div>
  )
}

export default NotFound

import React from 'react'
import Button from '@cypress-design/react-button'
import styles from './module.GroupList.scss'
import { Link } from '@reach/router'

type ViewDataExtractApiProps = {
  orgId: string
}

export const ViewDataExtractApi: React.FunctionComponent<
  ViewDataExtractApiProps
> = ({ orgId }) => {
  return (
    <div className={styles.actionButtons}>
      <Link to={`/organizations/${orgId}/integrations`}>
        <Button>View Data Extract API</Button>
      </Link>
    </div>
  )
}

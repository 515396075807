import { CustomIcon } from '@frontend/design-system'
import React, { FunctionComponent } from 'react'

import { MultiSelectFilter } from '../MultiSelectFilter'
import { MultiSelectOptionType, onMultiSelectFilterChange } from '../types'

type TestResultFlakyFilterProps = {
  id: string
  onChange: onMultiSelectFilterChange
  selected: MultiSelectOptionType[]
}

export const TestResultFlakyFilter: FunctionComponent<TestResultFlakyFilterProps> =
  React.memo(({ id, onChange, selected }) => {
    const options = [
      {
        label: 'Flaky',
        value: true,
      },
      {
        label: 'Not flaky',
        value: false,
      },
    ]

    return (
      <MultiSelectFilter
        id={id}
        title={<CustomIcon name="flaky" alt="Flaky" />}
        emptyLabel="Flaky Tests"
        isSearchable={false}
        selected={selected}
        onChange={onChange}
        options={options}
      />
    )
  })

import { useCallback, useEffect, useMemo } from 'react'
import * as atoms from '~/data/projects/analytics/atoms'
import * as selectors from '~/data/projects/analytics/selectors'
import globalAtoms from '~/data/global/atoms'
import {
  BrowserInfo,
  GroupNameNode,
  OperatingSystem,
  ProjectBranchNode,
  ProjectCommitter,
  ProjectSpecs,
  RunStatusEnum,
  RunTag,
  TagsMatchEnum,
  useProjectAnalyticsFilterDefaultsInitializationQuery,
  useProjectAnalyticsFilterOptionsInitializationQuery,
  useProjectBranchesQuery,
  useProjectCommittersQuery,
  useProjectRunGroupsQuery,
  useProjectSimulatedTimeSavingsLazyQuery,
  useProjectSimulatedTimeSavingsQuery,
  useProjectTagsQuery,
  useSetTagColorMutation,
  useTestReplayByTestIdQuery,
  ViewByEnum,
} from '~/graphql-codegen-operations.gen'
import { readableRunStatusEnum } from '~/lib/utils-ts'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  getFiltersFromQueryString,
  getMultiSelectOptionsFromValues,
  getQueryStringFromFilters,
  MultiSelectOptionType,
  removeNullifiedFilters,
  timeRangeAsInput,
} from '~/common/filters'
import { sendEventCustom } from '~/lib/page-events'
import { useLocation, useNavigate } from '@reach/router'
import { isEqual, uniqBy, pick } from 'lodash'
import { hasAtLeastSameKeys } from '~/data/lib/utilities'
import { stripLeadingCyDirsByRegex } from '@packages/common'
import {
  AnalyticsFilters,
  ProjectAnalyticsFilterDefaultsInitialization,
  ProjectAnalyticsFilterOptionsInitialization,
} from './types'
import { features } from '~/lib/feature-flags'
import { durationFormattedFull } from '~/lib/utils'
import { useDebounce } from 'use-debounce/lib'
import qs from 'query-string'
import { REPLAY_PARAMS } from '@frontend/test-replay/src/components/Context/UrlContext/useSearchParams'
import type { TestReplayParamKeys } from '@frontend/test-replay/src/components/Context/UrlContext/useSearchParams'

export const getProjectAnalyticsFamilyId = (
  projectId: string,
  groupId: number
) => `project[${projectId}]analytics[${groupId}]`

// Util
const transformOptions = <T, O>(items: T[], fn: (a: T) => O): O[] => {
  return items ? items.map(fn) : []
}

const cleanBranches = (
  items: Pick<ProjectBranchNode, 'name'>[]
): MultiSelectOptionType[] => {
  return transformOptions(items, (item) => {
    return {
      label: item.name,
      suggested: false,
      value: item.name,
    }
  })
}

const cleanTags = (
  items: RunTag[],
  editing: boolean
): MultiSelectOptionType<string, { color: string }>[] => {
  return transformOptions(items, (item) => {
    return {
      disabled: editing,
      label: item.name,
      labelProperties: { color: item.color },
      suggested: false,
      value: item.name,
    }
  })
}

const cleanRunGroups = (
  items: Pick<GroupNameNode, 'name'>[]
): MultiSelectOptionType[] => {
  return transformOptions(items, (item) => {
    return {
      label: item.name || 'Default group',
      suggested: false,
      value: item.name || 'Default group',
    }
  })
}

const cleanCommitters = (
  items: ProjectCommitter[]
): MultiSelectOptionType[] => {
  return transformOptions(items, (item) => {
    return {
      label: item.name,
      suggested: false,
      labelProperties: { avatar: item.avatar },
      value: item.email,
    }
  })
}

const cleanSpecFiles = (items: ProjectSpecs[]): MultiSelectOptionType[] => {
  return transformOptions<ProjectSpecs, MultiSelectOptionType>(
    items,
    (item) => {
      return {
        label: stripLeadingCyDirsByRegex(item.specPath),
        suggested: false,
        value: item.specHash,
      }
    }
  )
}

const cleanBrowsers = (
  items: Pick<
    BrowserInfo,
    'unformattedName' | 'formattedName' | 'formattedNameWithVersion'
  >[]
): MultiSelectOptionType[] => {
  const uniqueItems = uniqBy(items, 'formattedNameWithVersion')
  return transformOptions(uniqueItems, (item) => {
    return {
      label: item.formattedNameWithVersion,
      labelProperties: {
        unformattedName: item.unformattedName,
      },
      value: item.formattedNameWithVersion,
    }
  })
}

const cleanCypressVersions = (items: string[]): MultiSelectOptionType[] => {
  return transformOptions(items, (item) => {
    return {
      label: item,
      value: item,
    }
  })
}

const cleanOperatingSystems = (
  items: Pick<OperatingSystem, 'nameWithVersion' | 'unformattedName'>[]
): MultiSelectOptionType[] => {
  return transformOptions(items, (item) => {
    return {
      label: item.nameWithVersion,
      labelProperties: { unformattedName: item.unformattedName },
      value: item.nameWithVersion,
    }
  })
}

const cleanStatuses = (items: RunStatusEnum[]): MultiSelectOptionType[] => {
  return transformOptions(items, (item) => {
    return {
      label: readableRunStatusEnum(item),
      value: item,
    }
  })
}
//// Setup Event Handling
// BRANCHES
export const useBranchesFilter = (familyId) => {
  const [search, setSearch] = useRecoilState(atoms.branchesSearch(familyId))
  const [loading, setLoading] = useRecoilState(
    atoms.branchesSearchLoading(familyId)
  )
  const [selected, setSelected] = useRecoilState(
    atoms.branchesSelected(familyId)
  )
  const [options, setOptions] = useRecoilState(atoms.branchesOptions(familyId))
  const [defaults, setDefaults] = useRecoilState(
    atoms.branchesDefault(familyId)
  )
  return {
    selected,
    setSelected,
    options,
    setOptions,
    search,
    setSearch,
    loading,
    setLoading,
    defaults,
    setDefaults,
  }
}

export const useTimeRangeFilter = (familyId) => {
  const [timeRange, setTimeRange] = useRecoilState(atoms.timeRange(familyId))

  return {
    timeRange,
    setTimeRange: (timeRange) => setTimeRange(timeRangeAsInput(timeRange)),
  }
}

export const useTimeIntervalFilter = (familyId) => {
  const [timeInterval, setTimeInterval] = useRecoilState(
    atoms.timeInterval(familyId)
  )
  return { timeInterval, setTimeInterval }
}

export const useBuildIntervalFilter = (familyId) => {
  const [buildInterval, setBuildInterval] = useRecoilState(
    atoms.buildInterval(familyId)
  )
  return { buildInterval, setBuildInterval }
}

// TAGS
export const useTagsFilter = (familyId) => {
  const [selected, setSelected] = useRecoilState(atoms.tagsSelected(familyId))
  const [options, setOptions] = useRecoilState(atoms.tagsOptions(familyId))
  const [search, setSearch] = useRecoilState(atoms.tagsSearch(familyId))
  const [loading, setLoading] = useRecoilState(
    atoms.tagsSearchLoading(familyId)
  )
  const [editing, setEditing] = useRecoilState(atoms.tagsEditing(familyId))
  const [editorInfo, setEditorInfo] = useRecoilState(
    atoms.tagsEditorInfo(familyId)
  )
  const [matchType, setMatchType] = useRecoilState(atoms.tagsMatch(familyId))
  return {
    selected,
    setSelected,
    options,
    setOptions,
    search,
    setSearch,
    loading,
    setLoading,
    editing,
    setEditing,
    editorInfo,
    setEditorInfo,
    matchType,
    setMatchType,
  }
}

// RUN GROUPS
export const useRunGroupsFilter = (familyId) => {
  const [selected, setSelected] = useRecoilState(
    atoms.runGroupsSelected(familyId)
  )
  const [options, setOptions] = useRecoilState(atoms.runGroupsOptions(familyId))
  const [search, setSearch] = useRecoilState(atoms.runGroupsSearch(familyId))
  const [loading, setLoading] = useRecoilState(
    atoms.runGroupsSearchLoading(familyId)
  )
  return {
    selected,
    setSelected,
    options,
    setOptions,
    search,
    setSearch,
    loading,
    setLoading,
  }
}

// COMMITTERS
export const useCommittersFilter = (familyId) => {
  const [selected, setSelected] = useRecoilState(
    atoms.committersSelected(familyId)
  )
  const [options, setOptions] = useRecoilState(
    atoms.committersOptions(familyId)
  )
  const [search, setSearch] = useRecoilState(atoms.committersSearch(familyId))
  const [loading, setLoading] = useRecoilState(
    atoms.committersSearchLoading(familyId)
  )

  return {
    selected,
    setSelected,
    options,
    setOptions,
    search,
    setSearch,
    loading,
    setLoading,
  }
}

// SPEC FILES
export const useSpecFilesFilter = (familyId) => {
  const [selected, setSelected] = useRecoilState(
    atoms.specFilesSelected(familyId)
  )
  const [options, setOptions] = useRecoilState(atoms.specFilesOptions(familyId))
  return {
    selected,
    setSelected,
    options,
    setOptions,
  }
}
// BROWSERS
export const useBrowsersFilter = (familyId) => {
  const [selected, setSelected] = useRecoilState(
    atoms.browsersSelected(familyId)
  )
  const [options, setOptions] = useRecoilState(atoms.browsersOptions(familyId))

  return {
    selected,
    setSelected,
    options,
    setOptions,
  }
}
// CYPRESS VERSIONS
export const useCypressVersionsFilter = (familyId) => {
  const [selected, setSelected] = useRecoilState(
    atoms.cypressVersionsSelected(familyId)
  )
  const [options, setOptions] = useRecoilState(
    atoms.cypressVersionsOptions(familyId)
  )
  return {
    selected,
    setSelected,
    options,
    setOptions,
  }
}

// OPERATING SYSTEMS
export const useOperatingSystemsFilter = (familyId) => {
  const [selected, setSelected] = useRecoilState(
    atoms.operatingSystemsSelected(familyId)
  )
  const [options, setOptions] = useRecoilState(
    atoms.operatingSystemsOptions(familyId)
  )
  return {
    selected,
    setSelected,
    options,
    setOptions,
  }
}

// FLAKY
export const useFlakyFilter = (familyId) => {
  const [selected, setSelected] = useRecoilState(atoms.flakySelected(familyId))
  return {
    selected,
    setSelected,
  }
}

// TOP FAILURES BUCKET RANGE
export const useChartRangeTopFailuresFilter = (familyId) => {
  const [selected, setSelected] = useRecoilState(
    atoms.chartRangeTopFailures(familyId)
  )

  return {
    selected,
    setSelected,
  }
}

// SLOWEST TESTS BUCKET RANGE
export const useChartRangeSlowestTestsFilter = (familyId) => {
  const [selected, setSelected] = useRecoilState(
    atoms.chartRangeSlowestTests(familyId)
  )

  return {
    selected,
    setSelected,
  }
}

// MOST COMMON ERRORS BUCKET RANGE
export const useChartRangeMostCommonErrorsFilter = (familyId) => {
  const [selected, setSelected] = useRecoilState(
    atoms.chartRangeMostCommonErrors(familyId)
  )

  return {
    selected,
    setSelected,
  }
}

// FLAKY TEST CASES BUCKET RANGE
export const useChartFlakyTestsFilter = (familyId) => {
  const [selected, setSelected] = useRecoilState(
    atoms.chartFlakyTests(familyId)
  )

  return {
    selected,
    setSelected,
  }
}

export const useViewByFilter = (familyId) => {
  const [selected, setSelected] = useRecoilState(atoms.viewBy(familyId))

  return {
    selected,
    setSelected,
  }
}

export const useStatusFilter = (familyId) => {
  const [selected, setSelected] = useRecoilState(atoms.status(familyId))

  return {
    selected,
    setSelected,
  }
}

//////////////////////////////////////////
// Action Search
export const useBranchesSearch = (projectId: string, familyId: string) => {
  const search = useRecoilValue(atoms.branchesSearch(familyId))
  const setLoading = useSetRecoilState(atoms.branchesSearchLoading(familyId))
  const setOptions = useSetRecoilState(atoms.branchesOptions(familyId))

  const { data, loading } = useProjectBranchesQuery({
    variables: {
      id: projectId,
      branchesSearch: search,
    },
    skip: search === undefined,
  })

  useEffect(() => {
    setLoading(loading)
    if (data && !loading) {
      setOptions(cleanBranches(data.project.branches.nodes))
    }
  }, [loading, data, setLoading, setOptions])
}

// Tags
export const useTagsSearch = (projectId: string, familyId: string) => {
  const setOptions = useSetRecoilState(atoms.tagsOptions(familyId))
  const search = useRecoilValue(atoms.tagsSearch(familyId))
  const setLoading = useSetRecoilState(atoms.tagsSearchLoading(familyId))
  const editing = useRecoilValue(atoms.tagsEditing(familyId))

  const { data, loading } = useProjectTagsQuery({
    variables: {
      id: projectId,
      tagsSearch: search,
    },
    skip: search === undefined,
  })

  useEffect(() => {
    setLoading(loading)
    if (data && !loading) {
      setOptions(cleanTags(data.project.tags.nodes, editing))
    }
  }, [loading, data, setLoading, editing, setOptions])
}

export const useTagsEditor = (projectId: string, familyId: string) => {
  const editorInfo = useRecoilValue(atoms.tagsEditorInfo(familyId))
  const [setTagColorMutation] = useSetTagColorMutation()
  const editing = useRecoilValue(atoms.tagsEditing(familyId))

  useEffect(() => {
    if (editorInfo && editing) {
      setTagColorMutation({
        variables: {
          projectId,
          name: editorInfo.tag,
          color: editorInfo.color,
        },
      })
    }
  }, [projectId, editorInfo, editing, setTagColorMutation])
}

// Run Groups
export const useRunGroupsSearch = (projectId: string, familyId: string) => {
  const setOptions = useSetRecoilState(atoms.runGroupsOptions(familyId))
  const search = useRecoilValue(atoms.runGroupsSearch(familyId))
  const setLoading = useSetRecoilState(atoms.runGroupsSearchLoading(familyId))

  const { data, loading } = useProjectRunGroupsQuery({
    variables: {
      id: projectId,
      runGroupsSearch: search,
    },
    skip: search === undefined,
  })

  useEffect(() => {
    setLoading(loading)
    if (data && !loading) {
      setOptions(cleanRunGroups(data.project.runGroups.nodes))
    }
  }, [loading, data, setLoading, setOptions])
}

// Committers
export const useCommittersSearch = (projectId: string, familyId: string) => {
  const setOptions = useSetRecoilState(atoms.committersOptions(familyId))
  const search = useRecoilValue(atoms.committersSearch(familyId))
  const setLoading = useSetRecoilState(atoms.committersSearchLoading(familyId))

  const { data, loading } = useProjectCommittersQuery({
    variables: {
      id: projectId,
      committersSearch: search,
    },
    skip: search === undefined,
  })

  useEffect(() => {
    setLoading(loading)
    if (data && !loading) {
      setOptions(cleanCommitters(data.project.committers.nodes))
    }
  }, [loading, data, setLoading, setOptions])
}

// PAGES
export const useRunDurationsOverTime = () => {
  const [runDurationsOverTime, setRunDurationsOverTime] = useRecoilState(
    atoms.runDurationsOverTime
  )
  return { runDurationsOverTime, setRunDurationsOverTime }
}

export const useRunsOverTime = () => {
  const [runsOverTime, setRunsOverTime] = useRecoilState(atoms.runsOverTime)
  return { runsOverTime, setRunsOverTime }
}

export const useTestSuiteSizeOverTime = () => {
  const [testSuiteSizeOverTime, setTestSuiteSizeOverTime] = useRecoilState(
    atoms.testSuiteSizeOverTime
  )
  return { testSuiteSizeOverTime, setTestSuiteSizeOverTime }
}

export const useTopFailures = () => {
  const [topFailures, setTopFailures] = useRecoilState(atoms.topFailures)
  return { topFailures, setTopFailures }
}

export const useSlowestTests = () => {
  const [slowestTests, setSlowestTests] = useRecoilState(atoms.slowestTests)
  return { slowestTests, setSlowestTests }
}

export const useMostCommonErrors = () => {
  const [mostCommonErrors, setMostCommonErrors] = useRecoilState(
    atoms.mostCommonErrors
  )
  return { mostCommonErrors, setMostCommonErrors }
}

export const useFlakyTests = () => {
  const [flakyTests, setFlakyTests] = useRecoilState(atoms.flakyTests)
  return { flakyTests, setFlakyTests }
}

// Utils
export const useEventTracking = (
  analyticID: string,
  label: string,
  familyId: string
) => {
  const branches = useRecoilValue(atoms.branchesSelected(familyId))
  const timeRange = useRecoilValue(atoms.timeRange(familyId))
  const timeInterval = useRecoilValue(atoms.timeInterval(familyId))
  const tags = useRecoilValue(atoms.tagsSelected(familyId))
  const tagsMatch = useRecoilValue(atoms.tagsMatch(familyId))
  const specFiles = useRecoilValue(atoms.specFilesSelected(familyId))
  const runGroups = useRecoilValue(atoms.runGroupsSelected(familyId))
  const browsers = useRecoilValue(atoms.browsersSelected(familyId))
  const committers = useRecoilValue(atoms.committersSelected(familyId))
  const cypressVersions = useRecoilValue(
    atoms.cypressVersionsSelected(familyId)
  )
  const operatingSystems = useRecoilValue(
    atoms.operatingSystemsSelected(familyId)
  )
  const chartRangeTopFailures = useRecoilValue(
    atoms.chartRangeTopFailures(familyId)
  )
  const chartRangeSlowestTests = useRecoilValue(
    atoms.chartRangeSlowestTests(familyId)
  )
  const chartRangeMostCommonErrors = useRecoilValue(
    atoms.chartRangeMostCommonErrors(familyId)
  )
  const chartFlakyTests = useRecoilValue(atoms.chartFlakyTests(familyId))
  const flaky = useRecoilValue(atoms.flakySelected(familyId))
  const viewBy = useRecoilValue(atoms.viewBy(familyId))
  const status = useRecoilValue(atoms.status(familyId))

  useEffect(() => {
    /* istanbul ignore next */
    sendEventCustom('Analytics', 'Filter', {
      analyticID,
      type: 'setBranches',
      label,
    })
  }, [branches, analyticID, label])

  useEffect(() => {
    /* istanbul ignore next */
    sendEventCustom('Analytics', 'Filter', {
      analyticID,
      type: 'setTimeRange',
      label,
    })
  }, [timeRange, analyticID, label])

  useEffect(() => {
    /* istanbul ignore next */
    sendEventCustom('Analytics', 'Filter', {
      analyticID,
      type: 'setTimeInterval',
      label,
    })
  }, [timeInterval, analyticID, label])

  useEffect(() => {
    /* istanbul ignore next */
    sendEventCustom('Analytics', 'Filter', {
      analyticID,
      type: 'setTags',
      label,
    })
  }, [tags, analyticID, label])

  useEffect(() => {
    /* istanbul ignore next */
    sendEventCustom('Analytics', 'Filter', {
      analyticID,
      type: 'setTagsMatch',
      label,
    })
  }, [tagsMatch, analyticID, label])

  useEffect(() => {
    /* istanbul ignore next */
    sendEventCustom('Analytics', 'Filter', {
      analyticID,
      type: 'setSpecFiles',
      label,
    })
  }, [specFiles, analyticID, label])

  useEffect(() => {
    /* istanbul ignore next */
    sendEventCustom('Analytics', 'Filter', {
      analyticID,
      type: 'setRunGroups',
      label,
    })
  }, [runGroups, analyticID, label])

  useEffect(() => {
    /* istanbul ignore next */
    sendEventCustom('Analytics', 'Filter', {
      analyticID,
      type: 'setBrowsers',
      label,
    })
  }, [browsers, analyticID, label])

  useEffect(() => {
    /* istanbul ignore next */
    sendEventCustom('Analytics', 'Filter', {
      analyticID,
      type: 'setCommitters',
      label,
    })
  }, [committers, analyticID, label])

  useEffect(() => {
    /* istanbul ignore next */
    sendEventCustom('Analytics', 'Filter', {
      analyticID,
      type: 'setCypressVersions',
      label,
    })
  }, [cypressVersions, analyticID, label])

  useEffect(() => {
    /* istanbul ignore next */
    sendEventCustom('Analytics', 'Filter', {
      analyticID,
      type: 'setFlaky',
      label,
    })
  }, [flaky, analyticID, label])

  useEffect(() => {
    /* istanbul ignore next */
    sendEventCustom('Analytics', 'Filter', {
      analyticID,
      type: 'setOperatingSystems',
      label,
    })
  }, [operatingSystems, analyticID, label])

  useEffect(() => {
    /* istanbul ignore next */
    sendEventCustom('Analytics', 'Filter', {
      analyticID,
      type: 'setChartRangeTopFailures',
      label,
    })
  }, [chartRangeTopFailures, analyticID, label])

  useEffect(() => {
    /* istanbul ignore next */
    sendEventCustom('Analytics', 'Filter', {
      analyticID,
      type: 'setChartRangeSlowestTests',
      label,
    })
  }, [chartRangeSlowestTests, analyticID, label])

  useEffect(() => {
    /* istanbul ignore next */
    sendEventCustom('Analytics', 'Filter', {
      analyticID,
      type: 'setChartRangeMostCommonErrors',
      label,
    })
  }, [chartRangeMostCommonErrors, analyticID, label])

  useEffect(() => {
    /* istanbul ignore next */
    sendEventCustom('Analytics', 'Filter', {
      analyticID,
      type: 'setChartFlakyTests',
      label,
    })
  }, [chartFlakyTests, analyticID, label])

  useEffect(() => {
    /* istanbul ignore next */
    sendEventCustom('Analytics', 'Filter', {
      type: 'setViewBy',
      label,
    })
  }, [viewBy, analyticID, label])

  useEffect(() => {
    /* istanbul ignore next */
    sendEventCustom('Analytics', 'Filter', {
      type: 'status',
      label,
    })
  }, [status, analyticID, label])

  useEffect(() => {
    /* istanbul ignore next */
    sendEventCustom('Analytics', 'View', {
      analyticID,
      label,
    })
  }, [analyticID, label])
}

export const useFilterInfo = (familyId: string) => {
  const [filters, setFilters] = useRecoilState(selectors.filters(familyId))
  const [filtersDefault, setFiltersDefault] = useRecoilState(
    atoms.filtersDefault(familyId)
  )
  const [filtersInitialized, setFiltersInitialized] = useRecoilState(
    atoms.filtersInitialized(familyId)
  )
  const isFilterSetToDefaultValue = isEqual(filters, filtersDefault)
  const resetFilters = () => {
    setFilters(filtersDefault)
  }
  return {
    filtersDefault,
    setFiltersDefault,
    filtersInitialized,
    setFiltersInitialized,
    isFilterSetToDefaultValue,
    filters,
    setFilters,
    resetFilters,
  }
}

// Add any other params in this object if they are
// meant to be handled separate from recoil state:
function preserveNonRecoilParams(search: string) {
  const replayParams = pick(qs.parse(search), REPLAY_PARAMS)
  return {
    ...replayParams,
  }
}

// Router && Initialization
export const useSyncFiltersWithRouter = (familyId: string) => {
  const location = useLocation()
  const navigate = useNavigate()
  const filters = useRecoilValue(selectors.filters(familyId))
  const filtersDefault = useRecoilValue(atoms.filtersDefault(familyId))
  const filtersDefaultsInitialized = useRecoilValue(
    atoms.filterDefaultValuesInitialized(familyId)
  )
  useEffect(() => {
    if (filtersDefaultsInitialized) {
      const mergedUrlParams = {
        ...filters, // recoil state filters
        ...preserveNonRecoilParams(location.search),
      }
      let updatedQueryString = getQueryStringFromFilters(mergedUrlParams)
      if (isEqual(mergedUrlParams, filtersDefault)) updatedQueryString = ''
      if (location.search === updatedQueryString) return
      const basePath =
        location.pathname.split(/(\/projects\/[\w-]+\/analytics\/[\w-]+)/)[1] ||
        location.pathname
      navigate(`${basePath}${updatedQueryString}`, { replace: true })
    }
  }, [
    filtersDefault,
    filters,
    navigate,
    location.pathname,
    location.search,
    filtersDefaultsInitialized,
  ])
}

export const useSyncRouterWithFilter = (familyId: string) => {
  const location = useLocation()
  const navigate = useNavigate()

  const [filters, setFilters] = useRecoilState(selectors.filters(familyId))
  const setFiltersDefault = useSetRecoilState(atoms.filtersDefault(familyId))
  const [globalHasInitializedFromRouter, setGlobalHasInitializedFromRouter] =
    useRecoilState(globalAtoms.globalHasInitializedFromRouter)
  const setFiltersInitialized = useSetRecoilState(
    atoms.filtersInitialized(familyId)
  )
  useEffect(() => {
    if (globalHasInitializedFromRouter) return
    setGlobalHasInitializedFromRouter(true)
    if (!location.search) return // No URL Params
    const currentFilterState = removeNullifiedFilters(filters)
    const updatedFilterState = getFiltersFromQueryString(location.search)
    if (isEqual(updatedFilterState, currentFilterState)) return
    // Verify that the list of keys from the url, contain at-least all of the keys in our default object.
    // If not, we're missing some and may rely on default state that could vary per user so bail out of init.
    if (
      currentFilterState &&
      updatedFilterState &&
      hasAtLeastSameKeys(currentFilterState, updatedFilterState)
    ) {
      setFilters(updatedFilterState)
      setFiltersInitialized(true)
    }
  }, [
    setFiltersInitialized,
    globalHasInitializedFromRouter,
    setGlobalHasInitializedFromRouter,
    setFiltersDefault,
    filters,
    setFilters,
    navigate,
    location.pathname,
    location.search,
  ])
}

// FilterOptions Initialization
export const useProjectAnalyticsFilterOptionsInitialization = ({
  projectId,
  familyId,
  overrides,
}: ProjectAnalyticsFilterOptionsInitialization) => {
  // Global Init Flags
  const setFilters = useSetRecoilState(selectors.filters(familyId))
  const setFilterOptions = useSetRecoilState(selectors.filterOptions(familyId))
  const [filterOptionsInitialized, setFilterOptionsInitialized] =
    useRecoilState(atoms.filterOptionsInitialized(familyId))

  // Query
  // Contains filter options values
  const { data, loading } = useProjectAnalyticsFilterOptionsInitializationQuery(
    {
      variables: { id: projectId },
      skip: filterOptionsInitialized,
    }
  )

  // Defaults and Initial Value Hook
  // 1. Set Option Values
  useEffect(() => {
    if (!loading && data && !filterOptionsInitialized) {
      // 1. Set Options Values
      const filterData = {
        branches: cleanBranches(data.project.branches.nodes),
        // timeInterval : NA - Hard Coded
        // timeRange : NA - Date Based
        // buildInterval: NA - Hard Coded
        tags: cleanTags(data.project.tags.nodes, false),
        // tagsMatch: NA - Hard Coded
        specFiles: cleanSpecFiles(data.project.uniqueSpecs),
        runGroups: cleanRunGroups(data.project.runGroups.nodes),
        browsers: cleanBrowsers(data.project.uniqueBrowsers),
        committers: cleanCommitters(data.project.committers.nodes),
        cypressVersions: cleanCypressVersions(
          data.project.uniqueCypressVersions
        ),
        operatingSystems: cleanOperatingSystems(data.project.uniqueOses),
      }
      // Allows us to override what options may be returned from the query
      const defaultFilterOptions = overrides
        ? { ...filterData, ...overrides }
        : filterData
      setFilterOptions(defaultFilterOptions)
      setFilterOptionsInitialized(true)
    }
  }, [
    data,
    loading,
    setFilters,
    setFilterOptions,
    setFilterOptionsInitialized,
    filterOptionsInitialized,
    overrides,
  ])

  return { filterOptionsInitialized }
}

export const useProjectAnalyticsFilterDefaultsInitialization = ({
  projectId,
  familyId,
  overrides,
}: ProjectAnalyticsFilterDefaultsInitialization) => {
  // Global Init Flags
  const setFilters = useSetRecoilState(selectors.filters(familyId))
  const setFiltersDefault = useSetRecoilState(atoms.filtersDefault(familyId))
  const [filtersInitialized, setFiltersInitialized] = useRecoilState(
    atoms.filtersInitialized(familyId)
  )
  const [filterDefaultValuesInitialized, setFilterDefaultValuesInitialized] =
    useRecoilState(atoms.filterDefaultValuesInitialized(familyId))

  // Query
  // Contains filter default values
  const byBuildAnalytics = features.isEnabled('analytics-p1')
  const { data, loading } =
    useProjectAnalyticsFilterDefaultsInitializationQuery({
      variables: { id: projectId, byBuildAnalytics },
      skip: filterDefaultValuesInitialized && filtersInitialized,
    })

  // Defaults and Initial Value Hook
  // 1. Set Default Values
  // 2. Set Initial Values if no values are set from the URL (only defaults for 3 fields)
  useEffect(() => {
    if (!loading && data) {
      // 1. Set Default Values
      const {
        branches: branchesRaw,
        timeInterval,
        timeRange,
        buildInterval,
      } = data.project.analyticsDefaultParameters || {}

      const branches = getMultiSelectOptionsFromValues(branchesRaw)
      const filters = {
        branches,
        timeInterval,
        buildInterval,
        timeRange,
        tags: [],
        tagsMatch: 'ANY' as TagsMatchEnum,
        specFiles: [],
        runGroups: [],
        browsers: [],
        committers: [],
        cypressVersions: [],
        operatingSystems: [],
        chartRangeTopFailures: [],
        chartRangeSlowestTests: [],
        chartRangeMostCommonErrors: [],
        chartFlakyTests: undefined,
        flaky: [],
        viewBy: 'TEST_CASE' as ViewByEnum,
        status: cleanStatuses(['PASSED', 'FAILED']),
      }

      // Allows us to override the values returned for initial defaults
      const defaultFilters = overrides ? { ...filters, ...overrides } : filters
      setFiltersDefault(defaultFilters) // Set default value
      setFilterDefaultValuesInitialized(true)

      // 2. Set Initial Values if no values are set from the URL
      if (filtersInitialized) return
      setFilters(defaultFilters) // Set current value if not set by URL previously
      setFiltersInitialized(true)
    }
  }, [
    data,
    loading,
    filtersInitialized,
    setFilters,
    setFiltersInitialized,
    setFiltersDefault,
    setFilterDefaultValuesInitialized,
    overrides,
  ])

  return { filterDefaultValuesInitialized, filtersInitialized }
}

export const useUpdateDefaults = (familyId: string) => {
  const { filtersDefault, setFiltersDefault, setFilters } =
    useFilterInfo(familyId)

  return [
    (updatedFilters: Partial<AnalyticsFilters>) => {
      const updatedDefaults = { ...filtersDefault, ...updatedFilters }

      setFiltersDefault(updatedDefaults)
      setFilters(updatedDefaults)
    },
  ]
}

export const useUpdateProjectAnalyticsDefaults = (projectId: string) => {
  const [updateDefaults1] = useUpdateDefaults(
    getProjectAnalyticsFamilyId(projectId, 1)
  )
  const [updateDefaults2] = useUpdateDefaults(
    getProjectAnalyticsFamilyId(projectId, 2)
  )

  return [
    (updatedDefaults: Partial<AnalyticsFilters>) => {
      updateDefaults1(updatedDefaults)
      updateDefaults2(updatedDefaults)
    },
  ]
}

const SAVINGS_RUN_MULTIPLIER = 100
const MINUTE = 1000 * 60

const getFormattedTimeSaving = (timeSaving, display0m = true) => {
  const formattedTime = durationFormattedFull(
    timeSaving >= MINUTE ? timeSaving : 0,
    -2,
    true
  )

  return formattedTime === '' && display0m ? '0m' : formattedTime
}

const getExtrapolatedTimeSavings = (latestRunsTimeSavings, numBuilds) => {
  let projectedAutoCancelSavings
  let projectedAutoCancelWithSpecPrioritizationSavings
  let projectedOnlySpecPrioritizationSavings

  if (latestRunsTimeSavings && typeof numBuilds === 'number') {
    projectedAutoCancelSavings =
      latestRunsTimeSavings?.projectedAutoCancelSavings ?? 0
    projectedAutoCancelWithSpecPrioritizationSavings =
      latestRunsTimeSavings?.projectedAutoCancelWithSpecPrioritizationSavings ??
      0

    if (
      numBuilds > 0 &&
      numBuilds < SAVINGS_RUN_MULTIPLIER &&
      projectedAutoCancelSavings > 0 &&
      projectedAutoCancelWithSpecPrioritizationSavings > 0
    ) {
      // if we have less than 100 builds we want to extrapolate the savings to get them over 100 runs
      // so we get the savings per run
      const projectedAutoCancelSavingsPerRun =
        projectedAutoCancelSavings / numBuilds

      const projectedAutoCancelWithSpecPrioritizationSavingsPerRun =
        projectedAutoCancelWithSpecPrioritizationSavings / numBuilds

      // multiply savings per run by 100
      projectedAutoCancelSavings =
        projectedAutoCancelSavingsPerRun * SAVINGS_RUN_MULTIPLIER

      projectedAutoCancelWithSpecPrioritizationSavings =
        projectedAutoCancelWithSpecPrioritizationSavingsPerRun *
        SAVINGS_RUN_MULTIPLIER
    }

    // spec prioritization only savings are the difference between auto cancelation and auto cancelation with spec prioritization savings
    projectedOnlySpecPrioritizationSavings = Math.max(
      projectedAutoCancelWithSpecPrioritizationSavings -
        projectedAutoCancelSavings,
      0
    )
  }

  return {
    projectedAutoCancelSavings,
    projectedAutoCancelWithSpecPrioritizationSavings,
    projectedOnlySpecPrioritizationSavings,
  }
}

const getFormattedExtrapolatedTimeSavings = (
  latestRunsTimeSavings,
  numBuilds
) => {
  let {
    projectedAutoCancelSavings,
    projectedAutoCancelWithSpecPrioritizationSavings,
    projectedOnlySpecPrioritizationSavings,
  } = getExtrapolatedTimeSavings(latestRunsTimeSavings, numBuilds)

  projectedAutoCancelSavings = getFormattedTimeSaving(
    projectedAutoCancelSavings
  )
  projectedAutoCancelWithSpecPrioritizationSavings = getFormattedTimeSaving(
    projectedAutoCancelWithSpecPrioritizationSavings
  )

  // if there are no savings for spec prioritization only, we don't want to display 0m
  projectedOnlySpecPrioritizationSavings = getFormattedTimeSaving(
    projectedOnlySpecPrioritizationSavings,
    false
  )

  return {
    projectedAutoCancelSavings,
    projectedAutoCancelWithSpecPrioritizationSavings,
    projectedOnlySpecPrioritizationSavings,
  }
}

const useFormattedTimeSavingsWithOneFailure = (projectId: string) => {
  const { data: queryTimeSavings, loading: loadingSavings } =
    useProjectSimulatedTimeSavingsQuery({
      variables: { projectId, cancelOnFailCount: 1 },
    })
  const latestRunsTimeSavings = queryTimeSavings?.project.latestRunsTimeSavings
  const numBuilds = queryTimeSavings?.project.numBuilds ?? 0

  const savings = useMemo(() => {
    return getExtrapolatedTimeSavings(latestRunsTimeSavings, numBuilds)
  }, [latestRunsTimeSavings, numBuilds])

  return { savings, loadingSavings }
}

/**
 * Get the formatted (ex. 1h 10m) auto cancelation, auto cancelation with spec prioritization
 * and spec prioritization savings extrapolated by 100
 * @param projectId
 * @param cancelOnFailCount
 * @returns
 */
export const useFormattedTimeSavings = (
  projectId: string,
  cancelOnFailCount: number
) => {
  const [cancelOnFailCountDebounced] = useDebounce(cancelOnFailCount, 300)
  const {
    savings: savingsWithOneFailure,
    loadingSavings: loadingSavingsWithOneFailure,
  } = useFormattedTimeSavingsWithOneFailure(projectId)

  // if the savings are less than a minute then we don't have enough data
  const notEnoughDataForSavings =
    savingsWithOneFailure?.projectedAutoCancelSavings < MINUTE &&
    savingsWithOneFailure?.projectedAutoCancelWithSpecPrioritizationSavings <
      MINUTE

  const [
    fetchSavings,
    { loading: isLoadingSavings, data: queryTimeSavings = null },
  ] = useProjectSimulatedTimeSavingsLazyQuery({
    variables: { projectId, cancelOnFailCount: cancelOnFailCountDebounced },
  })

  useEffect(() => {
    if (!loadingSavingsWithOneFailure && !notEnoughDataForSavings) {
      fetchSavings()
    }
  }, [fetchSavings, notEnoughDataForSavings, loadingSavingsWithOneFailure])

  const latestRunsTimeSavings = queryTimeSavings?.project.latestRunsTimeSavings
  const numBuilds = queryTimeSavings?.project.numBuilds ?? 0

  const savings = useMemo(
    () => getFormattedExtrapolatedTimeSavings(latestRunsTimeSavings, numBuilds),
    [latestRunsTimeSavings, numBuilds]
  )

  // if the savings with one failure are less than or equal to 0 then we don't have enough data to return a recommendation
  if (notEnoughDataForSavings) {
    savings.projectedAutoCancelSavings = 'Not enough data'
    savings.projectedAutoCancelWithSpecPrioritizationSavings = 'Not enough data'
  }

  return {
    savings,
    isLoadingSavings: isLoadingSavings || loadingSavingsWithOneFailure,
  }
}

// tracks TestReplay params in a way that fits with the
// rest of the Analytics filters in Analytics pages urls:
export function useTestReplayInAnalytics() {
  const location = useLocation()
  const navigate = useNavigate()

  const urlSearchMap = useMemo(
    () => qs.parse(location.search),
    [location.search]
  )

  const { data, loading } = useTestReplayByTestIdQuery({
    variables: { uuid: urlSearchMap.replayTestId as string },
    skip: !urlSearchMap.replayTestId,
  })

  const updateUrlParams = useCallback(
    (newParams: string) => {
      const newSearch = newParams.length ? `?${newParams}` : ''
      navigate(`${location.pathname}${newSearch}`, { replace: true })
    },
    [navigate, location.pathname]
  )

  const clearReplay = useCallback(() => {
    Object.keys(urlSearchMap).forEach((k) => {
      if (REPLAY_PARAMS.includes(k as TestReplayParamKeys)) {
        // remove test-replay params from final url search:
        delete urlSearchMap[k]
      }
    })
    updateUrlParams(qs.stringify(urlSearchMap))
  }, [urlSearchMap, updateUrlParams])

  const setReplay = useCallback(
    (args: { id: string } | null) => {
      if (args === null) {
        clearReplay()
        return
      }
      updateUrlParams(
        qs.stringify({
          ...urlSearchMap,
          replayTestId: args.id,
        })
      )
    },
    [urlSearchMap, updateUrlParams, clearReplay]
  )

  return {
    setReplay,
    replay: data?.testResult,
    loading,
  }
}

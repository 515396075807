import _ from 'lodash'
import debugLib from '@packages/debug'

const debug = debugLib()

import {
  RunGroupStatuses,
  RunGroupStatusesValue,
  RunStatuses,
  RunStatusesValue,
} from '../enums'

export function determineGroupStatus(
  statuses: RunGroupStatusesValue[],
  opts?: { checkUnclaimed: boolean }
): RunGroupStatusesValue | undefined {
  if (_.includes(statuses, RunGroupStatuses.CANCELLED)) {
    return RunGroupStatuses.CANCELLED
  }
  if (_.includes(statuses, RunGroupStatuses.ERRORED)) {
    return RunGroupStatuses.ERRORED
  }
  if (_.includes(statuses, RunGroupStatuses.FAILED)) {
    return RunGroupStatuses.FAILED
  }
  if (_.includes(statuses, RunGroupStatuses.RUNNING)) {
    return RunGroupStatuses.RUNNING
  }
  if (_.includes(statuses, RunGroupStatuses.TIMEDOUT)) {
    return RunGroupStatuses.TIMEDOUT
  }
  if (_.every(statuses, _.matches(RunGroupStatuses.NOTESTS))) {
    return RunGroupStatuses.NOTESTS
  }

  // Added as an option for legacy consumers that expected undefined in this scenario.
  if (
    opts?.checkUnclaimed &&
    _.every(statuses, _.matches(RunGroupStatuses.UNCLAIMED))
  ) {
    return RunGroupStatuses.UNCLAIMED
  }
  if (
    statuses.every(
      (s) => s === RunGroupStatuses.PASSED || s === RunGroupStatuses.NOTESTS
    )
  ) {
    return RunGroupStatuses.PASSED
  }
  if (
    statuses.some(
      (s) => s === RunGroupStatuses.PASSED || s === RunGroupStatuses.NOTESTS
    )
  ) {
    return RunGroupStatuses.RUNNING
  }
  return
}

export function determineBuildStatus(
  statuses: RunStatusesValue[],
  options: { includeCanceledStatus?: boolean } = { includeCanceledStatus: true }
): RunStatusesValue | undefined {
  if (
    options.includeCanceledStatus &&
    _.includes(statuses, RunStatuses.CANCELLED)
  ) {
    return RunStatuses.CANCELLED
  }
  if (_.includes(statuses, RunStatuses.ERRORED)) {
    return RunStatuses.ERRORED
  }
  if (_.includes(statuses, RunStatuses.FAILED)) {
    return RunStatuses.FAILED
  }
  if (_.includes(statuses, RunStatuses.TIMEDOUT)) {
    return RunStatuses.TIMEDOUT
  }
  if (_.every(statuses, _.matches(RunStatuses.NOTESTS))) {
    return RunStatuses.NOTESTS
  }
  if (_.every(statuses, _.matches(RunStatuses.PASSED))) {
    return RunStatuses.PASSED
  }
  // Legacy
  if (_.includes(statuses, RunStatuses.PASSED)) {
    debug('Not all statuses passed, saw:', statuses)
    return RunStatuses.PASSED
  }
  return
}

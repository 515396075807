import { useCallback, useState } from 'react'
import { useInterval } from '~/lib/hooks/useInterval'

interface UsePopupWindowConfig {
  destination?: string
  onClose: () => void
  onOpen?: () => void
  delay?: number
  width?: number
  height?: number
}

export const usePopupWindow = (defaultConfig: UsePopupWindowConfig) => {
  const { onClose, onOpen, delay, destination, width, height } = defaultConfig
  const [isLoading, setIsLoading] = useState(false)
  const [popupWindow, setPopupWindow] = useState<Window | null>(null)

  const pollForPopupClose = useCallback(() => {
    if (!popupWindow || !popupWindow.closed) {
      return
    }

    setPopupWindow(null)

    if (onClose) {
      onClose()
    }

    setIsLoading(false)
  }, [onClose, popupWindow])

  useInterval(pollForPopupClose, delay || 500, {
    enabled: Boolean(popupWindow),
  })

  const openPopup = useCallback(
    (config?: Pick<UsePopupWindowConfig, 'destination'>) => {
      if (popupWindow) {
        return
      }

      setIsLoading(true)

      if (onOpen) {
        onOpen()
      }

      const w = width || 900
      const h = height || 600
      const left = screen.width / 2 - w / 2
      const top = screen.height / 2 - h / 2

      const popup = window.open(
        config?.destination || destination,
        '',
        `toolbar=0,status=0,width=${w},height=${h},left=${left},top=${top}`
      )

      setPopupWindow(popup)
    },
    [destination, height, onOpen, popupWindow, width]
  )

  return { openPopup, isLoading }
}

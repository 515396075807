import React, { FunctionComponent, useState } from 'react'
import { CollapsingSection, palette, Icon } from '@frontend/design-system'
import {
  TestHistoryItemFragment,
  FlakyRateEmptyStateProjectFragment,
  useDrawerTestResultAnalyticHistoricalFlakinessQuery,
} from '~/graphql-codegen-operations.gen'
import {
  shouldShowFlakyRateEmptyState,
  FlakyRateEmptyState,
} from '~/common/flaky-rate-empty-state/FlakyRateEmptyState'
import { TestTrend } from '~/test-trend/TestTrend'
import * as analytics from '~/data/projects/analytics/hooks'
import { TestTrendFlakinessLabel } from '~/test-trend/TestTrendFlakinessLabel/TestTrendFlakinessLabel'
import { getValuesFromMultiSelectOptions } from '~/common/filters'
import { NeedSingleBranchEmptyState } from '../NeedSingleBranchEmptyState'

type DrillInFlakinessProps = {
  familyId: string
  projectId: string
  specHash: string | null
  titleHash: string | null
  isHistoryEnabled: boolean
  project?: FlakyRateEmptyStateProjectFragment
  onModificationClick(testResultId: string): void
  history: TestHistoryItemFragment[]
  isSingleBranchSelected: boolean
}

export const DrillInFlakiness: FunctionComponent<DrillInFlakinessProps> = ({
  familyId,
  project,
  onModificationClick,
  projectId,
  isHistoryEnabled,
  specHash,
  titleHash,
  history,
  isSingleBranchSelected,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true)

  const { selected: branches } = analytics.useBranchesFilter(familyId)
  const { timeRange } = analytics.useTimeRangeFilter(familyId)
  const { selected: tags } = analytics.useTagsFilter(familyId)
  const { selected: runGroups } = analytics.useRunGroupsFilter(familyId)
  const { selected: browsers } = analytics.useBrowsersFilter(familyId)
  const { selected: committers } = analytics.useCommittersFilter(familyId)
  const { selected: cypressVersions } =
    analytics.useCypressVersionsFilter(familyId)
  const { selected: operatingSystems } =
    analytics.useOperatingSystemsFilter(familyId)

  const selectedBranch = branches?.[0]?.value

  const { data, loading } = useDrawerTestResultAnalyticHistoricalFlakinessQuery(
    {
      skip: !specHash || !titleHash || !selectedBranch || isCollapsed,
      variables: {
        projectId,
        specHash: specHash!,
        titleHash: titleHash!,
        input: {
          timeRange: timeRange!,
          commitBranch: selectedBranch,
          tags: getValuesFromMultiSelectOptions(tags || []),
          runGroups: getValuesFromMultiSelectOptions(runGroups || []),
          committers: getValuesFromMultiSelectOptions(committers || []),
          cypressVersions: getValuesFromMultiSelectOptions(
            cypressVersions || []
          ),
          browsers: getValuesFromMultiSelectOptions(browsers || []),
          oses: getValuesFromMultiSelectOptions(operatingSystems || []),
        },
      },
    }
  )

  const integrationBranchData =
    data?.testResultAnalytic?.historicalFlakiness?.integrationBranch
  const commitBranchData =
    data?.testResultAnalytic?.historicalFlakiness?.commitBranch

  return (
    <CollapsingSection
      title="Flaky rate"
      data-cy="drill-in-flakiness"
      isCollapsedSection={isCollapsed}
      setIsCollapsedSection={setIsCollapsed}
      data-pendo="test-result-drawer--flakiness"
    >
      {(() => {
        if (!project) {
          return (
            <div className="test-results-drawer__loader">
              <Icon name="spinner" spin />
            </div>
          )
        }

        if (shouldShowFlakyRateEmptyState({ project })) {
          return <FlakyRateEmptyState project={project} />
        }

        if (isSingleBranchSelected) {
          return (
            <TestTrend
              id="historical-flakiness"
              projectId={projectId}
              commitBranchColor={palette.orange200}
              isLoading={loading}
              isEnabled={isHistoryEnabled}
              history={history}
              formatYAxis={() => ({
                domain: [0, 100],
                tickValues: [0, 50, 100],
                tickFormat: (value: number) => `${value}%`,
              })}
              onModificationClick={onModificationClick}
              commitBranchPoints={commitBranchData?.points || []}
              integrationBranchPoints={integrationBranchData?.points || []}
              commitBranchName={commitBranchData?.branch}
              integrationBranchName={integrationBranchData?.branch}
              IntegrationBranchStat={(props) => (
                <TestTrendFlakinessLabel
                  {...props}
                  defaultData={integrationBranchData}
                />
              )}
              CommitBranchStat={(props) => (
                <TestTrendFlakinessLabel
                  {...props}
                  defaultData={commitBranchData}
                />
              )}
            />
          )
        }

        return <NeedSingleBranchEmptyState />
      })()}
    </CollapsingSection>
  )
}

import React from 'react'
import Button from '@cypress-design/react-button'
import styles from './module.GroupList.scss'
import {
  IconUserGeneralSolid,
  IconSecurityLockLocked,
} from '@cypress-design/react-icon'
import { Link } from '@reach/router'
import { InviteLink } from '../common/invite-link/InviteLink'

type InviteTeamProps = {
  orgId: string
}

export const InviteTeam: React.FunctionComponent<InviteTeamProps> = ({
  orgId,
}) => {
  return (
    <div className={styles.actionButtons}>
      <Link to={`/organizations/${orgId}/users`}>
        <Button variant="outline-indigo" className={styles.outlineButton}>
          <IconUserGeneralSolid className="mr-2" />
          Invite user
        </Button>
      </Link>

      <Link to={`/organizations/${orgId}/integrations/sso`}>
        <Button variant="outline-indigo" className={styles.outlineButton}>
          <IconSecurityLockLocked className="mr-2" />
          Enable SSO
        </Button>
      </Link>

      <InviteLink id={orgId} isOnboarding />
    </div>
  )
}

import React, { FunctionComponent } from 'react'
import { isNil, startCase, toLower } from 'lodash'
import { getFlakinessSeverity } from '@packages/common'
import styles from './module.FlakyLabel.scss'

import { FlakinessSeverityEnum } from '~/graphql-codegen-operations.gen'

interface FlakyLabelProps {
  percentage?: number | null
  severity?: FlakinessSeverityEnum | null
}

export const FlakyLabel: FunctionComponent<FlakyLabelProps> = ({
  percentage,
  severity,
}) => {
  const computedSeverity = (() => {
    if (severity) {
      return startCase(toLower(severity))
    }

    if (!isNil(percentage)) {
      return startCase(
        toLower(getFlakinessSeverity(percentage) as FlakinessSeverityEnum)
      )
    }

    return '—'
  })()

  return (
    <span
      className={
        computedSeverity === '—'
          ? styles['none']
          : styles[toLower(computedSeverity)]
      }
    >
      {computedSeverity}
    </span>
  )
}

import { useSidebarService } from '../sidebarService'
import { useSidebarRouterIds } from './useSidebarRouterIds'

export const useSidebarProject = () => {
  const [{ data }] = useSidebarService()
  const { routerProjectId } = useSidebarRouterIds()

  const currentProject = data?.currentProject
  const currentOrgProjects = data?.currentOrg?.projects?.nodes || []
  const hasNoProjectData = !currentProject
  const hasStaleProjectData = Boolean(
    currentProject && routerProjectId !== currentProject.id
  )
  const isLoadingProject = Boolean(
    routerProjectId && (hasNoProjectData || hasStaleProjectData)
  )
  const orgProject = currentOrgProjects.find(
    (project) => project.id === routerProjectId
  )

  return isLoadingProject ? orgProject : currentProject
}

import * as React from 'react'
import DocumentTitle from 'react-document-title'
import { observer } from 'mobx-react-lite'
import { Redirect, RouteComponentProps, Router } from '@reach/router'
import { LoaderPage } from '~/common/loader'
import { chunks } from '~/chunks'
import {
  OrganizationContainerQuery,
  useOrganizationContainerQuery,
} from '~/graphql-codegen-operations.gen'
import { useLocation } from '~/lib/hooks/useLocation'
import store from '~/lib/store'
import NotFound from '~/not-found'
import { features } from '~/lib/feature-flags'

interface OrganizationContainerProps
  extends RouteComponentProps<{ id: string }> {}

export const OrganizationContainer: React.FC<OrganizationContainerProps> = ({
  id,
}) => {
  const { navigate } = useLocation()
  const { data, error, refetch } = useOrganizationContainerQuery({
    variables: {
      orgId: id,
    },
  })

  React.useEffect(() => {
    if (error) {
      const errorCode = error.graphQLErrors[0]?.extensions?.code

      if (errorCode === 'INTERNAL_SERVER_ERROR') {
        store.setNotification(
          `Oops. Organization ${id} does not exist.`,
          'failure'
        )
      } else {
        console.error('Unexpected Error - Redirecting to /organizations', error)
      }

      navigate('/organizations')
    }
  }, [error, id, navigate])

  const org: OrganizationContainerQuery['organization'] | undefined =
    data?.organization

  if (!org) {
    return <LoaderPage showLoaders />
  }

  return (
    <DocumentTitle title={org.name}>
      <div className="organization-container">
        <Router primary={false}>
          <chunks.InviteTeam id={id} org={org} path="/invite" />
          <chunks.TrialProgress path="/trial-progress" org={org} />
          <chunks.ProjectsList id={id} org={org} path="/projects" />
          <chunks.ProjectsList id={id} org={org} path="/projects/new" />
          <chunks.OrgSettings id={id} org={org} path="/settings" />
          <chunks.OrgIntegrations
            id={id}
            org={org}
            refetchOrg={refetch}
            path="/integrations"
          />
          {features.isEnabled('metabase-static-embedding') && (
            <chunks.OrganizationEnterpriseReporting
              id={id}
              path="/enterprise-reporting"
            />
          )}
          {org.viewerIsOwnerOrAdmin &&
          Boolean(org.githubAppInstallations.length) ? (
            <chunks.GithubIntegrationOrganization
              id={id}
              org={org}
              path="/integrations/github"
              isEnterprise={false}
            />
          ) : (
            <Redirect
              from="/integrations/github"
              to={`/organizations/${id}/integrations`}
              noThrow
            />
          )}
          {org.viewerIsOwnerOrAdmin &&
          Boolean(org.githubAppEnterpriseInstallations.length) ? (
            <chunks.GithubIntegrationOrganization
              id={id}
              org={org}
              path="/integrations/github-enterprise"
              isEnterprise
            />
          ) : (
            <Redirect
              from="/integrations/github-enterprise"
              to={`/organizations/${id}/integrations`}
              noThrow
            />
          )}
          {org.viewerIsOwnerOrAdmin ? (
            <chunks.GithubIntegrationOrganization
              id={id}
              org={org}
              path="/integrations/github-enterprise-form"
              isEnterprise
            />
          ) : (
            <Redirect
              from="/integrations/github-enterprise-form"
              to={`/organizations/${id}/integrations`}
              noThrow
            />
          )}
          {org.viewerIsOwnerOrAdmin ? (
            <chunks.GitlabIntegrationOrganization
              id={id}
              org={org}
              path="/integrations/gitlab"
              isEnterprise={false}
            />
          ) : (
            <Redirect
              from="/integrations/gitlab"
              to={`/organizations/${id}/integrations`}
              noThrow
            />
          )}
          {org.viewerIsOwnerOrAdmin ? (
            <chunks.GitLabForEnterpriseIntegrationOrganization
              id={id}
              org={org}
              path="/integrations/gitlab-for-enterprise"
              isEnterprise
            />
          ) : (
            <Redirect
              from="/integrations/gitlab-for-enterprise"
              to={`/organizations/${id}/integrations`}
              noThrow
            />
          )}
          {org.viewerIsOwnerOrAdmin &&
          org.subscription.features.jiraIntegration &&
          Boolean(org.jiraAppInstallations) ? (
            <chunks.JiraIntegrationOrganization
              id={id}
              org={org}
              path="/integrations/jira"
            />
          ) : (
            <Redirect
              from="/integrations/jira"
              to={`/organizations/${id}/integrations`}
              noThrow
            />
          )}
          {org.viewerIsOwnerOrAdmin && Boolean(org.bitbucketAppInstallation) ? (
            <chunks.BitbucketIntegrationOrganization
              id={id}
              org={org}
              path="/integrations/bitbucket"
            />
          ) : (
            <Redirect
              from="/integrations/bitbucket"
              to={`/organizations/${id}/integrations`}
              noThrow
            />
          )}
          {org.viewerIsOwnerOrAdmin && org.isSlackIntegrationInstalled ? (
            <chunks.SlackIntegrationOrganization
              id={id}
              org={org}
              path="/integrations/slack"
            />
          ) : (
            <Redirect
              from="/integrations/slack"
              to={`/organizations/${id}/integrations`}
              noThrow
            />
          )}
          {org.viewerIsOwner && org.subscription.features.sso ? (
            <chunks.SSOIntegrationOrganization
              id={id}
              org={org}
              path="/integrations/sso"
            />
          ) : (
            <Redirect
              from="/integrations/sso"
              to={`/organizations/${id}/integrations`}
              noThrow
            />
          )}
          {org.viewerIsOwnerOrAdmin ? (
            <chunks.MSTeamsIntegrationOrganization
              id={id}
              org={org}
              path="/integrations/teams"
            />
          ) : (
            <Redirect
              from="/integrations/teams"
              to={`/organizations/${id}/integrations`}
              noThrow
            />
          )}
          <chunks.OrgMembers id={id} org={org} path="/users" />
          <chunks.BillingAndUsage id={id} org={org} path="/billing" />
          {features.isEnabled('user-journey-survey') ? (
            <chunks.UserJourneySurvey id={id} path="/survey" />
          ) : (
            <Redirect
              from={`/survey`}
              to={`/organizations/${id}/projects`}
              noThrow
            />
          )}
          <NotFound default />
        </Router>
      </div>
    </DocumentTitle>
  )
}

export default observer(OrganizationContainer)

import { Icon } from '@frontend/design-system'
import * as React from 'react'

import { AppBanners } from '~/navs/AppBanners'
import { useSidebarService } from '~/navs/sidebarService'

interface PageHeaderProps {
  className?: string
  title?: React.ReactNode
  actions?: React.ReactNode
  isBeta?: boolean
  hideNav?: boolean
}

export const PageHeader: React.FC<PageHeaderProps> = ({
  children,
  actions,
  className = '',
  title,
  isBeta,
  hideNav,
}) => {
  const [state, dispatch] = useSidebarService()

  React.useEffect(() => {
    if (state.isSidebarMenuOpen && hideNav) dispatch({ type: 'toggleSidebar' })
  }, [hideNav, state.isSidebarMenuOpen, dispatch])

  return (
    <div className={`page-header ${className}`}>
      <AppBanners />
      <div className="page-header--fancy-shadow" />
      <div className="page-header--content">
        {!hideNav && (
          <a
            className="page-header--nav-trigger"
            href="#"
            title="Show menu"
            onClick={(e) => {
              e.preventDefault()
              return dispatch({ type: 'toggleSidebar' })
            }}
          >
            <Icon name="bars" />
          </a>
        )}

        {title && (
          <div className="page-header--title">
            <h1>{title}</h1>
            {isBeta && <span className="page-header--title-beta">beta</span>}
          </div>
        )}
        {children}
        {actions && <div className="page-header--actions" children={actions} />}
      </div>
    </div>
  )
}

import { Icon } from '@frontend/design-system'
import cx from 'clsx'
import React, { FunctionComponent } from 'react'

import { ActivatableLink } from '~/common/ActivatableLink'

interface NavItemProps {
  addon?: boolean
  subnav?: boolean
  icon?: React.ReactNode
  label: string
  hideLabel?: boolean
  to?: string
  as?: string
  isBeta?: boolean
  onClick?: (label: string) => void
}

const NavItem: FunctionComponent<NavItemProps> = ({
  addon,
  subnav,
  icon,
  label,
  hideLabel,
  to,
  onClick,
  children,
  isBeta,
  ...props
}) => {
  return (
    <ActivatableLink
      className={cx('nav-item', {
        'sub-nav-item': subnav,
        'nav-addon': addon,
        [`nav-item-${label.toLowerCase().replace(/\s+/g, '-')}`]: true,
      })}
      activeClassName="active"
      to={to}
      role="menuitem"
      {...props}
    >
      <div onClick={(e) => onClick && onClick(label)} className="ellipsis">
        {icon && <Icon name={icon as any} />}
        {!hideLabel && label}
        {isBeta && <span className="nav-item--beta-tag">beta</span>}
      </div>
      {children}
    </ActivatableLink>
  )
}

export { NavItem }

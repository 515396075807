import cs from 'clsx'
import React, { useEffect } from 'react'
import DocumentTitle from 'react-document-title'
import { useLocation, useMatch } from '@reach/router'

import AppNotification from './lib/notification'
import { SideNav } from './navs/SidebarNav'
import { useSidebarService } from './navs/sidebarService'
import { setupNavigationGuards } from './lib/navigationGuards'

const isCypress = Boolean(window.Cypress)
const cypressClass = isCypress ? 'is-cypress' : ''

/** @type import("react").FC<import("@reach/router").RouteComponentProps> */
export const App = ({ children }) => {
  const [sidebarState] = useSidebarService()
  const location = useLocation()

  const path = location.pathname

  const branchSummaryMatch = useMatch('/projects/:slug/branches/:branchName/*')

  const shouldRenderSideNav =
    !!branchSummaryMatch ||
    !(
      path.includes('/reset-password') ||
      path.includes('/reset-password') ||
      path.includes('/verify') ||
      path.includes('/test-replay') ||
      path === '/organizations' ||
      path === '/organizations/' ||
      path.includes('/organizations/new') ||
      path.includes('/integrations/slack/organization-selection') ||
      path.includes('/integrations/jira/organization-selection') ||
      (path.includes('/organizations') && path.includes('/invite')) ||
      (path.includes('/organizations') && path.includes('/survey'))
    )

  useEffect(() => {
    return setupNavigationGuards()
  }, [])

  return (
    <DocumentTitle title="Cypress Cloud">
      <div
        className={cs(`app`, cypressClass, {
          'app--is-viewing-sidenav': sidebarState.isSidebarOpen,
        })}
      >
        {shouldRenderSideNav && <SideNav className="app--sidenav" />}

        <div
          id="main"
          className={cs('app--main', { 'hide-sidenav': !shouldRenderSideNav })}
        >
          {children}
        </div>

        <AppNotification />
      </div>
    </DocumentTitle>
  )
}

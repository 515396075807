import { Tooltip } from '@frontend/design-system'
import UilCloudDownload from '@iconscout/react-unicons/icons/uil-cloud-download'

import React, { FunctionComponent } from 'react'

type DownloadCSVProps = {
  analytic: string
  onClick: () => void
}

export const DownloadCSV: FunctionComponent<DownloadCSVProps> = ({
  analytic,
  onClick,
}) => {
  return (
    <Tooltip
      placement="bottomLeft"
      overlay={<span>Download CSV</span>}
      align={{ offset: [-12, 0] }}
    >
      <button
        aria-label="Download CSV"
        onClick={onClick}
        data-cy={`download-${analytic}`}
        data-pendo={`download-${analytic}`}
        className="analytic__download"
      >
        <UilCloudDownload />
      </button>
    </Tooltip>
  )
}

import { CustomIcon, TestResultStatusIcon } from '@frontend/design-system'
import { lowerCase } from 'lodash'
import React, { FunctionComponent } from 'react'

import { TestResultStateEnum } from '~/graphql-codegen-operations.gen'
import { readableTestResultStateEnum } from '~/lib/utils-ts'
import { features } from '~/lib/feature-flags'
import { MultiSelectFilter } from '../../MultiSelectFilter'
import { MultiSelectOptionType } from '../../types'

type ProjectRunStatusFilterProps = {
  id: string
  state: ProjectBranchesFilterStateProp
}

type ProjectBranchesFilterStateProp = {
  selected: MultiSelectOptionType[] | undefined
  setSelected: (value: []) => void
}

const renderLabel = (option: MultiSelectOptionType) => {
  const label = (
    <span>
      <TestResultStatusIcon status={lowerCase(option.value)} />
      {readableTestResultStateEnum(option.value)}
    </span>
  ) as unknown

  return label as string
}

const defaultOptions = (
  ['PASSED', 'FAILED', 'PENDING', 'SKIPPED'] as const
).map((status) => ({
  value: status,
  label: status,
})) as { value: TestResultStateEnum; label: TestResultStateEnum }[]

const isTestMutingEnabled = features.isEnabled('test-muting')

if (isTestMutingEnabled) {
  defaultOptions.push({
    value: 'RUNNING',
    label: 'RUNNING',
  })
}

export const ProjectTestStatusFilterGlobal: FunctionComponent<ProjectRunStatusFilterProps> =
  React.memo(({ id, state }) => {
    const { selected = [], setSelected } = state
    return (
      <div
        data-cy="test-status-filter"
        data-pendo="test-status-filter"
        className="test-status-filter"
      >
        <MultiSelectFilter
          id={id}
          title={<CustomIcon name="status" alt="Status" />}
          emptyLabel="Status"
          selected={selected || []}
          isSearchable={false}
          onChange={setSelected}
          getOptionLabel={renderLabel}
          options={defaultOptions}
        />
      </div>
    )
  })

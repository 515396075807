export const MS_TEAMS_APP_NOTIFICATION_SCOPES = [
  'ALL_RUNS',
  'UNSUCCESSFUL_RUNS',
  'SUCCESSFUL_RUNS',
] as const

export const MS_TEAMS_APP_FAILED_NOTIFICATION_OPTION = {
  value: 'UNSUCCESSFUL_RUNS',
  label: 'Failed runs',
}

export const MS_TEAMS_APP_PASSED_NOTIFICATION_OPTION = {
  value: 'SUCCESSFUL_RUNS',
  label: 'Passed runs',
}

export const MS_TEAMS_APP_ALL_NOTIFICATION_OPTIONS = [
  MS_TEAMS_APP_FAILED_NOTIFICATION_OPTION,
  MS_TEAMS_APP_PASSED_NOTIFICATION_OPTION,
]

import _ from 'lodash'
import React, { FunctionComponent } from 'react'
import { Banner } from '@frontend/design-system'
import { TestHistoryItemFragment } from '~/graphql-codegen-operations.gen'

import { NoHistoryOnCurrentBranch, TestHistoryItem } from './TestHistoryItem'
import { TestHistoryDisabled } from './TestHistoryDisabled'

type TestHistoryProps = {
  isEnabled: boolean
  focusedTestResultId: string | null
  onTestResultExpand(): void
  hasDuplicateTest: boolean
  history: TestHistoryItemFragment[]
  projectId: string
  runCommitBranch?: string
}

export const TestHistory: FunctionComponent<TestHistoryProps> = ({
  isEnabled,
  history,
  hasDuplicateTest,
  projectId,
  onTestResultExpand,
  focusedTestResultId,
  runCommitBranch,
}) => {
  const hasNoHistoryOnCurrentBranch = Boolean(
    runCommitBranch && history[0]?.run.commit.branch !== runCommitBranch
  )

  if (!isEnabled) {
    return <TestHistoryDisabled />
  }

  return (
    <>
      {hasDuplicateTest && (
        <Banner variant="danger">
          We've detected two test cases with the same name in this spec file.
        </Banner>
      )}
      <div className="test-history">
        {hasNoHistoryOnCurrentBranch && (
          <NoHistoryOnCurrentBranch runCommitBranch={runCommitBranch} />
        )}
        {history.map((item, i) => (
          <TestHistoryItem
            item={item}
            key={i}
            onTestResultExpand={onTestResultExpand}
            isFocused={item.testResult.id === focusedTestResultId}
            hasNoHistoryOnCurrentBranch={hasNoHistoryOnCurrentBranch}
            isOnParentBranch={
              item.run.commit.branch === _.last(history)?.run.commit.branch
            }
            isTopChildBranchCommit={i === 0}
            isBottomChildBranchCommit={
              i === history.length - 1
                ? false
                : history[i].run.commit.branch !==
                  history[i + 1].run.commit.branch
            }
            previousBranch={i === 0 ? '' : history[i - 1].run.commit.branch}
            projectId={projectId}
          />
        ))}
      </div>
    </>
  )
}

import _ from 'lodash'
import * as Sentry from '@sentry/browser'
import ReactGA from 'react-ga'
import ReactGA4 from 'react-ga4'
import { trackerNames, trackingIdG4 } from './tracking'
import { FullStory } from '~/lib/fullstory'
import { localStorageABTest } from './ab-tests'
import { features } from './feature-flags'

// This method is not supported anymore in GA4
// https://github.com/codler/react-ga4/blob/master/src/ga4.js#L341-L343
export const sendEventException = (description) => {
  ReactGA.exception({ description }, trackerNames)
}

export const sendEventModal = (url) => {
  ReactGA.modalview(url, trackerNames)

  const modalName = _.trimStart(_.trim(url), '/')
  const path = `/modal/${modalName}`

  ReactGA4.send({ hitType: 'pageview', page: path, send_to: trackingIdG4 })
}

/**
 * Load: the form was loaded
 * Success: the card was successfully charged
 * Error: there was some kind of error in Chargebee
 * Cancel: user opened the modal, but it was manually closed and not submitted
 */
type ChargebeeEventAction = 'Load' | 'Success' | 'Error' | 'Cancel'

/**
 * Use when the user is purchasing a new plan or upgrading.
 * @param type the event action, i.e. is this event referencing a successful purchase
 * @param wholeNumberInDollars Must be a whole number -- the wholeNumberInDollars that was (or would have been if Cancel) charged.
 * @param extraData any additional data to be sent
 */
export const sendPlanPurchaseEvent = (
  type: ChargebeeEventAction,
  wholeNumberInDollars?: number,
  extraData?: object | null
) => {
  sendEventCustom('Chargebee', type, {
    ...extraData,
    value: wholeNumberInDollars,
  })
}

export const sendLoginCustomEvent = (isSuccessEvent?: boolean) => {
  const loginABTestEnabled = features.isEnabled('login-ab-test')
  sendEventCustom('DashboardLogin', isSuccessEvent ? 'Success' : 'Started', {
    cohort:
      (loginABTestEnabled ? localStorageABTest('auth-layout', 2) : 0) === 0
        ? 'A'
        : 'B',
  })
}

export const sendSignupCustomEvent = (isSuccessEvent?: boolean) => {
  const loginABTestEnabled = features.isEnabled('login-ab-test')
  sendEventCustom('DashboardSignup', isSuccessEvent ? 'Success' : 'Started', {
    cohort:
      (loginABTestEnabled ? localStorageABTest('auth-layout', 2) : 0) === 0
        ? 'A'
        : 'B',
  })
}

export const sendEventCustom = (
  category: string,
  action: string,
  extraData?: {
    // This must be a whole number -- Google Analytics will silently drop events with a non-integer value (such as floats)
    value?: number | null
    [key: string]: any
  }
) => {
  // if value is undefined, it's not going to be serialized in the message sent to GA. if we send anything except for
  // a whole number (including null), GA silently drops that event.
  const value = extraData?.value ?? undefined
  if (value !== undefined && !Number.isInteger(value)) {
    Sentry.captureException(
      new Error(
        `Google Analytics value prop is not a whole number for ${category}:${action}, the value was ${value}`
      )
    )
    return
  }

  let eventData = { category, action }
  if (extraData && !_.isEmpty(extraData)) {
    eventData = { ...eventData, ...extraData }
  }

  ReactGA.event(eventData, trackerNames)
  ReactGA4.send({
    hitType: 'event',
    eventAction: action,
    eventCategory: category,
    send_to: trackingIdG4,
    ...(extraData ?? {}),
  })

  FullStory.event(category, action, extraData)
}

import { selectorFamily } from 'recoil'
import * as atoms from './atoms'
import { AnalyticsFilters } from './types'

export const filters = selectorFamily<Partial<AnalyticsFilters>, string>({
  key: 'filtersSelector',
  get:
    (key) =>
    ({ get }) => {
      return {
        branches: get(atoms.branchesSelected(key)),
        buildInterval: get(atoms.buildInterval(key)),
        timeRange: get(atoms.timeRange(key)),
        timeInterval: get(atoms.timeInterval(key)),
        tags: get(atoms.tagsSelected(key)),
        tagsMatch: get(atoms.tagsMatch(key)),
        specFiles: get(atoms.specFilesSelected(key)),
        runGroups: get(atoms.runGroupsSelected(key)),
        browsers: get(atoms.browsersSelected(key)),
        committers: get(atoms.committersSelected(key)),
        cypressVersions: get(atoms.cypressVersionsSelected(key)),
        operatingSystems: get(atoms.operatingSystemsSelected(key)),
        chartRangeTopFailures: get(atoms.chartRangeTopFailures(key)),
        chartRangeSlowestTests: get(atoms.chartRangeSlowestTests(key)),
        chartRangeMostCommonErrors: get(atoms.chartRangeMostCommonErrors(key)),
        chartFlakyTests: get(atoms.chartFlakyTests(key)),
        flaky: get(atoms.flakySelected(key)),
        viewBy: get(atoms.viewBy(key)),
        status: get(atoms.status(key)),
      }
    },
  set:
    (key) =>
    ({ set }, newValue: any) => {
      if (newValue) {
        set(atoms.branchesSelected(key), newValue.branches || [])
        set(atoms.buildInterval(key), newValue.buildInterval || undefined)
        set(atoms.timeRange(key), newValue.timeRange || undefined)
        set(atoms.timeInterval(key), newValue.timeInterval || undefined)
        set(atoms.tagsSelected(key), newValue.tags || [])
        set(atoms.tagsMatch(key), newValue.tagsMatch || 'ANY')
        set(atoms.specFilesSelected(key), newValue.specFiles || [])
        set(atoms.runGroupsSelected(key), newValue.runGroups || [])
        set(atoms.browsersSelected(key), newValue.browsers || [])
        set(atoms.committersSelected(key), newValue.committers || [])
        set(atoms.cypressVersionsSelected(key), newValue.cypressVersions || [])
        set(
          atoms.operatingSystemsSelected(key),
          newValue.operatingSystems || []
        )
        set(atoms.flakySelected(key), newValue.flaky || [])
        set(
          atoms.chartRangeTopFailures(key),
          newValue.chartRangeTopFailures || []
        )
        set(
          atoms.chartRangeSlowestTests(key),
          newValue.chartRangeSlowestTests || []
        )
        set(
          atoms.chartRangeMostCommonErrors(key),
          newValue.chartRangeMostCommonErrors || []
        )
        set(atoms.chartFlakyTests(key), newValue.chartFlakyTests || undefined)
        set(atoms.viewBy(key), newValue.viewBy || 'TEST_CASE')
        set(atoms.status(key), newValue.status || [])
      }
    },
})

export const filterOptions = selectorFamily<Partial<AnalyticsFilters>, string>({
  key: 'filterOptionsSelector',
  get:
    (key) =>
    ({ get }) => {
      return {
        branches: get(atoms.branchesOptions(key)),
        timeRange: undefined,
        timeInterval: undefined,
        buildInterval: undefined,
        tags: get(atoms.tagsOptions(key)),
        specFiles: get(atoms.specFilesOptions(key)),
        runGroups: get(atoms.runGroupsOptions(key)),
        browsers: get(atoms.browsersOptions(key)),
        committers: get(atoms.committersOptions(key)),
        cypressVersions: get(atoms.cypressVersionsOptions(key)),
        operatingSystems: get(atoms.operatingSystemsOptions(key)),
        chartRangeTopFailures: undefined,
        chartRangeSlowestTests: undefined,
        chartRangeMostCommonErrors: undefined,
        chartFlakyTests: undefined,
        viewBy: undefined,
        status: undefined,
      }
    },
  set:
    (key) =>
    ({ set }, newValue: any) => {
      if (newValue) {
        if (newValue.branches)
          set(atoms.branchesOptions(key), newValue.branches)
        // timeRange: NA - Date Based
        // timeInterval: NA - Hard Coded
        if (newValue.tags) set(atoms.tagsOptions(key), newValue.tags)
        // tagsMatch: NA
        if (newValue.specFiles)
          set(atoms.specFilesOptions(key), newValue.specFiles)
        if (newValue.runGroups)
          set(atoms.runGroupsOptions(key), newValue.runGroups)
        if (newValue.browsers)
          set(atoms.browsersOptions(key), newValue.browsers)
        if (newValue.committers)
          set(atoms.committersOptions(key), newValue.committers)
        if (newValue.cypressVersions)
          set(atoms.cypressVersionsOptions(key), newValue.cypressVersions)
        if (newValue.operatingSystems)
          set(atoms.operatingSystemsOptions(key), newValue.operatingSystems)
        // chartRangeTopFailures: NA
        // chartRangeSlowestTests: NA
        // chartRangeMostCommonErrors: NA
        //chartFlakyTests: NA
        // viewBy: NA
        // status: NA
      }
    },
})

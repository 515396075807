import { ProjectMostCommonErrorNodeFragment } from '~/graphql-codegen-operations.gen'

export const getTotalFailures = (
  nodes: ProjectMostCommonErrorNodeFragment[]
) => {
  let totalFailures = 0

  nodes.forEach((node) => {
    totalFailures += node.totalResults
  })

  return totalFailures
}

import { useRef, useState, useEffect } from 'react'

// Seems CSS cannot enforce a child element to
// define the parent element's max width, so we
// use a hook to pick and enforce these limits:
export const useRowWidthLimit = () => {
  const row1Ref = useRef<HTMLDivElement>(null)
  const row2Ref = useRef<HTMLDivElement>(null)
  const [widthLimit, setWidthLimit] = useState(0)

  useEffect(() => {
    if (row1Ref.current && row2Ref.current) {
      const r1 = row1Ref.current.getBoundingClientRect()
      const r2 = row2Ref.current.getBoundingClientRect()
      const r1Width = r1.right - r1.left
      const r2Width = r2.right - r2.left
      setWidthLimit(Math.max(r1Width, r2Width))
    }
  }, [])

  return {
    row1Ref,
    row2Ref,
    widthLimit,
  }
}

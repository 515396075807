import {
  ChartEmptyState,
  LoaderChart,
  Panel,
  Tooltip,
  palette,
} from '@frontend/design-system'
import {
  TimeIntervalEnum,
  ProjectRunsOverTimeQuery,
  ProjectRunStatusOverRunsQuery,
} from '~/graphql-codegen-operations.gen'
import _ from 'lodash'
import moment from 'moment'
import React, { FunctionComponent, useState, useEffect, useRef } from 'react'
import {
  FlexibleWidthXYPlot,
  Hint,
  HorizontalGridLines,
  VerticalRectSeries,
  XAxis,
  YAxis,
} from 'react-vis'
import { LoadingContainer } from '~/project-analytics/LoadingContainer'

import {
  ChartLegend,
  ChartLegendItemProps,
} from '~/common/chart-legend/ChartLegend'
import ErrorBoundaryDisplay from '~/lib/error-boundary'
import { useTimeSeriesAxis } from '~/lib/chart-utils'
import { statuses } from '../constants'
import { RunsOverTimeQueryData } from './RunsOverTimeAnalytic'
import { utcFormat } from 'd3-time-format'
import styles from './module.RunOverTimeChart.scss'

type RunsOverTimeChartProps = {
  data?: RunsOverTimeQueryData
  isLoading: boolean
  timeInterval?: TimeIntervalEnum | null
  navigateToRunList: (date: string, status: string, endDate: string) => void
  byBuildAnalytics: boolean
}

type RunsOverTimeDatum = {
  x: Date | number
  x0: Date | number
  y: number
  y0: number
  fullData:
    | ProjectRunsOverTimeQuery['metrics']['projectRunsOverTimeGroupedByStatus']['nodes'][number]
    | ProjectRunStatusOverRunsQuery['metrics']['projectRunDurationsOverRuns']['nodes'][number]
}

type RunsOverTimeChartTooltipProps = {
  bars: Array<ChartLegendItemProps>
  focusedValue: RunsOverTimeDatum & { width: number }
  timeInterval?: TimeIntervalEnum | null
}

interface RunsOverTimeChartBodyProps
  extends Pick<
    RunsOverTimeChartProps,
    'timeInterval' | 'navigateToRunList' | 'byBuildAnalytics'
  > {
  bars: Array<ChartLegendItemProps>
  metrics:
    | ProjectRunsOverTimeQuery['metrics']['projectRunsOverTimeGroupedByStatus']
    | ProjectRunStatusOverRunsQuery['metrics']['projectRunDurationsOverRuns']
}

function formatDataForChart(
  data: RunsOverTimeChartBodyProps['metrics']['nodes'],
  type: string,
  timeInterval?: TimeIntervalEnum | null,
  byBuildAnalytics: boolean = false
): Array<RunsOverTimeDatum> {
  // if the time interval is daily, center bar over the date
  if (timeInterval === 'DAY') {
    return data.map((d, idx) => {
      const t = moment.utc(d.timeSeriesPointStartDatetime)
      const x0 = byBuildAnalytics
        ? idx - 0.5
        : t.clone().subtract(6, 'hours').toDate()
      const x = byBuildAnalytics
        ? idx + 0.5
        : t.clone().add(6, 'hours').toDate()

      return {
        x,
        x0,
        y: d[type],
        y0: 0,
        fullData: d,
      }
    })
  }

  return data.map((d, idx) => {
    const t = moment.utc(d.timeSeriesPointStartDatetime)
    const x0 = byBuildAnalytics ? idx - 0.5 : t.clone().toDate()
    const x = byBuildAnalytics
      ? idx + 0.5
      : t
          .clone()
          // @ts-ignore - todo: fix this
          .add(moment.duration(1, timeInterval).as('hours'), 'hours')
          .toDate()

    return {
      x,
      x0,
      y: d[type],
      y0: 0,
      fullData: d,
    }
  })
}

const RunsOverTimeChartTooltip: FunctionComponent<
  RunsOverTimeChartTooltipProps
> = ({ bars, focusedValue, timeInterval }) => {
  return (
    <table>
      <thead>
        <tr>
          <th colSpan={3}>
            {timeInterval && timeInterval !== 'DAY' && (
              <>{_.capitalize(timeInterval)} beginning </>
            )}
            {focusedValue.fullData.timeSeriesPointStartDatetime &&
              moment(focusedValue.fullData.timeSeriesPointStartDatetime).format(
                'MMM D, YYYY'
              )}
          </th>
        </tr>
      </thead>
      <tbody>
        {_.reverse(
          bars.map((bar, i) => {
            const barValue = focusedValue.fullData[bar.name]

            if (!barValue) {
              return null
            }

            return (
              <tr key={i}>
                <td style={{ width: '8px' }}>
                  <div
                    className="bullet"
                    style={{
                      backgroundColor: bar.color,
                      border:
                        bar.color === palette.grayA2
                          ? `1px solid ${palette.gray300}`
                          : 'none',
                    }}
                  />
                </td>
                <td>{bar.title}</td>
                <td>{barValue}</td>
              </tr>
            )
          })
        )}
        <tr>
          <td></td>
          <td>Total</td>
          <td>{focusedValue.fullData.count}</td>
        </tr>
      </tbody>
    </table>
  )
}

type FocusedValueState = {
  x: null | number | Date
  fullData: null | RunsOverTimeDatum['fullData']
  width: number
}

const RunsOverTimeChartBody: FunctionComponent<RunsOverTimeChartBodyProps> = ({
  bars,
  metrics,
  timeInterval,
  navigateToRunList,
  byBuildAnalytics,
}) => {
  const [chartTimeInterval, setChartTimeInterval] = useState(timeInterval)
  const chartTimeIntervalRef = useRef(() => {})

  useEffect(() => {
    chartTimeIntervalRef.current = () => {
      if (timeInterval !== chartTimeInterval) {
        setChartTimeInterval(timeInterval)
      }
    }
  }, [chartTimeInterval, timeInterval])

  const [focusedValue, setFocusedValue] = useState<FocusedValueState>({
    x: null,
    fullData: null,
    width: 0,
  })

  useEffect(() => {
    chartTimeIntervalRef.current()
  }, [metrics])

  const focusValue = (
    value: RunsOverTimeDatum,
    event: { event: React.MouseEvent<any> }
  ) => {
    setFocusedValue({
      ...value,
      // @ts-ignore
      width: event.event.target?.getBoundingClientRect?.().width ?? 0,
    })
  }

  const clearFocus = () => {
    setFocusedValue({
      x: null,
      fullData: null,
      width: 0,
    })
  }

  const chartRef = useRef<FlexibleWidthXYPlot<any> | null>(null)
  const xAxis = useTimeSeriesAxis(
    metrics.nodes.map((d) => d.timeSeriesPointStartDatetime),
    timeInterval ?? null,
    { chartRef }
  )

  const allSeries = bars.map((bar) => {
    return {
      bar,
      data: formatDataForChart(
        metrics.nodes,
        bar.name,
        chartTimeInterval,
        byBuildAnalytics
      ),
    }
  })

  const handleClick = (status?: string) => {
    if (focusedValue?.fullData?.timeSeriesPointStartDatetime && status) {
      navigateToRunList(
        focusedValue?.fullData?.timeSeriesPointStartDatetime,
        status,
        (
          focusedValue?.fullData as ProjectRunStatusOverRunsQuery['metrics']['projectRunDurationsOverRuns']['nodes'][number]
        )?.timeSeriesPointEndDatetime
      )
    }
  }

  return (
    <Panel.Body>
      <FlexibleWidthXYPlot
        className="runs-over-time-chart"
        height={300}
        ref={chartRef}
        stackBy="y"
      >
        <HorizontalGridLines />
        {!byBuildAnalytics ? (
          <XAxis
            tickTotal={xAxis.tickTotal}
            tickFormat={xAxis.tickFormat}
            tickValues={xAxis.tickValues}
          />
        ) : (
          <XAxis
            tickFormat={(tickValue) => {
              // Get the date value from the first available series
              if (allSeries.length === 0) {
                return ''
              }
              const value = allSeries[0].data.find(
                (d) => (d.x0 as number) + 0.5 === tickValue
              )
              return value && value.fullData
                ? utcFormat('%b %d')(
                    moment
                      .utc(value.fullData.timeSeriesPointStartDatetime)
                      .toDate()
                  )
                : ''
            }}
          />
        )}

        <YAxis hideLine />
        {allSeries.map(({ bar, data }) => {
          return (
            <VerticalRectSeries
              color={bar.color}
              data={data}
              stroke={palette.grayA1}
              onValueMouseOver={(val: any, evt: any) => focusValue(val, evt)}
              key={bar.name}
              onValueClick={() => handleClick(bar.filterValue)}
              onValueMouseOut={clearFocus}
              style={{ cursor: 'pointer' }}
            />
          )
        })}
        {focusedValue && focusedValue.fullData && (
          <Hint value={focusedValue}>
            <Tooltip
              id="runs-over-time-chart__tooltip"
              align={{
                points: ['bc', 'tc'],
                offset: [-focusedValue.width / 2, 0],
                overflow: { alwaysByViewport: true },
              }}
              overlay={
                <RunsOverTimeChartTooltip
                  bars={bars}
                  focusedValue={focusedValue as any}
                  timeInterval={chartTimeInterval}
                />
              }
              visible
              // If the mouse hovered over the tooltip, it seemed
              // as if the mouse left the bar. This css ensures that
              // the browser still counts hovering over the tooltip
              // as hovering over whatever is *behind* the tooltip.
              overlayClassName={styles.pointerEventNone}
            >
              <span />
            </Tooltip>
          </Hint>
        )}
      </FlexibleWidthXYPlot>
    </Panel.Body>
  )
}

const RunsOverTimeChart: FunctionComponent<RunsOverTimeChartProps> = ({
  data,
  isLoading,
  timeInterval,
  navigateToRunList,
  byBuildAnalytics,
}) => {
  const metrics = React.useMemo(() => {
    return byBuildAnalytics
      ? (data as ProjectRunStatusOverRunsQuery)?.metrics
          .projectRunDurationsOverRuns
      : (data as ProjectRunsOverTimeQuery)?.metrics
          .projectRunsOverTimeGroupedByStatus
  }, [data, byBuildAnalytics])
  const barsWithData: Array<ChartLegendItemProps> = React.useMemo(() => {
    return _.filter(statuses, (bar) => {
      return metrics?.nodes.some((nodeValue) => nodeValue[bar.name] > 0)
    })
  }, [metrics])
  const hasData = React.useMemo(() => {
    if (!metrics) {
      return false
    }
    return byBuildAnalytics
      ? metrics?.nodes.some((nodeValue) => (nodeValue.count ?? 0) > 0)
      : (
          metrics as ProjectRunsOverTimeQuery['metrics']['projectRunsOverTimeGroupedByStatus']
        ).count > 0
  }, [byBuildAnalytics, metrics])

  if (!data || !data.metrics) {
    return (
      <Panel className="analytic__chart runs-over-time-chart">
        <Panel.Heading>
          <Panel.Title>Total runs over time</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <LoaderChart />
        </Panel.Body>
      </Panel>
    )
  }

  return (
    <LoadingContainer active={isLoading}>
      <ErrorBoundaryDisplay>
        <Panel className="analytic__chart runs-over-time-chart">
          <Panel.Heading>
            <Panel.Title>Total runs over time</Panel.Title>
            <ChartLegend items={barsWithData} />
          </Panel.Heading>
          {hasData ? (
            <RunsOverTimeChartBody
              bars={barsWithData}
              metrics={metrics}
              timeInterval={timeInterval}
              navigateToRunList={navigateToRunList}
              byBuildAnalytics={byBuildAnalytics}
            />
          ) : (
            <ChartEmptyState>
              <p>No runs match the supplied filters.</p>
            </ChartEmptyState>
          )}
        </Panel>
      </ErrorBoundaryDisplay>
    </LoadingContainer>
  )
}

export { RunsOverTimeChart, RunsOverTimeChartTooltip }

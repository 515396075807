import { format, parseISO } from 'date-fns'
import { flatten, identity, isString, uniq } from 'lodash'
import { TimeRangeInput } from '~/graphql-codegen-operations.gen'
import { MultiSelectOptionType } from './types'

export function getMultiSelectOptionsFromValues(
  values: any[] | null
): MultiSelectOptionType[] | undefined {
  if (!values || values.length === 0) {
    return []
  }

  return values.map((value) => ({ value, label: value }))
}

export function getValuesFromMultiSelectOptions(
  options: MultiSelectOptionType[],
  formatter: (option: string) => any = identity
) {
  if (!options || options.length === 0) {
    return
  }

  return uniq(flatten(options.map(({ value }) => formatter(value))))
}

export function formatDateRangeDate(date: Date | string | null | undefined) {
  return isString(date) ? parseISO(date) : date
}

export type TimeRangeDates = {
  id?: string
  startDate: Date
  endDate?: Date | null
}

export function timeRangeAsDates(
  range: TimeRangeDates | TimeRangeInput
): TimeRangeDates {
  return {
    startDate: formatDateRangeDate(range.startDate) || new Date(),
    endDate: formatDateRangeDate(range.endDate),
  }
}

export function timeRangeAsInput(
  range: TimeRangeDates | TimeRangeInput
): TimeRangeInput {
  return {
    startDate: formatDateRangeDateISO(range.startDate),
    endDate: formatDateRangeDateISO(range.endDate),
  }
}

export function formatDateRangeDateISO(date: Date | null | undefined) {
  return date
    ? format(isString(date) ? parseISO(date) : date, 'yyyy-MM-dd')
    : date
}

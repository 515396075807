import {
  ChartEmptyState,
  LoaderChart,
  Panel,
  Tooltip,
  palette,
} from '@frontend/design-system'
import {
  TimeIntervalEnum,
  ProjectRunDurationsOverTimeQuery,
  ProjectRunDurationsOverRunsQuery,
} from '~/graphql-codegen-operations.gen'
import { isoParse, utcFormat } from 'd3-time-format'
import { format } from 'date-fns'
import _ from 'lodash'
import pluralize from 'pluralize'
import React, { FunctionComponent, useState, useRef, useMemo } from 'react'
import {
  AreaSeries,
  FlexibleWidthXYPlot,
  Hint,
  HorizontalGridLines,
  LineMarkSeries,
  LineSeries,
  XAxis,
  YAxis,
} from 'react-vis'
import ErrorBoundaryDisplay from '~/lib/error-boundary'
import { getUTCDateAsLocalDate, durationFormattedFull } from '~/lib/utils'
import { formatTimeIntervalAxis, useTimeSeriesAxis } from '~/lib/chart-utils'
import { LoadingContainer } from '~/project-analytics/LoadingContainer'
import moment from 'moment'

type QueryData =
  | ProjectRunDurationsOverTimeQuery
  | ProjectRunDurationsOverRunsQuery

type RunDurationChartProps = {
  data?: QueryData
  isLoading: boolean
  timeInterval?: TimeIntervalEnum | null
  byBuildAnalytics: boolean
  navigateToRunList: (date: string, dateEnd?: string) => void
}

type RunDurationChartBodyProps = {
  byBuildAnalytics: boolean
  metrics: QueryData['metrics']['projectRunDurationsOverTime']
  timeInterval?: TimeIntervalEnum | null
  navigateToRunList: (date: string, dateEnd?: string) => void
}

type RunDurationChartDatum = {
  x: number
  y: number
  minutes: number | null
  seconds: number | null
  timeInterval?: TimeIntervalEnum | null
  fullData: Partial<
    QueryData['metrics']['projectRunDurationsOverTime']['nodes'][0]
  >
  hasData: boolean
}

function formatDataForChart(
  data: RunDurationChartBodyProps['metrics']['nodes'],
  byBuildAnalytics: boolean
): Array<RunDurationChartDatum> {
  return data.map((d, index) => {
    const x = isoParse(d.timeSeriesPointStartDatetime)
    return {
      x: byBuildAnalytics ? index : x!.valueOf(),
      y: (d.averageRuntime ?? 0) * 1000,
      minutes: Math.floor((d.averageRuntime ?? 0) / 60),
      seconds: (d.averageRuntime ?? 0) % 60, // seconds to be displayed with minutes
      fullData: d,
      hasData: Boolean(d.averageRuntime),
    }
  })
}

const RunDurationChartBody: FunctionComponent<RunDurationChartBodyProps> = ({
  metrics,
  timeInterval,
  navigateToRunList,
  byBuildAnalytics,
}) => {
  // Focus handling
  const [focusedValue, setFocusedValue] =
    useState<RunDurationChartDatum | null>(null)
  const onNearestX = (value) => setFocusedValue(value)
  const clearFocus = () => setFocusedValue(null)
  const chartRef = useRef<FlexibleWidthXYPlot<any> | null>(null)

  const series = formatDataForChart(metrics.nodes, byBuildAnalytics)
  const xAxis = useTimeSeriesAxis(
    series.map((d) => d.x),
    timeInterval ?? null,
    { chartRef }
  )
  const yAxis = useMemo(
    () => formatTimeIntervalAxis(series.map((d) => d.y)),
    [series]
  )

  // Create a special series that "interpolates" over dates with missing data
  const isDefined = (d: any) => d.hasData
  const undefinedSeries = series.filter(isDefined)
  const hasUndefinedValues = undefinedSeries.length < series.length

  const handleClick = () =>
    focusedValue?.fullData.timeSeriesPointStartDatetime &&
    navigateToRunList(
      focusedValue?.fullData.timeSeriesPointStartDatetime,
      (
        focusedValue?.fullData as ProjectRunDurationsOverRunsQuery['metrics']['projectRunDurationsOverTime']['nodes'][0]
      ).timeSeriesPointEndDatetime
    )

  return (
    <Panel.Body>
      <FlexibleWidthXYPlot
        ref={chartRef}
        className="run-duration-chart"
        height={300}
        yDomain={yAxis.domain}
        onMouseLeave={clearFocus}
      >
        <HorizontalGridLines tickValues={yAxis.tickValues} />
        {!byBuildAnalytics ? (
          <XAxis
            tickTotal={xAxis.tickTotal}
            tickFormat={xAxis.tickFormat}
            tickValues={xAxis.tickValues}
          />
        ) : (
          <XAxis
            tickFormat={(tickValue) => {
              const value = series.find((d) => d.x === tickValue)
              return value && value.hasData
                ? utcFormat('%b %d')(
                    moment
                      .utc(value.fullData.timeSeriesPointStartDatetime)
                      .toDate()
                  )
                : ''
            }}
          />
        )}
        <YAxis
          hideLine
          tickFormat={yAxis.tickFormat}
          tickValues={yAxis.tickValues}
        />

        {hasUndefinedValues && (
          <AreaSeries
            color={palette.gray200}
            data={undefinedSeries}
            opacity={0.1}
            key="missing-area-series"
          />
        )}
        {hasUndefinedValues && (
          <LineSeries
            color={palette.gray200}
            data={undefinedSeries}
            style={{ strokeDasharray: '4 4' }}
            key="missing-line-series"
            className="run-duration-chart--missing-data-series"
          />
        )}

        <AreaSeries
          color={palette.indigo600}
          data={series}
          onNearestX={onNearestX}
          opacity={0.2}
          key="area-series"
          getNull={isDefined}
        />

        <LineMarkSeries
          color={palette.indigo600}
          data={series}
          getSize={() => 3}
          markStyle={{ stroke: 'white' }}
          key="line-series"
          getNull={isDefined}
          onSeriesClick={handleClick}
          style={{ cursor: 'pointer' }}
        />

        {focusedValue && isDefined(focusedValue) && (
          <Hint
            value={focusedValue}
            align={{ horizontal: 'left', vertical: 'top' }}
          >
            <Tooltip
              id="run-duration-chart__tooltip"
              overlay={
                <table>
                  <thead>
                    <tr>
                      <th colSpan={3}>
                        {timeInterval &&
                          timeInterval !== 'DAY' &&
                          !byBuildAnalytics && (
                            <>{_.capitalize(timeInterval)} beginning </>
                          )}
                        {format(
                          getUTCDateAsLocalDate(
                            focusedValue.fullData.timeSeriesPointStartDatetime
                          ),
                          'PP'
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="bullet color-bullet" />
                      </td>
                      <td>Average run duration</td>
                      <td>
                        <strong>
                          {durationFormattedFull(focusedValue.y!, 2, true)}
                        </strong>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Saved by parallelization</td>
                      <td>
                        {focusedValue.fullData.parallelizationSavings ? (
                          durationFormattedFull(
                            focusedValue.fullData.parallelizationSavings * 1000,
                            2,
                            true
                          )
                        ) : (
                          <span>0s</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        {pluralize(
                          'Machine',
                          focusedValue.fullData.medianConcurrency!
                        )}
                      </td>
                      <td>
                        {focusedValue.fullData.medianConcurrency
                          ? Math.round(focusedValue.fullData.medianConcurrency)
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        {pluralize('Run', focusedValue.fullData.totalRuns!)}
                      </td>
                      <td>{focusedValue.fullData.totalRuns}</td>
                    </tr>
                  </tbody>
                </table>
              }
              placement="top"
              visible
            >
              <span />
            </Tooltip>
          </Hint>
        )}
      </FlexibleWidthXYPlot>
    </Panel.Body>
  )
}

export const RunDurationChart: FunctionComponent<RunDurationChartProps> = ({
  data,
  isLoading,
  timeInterval,
  navigateToRunList,
  byBuildAnalytics,
}) => {
  if (!data || !data.metrics) {
    return (
      <Panel className="analytic__chart run-duration-chart">
        <Panel.Heading>
          <Panel.Title>Average run duration over time</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <LoaderChart />
        </Panel.Body>
      </Panel>
    )
  }

  const metrics = data.metrics.projectRunDurationsOverTime
  const hasData = metrics && metrics.averageRuntime > 0

  return (
    <LoadingContainer active={isLoading}>
      <ErrorBoundaryDisplay>
        <Panel className="analytic__chart run-duration-chart">
          <Panel.Heading>
            <Panel.Title>Average run duration over time</Panel.Title>
          </Panel.Heading>
          {hasData ? (
            <RunDurationChartBody
              byBuildAnalytics={byBuildAnalytics}
              metrics={metrics}
              timeInterval={timeInterval}
              navigateToRunList={navigateToRunList}
            />
          ) : (
            <ChartEmptyState>
              <p>No runs match the supplied filters.</p>
            </ChartEmptyState>
          )}
        </Panel>
      </ErrorBoundaryDisplay>
    </LoadingContainer>
  )
}

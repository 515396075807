import { TimeIntervalOptions } from '@packages/common/src'
import { DatesUtilsForQueries } from '@packages/data-context/src/utils/dates'
import {
  useProjectTestSuiteSizeOverTimeQuery,
  TimeIntervalEnum,
} from '~/graphql-codegen-operations.gen'
import { StackedLayout } from '~/common/stacked-layout'
import { useNavigate } from '@reach/router'
import { parseISO } from 'date-fns'
import fileDownload from 'js-file-download'
import React, { FunctionComponent, useEffect } from 'react'
import DocumentTitle from 'react-document-title'
import {
  formatDateRangeDateISO,
  getQueryStringFromFilters,
  getValuesFromMultiSelectOptions,
} from '~/common/filters'
import { FiltersContainer } from '~/common/filters/FiltersContainer'
import { ErrorBoundaryContent } from '~/lib/error-boundary'
import { sendEventCustom } from '~/lib/page-events'

import { downloadAnalyticsCSV, getAnalyticsCSVFileName } from '../analytics-api'
import { DownloadCSV } from '../DownloadCSV'
import { Kpis } from '../Kpis'
import { TestSuiteSizeChart } from './TestSuiteSizeChart'
import { TestSuiteSizeTable } from './TestSuiteSizeTable'
import Filters from '../filters/Filters'
import * as analytics from '~/data/projects/analytics/hooks'
import { getKPIs } from './TestSuiteSizeKPI'
import { features } from '~/lib/feature-flags'

type TestSuiteSizeAnalyticProps = {
  projectId: string
  familyId: string
}

export const TestSuiteSizeAnalytic: FunctionComponent<
  TestSuiteSizeAnalyticProps
> = ({ projectId, familyId }) => {
  const navigate = useNavigate()
  const { selected: branches } = analytics.useBranchesFilter(familyId)
  const { selected: tags, matchType: tagsMatch } =
    analytics.useTagsFilter(familyId)
  const { timeInterval } = analytics.useTimeIntervalFilter(familyId)
  const { timeRange } = analytics.useTimeRangeFilter(familyId)
  const { testSuiteSizeOverTime, setTestSuiteSizeOverTime } =
    analytics.useTestSuiteSizeOverTime()
  analytics.useEventTracking('test-suite-size', 'Test suite size', familyId)

  const byBuildAnalytics =
    features.isEnabled('analytics-p1') &&
    features.isEnabled('analytics-p1-test-suite-size')
  // Don't add the tagsMatch parameter if there is no tags parameter.
  const tagsMatchOrUndefined = getValuesFromMultiSelectOptions(tags || [])
    ? tagsMatch
    : undefined
  const sharedInput = {
    projectId,
    branches: getValuesFromMultiSelectOptions(branches || []),
    tags: getValuesFromMultiSelectOptions(tags || []),
    tagsMatch: tagsMatchOrUndefined,
    timeRange: {
      startDate: formatDateRangeDateISO(timeRange?.startDate),
      endDate: formatDateRangeDateISO(timeRange?.endDate),
    },
  }

  const {
    data,
    loading: queryRunning,
    error,
  } = useProjectTestSuiteSizeOverTimeQuery({
    variables: {
      input: {
        ...sharedInput,
        ...(byBuildAnalytics
          ? { intervalType: 'BUILD' }
          : { timeInterval, intervalType: 'TIME' }),
      },
    },
  })

  useEffect(() => {
    if (!queryRunning && data) setTestSuiteSizeOverTime(data)
  }, [data, queryRunning, setTestSuiteSizeOverTime])

  async function downloadCSV() {
    const fileName = getAnalyticsCSVFileName('test-suite-size', timeRange)
    const response = await downloadAnalyticsCSV({
      id: projectId,
      type: 'test-suite-size',
      params: {
        branches: getValuesFromMultiSelectOptions(branches || []),
        tags: getValuesFromMultiSelectOptions(tags || []),
        tagsMatch: tagsMatchOrUndefined,
        timeInterval: (timeInterval &&
          timeInterval.toLowerCase()) as TimeIntervalEnum,
        startDate: formatDateRangeDateISO(timeRange?.startDate),
        endDate: formatDateRangeDateISO(timeRange?.endDate),
      },
    })
    /* istanbul ignore next */
    sendEventCustom('Analytics', 'Export', {
      analyticID: 'test-suite-size',
      label: 'Test suite size',
    })

    if (!window.Cypress) {
      /* istanbul ignore next */
      fileDownload(response, `${fileName}.csv`)
    }
  }

  const navigateToRunList = (date: string) => {
    const timeRangeForRuns = new DatesUtilsForQueries({
      timeRange: {
        startDate: parseISO(date),
      },
      timeInterval: TimeIntervalOptions[timeInterval!],
    }).datesForFilters

    const usedFilters = {
      branches,
      timeRange: timeRangeForRuns,
      status: [
        { value: 'PASSED', label: 'PASSED' },
        { value: 'FAILED', label: 'FAILED' },
      ],
      tags,
    }
    const updatedQueryString = getQueryStringFromFilters(usedFilters)
    navigate(`/projects/${projectId}/runs${updatedQueryString}`)
  }

  const isLoading = queryRunning || error !== undefined
  const kpis = getKPIs(testSuiteSizeOverTime, queryRunning)

  return (
    <DocumentTitle title="Test Suite Size">
      <StackedLayout title="Test suite size" className="no-content-padding">
        <div className="project-analytics">
          <div className="test-suite-size-analytic analytic">
            <FiltersContainer
              extra={
                <DownloadCSV analytic="test-suite-size" onClick={downloadCSV} />
              }
            >
              <Filters
                familyId={familyId}
                branches
                tags
                timeRange
                timeInterval={!byBuildAnalytics}
              />
            </FiltersContainer>
            {error ? (
              <ErrorBoundaryContent />
            ) : (
              <>
                <TestSuiteSizeChart
                  data={testSuiteSizeOverTime}
                  timeInterval={timeInterval}
                  isLoading={isLoading}
                  navigateToRunList={navigateToRunList}
                  byBuildAnalytics={byBuildAnalytics}
                />
                <Kpis
                  kpis={kpis}
                  isLoading={isLoading}
                  data-cy="test-suite-size-kpis"
                />
                <TestSuiteSizeTable
                  data={testSuiteSizeOverTime}
                  isLoading={isLoading}
                />
              </>
            )}
          </div>
        </div>
      </StackedLayout>
    </DocumentTitle>
  )
}

import type { BrowserName } from '@packages/types'

export const RECORDING_SERVICE_EXPIRATION_TIME = 60 * 60 * 24

export const CHROMIUM_BROWSERS: Array<BrowserName> = [
  'electron',
  'chrome',
  'chromium',
  'edge',
  'custom chromium',
  'custom chrome',
  'custom edge',
  'canary',
  'chrome beta',
  'edge dev',
  'edge canary',
  'brave',
  'chromium (npm)',
  'opera',
  'chromep',
  'google chrome',
  'edge beta',
  'local electron app',
  'chromium for bamboo',
]

export const DEFAULT_BURN_IN_CONFIG = { default: 3, flaky: 5 }

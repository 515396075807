import React, { FunctionComponent, useState } from 'react'
import cs from 'clsx'
import { Icon, IconButton, MarkDown } from '@frontend/design-system'
import pluralize from 'pluralize'
import { TestResultAnalyticCommonErrorFragment } from '~/graphql-codegen-operations.gen'
import styles from './module.MostCommonError.scss'

type MostCommonErrorProps = {
  error: TestResultAnalyticCommonErrorFragment
  isLast?: boolean
  resultsNoun?: string
}

export const MostCommonError: FunctionComponent<MostCommonErrorProps> = ({
  error,
  isLast = false,
  resultsNoun = 'time',
}) => {
  const [isExpanded, setExpanded] = useState(false)

  const errorMessage = error.error.message

  const strSplitPosition = errorMessage.indexOf('\n\n')

  const errorMessageTruncated =
    strSplitPosition > -1
      ? errorMessage.slice(0, strSplitPosition)
      : errorMessage

  const errorMessageRemainder =
    strSplitPosition > -1 ? errorMessage.slice(strSplitPosition) : ''

  return (
    <div
      data-cy="most-common-error"
      className={cs(styles.container, { [styles.lastContainer]: isLast })}
    >
      <div className={styles.header}>
        <h5>
          <Icon name="exclamation-circle" />
          {error.error.name}
        </h5>
        <small>
          {error.totalResults} {pluralize(resultsNoun, error.totalResults)}
        </small>
      </div>
      <pre>
        <MarkDown message={errorMessageTruncated} />
      </pre>
      {errorMessageRemainder && (
        <IconButton
          aria-label={`${isExpanded ? 'Collapse' : 'Expand'} error message`}
          icon="more-message"
          onClick={() => setExpanded(!isExpanded)}
        />
      )}
      {isExpanded && (
        <pre>
          <MarkDown message={errorMessageRemainder} />
        </pre>
      )}
    </div>
  )
}

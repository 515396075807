import React from 'react'
import styles from './module.LoadingDots.scss'

export const LoadingDots: React.FC = () => (
  <div style={{ height: 12, width: 12 }} data-cy="discovery-icon-processing">
    <svg viewBox="0 0 12 12" className={styles['icon-waiting']}>
      <circle
        id="loading-dot-1"
        className={styles['icon-waiting-dot-1']}
        vectorEffect="non-scaling-stroke"
        r="0.8"
        cx="1"
        cy="6"
      />
      <circle
        id="loading-dot-2"
        className={styles['icon-waiting-dot-2']}
        vectorEffect="non-scaling-stroke"
        r="0.8"
        cx="4"
        cy="6"
      />
      <circle
        id="loading-dot-3"
        className={styles['icon-waiting-dot-3']}
        vectorEffect="non-scaling-stroke"
        r="0.8"
        cx="7"
        cy="6"
      />
    </svg>
  </div>
)

import React, { FunctionComponent } from 'react'
import { features } from '~/lib/feature-flags'
import {
  useDrawerTestAnalyticActionBarQuery,
  useDrawerTestAnalyticActionBarOrganizationQuery,
} from '~/graphql-codegen-operations.gen'
import { ActionBarMute } from '~/test-results/drawer/TestResultDrawerActionBar/ActionBarMute'
import { ActionBarJiraIssue } from '~/test-results/drawer/TestResultDrawerActionBar/ActionBarJiraIssue'
import { userIsMemberOfOrg } from '~/lib/roles'

type TestAnalyticDrawerActionBarProps = {
  projectId: string
  specHash: string
  titleHash: string
  onJiraIssueClick: () => void
  orgId: string
}

export const TestAnalyticDrawerActionBar: FunctionComponent<
  TestAnalyticDrawerActionBarProps
> = ({ projectId, specHash, titleHash, onJiraIssueClick, orgId }) => {
  const { data } = useDrawerTestAnalyticActionBarQuery({
    variables: {
      projectId,
      specHash,
      titleHash,
    },
  })

  const shouldSkipOrgQuery = !orgId || !userIsMemberOfOrg(orgId)
  const { data: orgData } = useDrawerTestAnalyticActionBarOrganizationQuery({
    variables: {
      orgId,
    },
    skip: shouldSkipOrgQuery,
  })

  const isBeingMuted = Boolean(
    data?.testResultAnalytic?.activeMuteOrchestration
  )

  const org = orgData?.organization

  return (
    <div className="test-results-drawer__action-bar">
      {features.isEnabled('test-muting') &&
        specHash &&
        data?.testResultAnalytic &&
        org && (
          <ActionBarMute
            plan={org.plan}
            isBeingMuted={isBeingMuted}
            projectId={projectId}
            specHash={specHash}
            titleHash={titleHash}
            disabled={!org.subscription?.features?.flakeMitigation}
            orgId={org.id}
            viewerIsOwnerOrAdmin={org.viewerIsOwnerOrAdmin}
          />
        )}
      {org && (
        <ActionBarJiraIssue
          plan={org.plan}
          onJiraIssueClick={onJiraIssueClick}
          isAvailableOnCurrentPlan={org.subscription?.features?.jiraIntegration}
          isInstalled={Boolean(org.jiraAppInstallations)}
          orgId={org.id}
          viewerIsOwnerOrAdmin={org.viewerIsOwnerOrAdmin}
        />
      )}
    </div>
  )
}

import 'videojs-vjsdownload'

import React, { Component } from 'react'
import videojs from 'video.js'

export default class Video extends Component {
  componentDidMount() {
    if (this.props.startTime) {
      // startTime is in ms in failures view,
      // convert to secs & round down
      // so we don't pass the wanted recording in video
      const secs = Math.floor(this.props.startTime / 1000)

      this.refs.video.currentTime = secs
    }

    const settings = {
      controls: true,
      preload: 'auto',
      aspectRatio: '16:9',
      fluid: true,
      playbackRates: [0.25, 0.5, 1, 1.5, 2, 4],
      controlBar: {
        volumePanel: false,
      },
      inactivityTimeout: 0,
      plugins: {
        vjsdownload: {
          beforeElement: 'fullscreenToggle',
          textControl: 'Download video',
          name: 'downloadButton',
        },
      },
    }

    videojs(this.refs.video, settings, () => {})
  }

  render() {
    return (
      <div className="embed-responsive embed-responsive-16by9 video-wrapper">
        <video
          autoPlay={true}
          ref="video"
          id={this.props.id}
          className="video-js vjs-default-skin vjs-big-play-centered"
        >
          <source
            src={this.props.url || this.props.videoUrl}
            type="video/mp4"
          />
          <p className="vjs-no-js">
            To view this video please enable JavaScript, and consider upgrading
            to a web browser that
            <a
              href="http://videojs.com/html5-video-support/"
              target="_blank"
              rel="noopener noreferrer"
            >
              supports HTML5 video
            </a>
            .
          </p>
        </video>
      </div>
    )
  }
}

import { durationFormattedFull } from '~/lib/utils'
import pluralize from 'pluralize'
import {
  ProjectRunDurationsOverRunsQuery,
  ProjectRunDurationsOverTimeQuery,
} from '~/graphql-codegen-operations.gen'

export const getKPIs = (
  runDurationsOverTime:
    | ProjectRunDurationsOverTimeQuery
    | ProjectRunDurationsOverRunsQuery
    | undefined,
  queryRunning: boolean
) => {
  let kpis
  let loading = queryRunning
  if (runDurationsOverTime) {
    const metrics = runDurationsOverTime.metrics.projectRunDurationsOverTime
    loading = queryRunning && !metrics

    kpis = [
      {
        name: 'Average run duration',
        value: durationFormattedFull(metrics.averageRuntime * 1000, 2, true),
        loading,
      },
      {
        name: 'Typical concurrency',
        value: Math.round(metrics.medianConcurrency),
        subtitle: pluralize('machine', Math.round(metrics.medianConcurrency)),
        loading,
        tooltipInfo:
          'The median number of machines that were used for parallelizing for these runs',
      },
      {
        name: 'Time saved from parallelization',
        value: durationFormattedFull(
          metrics.parallelizationSavings * 1000,
          2,
          true
        ),
        loading,
      },
    ]
  } else {
    kpis = [
      {
        name: 'Average run duration',
        loading,
      },
      {
        name: 'Typical concurrency ℹ',
        loading,
        tooltipInfo:
          'The median number of machines that were used for parallelizing for these runs',
      },
      {
        name: 'Time saved from parallelization',
        loading,
      },
    ]
  }
  return kpis
}

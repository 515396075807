import type { OsName } from '@packages/types'
import _ from 'lodash'
import {
  PossibleOperatingSystems,
  PossibleOperatingSystemsValue,
} from '../enums'

type OsPlatform = 'mac' | 'windows' | 'linux'

export const osNameLookup = {
  darwin: 'mac',
  win32: 'windows',
  linux: 'linux',
}

export const platformByOs = {
  mac: 'darwin',
  windows: 'win32',
  linux: 'linux',
}

export const getPlatformByOs = (osName?: OsPlatform | string | null) => {
  if (!osName) return ''

  return platformByOs[_.lowerCase(osName) as OsPlatform] || _.lowerCase(osName)
}

export const osNameFormatted = (osName?: OsName | string | null) => {
  if (!osName) return ''

  return _.capitalize(osNameLookup[osName as OsName] || osName)
}

export const osNameToEnum = (
  osName: string | null
): PossibleOperatingSystemsValue => {
  if (!osName) return PossibleOperatingSystems.UNKNOWN

  osName = osNameLookup[osName as OsName]
  return (
    PossibleOperatingSystems[
      _.upperCase(osName) as keyof typeof PossibleOperatingSystems
    ] || PossibleOperatingSystems.UNKNOWN
  )
}

import currentUser from '../auth/current-user-model'
import { dashboardApolloClient } from '~/gql/dashboardApolloClient'
import {
  BillingAndUsageDocument,
  CurrentUsageDocument,
  CurrentUsageQuery,
} from '~/graphql-codegen-operations.gen'
import isUuid from 'is-uuid'
import { abTest } from './ab-tests'

interface Org {
  id: string
  name?: string
}

type FullStoryType = {
  identify: (
    userId: string,
    userVars?: {
      [key: string]: any
    }
  ) => void
  event: (eventName: string, eventProperties: any) => void
}

declare global {
  interface Window {
    FS: FullStoryType
  }
}

export const FullStory = {
  init() {
    // FS starts recording automatically upon load
    // here we simply initialize an identifier
    const orgId = window.location.pathname.startsWith('/organizations/')
      ? window.location.pathname.split('/')[2]
      : ''

    this.identify({ id: orgId })
  },

  identify(org: Org) {
    /* istanbul ignore if */
    if (typeof window.FS !== 'undefined') {
      this.userVars(org)
        .then((userVars) => window.FS.identify(this.userId(), userVars))
        .catch(() => {})
    }
  },

  userId() {
    return window.env === 'production'
      ? currentUser.id
      : `${window.env}_${currentUser.id}`
  },

  event(category: string, action: string, extraData: any) {
    /* istanbul ignore if */
    if (typeof window.FS !== 'undefined') {
      try {
        window.FS.event(`${category} ${action}`, extraData)
      } catch {}
    } else {
      console.info('Full Story Event', {
        name: `${category} ${action}`,
        extraData,
      })
    }
  },

  getCurrentUsage(orgId: string) {
    return dashboardApolloClient
      .query({
        variables: {
          orgId,
        },
        query: BillingAndUsageDocument,
      })
      .then((res) => {
        const isEnterprisePlanV2 =
          res.data?.organization?.plan.isEnterprise ?? false

        return dashboardApolloClient.query({
          variables: {
            orgId,
            isEnterprisePlanV2,
          },
          query: CurrentUsageDocument,
        })
      })
      .then((res) => res.data?.organization)
      .catch(() => {})
  },

  async userVars(org: Org) {
    const orgVars = {
      orgId: window.env === 'production' ? org.id : `${window.env}_${org.id}`,
      orgName: org.name || '',
    }

    const currentUsage: CurrentUsageQuery['organization'] | undefined =
      org.id && isUuid.v4(org.id) && (await this.getCurrentUsage(org.id))

    if (!currentUsage?.plan) {
      return orgVars
    }

    return {
      ...orgVars,
      orgPlanId: currentUsage.plan.id,
      orgPlanName: currentUsage.plan.name,
      orgPlanPrice: currentUsage.plan.price && currentUsage.plan.price / 100,
      orgPlanPeriodUnit: currentUsage.plan.periodUnit,
      // Feature flag: record-first-run-ab
      abOrgCohort: abTest(org.id, 2),
    }
  },
}

const cyDirRegex = /^cypress\/(integration|e2e)\//g

export function stripLeadingCyDirsByPaths(
  spec: string,
  specs: string[]
): string {
  if (!spec) {
    return 'unknown'
  }

  const splitStrings = (a: string[], sep: string = '/') =>
    a.map((i) => i.split(sep))
  /**
   * Given an index number, return a function that takes an array and returns the
   * element at the given index
   */
  const elementAt = (i: number) => (a: string[]) => a[i]
  /**
   * Transpose an array of arrays:
   * Example:
   * [['a', 'b', 'c'], ['A', 'B', 'C'], [1, 2, 3]] ->
   * [['a', 'A', 1], ['b', 'B', 2], ['c', 'C', 3]]
   */
  const rotate = (a: string[][]) =>
    a[0].map(({}, i: number) => a.map(elementAt(i))) // eslint-disable-line
  /**
   * Checks of all the elements in the array are the same.
   */
  const allElementsEqual = (arr: string[]) =>
    arr.every((e: string) => e === arr[0])
  const commonPath = (input: string[], sep: string = '/') =>
    rotate(splitStrings(input, sep))
      .filter(allElementsEqual)
      .map(elementAt(0))
      .join(sep)

  const path = `${commonPath(specs)}/`

  // @ts-ignore
  const fullSpecName = path !== '/' ? spec.replace(path, '', 'g') : spec
  // When returning, strip leading slashes, if there are any
  return fullSpecName ? fullSpecName.replace(/^\/+/g, '') : ''
}

export function stripLeadingCyDirsByRegex(spec: string | null) {
  if (!spec) return 'unknown'

  // remove leading 'cypress/integration' from spec
  return spec.replace(cyDirRegex, '')
}

import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
} from 'react'
import cs from 'clsx'
import {
  TestResultModificationTypeBadge,
  Tooltip,
} from '@frontend/design-system'
import { useHover } from '~/lib/hooks/useHover'
import { TestHistoryFragment } from '~/graphql-codegen-operations.gen'

type Node = TestHistoryFragment['history']['nodes'][0]

interface TestTrendModificationsProps {
  startDate: number
  endDate: number
  onClick(testResultId: string): void
  history: TestHistoryFragment['history']['nodes']
}

interface TestTrendModificationProps {
  idx: number
  startDate: number
  endDate: number
  onHover(isHovered: boolean, idx: number): void
  onClick(testResultId: string): void
  node: Node
  isInactive: boolean
}

const TestTrendModification: FunctionComponent<TestTrendModificationProps> = ({
  idx,
  startDate,
  endDate,
  node,
  onHover,
  onClick,
  isInactive,
}) => {
  const createdAt = new Date(node.run.createdAt).getTime()
  const isNew = node.modificationType === 'NEW'
  const styles = {
    left: `${((createdAt - startDate) / (endDate - startDate)) * 100}%`,
  }

  const [iconHoverRef, isIconHovered] = useHover<HTMLAnchorElement>()
  const [toolTipHoverRef, isToolTipHovered] = useHover<HTMLAnchorElement>()
  const isHovered = isIconHovered || isToolTipHovered

  useEffect(() => {
    onHover(isHovered, idx)
  }, [isHovered, onHover, idx])

  const href = `#test-history-item-${node.testResult.id}`

  const onAnchorClick = function (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) {
    e.preventDefault()

    onClick(node.testResult.id)

    const el = document.querySelector(href)

    /* istanbul ignore next */
    if (!el) {
      return
    }

    el.scrollIntoView({
      behavior: 'smooth',
    })
  }

  return (
    <Tooltip
      visible={isHovered}
      arrowContent={
        <div className="test-trend-modifications-tooltip__inner-arrow" />
      }
      overlayClassName={cs('test-trend-modifications-tooltip', {
        'test-trend-modifications-tooltip--new': isNew,
      })}
      overlay={
        <a
          href={href}
          ref={toolTipHoverRef}
          onClick={onAnchorClick}
          className="test-trend-modifications-tooltip__inner-link"
        >
          <p className="test-trend-modifications-tooltip__branch">
            {node.run.commit.branch}
          </p>
          <p className="test-trend-modifications-tooltip__build-number">
            #{node.run.buildNumber}
          </p>
          <TestResultModificationTypeBadge
            modificationType={node.modificationType}
          />
        </a>
      }
      placement="bottom"
    >
      <a
        href={href}
        onClick={onAnchorClick}
        ref={iconHoverRef}
        className="test-trend-modifications__icon"
        style={styles}
      >
        <span className="test-trend-modifications__icon-label">
          <TestResultModificationTypeBadge
            modificationType={node.modificationType}
            isCompact
            isInactive={isInactive}
          />
        </span>
      </a>
    </Tooltip>
  )
}

export const TestTrendModifications: FunctionComponent<
  TestTrendModificationsProps
> = ({ startDate, endDate, history, onClick }) => {
  const [hoveredIdx, setHoveredIdx] = useState<number | null>(null)
  const onNodeHover = useCallback((isHovered: boolean, idx: number) => {
    if (!isHovered) {
      setHoveredIdx(null)
      return
    }

    setHoveredIdx(idx)
  }, [])

  return (
    <div
      className={
        history.length > 0
          ? 'test-trend-modifications'
          : 'test-trend-modifications--empty'
      }
    >
      {history.map((node, idx) => (
        <TestTrendModification
          key={idx}
          idx={idx}
          isInactive={hoveredIdx !== null && hoveredIdx !== idx}
          endDate={endDate}
          startDate={startDate}
          onClick={onClick}
          onHover={onNodeHover}
          node={node}
        />
      ))}
    </div>
  )
}

import { Overlay, Popover } from '@frontend/design-system/src'
import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'
import { OverlayPopoverPlanFragment } from '~/graphql-codegen-operations.gen'
import styles from './module.overlayCTA.scss'
import { OverlayPopover } from './OverlayPopover'

type OverlayCTAProps = {
  title: string
  description: ReactNode
  orgId?: string
  placement: 'top' | 'right' | 'bottom' | 'left'
  learnMoreUri?: string
  hideOverlay?: boolean
  viewerIsOwnerOrAdmin?: boolean
  buttonLink?: string
  buttonText?: string
  plan?: OverlayPopoverPlanFragment
  hideButton?: boolean
}

const OverlayCTA: FunctionComponent<OverlayCTAProps> = ({
  title,
  description,
  placement,
  orgId,
  plan,
  learnMoreUri,
  hideOverlay,
  children,
  viewerIsOwnerOrAdmin,
  buttonLink,
  buttonText,
  hideButton,
}) => {
  const [showPopover, setShowPopover] = useState(false)
  const [isforcedOpen, setForcedOpen] = useState(false)

  const childNode = useRef(null)
  let setTimeoutConst: ReturnType<typeof setTimeout> | null = null

  useEffect(() => {
    return () => {
      if (setTimeoutConst) {
        clearTimeout(setTimeoutConst)
      }
    }
  })

  const handleMouseEnter = () => {
    setTimeoutConst = setTimeout(() => {
      setShowPopover(true)
    }, 0)
  }

  const handleMouseLeave = () => {
    if (setTimeoutConst) {
      clearTimeout(setTimeoutConst)
    }
    setShowPopover(false)
  }

  const displayChild = React.cloneElement(children as any, {
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
    ref: (node) => (childNode.current = node),
  })

  if (hideOverlay) {
    return <>{children}</>
  }

  return (
    <>
      {displayChild}
      <Overlay
        show={showPopover || isforcedOpen}
        placement={placement}
        target={childNode.current!}
      >
        <Popover
          id="popover"
          className={styles.popover}
          onMouseEnter={() => {
            setShowPopover(true)
          }}
          onMouseLeave={handleMouseLeave}
          data-cy="overlay-cta"
        >
          <OverlayPopover
            title={title}
            plan={plan}
            onModalToggle={setForcedOpen}
            description={description}
            learnMoreUri={learnMoreUri}
            orgId={orgId}
            viewerIsOwnerOrAdmin={viewerIsOwnerOrAdmin}
            buttonLink={buttonLink}
            buttonText={buttonText}
            hideButton={hideButton}
          />
        </Popover>
      </Overlay>
    </>
  )
}

export default OverlayCTA

import React, { useState, ReactNode, useEffect } from 'react'
import { Checkbox, Panel } from '@frontend/design-system'
import Button from '@cypress-design/react-button'
import { TriggerARun } from './TriggerARun/TriggerARun'
import { CliCommandSample } from '../../cli-command-sample'
import {
  IconCheckmarkSolid,
  IconLoading,
  IconNumber1,
  IconNumber2,
  IconNumber3,
} from '@cypress-design/react-icon'
import { RunsListQuery } from '~/graphql-codegen-operations.gen'
import { CodeSnippetV2 } from '~/common/code-snippet/CodeSnippet'
import { providerGridData, ProviderData } from '../providerData'
import CIProviderGrid from './CIProviderGrid'
import { useCommitIntendedCIProvider } from '../hooks/commitIntendedCIProvider'

type Step = 1 | 2 | 3

const ProgressBar = React.memo(({ step }: { step: number }) => (
  <div className="progress-bars">
    {[1, 2, 3].map((el, i) => {
      const className = `progress-bar ${i >= step ? 'incomplete' : 'complete'}`
      return <div key={i} className={className} />
    })}
  </div>
))

export const ProgressiveProjectSetup: React.FC<{
  projectId: string
  recordKey?: string
  isModal?: boolean
  intendedCIProviders: RunsListQuery['project']['intendedCIProviders']
  supportedCIProviders?: RunsListQuery['ciProviders']
  stepOverride?: Step
  selectedCIProviderOverride?: ProviderData
  isOnboarding?: boolean
}> = ({
  recordKey = '<record-key>',
  projectId,
  supportedCIProviders = [],
  intendedCIProviders: allIntendedCIProviders,
  isModal,
  stepOverride,
  selectedCIProviderOverride,
  isOnboarding,
}) => {
  const dataPendoPrefix = isModal
    ? 'progressive-project-setup-modal'
    : 'progressive-project-setup'

  const intendedCIProviders = allIntendedCIProviders.filter(
    ({ key }) => key !== 'NONE'
  )

  const setDefaultStep = () => {
    if (stepOverride) {
      return stepOverride
    }
    return isModal || intendedCIProviders.length ? 2 : 1
  }

  const defaultCurrentStep = setDefaultStep()
  const [currentStep, setCurrentStep] = useState<Step>(defaultCurrentStep)
  const [firstStepIsChecked, setFirstStepIsChecked] = useState(false)

  const initialSelectedProvider = providerGridData.find(
    (provider) => provider.databaseKey === intendedCIProviders[0]?.key
  )

  const [selectedCIProvider, setSelectedCIProvider] = useState<
    ProviderData | undefined
  >(selectedCIProviderOverride || initialSelectedProvider)

  useEffect(() => {
    if (selectedCIProviderOverride) {
      setSelectedCIProvider(selectedCIProviderOverride)
    }
  }, [selectedCIProviderOverride])

  const isProviderManualSetup = selectedCIProvider?.value === 'manual-setup'

  useCommitIntendedCIProvider({
    projectId,
    currentStep,
    selectedCIProvider,
    supportedCIProviders,
  })

  const navButtons = (
    <>
      {currentStep > 1 && (
        <Button
          size="40"
          variant="outline-light"
          className="bg-transparent"
          data-pendo={`${dataPendoPrefix}-back-button`}
          onClick={() => setCurrentStep((currentStep - 1) as Step)}
        >
          Back
        </Button>
      )}
      {currentStep < 3 && (
        <Button
          size="40"
          variant="indigo-dark"
          disabled={
            (currentStep === 1 && !firstStepIsChecked) ||
            (currentStep === 2 && !selectedCIProvider)
          }
          data-pendo={`${dataPendoPrefix}-next-button`}
          onClick={() => setCurrentStep((currentStep + 1) as Step)}
        >
          Next
        </Button>
      )}
    </>
  )

  const steps: {
    subtitle: string
    heading: string
    subheading: ReactNode
    body: ReactNode
    footer: ReactNode
  }[] = [
    {
      subtitle: 'Update your Cypress config file.',
      heading: 'Add your project ID',
      subheading: (
        <span>
          Hit a snag?{' '}
          <a
            href={'https://on.cypress.io/what-is-a-project-id'}
            target="_blank"
            data-pendo={`${dataPendoPrefix}-guides-link`}
            data-cy="guides-link"
          >
            Check the docs
          </a>
          .
        </span>
      ),
      body: (
        <CodeSnippetV2
          copyBtnPendo={`${dataPendoPrefix}-copy-project-id`}
          overlayDefaultText="Copy line 2"
          code={`module.exports = {
  projectId: "${projectId}",
  // ...rest of the Cypress project config
}`}
          copyText={`projectId: "${projectId}"`}
          fileName={'cypress.config.js'}
          withCopyBtn
        />
      ),
      footer: (
        <>
          <div className="footer-btn">
            <Checkbox
              className="checkbox"
              isChecked={firstStepIsChecked}
              data-pendo={`${dataPendoPrefix}-added-project-id`}
              onChange={() => setFirstStepIsChecked(!firstStepIsChecked)}
              label={<span>Ok, I added my project ID</span>}
            />
          </div>
          {navButtons}
        </>
      ),
    },
    {
      subtitle: 'Cypress Cloud is made for continuous integration.',
      heading: 'Choose your CI Provider',
      subheading: "We'll customize the setup instructions.",
      body: (
        <CIProviderGrid
          dataPendoPrefix={dataPendoPrefix}
          selectedProviderKey={selectedCIProvider?.databaseKey}
          onSelectProvider={(selectedProvider) => {
            setSelectedCIProvider(selectedProvider)
          }}
        />
      ),
      footer: navButtons,
    },
    {
      subtitle: isProviderManualSetup
        ? 'Trigger a run on any platform.'
        : `Trigger a run with ${selectedCIProvider?.title}.`,
      heading: isProviderManualSetup
        ? 'Manual Setup'
        : `${selectedCIProvider?.title ?? 'CI Provider'} Setup`,
      subheading: isProviderManualSetup
        ? 'Trigger a run on any platform.'
        : 'Run Cypress tests every time you push.',
      body: (
        <TriggerARun
          dataPendoPrefix={dataPendoPrefix}
          selectedCIProvider={selectedCIProvider!}
          recordKey={recordKey}
          changeSelectedProviderHandler={(selectedProvider) =>
            setSelectedCIProvider(selectedProvider)
          }
        />
      ),
      footer: (
        <>
          {navButtons}
          <Button size="40" variant="outline-light" disabled>
            <IconLoading className="spin hide-in-percy" />
            Waiting for run...
          </Button>
        </>
      ),
    },
  ]

  const step3LocalRunHeading = currentStep === 3 && (
    <Panel.Heading className="step-3-local-run-heading">
      <h5>Try it first</h5>
      <div className="subheading">Record a run in your terminal.</div>
      <CliCommandSample
        copyBtnPendo={`${dataPendoPrefix}-copy-terminal-command`}
        command={`npx cypress run --record --key ${recordKey}`}
      />
    </Panel.Heading>
  )

  const { subtitle, heading, subheading, body, footer } = steps[currentStep - 1]

  if (isOnboarding) {
    return (
      <div
        data-cy="progressive-project-setup"
        id="progressive-project-setup"
        className={`step-${currentStep} onboarding-checklist`}
      >
        {steps.map((step, i) => {
          const stepNumber = i + 1
          const isActiveStep = stepNumber === currentStep

          let Icon
          let fillColor
          let strokeColor
          if (stepNumber < currentStep) {
            Icon = IconCheckmarkSolid
          } else if (stepNumber === 1) {
            Icon = IconNumber1
          } else if (stepNumber === 2) {
            Icon = IconNumber2
          } else {
            Icon = IconNumber3
          }

          if (stepNumber < currentStep || isActiveStep) {
            fillColor = 'indigo-100'
            strokeColor = 'indigo-500'
          }

          return (
            <Panel key={i}>
              <Panel.Heading
                style={{ backgroundColor: isActiveStep ? 'white' : '#F3F4FA' }}
              >
                <div className="progressive-setup-step-heading">
                  <div className="progressive-setup-step-heading-step">
                    <Icon
                      size="24"
                      fillColor={fillColor}
                      strokeColor={strokeColor}
                    />
                  </div>
                  <div>
                    <h5>{step.heading}</h5>
                    {i === currentStep - 1 && (
                      <div className="subheading">{step.subheading}</div>
                    )}
                  </div>
                </div>
              </Panel.Heading>
              {isActiveStep && (
                <>
                  <Panel.Body>
                    {step3LocalRunHeading}
                    {step.body}
                  </Panel.Body>
                  <Panel.Footer>{step.footer}</Panel.Footer>
                </>
              )}
            </Panel>
          )
        })}
      </div>
    )
  }

  return (
    <div
      data-cy="progressive-project-setup"
      id="progressive-project-setup"
      className={`step-${currentStep} project-setup`}
    >
      <h3>Project setup</h3>
      <h4>{subtitle}</h4>
      <ProgressBar step={currentStep} />
      <Panel>
        {step3LocalRunHeading}
        <Panel.Heading style={{ backgroundColor: 'white' }}>
          <h5 id="progressive-setup-step-heading">{heading}</h5>
          <div className="subheading">{subheading}</div>
        </Panel.Heading>
        <Panel.Body>{body}</Panel.Body>
        <Panel.Footer>{footer}</Panel.Footer>
      </Panel>
    </div>
  )
}

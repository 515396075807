import { TestRunnerAuthPayload } from '@packages/common/src/isomorphicTypes'
import * as Sentry from '@sentry/browser'
import { stringify } from 'query-string'

import { features } from '~/lib/feature-flags'

import { isEnv } from '../lib/utils'
import xhr from '../lib/xhr'
import currentUser from './current-user-model'

export type AuthServiceConfig =
  | {
      source: 'dashboard'
    }
  | ({
      source: 'test_runner'
    } & TestRunnerAuthPayload)

export class AuthService {
  constructor(protected config: AuthServiceConfig) {}

  loggedIn() {
    if (currentUser.id) {
      return true
    }
    return false
  }

  login = (connection: string) => {
    sessionStorage.removeItem('initialQs')
    // Dashboard session should use the new social auth endpoints.
    if (
      features.isEnabled('new-social-auth') ||
      connection === 'dashboard-session'
    ) {
      let authPayload = ''
      if (Object.keys(this.config).length) {
        authPayload = `?${stringify(this.config)}`
      }
      this.setHref(`${window.AUTH_DOMAIN}/login/${connection}${authPayload}`)
    } else {
      const authPayload = stringify({
        connection,
        ...this.config,
      })
      this.setHref(`/auth/login?${authPayload}`)
    }
  }

  loginSSO = (connection: string) => {
    sessionStorage.removeItem('initialQs')
    let authPayload = ''
    if (Object.keys(this.config).length) {
      authPayload = `${connection.includes('?') ? '&' : '?'}${stringify(
        this.config
      )}`
    }
    this.setHref(`${connection}${authPayload}`)
  }

  loginLocal = ({ email, password }: { email: string; password: string }) => {
    sessionStorage.removeItem('initialQs')
    let authPayload = ''
    if (Object.keys(this.config).length) {
      authPayload = `?${stringify(this.config)}`
    }

    return fetch(`${window.AUTH_DOMAIN}/login/local${authPayload}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        email,
        password,
      }),
    })
  }

  registerLocal = ({
    email,
    password,
  }: {
    email: string
    password: string
  }) => {
    sessionStorage.removeItem('initialQs')
    let authPayload = ''
    if (Object.keys(this.config).length) {
      authPayload = `?${stringify(this.config)}`
    }

    return fetch(`${window.AUTH_DOMAIN}/login/local-register${authPayload}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        email,
        password,
      }),
    })
  }

  setHref = (url: string) => {
    window.location.href = url
  }

  logout = () => {
    return xhr({
      baseURL: '/',
      method: 'get',
      url: '/logout',
    })
      .catch((error) => {
        console.error(error)
        Sentry.captureException(error)
      })
      .then(() => {
        currentUser.clear()
        window.location.href = '/login'
        window.location.reload()
      })
  }
}

const defaultAuthService = new AuthService({ source: 'dashboard' })

declare global {
  interface Window {
    authService: AuthService
    AUTH_DOMAIN: string
  }
}

if (isEnv('test')) {
  window.authService = defaultAuthService
}

export { defaultAuthService as default }

import React from 'react'

export const Divider = (props: { height?: number }) => {
  return (
    <div
      style={{ height: props.height || '50%', alignSelf: 'center' }}
      className="border-solid border-0 border-r border-gray-100"
    />
  )
}

import { ApolloError } from '@apollo/client'
import { Modal } from '@frontend/design-system'
import $ from 'jquery'
import React, { FunctionComponent, useEffect, useState, useRef } from 'react'
import Loader from 'react-loader'
import { CheckoutError } from '../../billing/CheckoutError/CheckoutError'

type HubSpotModalProps = {
  formId: string
  title: string
  isLoading?: boolean
  onOpen: ($form: JQuery) => void
  onClose: () => void
  onFormSubmitted?: () => void
  error?: ApolloError
}

declare global {
  interface Window {
    jQuery?: JQueryStatic
    hbspt?: {
      forms: {
        create: (options: {
          portalId: string
          formId: string
          target: string
          onFormReady: ($form: JQuery) => void
          onFormSubmitted: () => void
        }) => void
      }
    }
  }
}

const formSelector = 'hubspot-form'

export const HubSpotModal: FunctionComponent<HubSpotModalProps> = ({
  formId,
  title,
  isLoading,
  onOpen,
  onClose,
  onFormSubmitted,
  error,
}) => {
  const [script, setScript] = useState<HTMLScriptElement | null>(null)
  const scriptRemovalRef = useRef(() => {})

  useEffect(() => {
    scriptRemovalRef.current = () => script?.remove()
  }, [script])

  useEffect(() => {
    window.jQuery = $

    if (isLoading || script) {
      return
    }

    const scriptEl = document.createElement('script')
    scriptEl.src = 'https://js.hsforms.net/forms/v2.js'
    setScript(scriptEl)

    document.body.appendChild(scriptEl)

    scriptEl.addEventListener('load', () => {
      /* istanbul ignore if */
      if (!window.hbspt) {
        return null
      }

      window.hbspt.forms.create({
        portalId: '5511862',
        formId,
        target: `#${formSelector}`,
        onFormReady: onOpen,
        onFormSubmitted: onFormSubmitted ?? onClose,
      })

      return null
    })
  }, [script, isLoading, formId, onClose, onOpen, onFormSubmitted])

  useEffect(() => {
    return () => {
      scriptRemovalRef.current()
    }
  }, [])

  return (
    <Modal data-cy="hubspot-modal" show backdrop="static" onHide={onClose}>
      <Modal.Header closeButton>
        <h3 className="modal-title">{title}</h3>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body">
          <div id="modal-body-wrapper">
            <div id="modal-body-container">
              {isLoading && <Loader />}
              <div id={formSelector} />
            </div>
          </div>
        </div>
      </Modal.Body>
      {error && <CheckoutError error={error} />}
    </Modal>
  )
}

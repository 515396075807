import { Button, EmptyState } from '@frontend/design-system/src'
import React, { FunctionComponent } from 'react'

type MostCommonErrorsEmptyStateProps = {
  shouldUpdateCypressVersion: boolean
}

export const MostCommonErrorsEmptyState: FunctionComponent<
  MostCommonErrorsEmptyStateProps
> = ({ shouldUpdateCypressVersion }) => {
  if (shouldUpdateCypressVersion) {
    return (
      <EmptyState>
        Upgrade to Cypress 5.0 to dig into the errors impacting your tests.
        <a
          data-cy="upgrade-cy-cta"
          href="https://docs.cypress.io/guides/references/migration-guide.html#Migrating-to-Cypress-5-0"
          target="_blank"
        >
          <Button className="btn-link">Learn how</Button>
        </a>
      </EmptyState>
    )
  }

  return (
    <EmptyState>
      <span>No data for this time range.</span>
    </EmptyState>
  )
}

import * as React from 'react'
import { Button, Modal } from '@frontend/design-system'

export interface DangerModalConfig {
  variant: 'danger'
  title: string
  description: React.ReactNode
  cancelButtonLabel?: string
  confirmButtonLabel?: string
  children?: React.ReactChild
}

interface DangerModalProps extends DangerModalConfig {
  onClose: () => void
  onSubmit: () => void
}

export const DangerModal: React.FC<DangerModalProps> = ({
  title,
  description,
  cancelButtonLabel,
  confirmButtonLabel,
  onClose,
  onSubmit,
  children,
}) => {
  return (
    <Modal
      data-cy="confirmation-modal"
      show={Boolean(title)}
      onHide={onClose}
      aria-labelledby="danger-modal-title"
    >
      <Modal.Header closeButton>
        <h3
          className="modal-title"
          data-cy="confirmation-modal-title"
          id="danger-modal-title"
        >
          {title}
        </h3>
      </Modal.Header>
      <Modal.Body>{description}</Modal.Body>
      {children}
      <Modal.Footer>
        <Button
          data-cy="confirmation-cancel"
          className="btn-light"
          onClick={onClose}
        >
          {cancelButtonLabel || 'Cancel'}
        </Button>
        <Button
          data-cy="confirmation-submit"
          bsStyle="danger"
          onClick={onSubmit}
        >
          {confirmButtonLabel || 'Yes, I agree'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

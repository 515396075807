import { palette } from '@frontend/design-system'
import cs from 'clsx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { Notification } from 'react-notification'

import store from './store'
import { IconActionDeleteXlarge } from '@cypress-design/react-icon'

@observer
export default class AppNotification extends Component {
  render() {
    if (!store.active) return null

    // FIXME: This is overly-fussy, but trying to pass a custom component
    // through the store doesn't seem to work
    const actionColor =
      store.variant === 'failure' && store.action
        ? palette.red500
        : palette.gray300

    const onClick = () => {
      this._clearNotification()
    }

    return (
      <Notification
        key={store.id}
        className={cs(store.variant || 'success', {
          'notification--multiline': store.multiline,
        })}
        barStyle={{
          font: '14px/1.5 sans-serif',
          background: palette.gray700,
          boxShadow: `0 3px 6px -3px rgba(33, 33, 33, 0.8)`,
          padding: 16,
          top: 112,
          bottom: null,
          right: 16,
          left: null,
          zIndex: 10000,
        }}
        actionStyle={{
          font: '12px/21px sans-serif',
          fontWeight: 'bold',
          letterSpacing: '0.5px',
          color: actionColor,
          verticalAlign: 'baseline',
          display: 'inline-block',
        }}
        isActive
        message={store.message}
        action={store.action || <IconActionDeleteXlarge alt="Close" />}
        dismissAfter={store.variant === 'failure' ? 20000 : 5000}
        onDismiss={this._clearNotification}
        onClick={onClick}
      />
    )
  }

  _clearNotification() {
    return store.clearNotification()
  }
}

export const getShareUrl = (mediaType) => {
  const search = new URLSearchParams(window.location.search)
  search.set('utm_source', 'Dashboard')
  search.set('utm_medium', 'Share URL')
  search.set('utm_campaign', mediaType)

  return `${window.location.protocol}//${window.location.host}${
    window.location.pathname
  }?${search.toString()}`
}

import { SamplingContext } from '@sentry/types'

const DEFAULT_RATE = 0.05 // default sample rate for everything else

export const customTracesSampler = (
  ct: SamplingContext,
  pathRates: { pathSubstring: string; rate: number }[]
): number => {
  // the tracesSampler will decide the sampling rate according to conditions
  // described in here.
  //
  // the `name` transactionContext is the url fetched (sans url root), e.g. `/myappfeature/specific-url`
  //
  //
  // if pathRates contains a path string match, then use the sampling rate provided, else use the default rate

  const pathMatch = pathRates.find((p) =>
    ct.transactionContext.name.includes(p.pathSubstring)
  )

  if (pathMatch) {
    return pathMatch.rate
  }

  return DEFAULT_RATE
}

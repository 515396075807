import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { PhotoSwipe } from 'react-photoswipe'

function onElementAvailable(selector, callback) {
  const observer = new MutationObserver((mutations) => {
    const elements = document.querySelectorAll(selector)
    if (elements.length > 0) {
      observer.disconnect()
      callback(elements)
    }
  })

  observer.observe(document.body, { childList: true, subtree: true })
}

@observer
export default class ScreenshotsLightbox extends Component {
  render() {
    const { isOpen, onClose, allScreenshots, lightboxIndex, id } = this.props

    // without this, an error occurs if you navigate directly to the screenshot in e.g. a new browser tab
    const isLightboxOpen = allScreenshots.length ? isOpen : false

    // `react-photoswipe` is unmaintained since 2017 and does not support `alt` attr, so we set it here
    // we use both `gettingData` and `imageLoadComplete` to avoid flake
    // long-term should switch to `photoswipe` or `react-photoswipe-gallery`, which support `alt`
    const setAltAttribute = () => {
      onElementAvailable('img.pswp__img:not([alt])', (images) => {
        Array.from(images).forEach((img) => {
          img.setAttribute('alt', 'Screenshot')
        })
      })
    }

    return (
      <PhotoSwipe
        id={id}
        isOpen={isLightboxOpen}
        onClose={onClose}
        gettingData={setAltAttribute}
        imageLoadComplete={setAltAttribute}
        items={allScreenshots}
        options={{
          closeOnScroll: false,
          index: lightboxIndex,
          history: false,
          shareButtons: [
            {
              id: 'download',
              label: 'Download image',
              url: '{{raw_image_url}}',
              download: true,
            },
          ],
        }}
      />
    )
  }
}

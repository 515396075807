import { CustomIcon, TestResultStatusIcon } from '@frontend/design-system'
import { lowerCase } from 'lodash'
import React, { FunctionComponent } from 'react'

import { readableTestResultStateEnum } from '~/lib/utils-ts'

import { MultiSelectFilter } from '../MultiSelectFilter'
import { MultiSelectOptionType, onMultiSelectFilterChange } from '../types'

type TestResultStatusFilterProps = {
  id: string
  onChange: onMultiSelectFilterChange
  selected: MultiSelectOptionType[]
}

const renderLabel = (option: MultiSelectOptionType) => {
  const label = (
    <span>
      <TestResultStatusIcon status={lowerCase(option.value)} />
      {readableTestResultStateEnum(option.value)}
    </span>
  ) as unknown

  return label as string
}

const options = (
  ['PASSED', 'FAILED', 'PENDING', 'SKIPPED', 'RUNNING'] as const
).map((status) => ({
  value: status,
  label: status,
}))

export const TestResultStatusFilter: FunctionComponent<TestResultStatusFilterProps> =
  React.memo(({ id, selected, onChange }) => {
    return (
      <MultiSelectFilter
        id={id}
        title={<CustomIcon name="status" alt="Status" />}
        emptyLabel="Status"
        isSearchable={false}
        selected={selected}
        getOptionLabel={renderLabel}
        onChange={onChange}
        options={options}
      />
    )
  })

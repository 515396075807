import { IconButton, Tooltip } from '@frontend/design-system'
import React, { FunctionComponent } from 'react'

import { useToggleRunArchiveMutation } from '~/graphql-codegen-operations.gen'

import store from '../lib/store'

interface ArchiveRunButton {
  runId: string
  buildNumber: number
}

export const ArchiveRunButton: FunctionComponent<ArchiveRunButton> = ({
  runId,
  buildNumber,
}) => {
  const [toggleRunArchiveMutation] = useToggleRunArchiveMutation()

  const handleClick = () => {
    toggleRunArchiveMutation({
      variables: {
        input: {
          runId,
          isArchived: true,
        },
      },
    })
      .then(() => {
        store.setNotification(`Run #${buildNumber} has been archived`)
      })
      .catch(() => {
        /* istanbul ignore next */
        store.setNotification(`Unable to archive run #${buildNumber}`)
      })
  }

  return (
    <Tooltip
      placement="top"
      overlay={<span data-cy="archive-run-tooltip">Archive this run</span>}
    >
      <IconButton
        aria-label="Archive run"
        data-cy="archive-run"
        className="pull-right"
        icon="archive"
        onClick={handleClick}
      />
    </Tooltip>
  )
}

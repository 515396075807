import { IconActionDeleteXlarge } from '@cypress-design/react-icon'
import { IconButton } from '@frontend/design-system'
import React, { FunctionComponent } from 'react'

type DrillInDrawerCloseBtnProps = {
  onClose: () => void
}

export const DrillInDrawerCloseBtn: FunctionComponent<
  DrillInDrawerCloseBtnProps
> = ({ onClose }) => {
  return (
    <div className="drill-in-drawer__close-btn-container">
      <IconButton
        aria-label="Close test results"
        IconComponent={IconActionDeleteXlarge}
        className="drill-in-drawer__close-btn"
        onClick={onClose}
        data-cy="drill-in-close-btn"
      />
    </div>
  )
}

import * as atoms from './atoms'
import { useRecoilState } from 'recoil'

export const useDrillInDrawerWidth = () => {
  const [drillInDrawerWidth, setDrillInDrawerWidth] = useRecoilState(
    atoms.drillInDrawerWidth
  )

  return {
    drillInDrawerWidth,
    setDrillInDrawerWidth,
  }
}

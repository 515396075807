import { Panel } from '../Panel'
import React, { FunctionComponent, ReactNode } from 'react'
import cs from 'clsx'

type PanelEmptyStateProps = {
  title?: string
  subtitle: string | ReactNode
  heading?: string
  withoutShadow?: boolean
  ctaLinkMessage?: string
  ctaLinkUrl?: string
  ctaLinkUrlClassName?: string
  ctaButton?: ReactNode
  ctaButtonMessage?: string
  ctaButtonUrl?: string
  icon?: string
  'data-cy'?: string
}

const PanelEmptyState: FunctionComponent<PanelEmptyStateProps> = ({
  title,
  subtitle,
  ctaLinkUrl,
  ctaLinkUrlClassName,
  heading,
  withoutShadow,
  ctaLinkMessage,
  icon = 'empty-state-graph',
  ctaButton,
  ctaButtonMessage,
  ctaButtonUrl,
  ...rest
}) => {
  return (
    <Panel
      data-cy={rest['data-cy']}
      className={cs({
        'panel-empty-state-without-shadow': withoutShadow,
      })}
    >
      {heading && (
        <Panel.Heading>
          <Panel.Title>{heading}</Panel.Title>
        </Panel.Heading>
      )}
      <Panel.Body className="panel-empty-state">
        <img src={`/img/${icon}.svg`} alt="" />
        {title && <span className="panel-empty-state__title">{title}</span>}
        <span className="panel-empty-state__subtitle">{subtitle}</span>
        <div className="panel-empty-state__cta">
          {ctaButtonMessage && ctaButtonUrl && (
            <a href={ctaButtonUrl} className="btn btn-default">
              {ctaButtonMessage}
            </a>
          )}
          {ctaButton}
          {ctaLinkUrl && ctaLinkMessage && (
            <a
              href={ctaLinkUrl}
              target="_blank"
              className={ctaLinkUrlClassName}
            >
              {ctaLinkMessage}
            </a>
          )}
        </div>
      </Panel.Body>
    </Panel>
  )
}

export { PanelEmptyState }

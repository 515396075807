import React, { FunctionComponent } from 'react'
import cs from 'clsx'
import { Button } from '@frontend/design-system'
import pluralize from 'pluralize'
import { useHover } from '~/lib/hooks/useHover'
import { ColorBreakDownSectionType } from '~/common/color-breakdown/types'

interface MostCommonErrorsErrorClassProps {
  section: ColorBreakDownSectionType
  isGreyedOut: boolean
  onClick(errorClass: string): void
}

export const MostCommonErrorsErrorClass: FunctionComponent<
  MostCommonErrorsErrorClassProps
> = ({ section, onClick, isGreyedOut }) => {
  const [hoverRef, isHovered] = useHover<HTMLDivElement>()

  return (
    <Button
      className="color-breakdown-row__container"
      bsStyle="link"
      onClick={() => onClick(section.id)}
    >
      <div
        ref={hoverRef}
        className={cs('color-breakdown-row', {
          'color-breakdown-row--greyed-out': isGreyedOut,
        })}
      >
        <div
          className="color-breakdown-row__color"
          style={{
            backgroundColor: isHovered ? section.hoverColor : section.color,
          }}
        />
        <h3 className="color-breakdown-row__title">{section.id}</h3>
        <h3 className="color-breakdown-row__total">
          <span className="color-breakdown-row__total-count">
            {section.total}
          </span>
          <span className="color-breakdown-row__stat--subtle">
            {pluralize('test cases', 0)}
          </span>
        </h3>
        <h3 className="color-breakdown-row__percentage">
          {section.percentage.toFixed(2)}%
        </h3>
      </div>
    </Button>
  )
}

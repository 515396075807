import React, { FunctionComponent, useState } from 'react'
import { CustomIcon, Tooltip } from '@frontend/design-system'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import styles from './module.CopyFilePath.scss'

interface CopyFilePathProps {
  filePath: string | null
  width?: number
  enabled?: boolean
}

const defaultCopyText = 'Copy filepath'

export const CopyFilePath: FunctionComponent<CopyFilePathProps> = ({
  filePath,
  width = 18,
  enabled = true,
}) => {
  const [copyText, setCopyText] = useState(defaultCopyText)

  if (!filePath) {
    return null
  }

  if (!enabled) {
    return (
      <span data-cy="copy-file-path" className={styles.container}>
        <CustomIcon name="spec" width={width} />
      </span>
    )
  }

  return (
    <Tooltip
      placement="top"
      overlay={<span>{copyText}</span>}
      onVisibleChange={(isVisible) => {
        if (isVisible) {
          return
        }

        setCopyText(defaultCopyText)
      }}
    >
      <CopyToClipboard onCopy={() => setCopyText('Copied!')} text={filePath}>
        <span data-cy="copy-file-path" className={styles.container}>
          <CustomIcon name="spec" width={width} />
        </span>
      </CopyToClipboard>
    </Tooltip>
  )
}

import React from 'react'

export type TabProps = {
  icon?: React.ReactNode
  title: React.ReactNode
  value: string | number
  onSelect?: VoidFunction
  selected?: boolean
  ['data-pendo']: string
  ['data-cy']?: string
}

export const Tab: React.FC<TabProps> = (props) => {
  const dataPendo = props['data-pendo']
  const dataCy = props['data-cy'] || 'tab'
  return (
    <div
      className="relative inline-block cursor-pointer py-4 pt-6 px-6 tab-component"
      data-pendo={dataPendo}
      data-cy={dataCy}
      onClick={props.onSelect}
    >
      <input
        id={`tab-${props.value}`}
        name="tab"
        className="hidden"
        type="radio"
        checked={props.selected}
        readOnly
      />
      <label
        htmlFor={`tab-${props.value}`}
        className={`cursor-pointer whitespace-nowrap font-sans ${
          props.selected ? 'text-indigo-500' : 'text-gray-700 hover:'
        } text-size-16 font-medium flex gap-4 items-center`}
      >
        {props.icon} {props.title}
      </label>
      {props.selected ? (
        <div
          style={{ width: '100%', bottom: 0 }}
          className="bg-indigo-400 rounded h-2 left-0 absolute"
        />
      ) : null}
    </div>
  )
}

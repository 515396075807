import React, { FunctionComponent } from 'react'
import styles from './module.FeedbackButton.scss'
import Button, { VariantClassesTable } from '@cypress-design/react-button'
import queryString from 'query-string'
import { useFeedbackButtonQuery } from '~/graphql-codegen-operations.gen'
import cs from 'clsx'

export const FeedbackButtonUrls = [
  'https://on.cypress.io/beta-feedback-branch-review',
  'https://cypress.io',
  'https://on.cypress.io/report-issue-ui-coverage',
  'https://on.cypress.io/report-issue-accessibility',
  'https://on.cypress.io/report-issue-test-replay',
  'https://on.cypress.io/free-trial-feedback',
] as const

export type FeedbackButtonUrl = (typeof FeedbackButtonUrls)[number]

function isFeedbackButtonUrl(url: string) {
  return FeedbackButtonUrls.includes(url as FeedbackButtonUrl)
}

interface FeedbackButtonProps {
  url: FeedbackButtonUrl
  organizationId: string
  children?: React.ReactNode
  textOnly?: boolean
  variant?: keyof typeof VariantClassesTable
  className?: string
}

export const FeedbackButton: FunctionComponent<FeedbackButtonProps> = ({
  url,
  organizationId,
  children,
  variant = 'purple-dark',
  className,
}) => {
  const { data } = useFeedbackButtonQuery()

  if (!isFeedbackButtonUrl(url)) {
    return null
  }

  // Maps properties for use with HubSpot forms
  const qs = queryString.stringify({
    uuid__c: data?.me?.id,
    email: data?.me?.email,
    organization_uuid: organizationId,
    source_url: window.location.href,
  })

  return (
    <Button
      data-cy="feedback-button"
      // deepcode ignore DOMXSS: "href" is assumed safe since it is acquired via queryString and a known URL
      href={`${url}?${qs}`}
      target="_blank"
      variant={variant}
      size="32"
      className={cs(
        styles.feedbackButton,
        className,
        ['purple-dark', 'outline-purple'].includes(variant) && styles[variant]
      )}
    >
      {children || 'Give feedback'}
    </Button>
  )
}

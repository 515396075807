import React from 'react'
import { OptionTypeBase, OptionsType, components } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import cs from 'clsx'
import './Select.scss'

export type ExtendedOptionTypeBase =
  | OptionTypeBase
  | (OptionsType<OptionTypeBase> & {
      // functional component you can pass as an alternative to the
      // basic row enforced in the original implementation.
      CustomListItem: React.FC<{
        asValue?: boolean
        asOption?: boolean
        isSelected?: boolean
      }>
    })

type StyledSelectProps = React.ComponentProps<typeof CreatableSelect> & {
  /**
   * Will show the icon corresponding to the
   * currently selected value, replacing the "baseIcon".
   */
  showValueIcon?: boolean
  /**
   * Default icon shown in the
   * ValueContainer portion of the Select UX.
   */
  baseIcon?: React.ReactElement
  /**
   * Whether to display selected value, else
   * will always display the placeholder in the value container.
   */
  displaySelected?: boolean
  /**
   * allow us to render any content we want in the dropdown options
   * and value container, instead of being forced to a single use case:
   */
  customListItems?: boolean
  /**
   * removes box shadow from the options list container.
   */
  removeListShadow?: boolean
  /**
   * Title label for the options container
   */
  optionsTitle?: string
  /**
   * removes dropdown indicator
   */
  removeDropdownIndicator?: boolean
  /**
   * Meant to allow us to hack the Select behavior.
   * Our designs sometimes have buttons inside other buttons.
   * The library wasn't designed with those edge-cases in mind.
   * Hence we need this workaround.
   */
  onClickOption?: (input: {
    nativeOnClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    closeMenu: () => void
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  }) => void
}
export const SingleSelect: React.FC<StyledSelectProps> = (props) => {
  // Seems that <Select/> doesn't let you know if it's open
  // from within the children elements, so we track ourselves.
  const [isOpen, setIsOpen] = React.useState(props.defaultMenuIsOpen)
  return (
    <CreatableSelect<ExtendedOptionTypeBase>
      {...props}
      openMenuOnClick
      menuIsOpen={isOpen}
      isSearchable={false}
      isClearable={false}
      isMulti={false}
      className={cs('single-select-custom-element', props.className)}
      onMenuOpen={() => {
        setIsOpen(true)
        props.onMenuOpen && props.onMenuOpen()
      }}
      onMenuClose={() => {
        setIsOpen(false)
        props.onMenuClose && props.onMenuClose()
      }}
      components={{
        IndicatorSeparator: null,
        ClearIndicator: ({ children, ...rest }) => (
          <components.ClearIndicator {...rest} className="custom-clear">
            {children}
          </components.ClearIndicator>
        ),
        DropdownIndicator: ({ children, ...rest }) =>
          props.removeDropdownIndicator ? null : (
            <div
              className="custom-svg-container"
              style={{ transform: isOpen ? 'rotate(180deg)' : '' }}
            >
              <components.DropdownIndicator {...rest}>
                {children}
              </components.DropdownIndicator>
            </div>
          ),
        MenuList: ({ children }) => (
          <div
            className={cs(
              'custom-opts-container',
              props.removeListShadow && 'no-shadow'
            )}
          >
            {props.optionsTitle && (
              <div className="custom-options-title">{props.optionsTitle}</div>
            )}
            {children}
          </div>
        ),
        Option: ({ data, innerProps, ...rest }) => (
          <div
            onClick={(e) => {
              if (props.onClickOption) {
                // This is meant to allow non-standard behavior
                // like buttons nested inside the option items:
                props.onClickOption?.({
                  event: e,
                  nativeOnClick: innerProps.onClick,
                  closeMenu: () => setIsOpen(false),
                })
              } else {
                // This is the normal onClick event if we don't try to hack
                // the component. Then the native onChange() works as expected.
                innerProps.onClick(e)
              }
            }}
            className={cs(
              'custom-opt cursor-pointer font-sans',
              rest.isSelected && 'selected',
              data.value?.isDisabled && 'custom-opt-disabled'
            )}
          >
            {props.customListItems ? (
              <data.CustomListItem asOption isSelected={rest.isSelected} />
            ) : (
              <div className="custom-opt-label">
                {data.icon}
                <label className="cursor-pointer">{data.label}</label>
              </div>
            )}
          </div>
        ),
        Control: ({ children, ...rest }) => (
          <components.Control className="custom-control" {...rest}>
            {children}
          </components.Control>
        ),
        ValueContainer: ({ children, ...rest }) => {
          return (
            <components.ValueContainer
              {...rest}
              className="custom-value-container !cursor-pointer font-sans"
            >
              {children}
            </components.ValueContainer>
          )
        },
        SingleValue: ({ data, children, ...rest }) => {
          let content = props.placeholder
          if (props.displaySelected) {
            if (props.customListItems) {
              content = <data.CustomListItem asValue />
            } else {
              content = children
            }
          }
          return (
            <components.SingleValue
              {...rest}
              data={data}
              className="custom-single-value text-gray-700 font-medium text-size-16 font-sans"
            >
              {content}
            </components.SingleValue>
          )
        },
        Placeholder: ({ children, ...rest }) => (
          <components.Placeholder
            {...rest}
            className="custom-placeholder text-gray-700 font-medium text-size-16 font-sans"
          >
            {children}
          </components.Placeholder>
        ),
      }}
    />
  )
}

import { EmptyState, Panel } from '@frontend/design-system'
import {
  TimeIntervalEnum,
  ProjectRunsOverTimeQuery,
  ProjectRunStatusOverRunsQuery,
} from '~/graphql-codegen-operations.gen'
import cs from 'clsx'
import commaNumber from 'comma-number'
import { format, parseISO } from 'date-fns'
import _ from 'lodash'
import React, { FunctionComponent } from 'react'
import { LoadingContainer } from '~/project-analytics/LoadingContainer'

import { ChartLegendItemProps } from '~/common/chart-legend/ChartLegend'
import { AnalyticRowLink } from '../AnalyticRowLink/AnalyticRowLink'
import { AnalyticsProgressBar } from '../AnalyticsProgressBar'
import { statuses } from '../constants'
import styles from '../module.AnalyticsTable.scss'
import { RunsOverTimeQueryData } from './RunsOverTimeAnalytic'

type RunsOverTimeTableProps = {
  data?: RunsOverTimeQueryData
  isLoading: boolean
  timeInterval?: TimeIntervalEnum
  byBuildAnalytics?: boolean
}

const RunsOverTimeRow = ({
  row,
  columnsWithData,
}: {
  row:
    | ProjectRunsOverTimeQuery['metrics']['projectRunsOverTimeGroupedByStatus']['nodes'][0]
    | ProjectRunStatusOverRunsQuery['metrics']['projectRunDurationsOverRuns']['nodes'][0]
  columnsWithData: Array<ChartLegendItemProps>
}) => {
  return (
    <AnalyticRowLink dataCy="runs-over-time-table__row">
      <div>{format(parseISO(row.timeSeriesPointStartDatetime), 'PP')}</div>
      {columnsWithData.map((column) => {
        return (
          <div
            key={column.name}
            className={cs({
              'text-muted': row[column.name] === 0,
            })}
          >
            <span className={styles.textRight}>{row[column.name]}</span>
          </div>
        )
      })}
      <div>
        <span
          className={cs(styles.textRight, {
            'text-muted': row.count === 0,
          })}
        >
          {commaNumber(row.count || 0)}
        </span>
      </div>
      <div>
        <AnalyticsProgressBar rate={row.failureRate} />
      </div>
    </AnalyticRowLink>
  )
}

const RunsOverTimeTable: FunctionComponent<RunsOverTimeTableProps> = ({
  data,
  isLoading,
  timeInterval,
  byBuildAnalytics,
}) => {
  const metrics = React.useMemo(() => {
    return byBuildAnalytics
      ? (data as ProjectRunStatusOverRunsQuery)?.metrics
          .projectRunDurationsOverRuns
      : (data as ProjectRunsOverTimeQuery)?.metrics
          .projectRunsOverTimeGroupedByStatus
  }, [data, byBuildAnalytics])

  // hide items from legend if they have no data
  const columnsWithData: Array<ChartLegendItemProps> = React.useMemo(() => {
    return _.filter(statuses, (bar) => {
      return metrics?.nodes.some((nodeValue) => nodeValue[bar.name] > 0)
    })
  }, [metrics])
  const gridTemplateColumns = `1fr repeat(${columnsWithData.length}, 1fr) 1fr 1fr`

  if (!data || !data.metrics) {
    return (
      <Panel>
        <EmptyState>
          <span>Loading...</span>
        </EmptyState>
      </Panel>
    )
  }

  return (
    <LoadingContainer active={isLoading}>
      <Panel>
        {columnsWithData.length < 1 ? (
          <EmptyState>
            <span>No data for this time range.</span>
          </EmptyState>
        ) : (
          <div
            className={cs(styles.analyticsTable)}
            data-cy="runs-over-time-table"
            style={{ gridTemplateColumns }}
          >
            <AnalyticRowLink dataCy="runs-over-time-table__header">
              {timeInterval === 'DAY' ? (
                <h6>Date</h6>
              ) : (
                <h6>{timeInterval} beginning</h6>
              )}
              {columnsWithData.map((column) => {
                return (
                  <h6 key={column.name}>
                    <span className={styles.textRight}>{column.title}</span>
                  </h6>
                )
              })}
              <h6>
                <span className={styles.textRight}>Total</span>
              </h6>
              <h6>
                <span className={styles.textRight}>Failure rate</span>
              </h6>
            </AnalyticRowLink>
            {_.reverse(_.cloneDeep(metrics.nodes)).map((row, idx) => {
              return (
                <RunsOverTimeRow
                  key={idx}
                  row={row}
                  columnsWithData={columnsWithData}
                />
              )
            })}
          </div>
        )}
      </Panel>
    </LoadingContainer>
  )
}

export { RunsOverTimeTable }

import React, { FunctionComponent } from 'react'
import moment from 'moment'
import pluralize from 'pluralize'
import { Tooltip } from '@frontend/design-system'

import {
  FailureRunFragment,
  FlakyRunFragment,
} from '~/graphql-codegen-operations.gen'

import styles from './module.LastRun.scss'

interface LastRunProps {
  lastRun: FailureRunFragment | FlakyRunFragment
}

export const LastRun: FunctionComponent<LastRunProps> = ({ lastRun }) => {
  return (
    <div className={styles.container}>
      <div>
        <Tooltip
          placement="top"
          overlay={<span>{moment.utc(lastRun.lastSeenAt).format('ll')}</span>}
        >
          <span className={styles.title} data-cy="last-run">
            {moment(lastRun.lastSeenAt).fromNow()}
          </span>
        </Tooltip>
      </div>
      <div>
        <span className={styles.subtitle}>
          {lastRun.runsAgo} test {pluralize('run', lastRun.runsAgo)} ago
        </span>
      </div>
    </div>
  )
}

import React, { FC } from 'react'
import cs from 'clsx'
import { IconTimeStopwatch } from '@cypress-design/react-icon'
import { durationFormatted } from '@packages/common'

import styles from './module.DurationDisplay.scss'

type DurationDisplayProps = {
  /**
   * Value, in milliseconds, to be rendered. Rendered value will be formatted unless
   * overridden with the formattingDisabled prop.
   */
  duration: string | number | moment.Duration
  /**
   * When true, the provided duration is not updated with the standard duration formatting
   */
  formattingDisabled?: boolean
  /**
   * Additional classes to be applied to the DurationDisplay's root element
   */
  className?: string
}

export const DurationDisplay: FC<DurationDisplayProps> = ({
  duration,
  formattingDisabled,
  ...rest
}) => {
  const className = cs(styles.container, rest.className)

  // The IconTimeStopwatch is currently shrunk to align with the
  // 14px size of legacy iconography used alongside this component.
  // Custom sizing can be removed when all icons are replaced with their
  // @design-system counterparts.
  return (
    <span {...rest} className={className}>
      <IconTimeStopwatch height="14" width="14" viewBox="0 0 16 17" />
      <span className={styles.durationText}>
        {formattingDisabled ? duration : durationFormatted(duration)}
      </span>
    </span>
  )
}

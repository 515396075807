export const getFlakinessSeverity = (flakePercentage: number) => {
  if (flakePercentage === 0) {
    return 'NONE'
  }

  if (flakePercentage <= 0.1) {
    return 'LOW'
  }

  if (flakePercentage <= 0.5) {
    return 'MEDIUM'
  }

  return 'HIGH'
}

import { isEmpty } from 'lodash'
import { useEffect } from 'react'

import { useSidebarService } from '../sidebarService'
import { useSidebarRouterIds } from './useSidebarRouterIds'

import {
  useSidebarOrganizationQuery,
  useSidebarProjectQuery,
} from '~/graphql-codegen-operations.gen'

const TEN_SECONDS = 10000

export const useUpdateSidebarData = () => {
  const [{ projectsOrder }, dispatch] = useSidebarService()

  const { routerOrganizationId, routerProjectId } = useSidebarRouterIds()
  const isProjectView = Boolean(routerProjectId)

  const { data: projectData, error: projectError } = useSidebarProjectQuery({
    skip: !isProjectView,
    variables: {
      projectId: routerProjectId!,
      orderProjectsByField: projectsOrder.field,
      orderProjectsByDirection: projectsOrder.direction,
    },
  })

  const {
    data: orgData,
    error: orgError,
    stopPolling,
    startPolling,
  } = useSidebarOrganizationQuery({
    skip: isProjectView,
    variables: {
      organizationId: routerOrganizationId || null,
      orderProjectsByField: projectsOrder.field,
      orderProjectsByDirection: projectsOrder.direction,
    },
  })

  useEffect(() => {
    startPolling?.(TEN_SECONDS)
    return () => stopPolling?.()
  }, [startPolling, stopPolling, routerOrganizationId])

  useEffect(
    () => dispatch({ type: 'showSidebar' }),
    [dispatch, routerProjectId]
  )

  useEffect(() => {
    if (!projectData || isEmpty(projectData) || !Boolean(routerProjectId)) {
      return
    }

    const { me, project } = projectData

    dispatch({
      type: 'updateData',
      payload: {
        userData: me,
        currentProject: project,
        currentOrg: project.organizationInfo,
        projectId: routerProjectId,
      },
    })
  }, [dispatch, projectData, routerProjectId])

  useEffect(() => {
    if (!orgData?.me || isEmpty(orgData) || Boolean(routerProjectId)) {
      return
    }

    const { me, organizationOrDefault } = orgData

    dispatch({
      type: 'updateData',
      payload: {
        userData: me,
        currentProject: projectData?.project,
        currentOrg: organizationOrDefault,
        projectId: routerProjectId,
      },
    })
  }, [dispatch, orgData, projectData, routerProjectId])

  useEffect(() => {
    const projectQueryError = projectError || null
    const orgQueryError = orgError || null

    dispatch({
      type: 'updateError',
      payload: projectQueryError || orgQueryError,
    })
  }, [dispatch, orgError, projectError])
}

import { CustomIcon, Icon } from '@frontend/design-system'
import { uniqBy } from 'lodash'
import React, { FunctionComponent, useEffect, useState } from 'react'

import { useRunOsFilterLazyQuery } from '~/graphql-codegen-operations.gen'
import { osIcon } from '~/lib/utils'

import { MultiSelectFilter } from '../../MultiSelectFilter'
import { MultiSelectOptionType, onMultiSelectFilterChange } from '../../types'

type RunOsFilterProps = {
  id: string
  onChange: onMultiSelectFilterChange
  runId: string
  selected: MultiSelectOptionType[]
}

const renderLabel = (option: MultiSelectOptionType) => {
  const label = (
    <span>
      <Icon
        data-cy="run-os-filter-icon"
        data-pendo="run-os-filter-icon"
        className="fa-fw has-label"
        name={osIcon(option.value)}
      />
      {option.label}
    </span>
  ) as unknown

  return label as string
}

export const RunOsFilter: FunctionComponent<RunOsFilterProps> = React.memo(
  ({ id, onChange, selected, runId }) => {
    const [options, setOptions] = useState<MultiSelectOptionType[] | null>(null)
    const [fetchRunOses] = useRunOsFilterLazyQuery({
      onCompleted: (data) => {
        if (!data.run || options) {
          return
        }

        const formattedOptions = data.run.uniqueOses.map(
          ({ name, unformattedName }) => ({
            label: name,
            value: unformattedName,
          })
        )

        const uniqueOptions = uniqBy(formattedOptions, 'value')

        setOptions(uniqueOptions)
      },
    })

    useEffect(() => {
      fetchRunOses({
        variables: {
          id: runId,
        },
      })
    }, [fetchRunOses, runId])

    return (
      <MultiSelectFilter
        id={id}
        title={<CustomIcon name="operating-system" alt="Operating system" />}
        emptyLabel="Operating System"
        selected={selected}
        onChange={onChange}
        getOptionLabel={renderLabel}
        options={options || []}
      />
    )
  }
)

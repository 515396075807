import cs from 'clsx'
import React, { FunctionComponent } from 'react'
import { Link } from '@reach/router'
import styles from './module.AnalyticRowLink.scss'

interface AnalyticRowLinkProps {
  to?: string
  dataCy: string
  isActive?: boolean
  isEnabled?: boolean
  onClick?: VoidFunction
  onMouseOver?: VoidFunction
  className?: string
}

export const AnalyticRowLink: FunctionComponent<AnalyticRowLinkProps> = ({
  onClick,
  onMouseOver,
  to,
  isActive,
  isEnabled,
  dataCy,
  children,
  className,
}) => {
  if (!isEnabled || !to) {
    return (
      <div
        onClick={onClick}
        onMouseOver={onMouseOver}
        className={cs(styles.row, { [styles.active]: isActive }, className)}
        data-cy={dataCy}
      >
        {children}
      </div>
    )
  }

  return (
    <Link
      to={`${to}${location.search}`}
      className={cs(
        styles.row,
        styles.rowLink,
        {
          [styles.active]: isActive,
        },
        className
      )}
      data-cy={dataCy}
    >
      {children}
    </Link>
  )
}

import { capitalize, startCase } from 'lodash'
import React, { FunctionComponent, SyntheticEvent } from 'react'

import { InstanceOrderEnum } from '~/graphql-codegen-operations.gen'

import { ListFilter } from '../ListFilter'

type InstanceOrderProps = {
  id: string
  value: InstanceOrderEnum
  onSelect: (eventKey: any, e?: SyntheticEvent<{}>) => void
}

const options = (['EXECUTION_ORDER', 'FILENAME'] as const).map((status) => ({
  value: status,
  label: capitalize(startCase(status)),
}))

export const InstanceOrderFilter: FunctionComponent<InstanceOrderProps> = ({
  id,
  value,
  onSelect,
}) => {
  return (
    <ListFilter id={id} value={value} options={options} onSelect={onSelect} />
  )
}

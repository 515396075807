import { Button } from '@frontend/design-system/src'
import { Link } from '@reach/router'
import { toLower } from 'lodash'
import React, { FunctionComponent, ReactNode } from 'react'
import { OverlayPopoverPlanFragment } from '~/graphql-codegen-operations.gen'
import { CustomPlanCTA } from '~/billing/CustomPlan/CustomPlanCTA/CustomPlanCTA'
import styles from './module.overlayCTA.scss'

type OverlayPopoverProps = {
  title: string
  description: ReactNode
  orgId?: string
  learnMoreUri?: string
  viewerIsOwnerOrAdmin?: boolean
  buttonLink?: string
  buttonText?: string
  plan?: OverlayPopoverPlanFragment
  onModalToggle(isToggled: boolean): void
  hideButton?: boolean
}

export const OverlayPopover: FunctionComponent<OverlayPopoverProps> = ({
  title,
  description,
  learnMoreUri,
  orgId,
  plan,
  viewerIsOwnerOrAdmin,
  buttonLink,
  onModalToggle,
  buttonText = 'Upgrade',
  hideButton,
}) => {
  return (
    <div>
      <div>
        <span className={styles.popoverTitle}>{title}</span>
      </div>
      <div className={styles.popoverDescription}>{description}</div>
      <div className={styles.popoverCta}>
        {learnMoreUri ? (
          <a
            href={learnMoreUri}
            target="_blank"
            className={styles.popoverLearnMore}
            data-cy="overlay-popover-learn-more"
          >
            Learn more
          </a>
        ) : (
          <div />
        )}
        {(() => {
          if (hideButton) {
            return null
          }

          if (plan?.isCustom && orgId) {
            return <CustomPlanCTA orgId={orgId} onModalToggle={onModalToggle} />
          }

          if (viewerIsOwnerOrAdmin) {
            return (
              <Link to={buttonLink || `/organizations/${orgId}/pricing`}>
                <Button>{buttonText}</Button>
              </Link>
            )
          }

          return (
            <span className={`text-muted ${styles.contactOwnerOrAdmin}`}>
              Contact owner to {toLower(buttonText)}
            </span>
          )
        })()}
      </div>
    </div>
  )
}

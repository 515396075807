import {
  ChartEmptyState,
  LoaderChart,
  Panel,
  Tooltip,
  palette,
} from '@frontend/design-system'
import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  FlexibleWidthXYPlot,
  Hint,
  RVTickFormat,
  HorizontalGridLines,
  XAxis,
  YAxis,
  VerticalRectSeries,
} from 'react-vis'
import {
  ProjectFlakyTestsQuery,
  ViewByEnum,
} from '~/graphql-codegen-operations.gen'
import { maxBy } from 'lodash'
import { LoadingContainer } from '~/project-analytics/LoadingContainer'
import moment from 'moment'
import { useTimeSeriesAxis, isValidTick } from '~/lib/chart-utils'
import { binsInfo, BucketData, getFlakyTestsBuckets } from './util'
import pluralize from 'pluralize'
import { readableViewByEnum } from '~/lib/utils-ts'
import { utcFormat } from 'd3-time-format'

type FlakyTestsChart = {
  data?: ProjectFlakyTestsQuery
  isLoading: boolean
  setChartFilter: (x0: Date | number, x: Date | number) => void
  chartFilter?: [Date | number, Date | number] | []
  shouldBeHourly: boolean
  viewBy: ViewByEnum
  byBuildAnalytics?: boolean
}

type FlakyTestsChartBodyProps = {
  metrics: ProjectFlakyTestsQuery['metrics']['projectFlakinessTestsOverTime']
  setChartFilter: (x0: Date | number, x: Date | number) => void
  chartFilter?: [Date | number, Date | number] | []
  timeIntervalHourly: boolean
  byBuildAnalytics?: boolean
}

type FlakyTestsChartTooltipProps = {
  focusedValue: FlakyTestsChartDataPointProps & { width: number }
  shouldBeHourly: boolean
}

type FlakyTestsChartDataPointProps = {
  x0: number
  x: number
  y: number
  y0: number
  value: number
  binSize: number
  date: string
}

/**
 * Creates the data series that react-viz expects
 * If using byBuildAnalytics the xAxis is index based, otherwise we use time based values.
 */
function formatDataForChart(
  buckets: BucketData[],
  binSize: number,
  focusedValue: null | FlakyTestsChartDataPointProps,
  shouldBeHourly: boolean,
  chartFilter?: [Date | number, Date | number] | [],
  byBuildAnalytics?: boolean
): Array<any> {
  return buckets.map((b, idx) => {
    const t = moment.utc(b.date)

    const amountToAdd = shouldBeHourly ? '60' : binSize
    const timeUnit = shouldBeHourly ? 'minutes' : 'days'
    // The value on the x axis where this bar begins (width)
    const x0 = byBuildAnalytics ? idx : t.toDate()
    // The value on the x axis where this bar ends (width)
    const x = byBuildAnalytics
      ? idx + 1
      : t.clone().add(amountToAdd, timeUnit).toDate()

    let color = palette.orange400
    const nodeIsFocused = focusedValue?.x === x && focusedValue?.x0 === x0
    if (chartFilter?.length) {
      const byDateFocused =
        !byBuildAnalytics &&
        new Date(chartFilter[0]).getTime() === (x0 as Date).getTime() &&
        new Date(chartFilter[1]).getTime() === (x as Date).getTime()
      const byBuildFocused =
        byBuildAnalytics && chartFilter[0] === x0 && chartFilter[1] === x
      if (!(byDateFocused || byBuildFocused)) {
        color = palette.gray100
      }
    } else {
      if (nodeIsFocused) {
        color = palette.orange500
      }
    }

    return {
      x,
      x0,
      y: b.number,
      y0: 0,
      value: b.number,
      binSize,
      date: b.date,
      endDate: b.endDate,
      color,
    }
  })
}

function formatDataForInvisibleRects(
  data: FlakyTestsChartDataPointProps[]
): Array<FlakyTestsChartDataPointProps> {
  const largestDataPoint = maxBy(data, 'y')
  return data.map((d) => ({
    x0: d.x0,
    x: d.x,
    y: largestDataPoint?.y || 0,
    y0: 0,
    binSize: d.binSize,
    value: d.value,
    date: d.date,
  }))
}

const FlakyTestsChartTooltip: FunctionComponent<
  FlakyTestsChartTooltipProps
> = ({ focusedValue, shouldBeHourly }) => {
  const getDateText = () => {
    const dateFormat = shouldBeHourly ? 'LLL' : 'll'
    const date = moment.utc(focusedValue.date).format(dateFormat)
    if (focusedValue.binSize === 1) {
      return date
    }

    if (focusedValue.binSize === 7) {
      return `Week beginning ${date}`
    }

    return `${focusedValue.binSize} days beginning ${date}`
  }
  return (
    <table>
      <thead>
        <tr>
          <th colSpan={2}>{getDateText()}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Flaky tests</td>
          <td>{focusedValue.value}</td>
        </tr>
      </tbody>
    </table>
  )
}

const FlakyTestsChartBody: FunctionComponent<FlakyTestsChartBodyProps> = ({
  metrics,
  setChartFilter,
  chartFilter,
  timeIntervalHourly,
  byBuildAnalytics,
}) => {
  const [shouldBeHourly, setShouldBeHourly] = useState(timeIntervalHourly)
  const shouldBeHourlyRef = useRef(() => {})

  useEffect(() => {
    shouldBeHourlyRef.current = () => {
      if (shouldBeHourly !== timeIntervalHourly) {
        setShouldBeHourly(timeIntervalHourly)
      }
    }
  }, [shouldBeHourly, timeIntervalHourly])

  const { buckets, binSize } = useMemo(
    () => getFlakyTestsBuckets(metrics, shouldBeHourly, byBuildAnalytics),
    [metrics, shouldBeHourly, byBuildAnalytics]
  )

  const [focusedValue, setFocusedValue] =
    useState<null | FlakyTestsChartDataPointProps>(null)

  useEffect(() => {
    shouldBeHourlyRef.current()
  }, [metrics])

  const clearFocus = () => {
    setFocusedValue(null)
  }

  const chartRef = useRef<FlexibleWidthXYPlot<any> | null>(null)
  const data = formatDataForChart(
    buckets,
    binSize,
    focusedValue,
    shouldBeHourly,
    chartFilter,
    byBuildAnalytics
  )
  const invisibleRects = formatDataForInvisibleRects(data)

  const xAxis = useTimeSeriesAxis([], null, { chartRef })
  const tickValues = React.useMemo(
    () =>
      data.map((d) => {
        return !byBuildAnalytics
          ? moment.utc(d.x0).add(amountToAdd, 'hours').toDate()
          : d.x0 + 0.5
      }),
    [data, byBuildAnalytics]
  )
  const formatByBuildTicks = (tickValue) => {
    const value = data.find((d) => (d.x0 as number) + 0.5 === tickValue)
    return value && value.date
      ? utcFormat('%b %d')(moment.utc(value.date).toDate())
      : ''
  }

  const amountToAdd = binSize === 1 && !shouldBeHourly ? 12 : 0
  const tickFormatDate = shouldBeHourly ? 'h A' : 'MM/DD'
  const focusedValueAdd = shouldBeHourly ? 60 : 24
  const focusedValueAddFormat = shouldBeHourly ? 'minutes' : 'hours'
  const tickFormat = (v, i, scale, tickTotal) => {
    const isValid = isValidTick(i, tickValues.length, tickTotal)
    return !isValid ? '' : moment.utc(v).format(tickFormatDate)
  }

  return (
    <Panel.Body>
      <FlexibleWidthXYPlot
        className="flaky-tests-chart"
        height={300}
        ref={chartRef}
        onMouseLeave={clearFocus}
      >
        <HorizontalGridLines />
        {!byBuildAnalytics ? (
          <XAxis
            tickTotal={xAxis.tickTotal}
            tickFormat={tickFormat as RVTickFormat}
            tickValues={tickValues}
          />
        ) : (
          <XAxis tickValues={tickValues} tickFormat={formatByBuildTicks} />
        )}
        <YAxis />

        <VerticalRectSeries
          colorType="literal"
          data={data}
          stroke="white"
          key="vertical-rect-series"
          style={{ cursor: 'pointer' }}
        />

        {focusedValue && (
          <Hint
            value={{
              x: byBuildAnalytics
                ? (focusedValue.x0 + focusedValue.x) / 2
                : moment
                    .utc(focusedValue.date)
                    .add(
                      (focusedValue.binSize * focusedValueAdd) / 2,
                      focusedValueAddFormat
                    )
                    .toDate(),
              y: focusedValue.value,
            }}
            align={{ horizontal: 'auto', vertical: 'top' }}
          >
            <Tooltip
              id="flaky-tests-chart__tooltip"
              overlay={
                <FlakyTestsChartTooltip
                  focusedValue={focusedValue as any}
                  shouldBeHourly={shouldBeHourly || !!byBuildAnalytics}
                />
              }
              overlayStyle={{ pointerEvents: 'none' }}
              placement="top"
              visible
            >
              <span />
            </Tooltip>
          </Hint>
        )}

        {/* a series of invisible rects covering the full column so hovering anywhere on the column will display the tooltip */}
        <VerticalRectSeries
          colorType="literal"
          color="#00000000"
          data={invisibleRects}
          stroke="white"
          onValueMouseOver={(val: any) => setFocusedValue(val)}
          onValueMouseOut={clearFocus}
          key="invisible-rect-series"
          onValueClick={(val: any) => {
            setChartFilter(val.x0, val.x)
          }}
          style={{ cursor: 'pointer' }}
        />
      </FlexibleWidthXYPlot>
    </Panel.Body>
  )
}

export const FlakyTestsChart: FunctionComponent<FlakyTestsChart> = ({
  data,
  isLoading,
  setChartFilter,
  chartFilter,
  shouldBeHourly,
  viewBy,
  byBuildAnalytics = false,
}) => {
  if (!data || !data.metrics) {
    return (
      <Panel className="analytic__chart flaky-tests-chart">
        <Panel.Heading>
          <Panel.Title>Number of flaky test cases</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <LoaderChart />
        </Panel.Body>
      </Panel>
    )
  }

  const metrics = data.metrics.projectFlakinessTestsOverTime
  const hasData = metrics && metrics.chartNodes.length > 0

  const { timeWindow } = binsInfo(metrics.chartNodes, shouldBeHourly)

  const chartTitle = `Flaky ${pluralize(
    readableViewByEnum(viewBy),
    0
  ).toLowerCase()} detected per ${timeWindow}`

  return (
    <LoadingContainer active={isLoading}>
      <Panel className="analytic__chart flaky-tests-chart">
        <Panel.Heading>
          <Panel.Title>{chartTitle}</Panel.Title>
        </Panel.Heading>
        {hasData ? (
          <FlakyTestsChartBody
            metrics={metrics}
            setChartFilter={setChartFilter}
            chartFilter={chartFilter}
            timeIntervalHourly={shouldBeHourly}
            byBuildAnalytics={byBuildAnalytics}
          />
        ) : (
          <ChartEmptyState>
            <p>No flaky test case match the supplied filters.</p>
          </ChartEmptyState>
        )}
      </Panel>
    </LoadingContainer>
  )
}

import {
  ProjectSlowestTestsQuery,
  ViewByEnum,
} from '~/graphql-codegen-operations.gen'
import { lowerCase, map } from 'lodash'
import pluralize from 'pluralize'
import { median, durationFormatted } from '@packages/common'
import { readableViewByEnum } from '~/lib/utils-ts'

export const getKPIs = (
  ProjectSlowestTests: ProjectSlowestTestsQuery | undefined,
  loading: boolean,
  viewBy: ViewByEnum,
  chartRange?: number[]
) => {
  let kpis
  if (ProjectSlowestTests) {
    const metrics = ProjectSlowestTests.metrics.projectSlowestTests
    let medianDurationValue: string | null = null
    let durationName = 'Median duration'

    if (chartRange?.length) {
      const [chartRangeMin, chartRangeMax] = chartRange
      medianDurationValue = `${durationFormatted(chartRangeMin, {
        includeMs: true,
      })} - ${durationFormatted(chartRangeMax, { includeMs: true })}`
      durationName = 'Duration'
    } else if (metrics.nodes.length > 0) {
      medianDurationValue = durationFormatted(
        median(map(metrics.nodes, 'medianDuration'))
      )
    }

    kpis = [
      {
        name: durationName,
        value: medianDurationValue,
        loading,
      },
      {
        name: `Number of ${lowerCase(pluralize(readableViewByEnum(viewBy)))}`,
        value: metrics.nodes.length,
        loading,
      },
    ]
  } else {
    kpis = [
      {
        name: 'Median duration',
        loading,
      },
      {
        loading,
      },
    ]
  }
  return kpis
}

import {
  Button,
  CustomIcon,
  FilterEmptyState,
  Icon,
  Label,
} from '@frontend/design-system'
import React, { FunctionComponent, ReactNode, memo } from 'react'
import Highlighter from 'react-highlight-words'
import { components } from 'react-select'
import _ from 'lodash'

import { MultiSelectFilter } from '~/common/filters'
import { MultiSelectOptionType } from '~/common/filters/types'

type ProjectBranchesFilterProps = {
  id: string
  state: ProjectBranchesFilterStateProp
}

type ProjectBranchesFilterStateProp = {
  search: string | undefined
  loading: boolean
  options: MultiSelectOptionType[] | undefined
  selected: MultiSelectOptionType[] | undefined
  defaults: MultiSelectOptionType[] | undefined
  setSearch: (value: string) => void
  setLoading: (value: boolean) => void
  setSelected: (value: []) => void
}

export const ProjectBranchesFilterGlobal: FunctionComponent<ProjectBranchesFilterProps> =
  memo(({ id, state }) => {
    const {
      options,
      defaults,
      search,
      setSearch,
      loading,
      setLoading,
      selected,
      setSelected,
    } = state

    // sort branches
    const branchOptions: MultiSelectOptionType[] = _.unionBy(
      selected,
      defaults,
      options
        ? options.map((option) => {
            return {
              ...option,
              suggested: false,
            }
          })
        : [],
      'value'
    )

    branchOptions.map((option) => {
      if (_.find(defaults, ['value', option.value])) {
        option = { ...option, suggested: true }
      }
    })

    const onClickClear = () => setSelected([])
    const onInputChange = () => setLoading(true)

    const unselectedItems: MultiSelectOptionType[] = _.difference(
      branchOptions,
      selected || []
    )

    const NoOptionsMessage: FunctionComponent = () => {
      return (
        <FilterEmptyState
          filter="branch"
          icon={<CustomIcon name="branch" />}
          info="Provide git information to Cypress to filter runs by branch"
          searchQuery={search}
          url="http://on.cypress.io/git-info"
        />
      )
    }

    const SelectedItemsHeader: FunctionComponent = () => {
      return (
        <div className="branches-filter--header h6">
          Selected
          <Button bsStyle="link" onClick={onClickClear}>
            Clear
          </Button>
        </div>
      )
    }

    const UnselectedItemsHeader: FunctionComponent = () => {
      if (search) {
        return (
          <div className="branches-filter--header unselected h6">
            Search results
          </div>
        )
      }

      return (
        <div className="branches-filter--header unselected h6">
          Recently updated
        </div>
      )
    }

    const customOption = (props): ReactNode => {
      return (
        <>
          {selected &&
            selected.length > 0 &&
            props.value === selected[0].value && <SelectedItemsHeader />}
          {unselectedItems.length > 0 &&
            unselectedItems[0].value === props.value && (
              <UnselectedItemsHeader />
            )}
          <components.Option {...props}>
            <span>
              {props.isSelected ? (
                <Icon
                  name="check-square"
                  className="filter__checkbox fa-lg primary"
                />
              ) : (
                <Icon name="square-o" className="filter__checkbox fa-lg" />
              )}{' '}
              <Highlighter
                searchWords={[search]}
                textToHighlight={props.data.label}
              />
            </span>
            {props.data.suggested && <Label bsStyle="default">default</Label>}
          </components.Option>
        </>
      )
    }

    return (
      <div
        data-cy="branches-filter"
        data-pendo="branches-filter"
        className="branches-filter multi-select-filter-with-categories"
      >
        <MultiSelectFilter
          id={id}
          title={<CustomIcon name="branch" alt="Branch" />}
          emptyLabel="Branch"
          placeholder="Search for a branch..."
          customOption={customOption}
          isLoading={loading}
          noOptionsMessage={NoOptionsMessage}
          onChange={setSelected}
          onInputChange={onInputChange}
          onSearch={setSearch}
          options={branchOptions}
          selected={selected || []}
        />
      </div>
    )
  })

import React from 'react'
import styles from './module.GroupList.scss'
import StatusIcon from '@cypress-design/react-statusicon'

type AlertProps = {
  isCiAlert?: boolean
}

export const Alert: React.FunctionComponent<AlertProps> = ({ isCiAlert }) => {
  return (
    <div className={styles.warningContainer} data-cy="onboarding-alert">
      <div className={styles.header}>
        <StatusIcon size="16" status="noTests" variant="outline" />
        <span>
          {isCiAlert
            ? 'Unable to detect git and/or CI information'
            : 'Unable to detect git information'}
        </span>
      </div>
      <div className={styles.content}>
        <span>
          Cypress uses git information to enable advanced analytics and provide
          insights into your testing process, such as Branch Review. You can
          pass git information to Cypress using environment variables, which
          will help enhance your experience with our platform and unlock
          additional features.{' '}
          <a href="https://on.cypress.io/git-info" target="_blank">
            Learn more
          </a>
        </span>
      </div>
    </div>
  )
}

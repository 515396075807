import moment from 'moment'
import * as React from 'react'

import { Icon, Tooltip } from '@frontend/design-system'

import TimerDisplay from '~/duration-timer/TimerDisplay'
import { DurationDisplay } from '~/duration-timer/DurationDisplay'

import { Run } from '~/graphql-codegen-operations.gen'
import { durationFormattedFull } from '~/lib/utils'
import { durationFormatted } from '@packages/common'

interface RunDurationProps {
  showScheduledToCompleteDuration?: boolean
  run: Pick<
    Run,
    | 'startTime'
    | 'parallelizationDisabled'
    | 'scheduledToCompleteAt'
    | 'status'
    | 'totalDuration'
    | 'cancelledAt'
  >
}

export const RunDuration: React.FC<RunDurationProps> = ({
  showScheduledToCompleteDuration = false,
  run,
}) => {
  // Run was blocked due to exceeding limit
  if (!run.startTime || run.status === 'OVERLIMIT') {
    return null
  }

  const scheduledToCompleteDuration =
    run.startTime && run.scheduledToCompleteAt
      ? moment.duration(
          moment(run.scheduledToCompleteAt).diff(moment(run.startTime))
        )
      : null

  const cancelledDuration = run.cancelledAt
    ? moment.duration(moment(run.cancelledAt).diff(moment(run.startTime)))
    : null

  const staticDuration =
    run.totalDuration || scheduledToCompleteDuration || cancelledDuration || 0

  // Run has completed
  if (
    run.totalDuration ||
    cancelledDuration ||
    (scheduledToCompleteDuration && showScheduledToCompleteDuration)
  ) {
    if (run.parallelizationDisabled) {
      return (
        <Tooltip
          placement="top"
          overlay="Parallelization was disabled because this run exceeded the monthly usage limits."
        >
          <span className="run-duration parallelization-disabled">
            <Icon name="exclamation-triangle" className="has-label orange" />{' '}
            {durationFormatted(staticDuration)}
          </span>
        </Tooltip>
      )
    }

    if (!staticDuration) {
      return null
    }

    return (
      <Tooltip
        placement="top"
        overlay={durationFormattedFull(staticDuration, 4, true)}
      >
        <DurationDisplay
          className="run-duration"
          duration={staticDuration}
          data-cy="run-duration"
        />
      </Tooltip>
    )
  }

  // Run is still going
  return <TimerDisplay startTime={run.startTime} data-cy="run-duration" />
}

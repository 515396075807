import * as React from 'react'
import { Button, Modal } from '@frontend/design-system'

export interface PreconditionsModalConfig {
  variant: 'preconditions'
  entityType: string
  description: React.ReactNode
  preconditionMessages: Array<{
    message: string
    key: string
    requiresCheck: boolean
  }>
}

interface PreconditionsModalProps extends PreconditionsModalConfig {
  onClose: () => void
  onSubmit: () => void
}

export const PreconditionsModal: React.FC<PreconditionsModalProps> = ({
  entityType,
  description,
  preconditionMessages,
  onClose,
}) => {
  return (
    <Modal
      aria-labelledby="confirmation-modal-title"
      data-cy="confirmation-modal"
      show={Boolean(entityType)}
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <h3
          className="modal-title"
          data-cy="confirmation-modal-title"
          id="confirmation-modal-title"
        >
          Remove {entityType}?
        </h3>
      </Modal.Header>
      <Modal.Body>
        {description}
        {preconditionMessages && (
          <ul className="preconditions text-muted">
            {preconditionMessages.map((precondition, i) => (
              <li key={precondition.key}>{precondition.message}</li>
            ))}
          </ul>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button
          data-cy="confirmation-cancel"
          className="btn-light"
          onClick={onClose}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

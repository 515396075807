import { palette } from '@frontend/design-system'
import React, { FunctionComponent } from 'react'

type ColorPickerPopoverProps = {
  onSelect: (string) => void
}

/**
 * This component needs to be wrapped in a <Popover>
 * The popover doesn't get positioned correctly when using OverlayTrigger if the <Popover> is in this file
 */
export const ColorPickerPopover: FunctionComponent<ColorPickerPopoverProps> = ({
  onSelect,
}) => {
  const colorOptions = [
    [
      palette.red200,
      palette.orange200,
      palette.jade100,
      palette.indigo200,
      palette.purple200,
      palette.gray200,
    ],
    [
      palette.red600,
      palette.orange600,
      palette.jade600,
      palette.indigo600,
      palette.purple600,
      palette.gray600,
    ],
  ]

  return (
    <div className="color-picker-popover">
      <div>Select a new color</div>
      {colorOptions.map((row, rowIndex) => {
        return (
          <div className="color-picker-popover--row" key={rowIndex}>
            {row.map((color) => {
              return (
                <div
                  className="color-picker-color-sample"
                  key={color}
                  style={{ backgroundColor: color }}
                  onClick={() => onSelect(color)}
                />
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

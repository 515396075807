/// <reference types="@packages/common/typings" />
import moment, { Duration } from 'moment'
import _ from 'lodash'
import padStart from 'string.prototype.padstart'
import type { Builds } from '@packages/types/src/db'
import { RunStatuses } from '../enums'

export const MAX_32_BIT = 2 ** 31 - 1

export const durationFormatted = (
  durationInMs: number | string | Duration,
  options: { padMinutes?: boolean; includeMs?: boolean } = {
    padMinutes: true,
    includeMs: false,
  }
) => {
  if (!durationInMs) {
    return '—'
  }

  if (Number(durationInMs) < 1000) {
    return `${durationInMs}ms`
  }

  const duration = moment.duration(durationInMs, 'ms')

  const padMinutes = options.padMinutes || true
  const includeMs = options.includeMs || false

  const durationAsMonths = Math.floor(duration.asMonths()) > 0
  const durationAsDays = Math.floor(duration.asDays()) > 0
  const durationAsHours = Math.floor(duration.asHours()) > 0

  const durationYears = duration.years() ? `${duration.years()}` : ''
  const durationMonths = duration.months() ? `${duration.months()}` : ''
  const durationDays = duration.days() ? `${duration.days()}` : ''
  const durationHrs = duration.hours() ? `${duration.hours()}` : ''

  const durationSecs = duration.seconds() ? `${duration.seconds()}` : ''
  const durationMins = duration.minutes() ? `${duration.minutes()}` : ''

  const total = _.compact([
    durationYears,
    durationAsMonths ? padStart(durationMonths, 2, '0') : durationMonths,
    durationAsDays ? padStart(durationDays, 2, '0') : durationDays,
    durationAsHours ? padStart(durationHrs, 2, '0') : durationHrs,
    !!durationHrs || padMinutes ? padStart(durationMins, 2, '0') : durationMins,
    padStart(durationSecs, 2, '0'),
  ])

  const totalMinSec = total.join(':')
  const durationMs = duration.milliseconds()

  if (includeMs && durationMs) {
    return `${totalMinSec}.${durationMs}`
  }

  return totalMinSec
}

export const getRunCompletedAt = (root: Builds): Date | null => {
  // logic migrated from build model getCompletedAt()
  if (root.specIsolation) {
    return root.completedAt
  }

  if (root.status === RunStatuses.RUNNING) {
    return null
  }

  // specs recorded earlier do not have "completedAt" timestamp
  // that have finished - we can use "updatedAt" timestamp
  return root.updatedAt
}

export function getTotalRunDuration(root: Builds) {
  const ca = getRunCompletedAt(root)

  /* istanbul ignore next */
  if (!ca) {
    return null
  }

  const totalDuration = ca!.valueOf() - root.createdAt!.valueOf()
  // If the total duration is gt than 23 days (almost 32bits) it should
  // return max value (it's ~32 bits) because the data is incorrect
  // 2000000000 = ~23 days
  if (totalDuration > MAX_32_BIT) {
    return MAX_32_BIT
  }

  return totalDuration
}

export function getTotalSavedByParallelization(root: Builds) {
  const totalDuration = getTotalRunDuration(root)

  if (!totalDuration || !root.totalUnparallelizedWallClockDuration) {
    return null
  }

  const savedByParallelization =
    root.totalUnparallelizedWallClockDuration * 1000 - totalDuration

  if (!savedByParallelization || savedByParallelization < 0) {
    return null
  }

  return savedByParallelization
}

import { CustomIcon, OrgLogo } from '@frontend/design-system'
import React from 'react'

import { NotificationIndicator } from './notification-indicator'
import { SwitcherSummaryProps } from './organization-switcher'
import { useSidebarService } from './sidebarService'
import { IconActionDeleteXlarge } from '@cypress-design/react-icon'

export const OrganizationSwitcherSummary: React.FC<SwitcherSummaryProps> = ({
  inMenu,
  onClick,
  userData,
  currentOrg: org,
}) => {
  const [state] = useSidebarService()
  const visible = state.isSidebarMenuOpen

  const notificationsCount =
    (userData &&
      userData.membershipInvitations.totalCount +
        userData.pendingGithubAppInstall.totalCount) ||
    0

  const pendingInvitationsCircle =
    notificationsCount > 0 ? (
      <NotificationIndicator count={notificationsCount} />
    ) : (
      <CustomIcon name="chevron-down" />
    )

  return (
    <button
      className={`organization-switcher-button ${inMenu && 'in-menu'}`}
      onClick={onClick}
      aria-haspopup="true"
      aria-controls="organization-switcher-menu"
      aria-expanded={visible}
      data-cy="organization-switcher-summary"
      data-pendo="organization-switcher-summary"
    >
      <div className="organization-switcher-summary">
        <OrgLogo org={org} logo={org?.logo} size="x32" />
        <div className="organization">{org?.name}</div>
        <div className="user">
          {userData ? userData.name : "You're not logged in"}
        </div>
        {inMenu ? (
          <IconActionDeleteXlarge aria-hidden className="close-icon" />
        ) : (
          pendingInvitationsCircle
        )}
      </div>
    </button>
  )
}

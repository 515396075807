import React from 'react'
import { IconStatusFailedSolid } from '@cypress-design/react-icon'
import Button from '@cypress-design/react-button'
import styles from './module.GroupList.scss'
import { useOrganizationOnboardingSkipMutation } from '../graphql-codegen-operations.gen'

type SkipButtonProps = {
  orgId: string
  field: string
  isStepChecked: boolean
}

export const SkipButton: React.FunctionComponent<SkipButtonProps> = ({
  orgId,
  field,
  isStepChecked,
}) => {
  const [organizationOnboardingSkip] = useOrganizationOnboardingSkipMutation({
    variables: {
      input: {
        orgId,
        field,
        status: 'SKIPPED',
      },
    },
  })

  if (isStepChecked) {
    return null
  }

  return (
    <Button
      variant="white"
      className={styles.skip}
      onClick={() => organizationOnboardingSkip()}
      data-cy="onboarding-skip"
    >
      <IconStatusFailedSolid className="mr-2" />
      Skip
    </Button>
  )
}

import { palette } from '@frontend/design-system'

export const statuses = [
  {
    name: 'countPassed',
    title: 'Passed',
    color: palette.jade400,
    filterValue: 'PASSED',
  },
  {
    name: 'countFailed',
    title: 'Failed',
    color: palette.red400,
    filterValue: 'FAILED',
  },
  {
    name: 'countRunning',
    title: 'Running',
    color: palette.indigo400,
    filterValue: 'RUNNING',
  },
  {
    name: 'countTimedOut',
    title: 'Timed out',
    color: palette.gray300,
    filterValue: 'TIMEDOUT',
  },
  {
    name: 'countErrored',
    title: 'Errored',
    color: palette.orange400,
    filterValue: 'ERRORED',
  },
  {
    name: 'countNoTests',
    title: 'No tests',
    color: palette.gray500,
    filterValue: 'NOTESTS',
  },
  {
    name: 'countOverLimit',
    title: 'Over limit',
    color: palette.gray700,
    filterValue: 'OVERLIMIT',
  },
  {
    name: 'countCancelled',
    title: 'Canceled',
    color: palette.orange300,
    filterValue: 'CANCELLED',
  },
]

import {
  CustomIcon,
  EmptyState,
  Panel,
  TextEmptyState,
  Tooltip,
} from '@frontend/design-system'
import { ProjectPastBuildRunAnalyticsQuery } from '~/graphql-codegen-operations.gen'
import cs from 'clsx'
import { format, parseISO } from 'date-fns'
import React, { FunctionComponent, useMemo } from 'react'
import { AnalyticRowLink } from '../AnalyticRowLink/AnalyticRowLink'
import { LoadingContainer } from '~/project-analytics/LoadingContainer'
import { durationFormattedFull } from '~/lib/utils'
import styles from '../module.AnalyticsTable.scss'
import pluralize from 'pluralize'

type PastRunDurationTableProps = {
  data?: ProjectPastBuildRunAnalyticsQuery
  isLoading: boolean
}

export const PastRunDurationTable: FunctionComponent<
  PastRunDurationTableProps
> = ({ data, isLoading }) => {
  const pastRuns = useMemo(() => data?.project?.runs?.nodes || [], [data])

  if (!data || !data.project) {
    return (
      <Panel>
        <EmptyState>
          <span>Loading...</span>
        </EmptyState>
      </Panel>
    )
  }
  return (
    <LoadingContainer active={isLoading}>
      <Panel>
        {pastRuns.length < 1 ? (
          <EmptyState>
            <span>No data for this time range.</span>
          </EmptyState>
        ) : (
          <div
            className={cs(styles.analyticsTable, styles.pastRunDurationTable)}
            data-cy="run-duration-table"
          >
            <AnalyticRowLink dataCy="run-duration-table__header">
              <h6>
                <span className={styles.textLeft}>Date</span>
              </h6>
              <h6>
                <span className={styles.textRight}>Run Time</span>
              </h6>
              <h6>
                <span className={styles.textRight}>
                  <Tooltip
                    overlay="The number of machines that were used for parallelizing for these runs"
                    placement="top"
                  >
                    <span>Concurrency ℹ</span>
                  </Tooltip>
                </span>
              </h6>
              <h6>
                <span className={styles.textRight}>
                  Time saved from parallelization
                </span>
              </h6>
            </AnalyticRowLink>

            {pastRuns.map((row) => {
              return (
                <AnalyticRowLink
                  dataCy="past-build-run-duration-table__row"
                  key={row.id}
                >
                  <div>
                    <div>
                      <div className={styles.titleContainer}>
                        <CustomIcon name="number" variant="small" />{' '}
                        <p className={styles.titleText}>{row.buildNumber}</p>
                      </div>
                      <small>{format(parseISO(row.createdAt), 'PP')}</small>
                    </div>
                  </div>
                  <div>
                    {row.totalDuration ? (
                      <span className={styles.textRight}>
                        {durationFormattedFull(row.totalDuration, 2)}
                      </span>
                    ) : (
                      <span className={styles.textRight}>
                        <TextEmptyState />
                      </span>
                    )}
                  </div>
                  <div>
                    <span className={styles.textRight}>
                      {row.machineCount ? (
                        <>
                          {row.machineCount}{' '}
                          {pluralize('machine', row.machineCount)}
                        </>
                      ) : (
                        <TextEmptyState />
                      )}
                    </span>
                  </div>
                  <div>
                    <span className={styles.textRight}>
                      {row.totalSavedByParallelization ? (
                        <span>
                          {durationFormattedFull(
                            row.totalSavedByParallelization * 1000,
                            2
                          )}
                        </span>
                      ) : (
                        <TextEmptyState />
                      )}
                    </span>
                  </div>
                </AnalyticRowLink>
              )
            })}
          </div>
        )}
      </Panel>
    </LoadingContainer>
  )
}

import {
  ChartEmptyState,
  LoaderChart,
  Panel,
  Tooltip,
  palette,
  Switch,
} from '@frontend/design-system'
import {
  ProjectPastBuildRunAnalyticsQuery,
  RunStatusEnum,
} from '~/graphql-codegen-operations.gen'
import { utcFormat } from 'd3-time-format'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'
import {
  AreaSeries,
  FlexibleWidthXYPlot,
  Hint,
  HorizontalGridLines,
  LineSeries,
  RVNearestXEventHandler,
  VerticalBarSeries,
  VerticalBarSeriesPoint,
  XAxis,
  YAxis,
} from 'react-vis'
import ErrorBoundaryDisplay from '~/lib/error-boundary'
import { durationFormattedFull } from '~/lib/utils'
import {
  filterOutliers,
  formatTimeIntervalAxis,
  simpleMovingAverage,
} from '~/lib/chart-utils'
import { LoadingContainer } from '~/project-analytics/LoadingContainer'
import moment from 'moment'

type PastRunDurationChartProps = {
  data?: ProjectPastBuildRunAnalyticsQuery
  isLoading: boolean
  navigateToRun: (buildNumber: number) => void
}

type PastRunDurationChartBodyProps = {
  pastRuns: ProjectPastBuildRunAnalyticsQuery['project']['runs']
  navigateToRun: (buildNumber: number) => void
  showOutliers: boolean
}

interface RunDurationChartDatum extends VerticalBarSeriesPoint {
  x: number
  y: number
  minutes: number | null
  seconds: number | null
  fullData: ProjectPastBuildRunAnalyticsQuery['project']['runs']['nodes'][0]
  hasData: boolean
}

const colorFromStatus = (status: RunStatusEnum) => {
  switch (status) {
    case 'PASSED':
      return palette.jade400
    case 'TIMEDOUT':
      return palette.orange200
    case 'FAILED':
      return palette.red400
    default:
      return palette.gray100
  }
}

function formatDataForChart(
  data: ProjectPastBuildRunAnalyticsQuery['project']['runs']['nodes']
): Array<RunDurationChartDatum> {
  return data.map((datum, index) => {
    const duration = datum.totalDuration ?? 0
    return {
      color: colorFromStatus(datum.status),
      x: data.length - index,
      y: duration,
      minutes: Math.floor(duration / 60),
      seconds: duration % 60, // seconds to be displayed with minutes
      fullData: datum,
      hasData: Boolean(duration),
    }
  })
}

const PastRunDurationToolTip: FunctionComponent<{
  focusedValue: RunDurationChartDatum
}> = ({ focusedValue }) => {
  return (
    <Tooltip
      id="run-duration-chart__tooltip"
      overlay={
        <table>
          <thead>
            <tr>
              <th colSpan={3}>
                <div
                  className="bullet"
                  style={{
                    backgroundColor: colorFromStatus(
                      focusedValue.fullData.status
                    ),
                  }}
                />{' '}
                {focusedValue.fullData.status}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Build Number</td>
              <td>
                <strong>{focusedValue.fullData.buildNumber}</strong>{' '}
              </td>
            </tr>
            <tr>
              <td>Run Duration</td>
              <td>
                <strong>
                  {durationFormattedFull(focusedValue.y!, 2, true)}
                </strong>{' '}
              </td>
            </tr>
            <tr>
              <td>Date</td>
              <td>
                <strong>
                  {utcFormat('%b %d')(
                    moment.utc(focusedValue.fullData.createdAt).toDate()
                  )}
                </strong>{' '}
              </td>
            </tr>
          </tbody>
        </table>
      }
      placement="top"
      visible
    >
      <span />
    </Tooltip>
  )
}

const PastRunDurationChartBody = React.memo<PastRunDurationChartBodyProps>(
  ({ pastRuns, showOutliers, navigateToRun }) => {
    // Focus handling
    const [focusedValue, setFocusedValue] =
      useState<RunDurationChartDatum | null>(null)
    const handleSetFocus = useCallback<
      RVNearestXEventHandler<VerticalBarSeriesPoint>
    >((value) => setFocusedValue(value as RunDurationChartDatum), [])
    const handleClearFocus = useCallback(() => setFocusedValue(null), [])
    const handleClick = useCallback(() => {
      focusedValue?.fullData.buildNumber &&
        navigateToRun(focusedValue.fullData.buildNumber)
    }, [focusedValue, navigateToRun])

    const series = useMemo(() => {
      let formattedData = formatDataForChart(pastRuns.nodes)
      if (!showOutliers) {
        formattedData = filterOutliers(formattedData).map((v, i, arr) => ({
          ...v,
          x: arr.length - i,
        }))
      }
      return formattedData
    }, [showOutliers, pastRuns])
    const isDefined = (d: RunDurationChartDatum) => d.x || d.y
    const yAxis = useMemo(
      () => formatTimeIntervalAxis(series.map((d) => d.y)),
      [series]
    )
    const movingAverageSeries = useMemo(
      () => simpleMovingAverage(series),
      [series]
    )
    const undefinedSeries = useMemo(() => series.filter(isDefined), [series])
    const hasUndefinedValues = undefinedSeries.length < series.length

    return (
      <Panel.Body>
        <FlexibleWidthXYPlot
          className="run-duration-chart"
          height={300}
          yDomain={yAxis.domain}
          onMouseLeave={handleClearFocus}
        >
          <HorizontalGridLines tickValues={yAxis.tickValues} />
          <XAxis
            tickFormat={(tickValue) => {
              const value = series.find((d) => d.x === tickValue)
              return value && value.hasData
                ? utcFormat('%b %d')(
                    moment.utc(value.fullData.createdAt).toDate()
                  )
                : ''
            }}
          />
          <YAxis
            hideLine
            tickFormat={yAxis.tickFormat}
            tickValues={yAxis.tickValues}
          />

          {hasUndefinedValues && (
            <>
              <AreaSeries
                color={palette.gray200}
                data={undefinedSeries}
                opacity={0.1}
                key="missing-area-series"
              />
              <LineSeries
                color={palette.gray200}
                data={undefinedSeries}
                style={{ strokeDasharray: '4 4' }}
                key="missing-line-series"
                className="run-duration-chart--missing-data-series"
              />
            </>
          )}

          <VerticalBarSeries
            colorType="literal"
            data={series}
            onNearestX={handleSetFocus}
            barWidth={0.8}
            key="area-series"
            onSeriesClick={handleClick}
          />
          <LineSeries data={movingAverageSeries} color={palette.purple500} />

          {focusedValue && isDefined(focusedValue) && (
            <Hint
              value={focusedValue}
              align={{ horizontal: 'left', vertical: 'top' }}
            >
              <PastRunDurationToolTip focusedValue={focusedValue} />
            </Hint>
          )}
        </FlexibleWidthXYPlot>
      </Panel.Body>
    )
  }
)

export const PastRunDurationChart = React.memo<PastRunDurationChartProps>(
  ({ data, isLoading, navigateToRun }) => {
    const [showOutliers, setShowOutliers] = useState(true)
    const pastRuns = useMemo(() => data?.project.runs, [data])
    const pendingData = useMemo(() => !data || !data.project, [data])
    return (
      <LoadingContainer active={isLoading}>
        <ErrorBoundaryDisplay>
          <Panel className="analytic__chart run-duration-chart">
            <Panel.Heading>
              <Panel.Title
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                Past Build Status
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ paddingRight: 4 }}>Display outlier Runs</span>
                  <Switch checked={showOutliers} onChange={setShowOutliers} />
                </div>
              </Panel.Title>
            </Panel.Heading>
            {pendingData ? (
              <LoaderChart />
            ) : pastRuns && pastRuns?.nodes?.length > 0 ? (
              <PastRunDurationChartBody
                pastRuns={pastRuns}
                navigateToRun={navigateToRun}
                showOutliers={showOutliers}
              />
            ) : (
              <ChartEmptyState>
                <p>No runs match the supplied filters.</p>
              </ChartEmptyState>
            )}
          </Panel>
        </ErrorBoundaryDisplay>
      </LoadingContainer>
    )
  }
)

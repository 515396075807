import React from 'react'
import Button from '@cypress-design/react-button'
import styles from './module.GroupList.scss'
import { Link } from '@reach/router'

type ViewProjectAnalyticsProps = {
  projectId: string
}

export const ViewProjectAnalytics: React.FunctionComponent<
  ViewProjectAnalyticsProps
> = ({ projectId }) => {
  return (
    <div className={styles.actionButtons}>
      <Link to={`/projects/${projectId}/analytics/slowest-tests`}>
        <Button variant="outline-indigo" className={styles.outlineButton}>
          View slowest tests
        </Button>
      </Link>

      <Link to={`/projects/${projectId}/analytics/top-failures`}>
        <Button variant="outline-indigo" className={styles.outlineButton}>
          See top failures
        </Button>
      </Link>

      <Link to={`/projects/${projectId}/analytics/flaky-tests`}>
        <Button variant="outline-indigo" className={styles.outlineButton}>
          Review top flaky tests
        </Button>
      </Link>
    </div>
  )
}

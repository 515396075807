import React from 'react'
import { Link } from '@reach/router'
import cs from 'clsx'

export const StackedLayoutTab: React.FC<{
  url: string
  active?: boolean
  disabled?: boolean
  className?: string
  'data-cy'?: string
  children
}> = ({ active, className, url, children, disabled, ...rest }) => {
  const content = (
    <div className="nav-item--tab" data-cy={rest['data-cy']}>
      {children}
    </div>
  )

  return (
    <li role="presentation" className={cs(className, { active })}>
      {disabled ? (
        <button type="button" disabled>
          {content}
        </button>
      ) : (
        <Link to={url}>{content}</Link>
      )}
    </li>
  )
}

import { HistoryLocation, NavigateFn } from '@reach/router'
import { hook2hoc } from 'hook2hoc'
import { useEffect, useState } from 'react'

import { history } from '~/lib/history'

export const useLocation = () => {
  const [state, setState] = useState({
    location: history.location,
    navigate: history.navigate,
  })

  useEffect(() => {
    const removeListener = history.listen(({ location }) => {
      setState({
        location,
        navigate: history.navigate,
      })
    })

    return removeListener
  }, [])

  return state
}

export const withLocation = hook2hoc('router', useLocation)

export type WithLocationProps = {
  router: { location: HistoryLocation; navigate: NavigateFn }
}

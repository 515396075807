import gravatar from 'gravatar'
import _ from 'lodash'

export type GravatarUrlOptions = {
  size?: string
  default?: string
  forcedefault?: string
}

export const gravatarUrl = (
  email: string | null | undefined,
  options: GravatarUrlOptions = {}
) => {
  const opts: GravatarUrlOptions = { size: '13', default: 'mm' }

  if (!email) {
    opts.forcedefault = 'y'
  }

  return gravatar.url(email || '', _.merge(opts, options), true)
}

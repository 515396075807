import { CustomIcon } from '@frontend/design-system'
import React, { FunctionComponent, SyntheticEvent } from 'react'
import { ListFilter } from './ListFilter'

type BuildIntervalFilterProps = {
  id: string
  value: number
  onSelect: (eventKey: any, e?: SyntheticEvent<{}>) => void
}

const options = [
  { value: 1, label: 'Single Run' },
  { value: 5, label: 'Bucket by 5 Runs' },
  { value: 10, label: 'Bucket by 10 Runs' },
  { value: 50, label: 'Bucket by 50 Runs' },
  { value: 100, label: 'Bucket by 100 Runs' },
]

export const BuildIntervalFilter: FunctionComponent<
  BuildIntervalFilterProps
> = ({ id, value, onSelect }) => {
  return (
    <ListFilter
      id={id}
      title={<CustomIcon name="number" alt="Build Interval" />}
      value={value}
      options={options}
      onSelect={onSelect}
    />
  )
}

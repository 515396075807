import { features } from '~/lib/feature-flags'

export const testedViewsFeatureFlag = 'ui-cov-tested-views'
export const unTestedViewsFeatureFlag = 'ui-cov-untested-views'
export const testedElementsFeatureFlag = 'ui-cov-tested-elements'
export const unTestedElementsFeatureFlag = 'ui-cov-untested-elements'

export const testedViewsPath = 'views'
export const unTestedLinksPath = 'untested-links'
export const testedElementsPath = 'tested-elements'
export const unTestedElementsPath = 'untested-elements'

const uiCovIsEnabled = features.isEnabled('intelligence-interactivity')

export const testedViewsIsEnabled =
  uiCovIsEnabled && features.isEnabled(testedViewsFeatureFlag)
export const unTestedViewsIsEnabled =
  uiCovIsEnabled && features.isEnabled(unTestedViewsFeatureFlag)
export const testedElementsIsEnabled =
  uiCovIsEnabled && features.isEnabled(testedElementsFeatureFlag)
export const unTestedElementsIsEnabled =
  uiCovIsEnabled && features.isEnabled(unTestedElementsFeatureFlag)

export const sidebarNavEnabled =
  uiCovIsEnabled &&
  (testedViewsIsEnabled ||
    unTestedViewsIsEnabled ||
    testedElementsIsEnabled ||
    unTestedElementsIsEnabled)

export const allDimensionTablesEnabled =
  uiCovIsEnabled &&
  testedViewsIsEnabled &&
  unTestedViewsIsEnabled &&
  testedElementsIsEnabled &&
  unTestedElementsIsEnabled

export const uiCovPath = (() => {
  if (testedViewsIsEnabled) {
    return `ui-coverage/${testedViewsPath}`
  }
  if (unTestedViewsIsEnabled) {
    return `ui-coverage/${unTestedLinksPath}`
  }
  if (testedElementsIsEnabled) {
    return `ui-coverage/${testedElementsPath}`
  }
  if (unTestedElementsIsEnabled) {
    return `ui-coverage/${unTestedElementsPath}`
  }

  return 'ui-coverage'
})()

export const returnToViewsPath = `../../${uiCovPath}`

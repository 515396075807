import { CustomIcon, Icon, StatusIcon } from '@frontend/design-system'
import { get } from 'lodash'
import React, { useEffect } from 'react'
import { features } from '~/lib/feature-flags'

import {
  SidebarProjectQuery,
  useSidebarLatestRunProjectStatusPollingQuery,
} from '~/graphql-codegen-operations.gen'

import { NavItem } from './nav-item'
import { NavLink } from './nav-link'
import { CurrentProject } from './types'
import OnboardingProgressBar from './OnboardingProgressBar'

type Organization = SidebarProjectQuery['project']['organizationInfo']

interface ProjectNavProps {
  currentProject: CurrentProject
  currentOrg: Organization
  isActiveView: boolean
}

const ProjectNav: React.FunctionComponent<ProjectNavProps> = ({
  currentProject,
  currentOrg,
  isActiveView,
}) => {
  const { id: projectId, name: projectName } = currentProject

  const { data, stopPolling, startPolling } =
    useSidebarLatestRunProjectStatusPollingQuery({
      variables: { projectId },
    })

  const isViewingPublicProject = currentOrg.__typename === 'PublicOrganization'
  const latestRunStatus = get(currentProject, ['latestRun', 'status'], '')
  const pollingProjectData = get(data, ['project'], { id: null })
  const pollingRunStatus = get(pollingProjectData, ['latestRun', 'status'], '')
  // https://github.com/apollographql/react-apollo/issues/2202
  const status =
    pollingProjectData.id === projectId ? pollingRunStatus : latestRunStatus

  useEffect(() => {
    isActiveView ? startPolling(30000) : stopPolling()
  }, [isActiveView, startPolling, stopPolling])

  return (
    <nav className="nav-container">
      {!isViewingPublicProject &&
        currentOrg &&
        currentOrg.hasFullCloudTrial &&
        features.isEnabled('updated-onboarding-ui') && (
          <div className="nav--org-onboarding">
            <NavItem
              hideLabel={true}
              label="trial-progress"
              to={`/organizations/${currentOrg.id}/trial-progress`}
            >
              <OnboardingProgressBar organizationId={currentOrg.id} />
            </NavItem>
          </div>
        )}

      <div className="nav--project-container">
        <div className="nav--project-name">
          <div className="nav--project-status">
            <StatusIcon
              status={status.toLowerCase()}
              data-cy="latest-run-icon"
              data-pendo="latest-run-icon"
            />
          </div>
          <span>{projectName}</span>
        </div>

        {!isViewingPublicProject && currentOrg && (
          <div className="nav--back-link">
            <NavLink
              to={`/organizations/${currentOrg.id}/projects`}
              role="link"
              data-cy="view-all-projects"
              data-pendo="view-all-projects"
            >
              <Icon name="caret-left" /> View all projects
            </NavLink>
          </div>
        )}
      </div>

      <ul className="nav-list" role="menu" aria-label={`${projectName}`}>
        <li role="none">
          <NavItem
            icon="check-circle"
            label="Latest runs"
            data-cy="latest-runs"
            data-pendo="latest-runs"
            to={`/projects/${projectId}/runs`}
          />
        </li>
        <li>
          <NavItem
            icon={
              <CustomIcon name="technology-branch-horizontal-tall" size="x16" />
            }
            label="Branches"
            data-cy="project-branches"
            data-pendo="project-branches"
            to={`/projects/${projectId}/branches`}
          />
        </li>
        <li role="none" className="settings-nav-item">
          <NavItem
            icon={
              <CustomIcon name="nav-analytics" size="x16" className="cy-icon" />
            }
            label="Analytics"
            data-cy="project-analytics"
            data-pendo="project-analytics"
            to={`/projects/${projectId}/analytics/runs-over-time`}
            as={`/projects/${projectId}/analytics`}
          />
          <ul className="nav-list">
            <li>
              <NavItem
                subnav
                label="Run status"
                to={`/projects/${projectId}/analytics/runs-over-time`}
              />
            </li>
            <li>
              <NavItem
                subnav
                label="Run duration"
                to={`/projects/${projectId}/analytics/run-duration`}
              />
            </li>
            <li>
              <NavItem
                subnav
                label="Test suite size"
                to={`/projects/${projectId}/analytics/test-suite-size`}
              />
            </li>
            <li>
              <NavItem
                subnav
                label="Top failures"
                to={`/projects/${projectId}/analytics/top-failures`}
              />
            </li>
            <li>
              <NavItem
                subnav
                label="Slowest tests"
                to={`/projects/${projectId}/analytics/slowest-tests`}
              />
            </li>
            <li>
              <NavItem
                subnav
                label="Most common errors"
                to={`/projects/${projectId}/analytics/most-common-errors`}
              />
            </li>
            <li>
              <NavItem
                subnav
                label="Flaky tests"
                to={`/projects/${projectId}/analytics/flaky-tests`}
              />
            </li>
          </ul>
        </li>
        {!isViewingPublicProject && (
          <>
            <li role="none" className="settings-nav-item">
              <NavItem
                icon={
                  <CustomIcon
                    name="nav-settings"
                    size="x16"
                    className="cy-icon"
                  />
                }
                label="Project settings"
                data-cy="project-settings"
                data-pendo="project-settings"
                to={`/projects/${projectId}/settings`}
              />
            </li>
          </>
        )}
      </ul>
    </nav>
  )
}

export { ProjectNav }

import debugLib from 'debug'
import path from 'path'
import stackTrace from 'stack-trace'

const pkgPath = path.join(__dirname, '../..')
const IS_PROD = process.env.NODE_ENV === 'production'
const sourceDirs = new Set(['dist', 'lib', 'src'])

/**
 * Debugs based on where the module is called from
 */
export function cyDebug(debugAll?: '*') {
  if (debugAll === '*') {
    return debugLib('*')
  }
  if (debugAll && IS_PROD) {
    throw new Error(
      `@packages/debug called with arg ${debugAll}, it should be called with * or nothing`
    )
  }
  try {
    const trace = stackTrace.get()
    const callerPath = getCallerPath(trace)
    const [pkgDir, ...rest] = callerPath.split('/')
    const toJoin = sourceDirs.has(rest[0]) ? rest.slice(1) : rest
    let nsp = `cy:${pkgDir}`
    // path.parse is not a function in webpack for some reason:
    // https://github.com/webpack/webpack/issues/3494
    if (typeof path.parse === 'function') {
      const { root, dir, name } = path.parse(toJoin.join('/'))
      nsp = `${nsp}:${path.format({ root, dir, name })}`
    }
    return debugLib(nsp)
  } catch (e) {
    // Quick fix, for the browser
    return debugLib('browser-error')
  }
}

const getCallerPath = (trace: stackTrace.StackFrame[]) => {
  const callerPathVal = trace[1]
    ? trace[1].getFileName()
    : path.join(__dirname, 'unknown')
  return path.relative(pkgPath, callerPathVal)
}

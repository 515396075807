import { Banner, Button, Icon, Panel } from '@frontend/design-system'
import comma from 'comma-number'
import { isFuture, parseISO } from 'date-fns'
import moment from 'moment'
import React, { FunctionComponent } from 'react'

import { useOverRecordingsNoticeQuery } from '~/graphql-codegen-operations.gen'
import { roleIsOwnerOrAdmin } from '../lib/roles'
import { TestRecording } from '../common/test-recording'
import { Link } from '@reach/router'

declare global {
  interface Window {
    BillingConfig: {
      hardUsageLimitsEnabled: boolean
      warningThreshold: number // 90 (NEAR_LIMIT_THRESHOLD)
      usageLimitThreshold: number // 100 (EXCEEDED_THRESHOLD)
    }
  }
}

type OverRecordingsNoticeProps = {
  asBanner?: boolean
  orgId: string
}

export const OverRecordingsNotice: FunctionComponent<
  OverRecordingsNoticeProps
> = ({ asBanner = false, orgId }) => {
  const HARD_USAGE_LIMITS_ENABLED = window.BillingConfig.hardUsageLimitsEnabled
  const EXCEEDED_LIMIT_THRESHOLD = window.BillingConfig.usageLimitThreshold
  const NEAR_LIMIT_THRESHOLD = window.BillingConfig.warningThreshold

  const { data } = useOverRecordingsNoticeQuery({
    variables: {
      orgId,
    },
  })

  const isPrivate =
    data?.organization.subscription.plan.usageCalculation === 'V0_PRIVATE_TESTS'
  const monthlyLimitTests = data?.organization.subscription.limits.tests || 0
  const monthlyUsedTests = data?.organization.usage.tests || 0
  const percentExceeded =
    monthlyLimitTests > 0 ? 100 * (monthlyUsedTests / monthlyLimitTests) : 0
  const usageResetDate = moment
    .utc(data?.organization.usage.whenUsageResets)
    .format('ll')
  const isOrgOnFreePlan = !data?.organization.subscription.plan.isPaid
  const isOrgInGracePeriod = data?.organization.gracePeriodUntil
    ? isFuture(parseISO(data.organization.gracePeriodUntil))
    : false
  const isUserAdmin = roleIsOwnerOrAdmin(orgId)

  const getMode = () => {
    if (isOrgInGracePeriod) {
      return 'UNDER_LIMIT'
    }

    if (isOrgOnFreePlan) {
      if (percentExceeded >= 100) {
        return 'FREE_PLAN_EXCEEDED'
      }

      if (percentExceeded >= NEAR_LIMIT_THRESHOLD) {
        return 'FREE_PLAN_NEARING_LIMITS'
      }
    }

    if (
      HARD_USAGE_LIMITS_ENABLED &&
      percentExceeded >= EXCEEDED_LIMIT_THRESHOLD
    ) {
      return 'PAID_PLAN_EXCEEDED_LIMITS'
    }

    if (HARD_USAGE_LIMITS_ENABLED && percentExceeded >= NEAR_LIMIT_THRESHOLD) {
      return 'NEARING_LIMITS'
    }

    return 'UNDER_LIMIT'
  }
  const mode = getMode()

  const variant =
    mode === 'PAID_PLAN_EXCEEDED_LIMITS' || mode === 'FREE_PLAN_EXCEEDED'
      ? 'danger'
      : 'warning'

  const getWarningSummary = () => {
    switch (getMode()) {
      case 'FREE_PLAN_EXCEEDED':
      case 'PAID_PLAN_EXCEEDED_LIMITS':
        return 'Plan Limit Reached'
      case 'FREE_PLAN_NEARING_LIMITS':
      case 'NEARING_LIMITS':
        return 'Nearing Plan Limits'
      case 'UNDER_LIMIT':
      default:
        return ''
    }
  }

  const isV2Plan =
    data?.organization.subscription.plan.usageCalculation === 'V2_ALL_TESTS'

  const shouldShowNotice = !(
    !monthlyUsedTests ||
    !monthlyLimitTests ||
    getMode() === 'UNDER_LIMIT' ||
    isV2Plan
  )

  const usageDetails = () => {
    return (
      <>
        You've reached{' '}
        <strong>
          {comma(Math.floor(percentExceeded))}% of your{' '}
          <span>
            {isPrivate && 'private '}
            <TestRecording label="test results" isV2Plan={isV2Plan} />
          </span>
        </strong>{' '}
        for this usage period.
      </>
    )
  }

  const impactDetails = () => {
    if (mode === 'NEARING_LIMITS' || mode === 'FREE_PLAN_NEARING_LIMITS') {
      return (
        <>
          At 100% usage, new runs will be recorded but their details will be
          hidden.
          <br />
          <br />
          To prevent this, you can upgrade your plan or wait until your usage
          period resets.
        </>
      )
    }

    return (
      <>
        Parallelization has been disabled and run details will be hidden.
        <br />
        <br />
        To re-enable parallelization and to see new runs, you can upgrade your
        plan or wait until your usage period resets.
      </>
    )
  }

  if (!shouldShowNotice) {
    return null
  }

  const isCustom = data?.organization.subscription.plan.isCustom

  const href = isCustom
    ? `/organizations/${orgId}/billing?contactSales=true`
    : `/organizations/${orgId}/pricing`

  const label = isCustom ? 'Contact Sales' : 'Upgrade'

  if (asBanner) {
    return (
      <Banner variant={variant} className="over-recordings-notice">
        <div>
          <strong>
            <Icon name="exclamation-triangle" /> {getWarningSummary()}:
          </strong>{' '}
          {usageDetails()}
        </div>

        {isUserAdmin ? (
          <Button
            bsStyle={variant}
            bsSize="small"
            className="btn-outline pull-right"
            href={href}
          >
            {label}
          </Button>
        ) : (
          <span> Contact your admin to upgrade your plan.</span>
        )}
      </Banner>
    )
  }

  return (
    <Panel bsStyle={variant} className="over-recordings-notice">
      <Panel.Heading>
        <Panel.Title>
          <i className="fa fa-exclamation-triangle" /> {getWarningSummary()}
        </Panel.Title>
      </Panel.Heading>

      <Panel.Body>
        <p style={{ marginBottom: 24 }}>
          {usageDetails()} {impactDetails()}
        </p>

        <div>
          <Link className={`btn btn-${variant}`} to={href}>
            {label}
          </Link>

          <span className="text-muted" style={{ marginLeft: 16 }}>
            Your usage period will automatically reset on{' '}
            <strong>{usageResetDate}</strong>.
          </span>
        </div>
      </Panel.Body>
    </Panel>
  )
}

import { Button, FormControl, Icon } from '@frontend/design-system/src'
import { Form, Formik } from 'formik'
import _ from 'lodash'
import React, { FunctionComponent, useState, useEffect } from 'react'
import * as yup from 'yup'
import {
  JiraIssueTypeFragment,
  useCreateJiraIssueMutation,
  useJiraFieldSearchQuery,
  useJiraIssueTypesQuery,
} from '~/graphql-codegen-operations.gen'
import styles from './module.CreateJiraIssuePrompt.scss'

import { FormGroupField } from '~/lib/form'
import { JiraSelect } from './JiraSelect'
import { LoadingContainer } from '~/project-analytics/LoadingContainer'
import store from '~/lib/store'

type CreateJiraIssueProps = {
  selectedProjectId: string
  summary: string
  updateShowModal: (v: boolean) => void
  specHash: string
  titleHash: string
  description: string
  screenshotId?: string
}

type JiraSelectOptionProps = {
  optionValues: { id: string; name: string }[]
  fieldKey: string
  fieldName: string
  handleChange: (v) => void
  handleInputChange?: (v) => void
  isSubmitting: boolean
  selectedValue: string
  isMulti: boolean
}

type JiraIssueFormValues = {
  summary: string
  description?: string
  issuetype?: string
  [key: string]: any
}

type FieldType = { key: string; value?: any; values?: any[] }

const validationSchema = {
  summary: yup.string().required('Summary is required'),
}

export const CreateJiraIssueForm: FunctionComponent<CreateJiraIssueProps> = ({
  selectedProjectId,
  summary,
  updateShowModal,
  specHash,
  titleHash,
  description,
  screenshotId,
}) => {
  const [initialValues, setInitialValues] = useState({})
  const [formikSchema, setFormikSchema] = useState({})
  const [selectedIssueType, setSelectedIssueType] =
    useState<JiraIssueTypeFragment>()
  const [searchOptions, setSearchOptions] = useState({})

  const { data, loading } = useJiraIssueTypesQuery({
    variables: {
      jiraProjectId: selectedProjectId,
    },
  })
  const [createJiraIssueMutation] = useCreateJiraIssueMutation({
    refetchQueries: ['DrawerJiraIssue', 'DrawerTestResultAnalyticJiraIssue'],
  })
  const { refetch } = useJiraFieldSearchQuery({
    skip: true,
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    const issueType = _.filter(
      data?.jiraIssueTypes,
      (issueType) => issueType.name === 'Bug'
    )
    if (issueType.length) {
      setSelectedIssueType(issueType[0])
    } else {
      setSelectedIssueType(data?.jiraIssueTypes[0])
    }
  }, [data])

  useEffect(() => {
    const values = {}
    const schema = {}

    selectedIssueType?.fields.forEach((field) => {
      if (field.defaultValue) {
        values[field.key] = field.defaultValue?.id || ''
      } else {
        values[field.key] = ''
      }

      if (field.isRequired) {
        schema[field.key] = yup.mixed().required(`${field.name} is required`)
      }
    })

    setInitialValues(values)
    setFormikSchema(schema)
  }, [selectedIssueType, summary])

  const handleSearch = (search, field) => {
    if (!search) {
      return
    }

    refetch({
      jiraProjectId: selectedProjectId,
      jiraAutoCompleteUrl: field.autoCompleteUrl!,
      search,
    }).then((res) => {
      if (res.data) {
        setSearchOptions({
          ...searchOptions,
          [field.key]: res.data.jiraFieldSearch,
        })
      }
    })
  }

  const handleSubmit = (values, { setSubmitting }) => {
    const fields: FieldType[] = []
    _.forEach(values, (value, key) => {
      if (key === 'summary' || key === 'description' || key === 'issuetype') {
        return
      }

      const fieldValue: FieldType = { key }
      if (_.isArray(value)) {
        fieldValue.values = value
      } else {
        fieldValue.value = value
      }

      fields.push(fieldValue)
    })

    createJiraIssueMutation({
      variables: {
        input: {
          specHash,
          titleHash,
          jiraProjectId: selectedProjectId,
          summary: values.summary,
          description: values.description,
          issuetype: selectedIssueType!.id,
          fields,
          screenshotId,
        },
      },
    })
      .then(({ data }) => {
        if (!data?.createJiraIssue) {
          store.setNotification(
            'There was an error creating the issue.',
            'failure'
          )
          return
        }
        store.setNotification('Issue was successfully created.')
        updateShowModal(false)
      })
      .catch((err) => {
        const graphQLError = err.graphQLErrors[0]

        if (graphQLError?.extensions?.code === 'JIRA_ISSUE_ERROR') {
          store.setNotification(graphQLError.message, 'failure')
        } else {
          store.setNotification(
            'There was an error creating the issue.',
            'failure'
          )
        }
        setSubmitting(false)
      })
  }

  const handleSelectChange = (field, value, setFieldValue) => {
    let newValue = value?.value
    if (field.isMulti) {
      newValue = _.map(value || [], (val) => val.value)
    }
    setFieldValue(field.key, newValue)
  }

  const issueTypesOptions = _.map(data?.jiraIssueTypes, (issueType) => ({
    label: issueType.name,
    value: issueType.id,
  }))

  return (
    <LoadingContainer active={loading}>
      <Formik<JiraIssueFormValues>
        validationSchema={() =>
          yup.object().shape({ ...validationSchema, ...formikSchema })
        }
        onSubmit={handleSubmit}
        enableReinitialize={true}
        initialValues={{ summary, description, ...initialValues }}
      >
        {({ isSubmitting, setFieldValue, values, isValid }) => (
          <Form>
            <FormGroupField<JiraIssueFormValues>
              data-cy="jira-issue-summary"
              className={styles.fullRowInput}
              disabled={isSubmitting}
              editable
              name="summary"
              label="Summary"
            />
            <FormGroupField<JiraIssueFormValues>
              data-cy="jira-issue-description"
              className={styles.fullRowInput}
              disabled={isSubmitting}
              editable
              name="description"
              label="Description"
              InputComponent={FormControl as any}
              InputProps={
                {
                  componentClass: 'textarea',
                  rows: 10,
                  onChange: (e) => setFieldValue('description', e.target.value),
                  value: values['description'],
                } as any
              }
            />
            <FormGroupField<JiraIssueFormValues>
              data-cy="jira-issue-type"
              disabled={isSubmitting}
              editable
              name="issuetype"
              label="Issue Type"
              InputComponent={() => (
                <JiraSelect
                  defaultOption={{
                    label: selectedIssueType?.name || '',
                    value: selectedIssueType?.id || '',
                  }}
                  options={issueTypesOptions}
                  onChange={(value) => {
                    const issueType = _.filter(
                      data?.jiraIssueTypes,
                      (issueType) => issueType.id === value.value
                    )

                    setSelectedIssueType(issueType[0])
                    setFieldValue('issuetype', value.value)
                  }}
                />
              )}
            />
            {_.map(selectedIssueType?.fields, (field) => {
              // TODO: Remove the URL string segment test once we fix label suggestion logic
              if (
                field.autoCompleteUrl &&
                !field.autoCompleteUrl.includes(`/rest/api/1.0/labels`)
              ) {
                return (
                  <JiraSelectOption
                    key={field.key}
                    optionValues={searchOptions[field.key]}
                    fieldKey={field.key}
                    fieldName={field.name}
                    isMulti={field.isMulti}
                    handleChange={(value) =>
                      handleSelectChange(field, value, setFieldValue)
                    }
                    isSubmitting={isSubmitting}
                    selectedValue={values[field.key]}
                    handleInputChange={(value) => handleSearch(value, field)}
                  />
                )
              }

              if (field.allowedValues) {
                return (
                  <JiraSelectOption
                    key={field.key}
                    optionValues={field.allowedValues}
                    fieldKey={field.key}
                    fieldName={field.name}
                    isMulti={field.isMulti}
                    handleChange={(value) =>
                      handleSelectChange(field, value, setFieldValue)
                    }
                    isSubmitting={isSubmitting}
                    selectedValue={values[field.key]}
                  />
                )
              }

              return (
                <FormGroupField<any>
                  data-cy={`jira-issue-${field.key}`}
                  key={field.key}
                  disabled={isSubmitting}
                  editable
                  name={field.key}
                  label={field.name}
                />
              )
            })}
            <div className={styles.submitContainer}>
              <Button
                bsStyle="primary"
                type="submit"
                disabled={!isValid || isSubmitting || loading}
                className={styles.submitBtn}
              >
                {isSubmitting && (
                  <span>
                    <Icon name="refresh" className="fa-spin" />{' '}
                  </span>
                )}
                Create issue
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </LoadingContainer>
  )
}

const JiraSelectOption: FunctionComponent<JiraSelectOptionProps> = ({
  optionValues,
  fieldKey,
  fieldName,
  handleChange,
  handleInputChange,
  isSubmitting,
  selectedValue,
  isMulti,
}) => {
  const options = _.map(optionValues, (allowedValue) => ({
    label: allowedValue.name,
    value: allowedValue.id,
  }))

  const keyedOptions = _.keyBy(options, 'value')

  return (
    <FormGroupField<JiraIssueFormValues, any>
      data-cy={`jira-issue-${fieldKey}`}
      disabled={isSubmitting}
      editable
      name={fieldKey}
      label={fieldName}
      InputComponent={JiraSelect}
      InputProps={
        {
          value: isMulti ? undefined : keyedOptions[selectedValue],
          options,
          onInputChange: handleInputChange,
          onChange: handleChange,
          isMulti,
        } as any
      }
    />
  )
}

import type { RestEndpointMethodTypes } from '@octokit/rest'
import type { GitlabStatusStates, BitbucketStatusStates } from '@packages/types'
import type { AllPossibleStatuses } from '../utils'

export function determineGithubStatus(
  status: AllPossibleStatuses
): RestEndpointMethodTypes['repos']['createCommitStatus']['parameters']['state'] {
  switch (status) {
    case 'passed':
    case 'noTests':
      return 'success'

    case 'failed':
    case 'cancelled':
    case 'timedOut':
      return 'failure'

    case 'errored':
      return 'error'

    default:
      return 'pending'
  }
}

export function determineGitlabStatus(
  status: AllPossibleStatuses
): GitlabStatusStates {
  switch (status) {
    case 'running':
      return 'running'

    case 'passed':
    case 'noTests':
      return 'success'

    case 'failed':
    case 'errored':
    case 'timedOut':
      return 'failed'

    case 'cancelled':
      return 'canceled'

    default:
      return 'pending'
  }
}

export function determineBitbucketStatus(
  status: AllPossibleStatuses
): BitbucketStatusStates {
  switch (status) {
    case 'running':
      return 'INPROGRESS'

    case 'passed':
    case 'noTests':
      return 'SUCCESSFUL'

    case 'failed':
    case 'errored':
    case 'timedOut':
      return 'FAILED'

    case 'cancelled':
      return 'STOPPED'

    default:
      return 'INPROGRESS'
  }
}

export function isValidCommitSha(commitSha: string) {
  return commitSha.length === 40 && !commitSha.includes('-')
}

/**
 * Remove extra white spaces, remove HTML URL Encoding References
 */
export function formatCommitSha(commitSha: string) {
  return commitSha.trim().split('%')[0]
}

export function getCommitShaFromMessage(commitMessage: string | null) {
  if (commitMessage && /Merge [a-f0-9]+ into [a-f0-9]+/.test(commitMessage)) {
    // Example Merge ab4e45cb4b68a8a701361d634fbd8a50567bafc8 into a834d5b1e3532313c9da5cc97db66e39ab451ba0
    const possibleCommitSha = formatCommitSha(commitMessage.split(' ')[1])
    if (possibleCommitSha.length === 40) {
      return possibleCommitSha
    }
  }
  return
}

export function getMergeRequestFromCommitMessage(commitMessage: string | null) {
  const possibleMergeRequestId = commitMessage?.match(
    /refs\/merge-requests\/(.*?)\/train/
  )

  if (possibleMergeRequestId?.length) {
    return possibleMergeRequestId[1]
  }

  // This can happen with gitlab merge results (https://docs.gitlab.com/ee/ci/pipelines/merged_results_pipelines.html)
  // Checking its behavior the commit message is auto generated by gitlab and it is something like
  // Merge branch 'test' into 'master'
  // Test
  // See merge request foo/cypress-example-kitchensink!1048
  if (commitMessage?.includes('See merge request ')) {
    const possibleMergeRequestId = commitMessage.split('!')
    return possibleMergeRequestId?.[possibleMergeRequestId.length - 1]
  }

  return
}

import React, { FunctionComponent } from 'react'
import { Panel, EmptyState } from '@frontend/design-system'
import { ColorBreakdown } from '~/common/color-breakdown/ColorBreakdown'
import { ColorBreakDownSectionType } from '~/common/color-breakdown/types'
import { MostCommonErrorsErrorClass } from './MostCommonErrorsErrorClass'
import { MostCommonErrorsEmptyState } from '../MostCommonErrorsEmptyState'

interface MostCommonErrorsBreakdownProps {
  isLoading: boolean
  errors: ColorBreakDownSectionType[]
  onSelect(errorClass: string): void
  selectedErrorClass?: string
  shouldUpdateCypressVersion: boolean
}

export const MostCommonErrorsBreakdown: FunctionComponent<
  MostCommonErrorsBreakdownProps
> = ({
  errors,
  isLoading,
  onSelect,
  selectedErrorClass,
  shouldUpdateCypressVersion,
}) => {
  return (
    <Panel className="analytic__chart">
      <Panel.Heading>
        <Panel.Title>Top error classes</Panel.Title>
      </Panel.Heading>
      <Panel.Body className="color-breakdown-container">
        {(!isLoading && !errors.length) || shouldUpdateCypressVersion ? (
          <EmptyState>
            <MostCommonErrorsEmptyState
              shouldUpdateCypressVersion={shouldUpdateCypressVersion}
            />
          </EmptyState>
        ) : (
          <>
            <ColorBreakdown
              onClick={onSelect}
              selected={selectedErrorClass}
              sections={errors}
            />
            {errors.map((error) => (
              <MostCommonErrorsErrorClass
                section={error}
                onClick={onSelect}
                key={error.id}
                isGreyedOut={Boolean(
                  selectedErrorClass && error.id !== selectedErrorClass
                )}
              />
            ))}
          </>
        )}
      </Panel.Body>
    </Panel>
  )
}

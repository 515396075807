export const calculateAdditionalTestUnits = ({
  used,
  limit,
  billingUnit,
}: {
  used: number
  limit: number
  billingUnit: number
}) => {
  const additionalTestRecordings = used - limit
  const additionalTestUnits = Math.max(
    Math.ceil(additionalTestRecordings / billingUnit),
    0
  )

  return { additionalTestRecordings, additionalTestUnits }
}

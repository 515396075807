import React, { FunctionComponent } from 'react'
import Select, { components } from 'react-select'
import cs from 'clsx'
import styles from './module.CreateJiraIssuePrompt.scss'

type SelectValue = {
  label: string
  value: string
}

type DefaultOption = SelectValue & {
  [key: string]: any
}

export type JiraSelectProps = {
  defaultOption: DefaultOption
  value?: SelectValue
  instanceId?: string
  isLoading?: boolean
  options: SelectValue[]
  placeholder?: string
  onChange: (value: any) => void
  onInputChange?: (value: string) => void
  suppressError?: boolean
  isMulti?: boolean
  label?: string
}

const JiraSelect: FunctionComponent<JiraSelectProps> = (props) => {
  const NoOptions = (optionProps) => (
    <components.NoOptionsMessage {...optionProps}>
      No results
    </components.NoOptionsMessage>
  )

  const Option = (optionProps) => {
    const { data, isDisabled } = optionProps

    return !isDisabled ? (
      <components.Option {...optionProps}>
        <div>{data.label}</div>
      </components.Option>
    ) : null
  }

  const SelectedOption = ({ data }) => {
    return <div>{data.label}</div>
  }

  return (
    <div
      className={cs({
        [styles.selectWithLabel]: Boolean(props.label),
      })}
    >
      {props.label && <span className={styles.label}>{props.label}</span>}

      <Select
        className="jira-autocomplete"
        classNamePrefix="jira-autocomplete"
        components={{
          NoOptionsMessage: NoOptions,
          Option,
          SingleValue: SelectedOption,
        }}
        value={props.value}
        defaultValue={props.defaultOption}
        instanceId={props.instanceId}
        isClearable={false}
        isLoading={props.isLoading}
        options={props.options}
        onChange={props.onChange}
        onInputChange={props.onInputChange}
        isMulti={props.isMulti}
        placeholder={<span>---</span>}
      />
    </div>
  )
}

export { JiraSelect }

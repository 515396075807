import { useEffect, useRef } from 'react'

export function useInterval(
  callback: () => void,
  delay: number,
  options: {
    enabled: boolean
  } = { enabled: true }
) {
  const savedCallback = useRef<() => void>(callback)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    if (options.enabled && delay !== null) {
      let exited = false
      const runCallback = () => {
        Promise.resolve(savedCallback.current()).then(() => {
          if (!exited) {
            timer = setTimeout(runCallback, delay)
          }
        })
      }
      let timer = setTimeout(runCallback, delay)
      return () => {
        exited = true
        if (timer) {
          clearTimeout(timer)
        }
      }
    }
    return undefined
  }, [options.enabled, delay])
}

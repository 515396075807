import ReactGA from 'react-ga'
import ReactGA4 from 'react-ga4'

import { isDeployedEnv, isEnv } from './utils'

const isTestMode = !isDeployedEnv()

const TRACKER_2_NAME = 'tracker2'

const TRACKER_2_G4_ID = 'G-66E86SXGKY'

export const trackerNames =
  isEnv('production') || isTestMode ? [TRACKER_2_NAME] : []

export const trackingIdG4 =
  isEnv('production') || isTestMode ? [TRACKER_2_G4_ID] : []

export const setupGa = () => {
  const trackingIds = [
    {
      trackingId: isEnv('production') ? 'UA-59606812-4' : 'UA-59606812-5',
      gaOptions: {
        alwaysSendReferrer: true,
        allowLinker: true,
      },
    },
  ]

  trackingIds.push({
    trackingId: 'UA-59606812-1',
    gaOptions: {
      alwaysSendReferrer: true,
      allowLinker: true,
      name: TRACKER_2_NAME,
    },
  })

  if (isTestMode) {
    window.__ReactGATestMode = ReactGA.testModeAPI
  }

  ReactGA.initialize(trackingIds, { testMode: isTestMode })
  ReactGA.ga('require', 'linker')
  ReactGA.ga('linker:autoLink', ['chargebee.com'])

  trackerNames.forEach((name) => {
    ReactGA.ga(`${name}.require`, 'linker')
    ReactGA.ga(`${name}.linker:autoLink`, ['chargebee.com'])
  })
}

export const setupGa4 = () => {
  const trackingIds = [
    {
      trackingId: isEnv('production') ? 'G-T7F16M70MW' : 'G-QBFF8RD6RP',
      gaOptions: {
        alwaysSendReferrer: true,
        allowLinker: true,
      },
    },
  ]

  trackingIds.push({
    trackingId: TRACKER_2_G4_ID,
    gaOptions: {
      alwaysSendReferrer: true,
      allowLinker: true,
    },
  })

  if (isTestMode) {
    window.__ReactGA4TestMode = ReactGA4._queueGtag
  }

  ReactGA4.initialize(trackingIds, {
    testMode: isTestMode,
  })

  // Start receiving data to figure out what was intended with this
  // possible changes: https://support.google.com/analytics/answer/10071811?hl=en
  // ReactGA4.ga('require', 'linker')
  // ReactGA4.ga('linker:autoLink', ['chargebee.com'])

  // trackerNames.forEach((name) => {
  //   ReactGA4.ga(`${name}.require`, 'linker')
  //   ReactGA4.ga(`${name}.linker:autoLink`, ['chargebee.com'])
  // })
}

import React from 'react'
import { Button, CustomIcon, Tooltip } from '@frontend/design-system'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useCopyText } from '~/lib/hooks/useCopyText'

export const CliCommandSample: React.FC<{
  command: string
  copyBtnPendo?: string
  overlayDefaultText?: string
}> = ({ command, overlayDefaultText = 'Copy command', copyBtnPendo }) => {
  const copiedOverlay = 'Copied!'
  const [overlayText, setOverlayText] = React.useState(overlayDefaultText)
  const { onCopy } = useCopyText()

  return (
    <div className="code-sample">
      <div className="decorative-icons">
        <CustomIcon
          className="code-icon"
          name="technology-terminal"
          size="x16"
        />
        <span className="command-prompt">$</span>
      </div>
      <pre>
        <code>{command}</code>
      </pre>
      <Tooltip
        placement="top"
        overlay={<p style={{ marginBottom: -10 }}>{overlayText}</p>}
        onVisibleChange={() => setOverlayText(overlayDefaultText)}
      >
        <CopyToClipboard
          text={command}
          onCopy={() => {
            onCopy()
            setOverlayText(copiedOverlay)
          }}
        >
          <Button
            className="cli-sample-copy-btn"
            data-pendo={copyBtnPendo}
            data-cy="cli-sample-copy-btn"
          >
            <CustomIcon
              className="copy-icon"
              name="general-clipboard"
              size="x16"
            />{' '}
            <span className="copy-text">Copy</span>
          </Button>
        </CopyToClipboard>
      </Tooltip>
    </div>
  )
}

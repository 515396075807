import { FunctionComponent } from 'react'
import { RouteComponentProps } from '@reach/router'

type ProjectAnalyticsProps = {
  children: any
} & RouteComponentProps

export const ProjectAnalytics: FunctionComponent<ProjectAnalyticsProps> = ({
  children,
}) => {
  return children
}

import { isNil, isNumber } from 'lodash'
import React, { FunctionComponent } from 'react'
import styles from './module.Percentage.scss'

type Percentage = number | '-' | null

export type PercentageProps = {
  percentage?: Percentage
  color?: string
}

const formatPercentage = (percentage: number | '-') => {
  if (percentage === '-') {
    return percentage
  }

  /* istanbul ignore next */
  if (percentage > 100) {
    return 100
  }

  /* istanbul ignore next */
  if (percentage < 0) {
    return 0
  }

  return percentage
}

export const Percentage: FunctionComponent<PercentageProps> = ({
  percentage,
  color,
}) => {
  /* istanbul ignore next */
  if (isNil(percentage)) {
    return null
  }

  const formattedPercentage = formatPercentage(percentage)

  return (
    <p className={styles.percentage} style={{ color }}>
      {isNumber(formattedPercentage) ? `${formattedPercentage}%` : '—'}
    </p>
  )
}

import React, { FunctionComponent } from 'react'
import { Button, CustomIcon } from '@frontend/design-system'

type IntegrationBranchProps = {
  updateShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const IntegrationBranchPrompt: FunctionComponent<
  IntegrationBranchProps
> = ({ updateShowModal }) => {
  return (
    <>
      <div className="test-trend-branch">
        <div className="test-trend-branch__branch-container">
          <CustomIcon
            name="add"
            variant="small"
            className="test-trend-branch__add-integration-branch"
            onClick={() => updateShowModal(true)}
          />
          <Button
            className="test-trend-branch__add-integration-branch-btn"
            bsStyle="link"
            onClick={() => updateShowModal(true)}
          >
            Compare to another branch
          </Button>
        </div>
      </div>
    </>
  )
}

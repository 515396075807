import React, { FunctionComponent } from 'react'
import { CustomIcon } from './custom-icons/CustomIcon'
import styles from './module.RunStatsV2.scss'

interface RunStatsV2Props {
  failed: number
  passed: number
  skipped: number
}

/**
 * Altered version of the older RunStats component that differs in the following ways:
 * - Failed tests are shown first
 * - Icons are thinner in the new view
 * - Does not show "--" for unset values
 */
export const RunStatsV2: FunctionComponent<RunStatsV2Props> = ({
  failed,
  passed,
  skipped,
}) => {
  return (
    <div className={styles.container} data-cy="spec-summary-stats">
      <ul>
        <li className={styles.runStat} data-cy="spec-failed-count">
          <div>
            <CustomIcon name="status-failed-circle-outline" size="x12" />
          </div>
          {`${failed}`}
        </li>
        <li className={styles.runStat} data-cy="spec-passed-count">
          <div>
            <CustomIcon name="status-passed-circle-solid" size="x12" />
          </div>
          {`${passed}`}
        </li>
        <li className={styles.runStat} data-cy="spec-pending-count">
          <div>
            <CustomIcon name="status-pending" size="x12" />
          </div>
          {`${skipped}`}
        </li>
      </ul>
    </div>
  )
}

import * as React from 'react'
import { DangerModal, DangerModalConfig } from './DangerModal'
import {
  PreconditionsModalConfig,
  PreconditionsModal,
} from './PreconditionsModal'

type CustomVariants = DangerModalConfig | PreconditionsModalConfig

type ConfirmationOptions = {
  /* Reject promise when closing dialog */
  catchOnCancel?: boolean
} & CustomVariants

const ConfirmationServiceContext = React.createContext<
  (options: ConfirmationOptions) => Promise<void>
>(Promise.reject)

export const useConfirmationService = () =>
  React.useContext(ConfirmationServiceContext)

export const ConfirmationServiceProvider: React.FC = ({ children }) => {
  const awaitingPromiseRef = React.useRef<{
    resolve: () => void
    reject: () => void
  }>()

  const [confirmationState, setConfirmationState] =
    React.useState<ConfirmationOptions | null>(null)

  const openConfirmation = React.useCallback((options: ConfirmationOptions) => {
    setConfirmationState(options)
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject }
    })
  }, [])

  const handleClose = React.useCallback(() => {
    if (
      awaitingPromiseRef.current &&
      confirmationState &&
      confirmationState.catchOnCancel // mostly always we don't want to handle closing dialogs
    ) {
      awaitingPromiseRef.current.reject()
    }

    setConfirmationState(null)
  }, [confirmationState])

  const handleSubmit = React.useCallback(() => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve()
    }

    setConfirmationState(null)
  }, [])

  const modalDialog = React.useMemo(() => {
    if (!confirmationState) {
      return null
    }

    const modalProps = {
      onClose: handleClose,
      onSubmit: handleSubmit,
    }

    switch (confirmationState.variant) {
      case 'preconditions':
        return <PreconditionsModal {...confirmationState} {...modalProps} />
      case 'danger':
        return <DangerModal {...confirmationState} {...modalProps} />
      default:
        return null
    }
  }, [confirmationState, handleClose, handleSubmit])

  return (
    <>
      <ConfirmationServiceContext.Provider
        value={openConfirmation}
        children={children}
      />

      {modalDialog}
    </>
  )
}

import { filter, map, sumBy } from 'lodash'
import React, { FunctionComponent } from 'react'
import { Button, palette } from '@frontend/design-system'
import { useHover } from '~/lib/hooks/useHover'
import { ColorBreakDownSectionType } from './types'

interface ColorBreakdownSectionProps extends ColorBreakDownSectionType {
  isGreyedOut: boolean
  onClick(selected: string): void
}

export const ColorBreakdownSection: FunctionComponent<
  ColorBreakdownSectionProps
> = ({ id, color, hoverColor, onClick, isGreyedOut, width }) => {
  const [hoverRef, isHovered] = useHover<HTMLDivElement>()
  const backgroundColor = (() => {
    if (isGreyedOut) {
      return palette.gray100
    }

    if (isHovered) {
      return hoverColor
    }

    return color
  })()

  return (
    <Button
      key={id}
      onClick={() => onClick(id)}
      bsStyle="link"
      className="color-breakdown__section"
      style={{
        backgroundColor,
        width: `${width}fr`,
      }}
    >
      <span className="color-breakdown__hover-ref" ref={hoverRef} />
    </Button>
  )
}

interface ColorBreakdownProps {
  selected?: string
  sections: ColorBreakDownSectionType[]
  onClick(selected: string): void
}

export const ColorBreakdown: FunctionComponent<ColorBreakdownProps> = ({
  sections,
  onClick,
  selected,
}) => {
  const nonZeroSections = filter(sections, function (section) {
    return section.total > 0
  })

  const totalOfAllSections = sumBy(nonZeroSections, 'total')

  let gridTemplateColumns = ''
  map(nonZeroSections, (section) => {
    section.width = Math.max(section.total, totalOfAllSections * 0.01) // make each section at least (approx) 1% wide
    gridTemplateColumns += `${section.width}fr `
  })

  return (
    <div
      className="color-breakdown"
      style={{
        gridTemplateColumns,
      }}
    >
      {nonZeroSections.map((section) => (
        <ColorBreakdownSection
          key={section.id}
          {...section}
          isGreyedOut={Boolean(selected && section.id !== selected)}
          onClick={onClick}
        />
      ))}
    </div>
  )
}

import { IconButton } from '@frontend/design-system/src'
import React, { FunctionComponent } from 'react'
import { OverlayPopoverPlanFragment } from '~/graphql-codegen-operations.gen'
import OverlayCTA from '~/common/overlay-cta/OverlayCTA'
import styles from './module.TestResultDrawerActionBar.scss'

type ActionBarJiraIssueProps = {
  onJiraIssueClick: () => void
  isAvailableOnCurrentPlan?: boolean
  isInstalled?: boolean
  plan?: OverlayPopoverPlanFragment
  orgId: string
  viewerIsOwnerOrAdmin?: boolean
}

export const ActionBarJiraIssue: FunctionComponent<ActionBarJiraIssueProps> = ({
  onJiraIssueClick,
  plan,
  isAvailableOnCurrentPlan = false,
  isInstalled,
  orgId,
  viewerIsOwnerOrAdmin,
}) => {
  if (isAvailableOnCurrentPlan && !isInstalled) {
    return (
      <OverlayCTA
        plan={plan}
        placement="bottom"
        orgId={orgId}
        title="Install the Jira integration to create an issue"
        description="Seamlessly track and manage failed or flaky tests with the Cypress integration for JIRA."
        viewerIsOwnerOrAdmin={viewerIsOwnerOrAdmin}
        buttonLink={`/organizations/${orgId}/integrations`}
        buttonText="Install"
      >
        <div>
          <IconButton
            icon="technology-atlassian-jira"
            isCircle
            onClick={onJiraIssueClick}
            text="Create issue"
            disabled
            data-cy="action-bar-jira"
            className={styles.jiraIcon}
          />
        </div>
      </OverlayCTA>
    )
  }

  return (
    <OverlayCTA
      plan={plan}
      placement="bottom"
      orgId={orgId}
      learnMoreUri="https://on.cypress.io/jira-integration"
      title="Create Jira issue"
      description={
        <span>
          Push ready-to-work Jira issues to your development backlog. Available
          on <b>Team</b> plans.
        </span>
      }
      hideOverlay={isAvailableOnCurrentPlan}
      viewerIsOwnerOrAdmin={viewerIsOwnerOrAdmin}
    >
      <div>
        <IconButton
          icon="technology-atlassian-jira"
          isCircle
          onClick={onJiraIssueClick}
          text="Create issue"
          disabled={!isAvailableOnCurrentPlan}
          data-cy="action-bar-jira"
          className={styles.jiraIcon}
        />
      </div>
    </OverlayCTA>
  )
}

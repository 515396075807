import { atom, atomFamily } from 'recoil'
import {
  ProjectRunDurationsOverTimeQuery,
  ProjectRunsOverTimeQuery,
  ProjectSlowestTestsQuery,
  ProjectTestSuiteSizeOverTimeQuery,
  ProjectTopFailuresQuery,
  ProjectFlakyTestsQuery,
  ProjectMostCommonErrorsQuery,
  TimeIntervalEnum,
  TimeRangeInput,
  ViewByEnum,
  ProjectRunDurationsOverRunsQuery,
  TagsMatchEnum,
} from '~/graphql-codegen-operations.gen'

import { MultiSelectOptionType, TagEditorInfo } from '~/common/filters'
import { AnalyticsFilters } from './types'

// Specific
// Branches // Detailed
export const branchesSearch = atomFamily<string | undefined, string>({
  key: 'branchesSearch',
  default: undefined,
})
export const branchesSearchLoading = atomFamily<boolean, string>({
  key: 'branchesSearchLoading',
  default: false,
})
export const branchesSelected = atomFamily<
  MultiSelectOptionType[] | undefined,
  string
>({
  key: 'branchesSelected',
  default: undefined,
})
export const branchesOptions = atomFamily<
  MultiSelectOptionType[] | undefined,
  string
>({
  key: 'branchesOptions',
  default: undefined,
})
export const branchesDefault = atomFamily<
  MultiSelectOptionType[] | undefined,
  string
>({
  key: 'branchesDefault',
  default: undefined,
})

// Tags // Detailed
export const tagsSelected = atomFamily<
  MultiSelectOptionType<string, { color: string }>[] | undefined,
  string
>({
  key: 'tagsSelected',
  default: undefined,
})
export const tagsOptions = atomFamily<
  MultiSelectOptionType<string, { color: string }>[] | undefined,
  string
>({
  key: 'tagsOptions',
  default: undefined,
})
export const tagsSearch = atomFamily<string | undefined, string>({
  key: 'tagsSearch',
  default: undefined,
})
export const tagsSearchLoading = atomFamily<boolean, string>({
  key: 'tagsSearchLoading',
  default: false,
})
export const tagsEditing = atomFamily<boolean, string>({
  key: 'tagsEditing',
  default: false,
})
export const tagsEditorInfo = atomFamily<TagEditorInfo | undefined, string>({
  key: 'tagsEditorInfo',
  default: undefined,
})
export const tagsMatch = atomFamily<TagsMatchEnum | undefined, string>({
  key: 'tagsMatch',
  default: 'ANY',
})

// Run Group // Detailed
export const runGroupsSelected = atomFamily<
  MultiSelectOptionType[] | undefined,
  string
>({
  key: 'runGroupsSelected',
  default: undefined,
})
export const runGroupsOptions = atomFamily<
  MultiSelectOptionType[] | undefined,
  string
>({
  key: 'runGroupsOptions',
  default: undefined,
})
export const runGroupsSearch = atomFamily<string | undefined, string>({
  key: 'runGroupsSearch',
  default: undefined,
})
export const runGroupsSearchLoading = atomFamily<boolean, string>({
  key: 'runGroupsSearchLoading',
  default: false,
})

// Committer // Detailed
export const committersSelected = atomFamily<
  MultiSelectOptionType[] | undefined,
  string
>({
  key: 'committersSelected',
  default: undefined,
})
export const committersOptions = atomFamily<
  MultiSelectOptionType[] | undefined,
  string
>({
  key: 'committersOptions',
  default: undefined,
})
export const committersSearch = atomFamily<string | undefined, string>({
  key: 'committersSearch',
  default: undefined,
})
export const committersSearchLoading = atomFamily<boolean, string>({
  key: 'committersSearchLoading',
  default: false,
})

// Spec Files
export const specFilesSelected = atomFamily<
  MultiSelectOptionType[] | undefined,
  string
>({
  key: 'specFilesSelected',
  default: undefined,
})
export const specFilesOptions = atomFamily<
  MultiSelectOptionType[] | undefined,
  string
>({
  key: 'specFilesOptions',
  default: undefined,
})

// Browsers
export const browsersSelected = atomFamily<
  MultiSelectOptionType[] | undefined,
  string
>({
  key: 'browsersSelected',
  default: undefined,
})
export const browsersOptions = atomFamily<
  MultiSelectOptionType[] | undefined,
  string
>({
  key: 'browsersOptions',
  default: undefined,
})

// Cypress Versions
export const cypressVersionsSelected = atomFamily<
  MultiSelectOptionType[] | undefined,
  string
>({
  key: 'cypressVersionsSelected',
  default: undefined,
})
export const cypressVersionsOptions = atomFamily<
  MultiSelectOptionType[] | undefined,
  string
>({
  key: 'cypressVersionsOptions',
  default: undefined,
})

// OperatingSystem
export const operatingSystemsSelected = atomFamily<
  MultiSelectOptionType[] | undefined,
  string
>({
  key: 'operatingSystemsSelected',
  default: undefined,
})
export const operatingSystemsOptions = atomFamily<
  MultiSelectOptionType[] | undefined,
  string
>({
  key: 'operatingSystemsOptions',
  default: undefined,
})

// Flaky
export const flakySelected = atomFamily<
  MultiSelectOptionType[] | undefined,
  string
>({
  key: 'flakySelected',
  default: undefined,
})

// Time Interval
export const timeInterval = atomFamily<TimeIntervalEnum | undefined, string>({
  key: 'analyticsTimeIntervalFilter',
  default: undefined,
})

// Build Interval
export const buildInterval = atomFamily<number | undefined, string>({
  key: 'analyticsBuildIntervalFilter',
  default: undefined,
})

// Time Range
export const timeRange = atomFamily<TimeRangeInput | undefined, string>({
  key: 'analyticsTimeRangeFilter',
  default: undefined,
})

// View By
export const viewBy = atomFamily<ViewByEnum, string>({
  key: 'viewByFilter',
  default: 'TEST_CASE',
})

// Status
export const status = atomFamily<MultiSelectOptionType[] | undefined, string>({
  key: 'statusFilter',
  default: undefined,
})

// Chart Range Top Failures
export const chartRangeTopFailures = atomFamily<number[] | undefined, string>({
  key: 'chartRangeTopFailuresFilter',
  default: undefined,
})

// Chart Range Slowest Tests
export const chartRangeSlowestTests = atomFamily<number[] | undefined, string>({
  key: 'chartRangeSlowestTestsFilter',
  default: undefined,
})

// Chart Range Most Common Errors
export const chartRangeMostCommonErrors = atomFamily<
  string[] | undefined,
  string
>({
  key: 'chartRangeMostCommonErrorsFilter',
  default: undefined,
})

// Chart Range Flaky test
export const chartFlakyTests = atomFamily<
  [Date | number, Date | number] | [] | undefined,
  string
>({
  key: 'chartFlakyTestsFilter',
  default: undefined,
})

// UTILS ////////////////////////////////////////////////////////////
export const filtersDefault = atomFamily<AnalyticsFilters, string>({
  key: 'analyticsFiltersDefaultValue',
  default: {
    branches: undefined,
    timeRange: undefined,
    timeInterval: undefined,
    buildInterval: undefined,
    tags: undefined,
    tagsMatch: undefined,
    specFiles: undefined,
    runGroups: undefined,
    browsers: undefined,
    committers: undefined,
    cypressVersions: undefined,
    operatingSystems: undefined,
    viewBy: undefined,
    status: undefined,
    chartRangeTopFailures: undefined,
    chartRangeSlowestTests: undefined,
    chartRangeMostCommonErrors: undefined,
    chartFlakyTests: undefined,
  },
})

// PAGE DATA
export const runDurationsOverTime = atom<
  | ProjectRunDurationsOverTimeQuery
  | ProjectRunDurationsOverRunsQuery
  | undefined
>({
  key: 'analyticsRunDurationsOverTimeQuery',
  default: undefined,
})
export const runsOverTime = atom<ProjectRunsOverTimeQuery | undefined>({
  key: 'analyticsRunsOverTimeQuery',
  default: undefined,
})
export const testSuiteSizeOverTime = atom<
  ProjectTestSuiteSizeOverTimeQuery | undefined
>({
  key: 'analyticsTestSuiteSizeOverTimeQuery',
  default: undefined,
})
export const topFailures = atom<ProjectTopFailuresQuery | undefined>({
  key: 'analyticsTopFailuresQuery',
  default: undefined,
})
export const slowestTests = atom<ProjectSlowestTestsQuery | undefined>({
  key: 'analyticsSlowestTestsQuery',
  default: undefined,
})
export const mostCommonErrors = atom<ProjectMostCommonErrorsQuery | undefined>({
  key: 'analyticsMostCommonErrorsQuery',
  default: undefined,
})
export const flakyTests = atom<ProjectFlakyTestsQuery | undefined>({
  key: 'analyticsFlakyTestsQuery',
  default: undefined,
})

// INITIALIZED
export const filtersInitialized = atomFamily<boolean, string>({
  key: 'filtersInitialized',
  default: false,
})
export const filterDefaultValuesInitialized = atomFamily<boolean, string>({
  key: 'filterDefaultValuesInitialized',
  default: false,
})
export const filterOptionsInitialized = atomFamily<boolean, string>({
  key: 'filterOptionsInitialized',
  default: false,
})

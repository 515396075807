import cs from 'clsx'
import React, { FC } from 'react'
import { truncateWordByChars } from '../lib/utils'
import { Tooltip } from '@frontend/design-system'

interface SpecNameProps {
  spec: string
  className?: string
}

const MAX_SPEC_CHARS = 80

const SpecName: FC<SpecNameProps> = ({ className, spec }) => {
  const displaySpecName = spec ? truncateWordByChars(spec, MAX_SPEC_CHARS) : ''

  return (
    <span className={cs(className)}>
      {displaySpecName.length > MAX_SPEC_CHARS ? (
        <Tooltip placement="top" overlay={<pre>{spec}</pre>}>
          <span>{displaySpecName}</span>
        </Tooltip>
      ) : (
        displaySpecName
      )}
    </span>
  )
}

export default SpecName

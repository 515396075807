import cs from 'clsx'
import React, { FunctionComponent, ReactNode } from 'react'

type DrawerProps = {
  isOpen: boolean
  children: ReactNode
}

export const Drawer: FunctionComponent<DrawerProps> = ({
  isOpen,
  children,
}) => {
  return (
    <div
      aria-hidden={!isOpen}
      className={cs('drawer', { 'drawer--open': isOpen })}
    >
      {children}
    </div>
  )
}

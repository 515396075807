import React from 'react'

import { SidebarNavLoader } from './SidebarNavLoader'
import { SidebarOrgSwitcherLoader } from './SidebarOrgSwitcherLoader'

export const SidebarLoader = () => (
  <>
    <SidebarOrgSwitcherLoader />
    <SidebarNavLoader />
    <div />
  </>
)

import React, { FunctionComponent } from 'react'
import cs from 'clsx'
import Button from '@cypress-design/react-button'
import { CustomIcon, Tooltip } from '@frontend/design-system'
import { SingleSelect } from '@frontend/design-system/src/components/Select/Select'
import { TestReplayOverlay } from '~/common/testReplayOverlay/TestReplayOverlay'
import { isTestReplayModalPresentable } from '~/common/test-replay/testReplayUtils'
import { TestReplayOverlayFragment } from '../../graphql-codegen-operations.gen'
import {
  IconActionTestReplay,
  IconChevronRightSmall,
} from '@cypress-design/react-icon'

import styles from './module.ArtifactControls.scss'

interface ArtifactControlsProps {
  disableAll?: boolean
  onScreenshotClick?: () => void
  onOutputClick?: () => void
  onVideoClick?: () => void
  onReplayClick?: () => void
  onChevronClick?: () => void
  frag?: TestReplayOverlayFragment
  variant?: 'large' | 'small'
  chevronContent?: React.ReactNode
  forceHideChevron?: boolean
  forceHideOutput?: boolean
  forceHideMoreDropdown?: boolean
}

const OverageTooltip: React.FC<{
  children: React.ReactNode
}> = ({ children }) => (
  <Tooltip
    placement="left"
    overlay={
      <span data-cy="artifact-controls-over-limit-tooltip">
        You've reached the usage limit for your plan.
      </span>
    }
  >
    {children}
  </Tooltip>
)

function selectItemFactory({
  value,
  icon,
  onClick,
  className,
  dataCy,
}: {
  value: string
  icon: string
  onClick: () => void
  className: string
  dataCy: string
}) {
  const IconComponent: React.FC<{}> = () => {
    return (
      <div
        onClick={onClick}
        className={cs(styles['select-option'], className)}
        data-cy={dataCy}
      >
        <CustomIcon name={icon} size="x16" />
        {value}
      </div>
    )
  }
  return IconComponent
}

export const ArtifactControls: FunctionComponent<ArtifactControlsProps> = ({
  disableAll: disableAllProp,
  onScreenshotClick,
  onOutputClick,
  onVideoClick,
  onReplayClick,
  onChevronClick,
  frag,
  variant = 'large',
  chevronContent,
  forceHideOutput,
  forceHideChevron,
  forceHideMoreDropdown = false,
  ...rest
}) => {
  const isPastLimits =
    frag?.run.isHiddenByUsageLimits || frag?.run.isPastDataRetention
  const disableAll = disableAllProp || isPastLimits

  const ARTIFACTS_OPTS = forceHideMoreDropdown
    ? []
    : [
        {
          value: 'Video',
          icon: 'action-play-video',
          onClick: () => {
            if (!disableAll && onVideoClick) onVideoClick()
          },
          className:
            disableAll || !onVideoClick
              ? styles.disabledVideoArtifact
              : styles.videoArtifact,
          dataCy: 'artifact-video',
          isDisabled: Boolean(disableAll || !onVideoClick),
        },
        {
          value: 'Screenshots',
          icon: 'technology-image-screenshot',
          onClick: () => {
            if (!disableAll && onScreenshotClick) onScreenshotClick()
          },
          className:
            disableAll || !onScreenshotClick ? styles.disabledArtifact : '',
          dataCy: 'artifact-screenshot',
          isDisabled: Boolean(disableAll || !onScreenshotClick),
        },
      ]

  if (!forceHideOutput && !forceHideMoreDropdown) {
    ARTIFACTS_OPTS.push({
      value: 'Output',
      icon: 'technology-terminal-log',
      onClick: () => {
        if (!disableAll && onOutputClick) onOutputClick()
      },
      className: disableAll || !onOutputClick ? styles.disabledArtifact : '',
      dataCy: 'artifact-output',
      isDisabled: Boolean(disableAll || !onOutputClick),
    })
  }

  const options = ARTIFACTS_OPTS.map((v) => ({
    CustomListItem: selectItemFactory({
      value: v.value,
      icon: v.icon,
      onClick: v.onClick,
      className: v.className,
      dataCy: v.dataCy,
    }),
    value: v,
  }))

  const disableTestReplayBtn =
    disableAll || !onReplayClick || !isTestReplayModalPresentable(frag?.capture)

  // By default the Designs system button size is 40px
  const buttonSize = variant === 'small' ? '24' : '32'

  const content = (
    <ul
      data-cy={rest['data-cy']}
      className={cs(styles.artifactControls, styles[`variant-${variant}`])}
    >
      <li>
        <TestReplayOverlay frag={frag}>
          <Button
            variant="white"
            size={buttonSize}
            className={cs('gap-[8px]')}
            onClick={(e) => {
              e.stopPropagation()
              if (!disableTestReplayBtn && onReplayClick) {
                onReplayClick()
              }
            }}
            data-cy="artifact-controls_replay"
            disabled={disableTestReplayBtn}
            square={variant !== 'large'}
          >
            <IconActionTestReplay
              className="shrink-0"
              strokeColor={
                variant === 'small'
                  ? // in "small" variant, the active icon is gray-600. We have to force
                    // it to be lighter when disabled
                    disableTestReplayBtn
                    ? 'gray-300'
                    : 'gray-600'
                  : 'current'
              }
            />
            {variant === 'large' ? 'Test Replay' : undefined}
          </Button>
        </TestReplayOverlay>
      </li>
      {!forceHideMoreDropdown && (
        <li className={styles.moreSelect} data-cy="more-button">
          <SingleSelect
            placeholder={
              <div
                data-cy="artifact-controls_more"
                style={{
                  height: '20px',
                  width: '20px',
                  color: '#747994',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CustomIcon name="more-message" />
              </div>
            }
            removeDropdownIndicator
            customListItems
            removeListShadow
            options={options}
            menuPlacement="bottom"
            onClickOption={(e) => {
              e.event.stopPropagation()
              e.nativeOnClick(e.event)
            }}
            theme={(defaults) => ({
              ...defaults,
              spacing: {
                ...defaults.spacing,
                controlHeight: 28,
              },
            })}
            styles={{
              menu: (styles) => ({
                ...styles,
                width: '130px',
                right: '0',
              }),
            }}
          />
        </li>
      )}
      {!forceHideChevron && (
        <li>
          <Button
            variant="white"
            size={buttonSize}
            className={cs('gap-[8px]', styles.squareButton, {
              'bg-white': !onChevronClick,
            })}
            disabled={!onChevronClick}
            onClick={onChevronClick}
            data-cy="artifact-controls_chevron"
            square
          >
            {chevronContent ? (
              chevronContent
            ) : (
              <IconChevronRightSmall
                className="shrink-0"
                strokeColor={variant === 'small' ? 'gray-600' : 'current'}
              />
            )}
          </Button>
        </li>
      )}
    </ul>
  )

  return isPastLimits ? <OverageTooltip>{content}</OverageTooltip> : content
}
